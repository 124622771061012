<template>
  <div>
    <v-snackbar v-model="snackbar" timeout="5000" color="green lighten-1">
      You will receive an email with a link to your program information shortly.
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarSave" timeout="2000" color="green lighten-1">
      Program Filter Saved!
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <ConfirmInsetOrderModal
      v-if="showModal"
      :filterParams="filterParams"
      :headers="headers"
      :items="items"
      :currentOrderName="currentOrderName"
      :currentOrderID="currentOrderID"
      :currentSupplyTarget="currentSupplyTarget"
      :currentAggregationMetric="currentAggregationMetric"
      :currentOrderPrice="currentOrderPrice"
      @close-modal="handleCloseModal"
      @save-from-modal="handleSaveModal"
    />

    <ConfirmInsetOrderModal
      v-if="showCloneModal"
      :filterParams="filterParams"
      :headers="headers"
      :items="items"
      :currentSupplyTarget="currentSupplyTarget"
      :currentAggregationMetric="currentAggregationMetric"
      :currentOrderPrice="currentOrderPrice"
      @close-modal="handleCloseModal"
      @save-from-modal="handleSaveModal"
    />

    <ExecuteInsetOrderModal
      v-if="showExecuteModal"
      :filterParams="filterParams"
      :headers="headers"
      :items="items"
      :currentOrderName="currentOrderName"
      :currentOrderID="currentOrderID"
      @close-modal="showExecuteModal = false"
      @save-from-modal="handleExecuteModal"
    />

    <ConfirmDeleteInsetOrderModal
      v-if="currentOrderID && showDeleteModal"
      :currentOrderName="currentOrderName"
      :currentOrderID="currentOrderID"
      @close-modal="handleCloseDeleteModal"
      @confirm-delete-modal="handleConfirmDeleteModal"
    />
    <Permissions package="sustainability" :superUserOnly="true" />

    <div v-if="showSustainability && isSuperuser" class="row reverse-margin">
      <div class="col scroll-col">
        <!-- button container -->
        <div class="d-flex mb-3">
          <DashboardTitle
            title="Scope 3 Emission Supply Search"
            faClass="fa-globe"
          />
        </div>

        <v-card>
          <v-toolbar flat>
            <div v-if="tab == 0">
              <div v-if="currentOrderName">
                <h5>
                  <span>
                    Viewing Order:
                    <v-chip color="green lighten-1">
                      {{ orderShortText }} ({{ currentOrderID }})
                    </v-chip>
                  </span>
                  <v-btn icon @click="handleDeselectOrder"
                    ><v-icon color="red" small>mdi-close-circle</v-icon></v-btn
                  >
                </h5>
              </div>
              <div v-else>
                <h5>Building New Order</h5>
              </div>
            </div>
            <v-btn class="ml-auto" @click="handleHideShowMap"
              >{{ showMap ? "Hide Map " : "Show Map " }}
              <v-icon v-if="showMap">mdi-map-minus</v-icon
              ><v-icon v-else>mdi-map-plus</v-icon>
            </v-btn>

            <template v-slot:extension>
              <v-tabs grow v-model="tab">
                <v-tabs-slider color="blue"></v-tabs-slider>
                <v-tab v-for="item in tabTitles" :key="item.index">
                  {{ item.name }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
        </v-card>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabTitles" :key="item.index">
            <v-card flat v-if="item.name == 'Search'">
              <div v-if="loading" class="mt-3 d-flex justify-content-center">
                <div class="text-bold mb-0">Fetching Data</div>
                <div class="spinner-border ml-4" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div v-else>
                <v-expansion-panels focusable v-model="panel" :key="key">
                  <template v-for="filterSet in filterSets">
                    <InsetFilter
                      :filterParams="filterParams"
                      :title="filterSet.title"
                      :graphTitle="filterSet.graphTitle"
                      :previewParam="filterSet.previewParam"
                      :selectionType="filterSet.selectionType"
                      @matchSummary="handleMatchSummary"
                      @choroplethData="handleChoroplethData"
                      @filterParams="handleFilterParams"
                      @close="handleClose"
                      @save="snackbarSave = true"
                    />
                  </template>

                  <v-expansion-panel v-if="items.length > 0">
                    <v-expansion-panel-header>
                      Selection Summary
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :headers="headers"
                        :items="items"
                      ></v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-container>
                  <v-row class="justify-end">
                    <v-col cols="auto" class="text-right">
                      <v-btn
                        class="ml-5 mt-3"
                        v-if="currentOrderName && status != 'executed'"
                        @click="handleShowModal"
                        >Modify / Review</v-btn
                      >
                      <v-btn
                        class="ml-5 mt-3"
                        v-if="currentOrderName"
                        @click="handleShowCloneModal"
                        >Clone Order</v-btn
                      >
                      <v-btn
                        class="ml-5 mt-3"
                        v-if="currentOrderName && status != 'executed'"
                        @click="handleShowExecuteModal"
                      >
                        Execute Order</v-btn
                      >

                      <v-btn
                        v-else-if="status != 'executed'"
                        @click="handleShowModal"
                        >Finish Creating Order</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="currentOrderName && pctFilled > 0">
                    <v-col></v-col>
                    <v-col>
                      <v-btn
                        class="mb-1 mr-1 float-right"
                        @click="handleExport"
                      >
                        <v-icon>mdi-download</v-icon>
                        Export Supply Summary
                        <div
                          v-if="isZipExportLoading"
                          class="spinner-border spinner-border-sm text-light ml-1"
                          role="status"
                        ></div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-card>

            <v-card flat v-if="item.name == 'Manage'">
              <v-data-table
                :headers="EAListHeaders"
                :items="EAListItems"
                :search="searchOrder"
                :sort-by="['id']"
                :sort-desc="true"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="searchOrder"
                    label="Filter"
                    class="mx-4"
                  ></v-text-field>
                </template>

                <template slot="item.name" slot-scope="props">
                  <span
                    class="hover blue--text font-weight-bold"
                    @click="handleEAOrderSelect(props.item)"
                    style="max-width: 100px"
                  >
                    {{ props.item.name.replace(/_/g, " ") }}
                    <v-icon color="blue">
                      mdi-arrow-top-right-bold-box-outline
                    </v-icon>
                  </span>
                </template>

                <template slot="item.status" slot-scope="props">
                  {{ props.item.status | capitalize }}
                  <i
                    v-if="
                      props.item.status != 'executed' &&
                      props.item.status != 'closed'
                    "
                    class="ml-1 fa fa-times-circle"
                    @click="handleOpenDeletePlanModal(props.item)"
                  />
                </template>
              </v-data-table>
              <!-- -->
              <!-- make a v-data-table here with id, name, status id/name have click events to retrieve full filterParams from a get of that id and set tab to Search-->
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <transition name="slide" v-if="mapShowCounter > 0">
        <div v-show="showMap" class="col map-col">
          <div class="map-wrapper" oncontextmenu="return false">
            <LeafletMap
              :fields="[]"
              :isYield="isYield"
              :zoneGeoJSON="zoneGeoJSON"
              :mapParams="mapParams"
              :mapStart="mapStart"
              :editing="editing"
              @drawCreated="drawCreated"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import LeafletMap from "@/components/map/LeafletMap"
import DashboardTitle from "@/components/dashboard/DashboardTitle"

import InsetFilter from "@/components/insets/filters/GenericInsetFilter"
import ConfirmInsetOrderModal from "@/components/insets/modals/ConfirmOrderModal"
import ExecuteInsetOrderModal from "@/components/insets/modals/ExecuteInsetOrderModal"
import ConfirmDeleteInsetOrderModal from "@/components/insets/modals/ConfirmInsetDeleteModal"
import Permissions from "@/components/permissions/Permissions"

import { Filter } from "@/store/modules"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import { pickBy } from "lodash"
import { limitTextLength } from "@/utility"

export default {
  name: "BuyerInsetFilterView",
  components: {
    LeafletMap,
    DashboardTitle,
    Permissions,
    ConfirmInsetOrderModal,
    ConfirmDeleteInsetOrderModal,
    ExecuteInsetOrderModal,
    InsetFilter,
  },
  data() {
    return {
      tab: 0,
      snackbar: false,
      snackbarSave: false,
      tabTitles: [
        { index: 0, name: "Search" },
        { index: 1, name: "Manage" },
      ],
      currentOrderID: null,
      currentOrderName: null,
      currentOrderPrice: null,
      currentSupplyTarget: null,
      currentAggregationMetric: null,

      status: "open",
      key: 0,
      showExecuteModal: false,

      searchOrder: "",
      zoneGeoJSON: null,
      isYield: false,
      loading: false,
      showMap: true,
      mapShowCounter: 1,
      mapStart: null,
      pctFilled: 0,
      filterParams: { filter_params: {} }, // "year": [2023], "crop_id": [4] }
      panel: -1,
      items: [],
      headers: [
        { text: "Category", value: "category" },
        { text: "Value", value: "value" },
      ],
      colorLUT: {},
      showModal: false,
      showCloneModal: false,
      showDeleteModal: false,
      EAListItems: [],
      editing: false,
      isZipExportLoading: false,
      EAListHeaders: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "% Filled", value: "pct_filled" },
        { text: "% Confirmed", value: "pct_filled_and_signed" },
        { text: "Status", value: "status" },
      ],
      filterSets: [
        {
          title: "Filter by Crop",
          graphTitle: "Crops: ",
          previewParam: "crop_id",
          selectionType: "select",
        },
        {
          title: "Filter by Year",
          graphTitle: "Years: ",
          previewParam: "year",
          selectionType: "select",
        },
        {
          title: "Filter by Region",
          graphTitle: "Counties: ",
          previewParam: "geoids",
          selectionType: "select",
        },
        {
          title: "Filter by DAS",
          graphTitle: "Data Assurance Scores: ",
          previewParam: "min_das",
          selectionType: "slider",
        },
        {
          title: "Filter by Price",
          graphTitle: "Prices: ",
          previewParam: "max_price",
          selectionType: "slider",
        },
        {
          title: "Filter by Available Practice",
          graphTitle: "Regenerative Practices: ",
          previewParam: "practices",
          selectionType: "select",
        },
        {
          title: "Filter by Supplier Org",
          graphTitle: "Supplier Organizations: ",
          previewParam: "org_nodes",
          selectionType: "select",
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      organization: state => state.Organization.organization,
      selectedFields: state => state.Filter.selectedFields,
      fieldBoundaries: state => state.Map.fieldBoundaries,
      user: state => state.User.user,
      showSustainability: state =>
        state.Organization.organization.showSustainability,
      isSuperuser: state => state.User.user.is_superuser,
    }),

    mapParams() {
      let tempParams = {}
      tempParams["order_id"] = -99
      tempParams["category"] = "insets"

      tempParams["useMap"] = true
      tempParams["useInset"] = true
      tempParams["colorLUT"] = this.colorLUT
      return tempParams
    },

    fields() {
      const activeFieldIds = this.activeFields.map(field => field.id)
      const filteredBounds =
        activeFieldIds.length > 0
          ? this.fieldBoundaries.filter(row =>
              activeFieldIds.includes(row.properties.field.id)
            )
          : this.fieldBoundaries
      return filteredBounds
    },
    orderShortText() {
      return limitTextLength(this.currentOrderName, 21)
    },
  },

  methods: {
    handleHideShowMap() {
      this.showMap = !this.showMap
      this.mapShowCounter = this.mapShowCounter + 1
    },

    handleMatchSummary(resp) {
      this.items = []
      for (const [key, value] of Object.entries(resp)) {
        this.items.push({ category: key, value: value })
      }
    },

    handleChoroplethData(resp) {
      this.colorLUT = {}
      resp.forEach(x => {
        let key = String(x[0])
        this.colorLUT[key] = { value: x[3], label: x[1], supply: x[2] }
      })

      this.mapShowCounter += 1
    },
    handleFilterParams(resp) {
      this.filterParams = resp
    },
    handleClose() {
      this.panel = -1
    },

    handleCloseModal() {
      this.showModal = false
      this.showCloneModal = false
    },

    handleSaveModal() {
      this.showModal = false
      this.showCloneModal = false

      CarbonReadyAPI.getInsetOrders().then(response => {
        this.EAListItems = response.data
        this.EAListItems.forEach(x => {
          x["params_json"] = JSON.stringify(x["params"])
        }) //add % filled to EAListItems
        this.tab = 1
      })
    },
    handleShowModal() {
      this.showModal = true
    },
    handleShowCloneModal() {
      this.showCloneModal = true
    },
    handleShowExecuteModal() {
      this.showExecuteModal = true
    },

    handleDeselectOrder() {
      this.currentOrderID = null
      this.currentOrderName = null
      this.filterParams = { filter_params: {} }
      this.status = null
      this.tab = 1
    },

    handleOpenDeletePlanModal(e) {
      this.currentOrderID = e.id
      this.currentOrderName = e.name
      this.showDeleteModal = true
    },
    handleCloseDeleteModal() {
      this.currentOrderID = null
      this.currentOrderName = null
      this.showDeleteModal = false
    },

    handleConfirmDeleteModal() {
      this.currentOrderID = null
      this.currentOrderName = null
      this.showDeleteModal = false
      CarbonReadyAPI.getInsetOrders().then(response => {
        this.EAListItems = response.data
        this.EAListItems.forEach(x => {
          x["params_json"] = JSON.stringify(x["params"])
        }) //add % filled to EAListItems
      })
    },

    handleExecuteModal() {
      this.showExecuteModal = false
      this.status = "executed"
    },
    drawCreated(geojson) {
      this.filterParams["geojson"] = geojson
      this.key = this.key + 1
    },

    async handleExport() {
      this.isZipExportLoading = true
      if (this.currentOrderID == null) {
        this.isZipExportLoading = false
        return
      }

      const payload = {
        eao_id: this.currentOrderID,
      }
      await CarbonReadyAPI.fetchProgramExport(payload).then(response => {
        this.isZipExportLoading = false
        if (response.status == 200) {
          this.snackbar = true
        }
      })
    },

    async handleEAOrderSelect(e) {
      this.tab = 0

      this.currentOrderID = e.id
      this.currentOrderName = e.name
      this.currentOrderPrice = e.price
      this.pctFilled = e.pct_filled

      if ("supply_target" in e.params) {
        this.currentSupplyTarget = e.params.supply_target.value
        this.currentAggregationMetric = e.params.supply_target.type
      }
      this.status = e.status

      this.filterParams["filter_params"]["geoids"] = e.aoi.map(x => x.geoid)
      this.filterParams["filter_params"]["org_nodes"] = e.org_nodes

      this.filterParams["filter_params"]["practices"] = e.params.practices
      this.filterParams["filter_params"]["min_das"] = e.params.das
      this.filterParams["filter_params"]["max_price"] = e.params.price
      this.filterParams["filter_params"]["year"] = e.params.year
      this.filterParams["filter_params"]["crop_id"] = e.params.crop_id

      this.filterParams["preview_param"] = "crop_id"
      this.filterParams["aggregation_metric"] = "acreage" //change this in above view later

      this.filterParams["filter_params"] = pickBy(
        this.filterParams["filter_params"],
        v => v !== undefined
      )

      let payload = this.filterParams
      this.loading = true
      CarbonReadyAPI.postInsetFilter(payload).then(response => {
        this.loading = false
        this.panel = -1

        this.handleMatchSummary(response.data["match_summary"])
      })
    },
  },

  watch: {
    showMap() {
      if (this.showMap) {
        this.EAListHeaders = [
          { text: "ID", value: "id" },
          { text: "Name", value: "name" },
          { text: "% Filled", value: "pct_filled" },
          { text: "Status", value: "status" },
        ]
      } else {
        this.EAListHeaders = [
          { text: "ID", value: "id" },
          { text: "Name", value: "name" },
          { text: "Filters", value: "params_json" },
          { text: "% Filled", value: "pct_filled" },
          { text: "% Commited", value: "pct_filled_and_signed" },
          { text: "Status", value: "status" },
        ]
      }
    },
    panel: function () {
      if (this.panel == 2) {
        this.editing = true
      } else {
        this.editing = false
      }
    },
  },

  mounted() {
    CarbonReadyAPI.getInsetOrders().then(response => {
      this.EAListItems = response.data
      this.EAListItems.forEach(x => {
        x["params_json"] = JSON.stringify(x["params"])
      }) //add % filled to EAListItems
    })
  },
}
</script>

<style scoped>
h2 {
  font-weight: bold;
  border-bottom: 1pt solid #bbb;
  padding-bottom: 9px;
  margin-bottom: 20px;
}

.pointer {
  cursor: pointer;
}

.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}

.map-col {
  padding-left: 0px;
}

.map-wrapper {
  height: calc(100vh - 65px);
  width: 100%;
  margin-top: -30px;
  margin-bottom: -17px;
  position: relative;
  background: #1b1b1d;
}

.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.scroll-col {
  max-height: 85vh;
  overflow: scroll;
  padding-right: 40px;
}

.reverse-margin {
  margin-bottom: -60px;
}

.single {
  font-weight: bold;
  color: green;
}

.comparison {
  font-weight: bold;
  color: purple;
}

.loading-text {
  font-weight: bold;
  font-size: 16px;
}

.info {
  color: #666666;
}

.thirty {
  width: 30%;
}

.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
</style>
