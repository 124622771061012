<template>
  <div>
    <EvidencingActionRanchSidebar
      :action="tableActionSelection"
      :confirmations="[]"
      :fieldRowData="fieldRowData"
      :groupRowData="[]"
      :visible="showTableActionSidebar"
      @closeSidebar="handleClosedSidebar"
      @submitted="handleSubmitted"
    />
    <h1 class="evidencing-title">Evidencing</h1>

    <div class="evidencing-view-selector">
      <a class="selected">All</a>
      <a class="no-decoration" href="/ranch-evidencing/view-group/all/">Groups</a>

      <div class="evidencing-view-btns">
        <div class="groups-search">
          <v-icon>mdi-magnify</v-icon>
          <input
            type="text"
            placeholder="Group/Client name..."
            v-model="tableData['groupSearch']"
          />
        </div>

        <v-btn 
          v-if="isSuperUser" 
          class="create-group-btn" 
          outlined 
          height="32" 
          :href="isRanchForce ? '/evidencing/create-group-ranch/' : '/evidencing/create-group/'"
        >
          <img :src="plusIcon" />
        </v-btn>
      </div>
    </div>

    <div class="evidencing-table-filters">
      <div class="evidencing-table-filter-selections activated">
        <div class="table-filter-selector">
          <p>Species</p>
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="tableData['filters']['livestock_species']"
            :items="totalLivestockSpecies"
            label="Species"
            clearable
            dense
            solo
          >
            <!-- <template v-slot:selection="{ item, index }">
              <p v-if="index == 0">
                {{ tableData["filters"]["livestock_species"] ? "1" : "" }}
              </p>
            </template> -->
          </v-select>
        </div>

        <div class="table-filter-selector">
          <p>Pastures</p>
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="tableData['filters']['pastures']"
            :items="totalPastures"
            label="Pastures"
            clearable
            dense
            solo
          >
            <!-- <template v-slot:selection="{ item, index }">
              <p v-if="index == 0">
                {{ tableData["filters"]["pastures"].length }}
              </p>
            </template> -->
          </v-select>
        </div>

        <div class="table-filter-selector">
          <p>Status</p>
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="tableData['filters']['status']"
            :items="totalStatus"
            label="Status"
            clearable
            dense
            solo
          />
        </div>
      </div>
    </div>

    <div
      v-if="fieldsToEdit.length > 0"
      class="evidencing-action-bar"
    >
      <h2>
        {{ fieldsToEdit.length }} Request{{
          fieldsToEdit.length == 1 ? "" : "s"
        }}
      </h2>

      <v-btn
        @click="handleActionSelect(UPLOAD_DATA_ACTION)"
        color="#FFFFFF"
        outlined
        height="44px"
      >
        Upload Evidencing
      </v-btn>

      <!-- <v-btn
        @click="
          isAssignmentActionDisabled
            ? null
            : handleActionSelect(ASSIGN_USERS_ACTION)
        "
        :class="isAssignmentActionDisabled ? 'assign-btn-disabled' : ''"
        color="#FFFFFF"
        outlined
        height="44px"
        @mouseenter="assignHovered = true"
        @mouseleave="assignHovered = false"
      >
        Assign
      </v-btn> -->

      <!-- <v-btn
        @click="exportEvidencingTable"
        color="#FFFFFF"
        outlined
        height="44px"
      >
        Export
      </v-btn> -->
      <v-progress-circular v-if="loadingEvidencingFile" :size="44" color="#79c61c" indeterminate />
      <v-btn
        v-if="!loadingEvidencingFile"
        @click="viewEvidencing"
        color="#FFFFFF"
        outlined
        height="44px"
      >
        View
      </v-btn>

      <!-- <div
        class="assign-btn-disabled-tooltip"
        :class="assignHovered ? 'visible' : ''"
      >
        Untracked evidencing requests cannot be assigned to users.
      </div> -->
    </div>

    <div class="evidencing-table-wrapper">
      <div class="evidencing-table">
        <v-simple-table>
          <thead>
            <tr>
              <th>
                  <input
                    @click="selectAllCheckBox"
                    type="checkbox"
                    name="selected"
                    ref="fieldSelectAllCheckbox"
                  />
                </th>
              <th
                v-for="head, idx in tableData['headers']"
                :key="head + idx"
                :data-header="head.toLowerCase()"
              >
                <a>
                  {{ head }}
                  <div class="evidencing-table-sorters" v-if="idx < tableData['headers'].length-1">
                    <font-awesome-icon :icon="['fas', 'caret-up']" />
                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                  </div>
                </a>
              </th>
            </tr>
          </thead>
  
          <tbody>
            <EvidencingListAllRow
              v-for="(e, idx) in sortedFilteredEvidencingGroups"
              :key="e['id'] + e['name'] + idx"
              :evdiencing="e"
              @checked="checked"
              @data-checked="dataChecked"
              @unchecked="unchecked"
              @step=""
              ref="fieldAllListRows"
            />
          </tbody>
        </v-simple-table>
      </div>
    </div>

    <EvidencingViewModal
      v-if="evidencingViewModalOpen"
      titleText="Evidence List"
      :fileDetails="fileDetails"
      @confirm="closeEvidencingViewModal"
      @close-modal="closeEvidencingViewModal"
    />
    <EvidencingViewErrorModal
      v-if="evidencingViewModalOpenError"
      titleText="Evidence List"
      :confirmText="evidencingErrorMessage"
      @confirm="closeEvidencingViewModal"
      @close-modal="closeEvidencingViewModal"
    />
  </div>
</template>

<script>
import plusIcon from "@/assets/images/plus-icon.svg";
import EvidencingListAllRow from "@/components/evidencing/EvidencingListAllRow"
import { fetchRanchYearBasedGroups, getEvidencingFileUrl } from "@/api/EvidencingAPI";
import { mapState } from "vuex";
import { UPLOAD_DATA_ACTION, IS_RANCHFORCE } from "@/constants";
import EvidencingActionRanchSidebar from "@/components/evidencing/EvidencingActionRanchSidebar";
import EvidencingViewModal from "@/components/modals/EvidencingViewModal"
import EvidencingViewErrorModal from "@/components/modals/EvidencingViewErrorModal"

export default {
  name: "EvidencingRanchView",
  components: {
    EvidencingListAllRow,
    EvidencingActionRanchSidebar,
    EvidencingViewModal,
    EvidencingViewErrorModal,
  },
  data() {
    return {
      showTableActionSidebar: false,
      tableActionSelection: "",
      UPLOAD_DATA_ACTION,
      tableData: {
        groupSearch: '',
        filters: {
          livestock_species: null,
          pastures: null,
          status: null,
        },
        headers: [
          "Pasture ID",
          "Year",
          "Pasture",
          "Group",
          "Start Date",
          "End Date",
          "Species",
          "# Animals",
          "Feed Additive",
          "Due",
          "Status"
        ]
      },
      fileDetails: [],
      evidencingViewModalOpen: false,
      evidencingViewModalOpenError: false,
      loadingEvidencingFile: false,
      fieldRowData: [],
      fetchedGroups: [],
      plusIcon,
      isRanchForce: IS_RANCHFORCE,
      fieldsToEdit: [],
      fileUrl: null,  // URL of the file to be displayed
      evidencingErrorMessage: '',
    }
  },
  computed: {
    ...mapState({
      year: state => state.Organization.year,
      organization: state => state.Organization.organization,
      user: state => state.User.user
    }),
    isSuperUser() {
      if (this.user != null) {
        return this.user['is_superuser'];
      }
      return false;
    },
    totalLivestockSpecies() {
      const species = [];

      for (const data of this.fetchedGroups) {
        if (!species.includes(data.livestock_species)) {
          species.push(data.livestock_species)
        }
      }

      return species.map(n => {
        if (n) {
          return {
            text: `${n.slice(0, 1).toUpperCase()}${n.slice(1)}`,
            value: n,
          }
        }
      })
    },
    totalPastures() {
      const pastures = [];

      for (const data of this.fetchedGroups) {
        if (!pastures.includes(data.field_name)) {
          pastures.push(data.field_name)
        }
      }

      return pastures.map(n => {
        return {
          text: `${n.slice(0, 1).toUpperCase()}${n.slice(1)}`,
          value: n,
        }
      })
    },
    totalStatus() {
      const status = [];

      for (const data of this.fetchedGroups) {
        if (!status.includes(data.status)) {
          status.push(data.status)
        }
      }

      return status.map(n => {
        return {
          text: `${n.slice(0, 1).toUpperCase()}${n.slice(1)}`,
          value: n,
        }
      })
    },
    sortedFilteredEvidencingGroups() {
      let filteredGroups = this.fetchedGroups;
      if (this.tableData['filters']['livestock_species']) {
        filteredGroups = filteredGroups.filter((v) => v.livestock_species == this.tableData['filters']['livestock_species'])
      }

      if (this.tableData['filters']['pastures']) {
        filteredGroups = filteredGroups.filter((v) => v.field_name == this.tableData['filters']['pastures'])
      }

      if (this.tableData['filters']['status']) {
        filteredGroups = filteredGroups.filter((v) => v.field_name == this.tableData['filters']['status'])
      }

      return filteredGroups;
    }
  },
  methods: {
    handleClosedSidebar() {
      this.showTableActionSidebar = false
      // this.fetchPracticeConfirmations()
    },
    handleSubmitted() {
      this.fetchedGroups.map((d) => {
        const fetchGroupIdx =
            d.id + 
            '_' + 
            d.client + 
            '_' + 
            d.grazings_start_date + 
            '_' + 
            d.grazings_end_date + 
            '_' + 
            d.livestock_species + 
            '_' + 
            d.feed_additive + 
            '_' + 
            d.count_animals;
          
          if (this.fieldsToEdit.includes(fetchGroupIdx)) {
            d.status = 'Completed';
          }
      })
    },
    handleIndividualConfirmationUploadClick(confirmationId) {
      // this.tableData["selectedRows"] = [confirmationId]
      this.tableActionSelection = "Upload Data"
      this.showTableActionSidebar = true
    },
    handleActionSelect(action) {
      this.fieldRowData = [];
      for (const idx of this.fieldsToEdit) {
        for (const d of this.fetchedGroups) {
          const fetchGroupIdx =
            d.id + 
            '_' + 
            d.client + 
            '_' + 
            d.grazings_start_date + 
            '_' + 
            d.grazings_end_date + 
            '_' + 
            d.livestock_species + 
            '_' + 
            d.feed_additive + 
            '_' + 
            d.count_animals;
            
            if (idx == fetchGroupIdx) {
              this.fieldRowData.push(d);
            }
        }
      }
      this.tableActionSelection = action
      this.showTableActionSidebar = true
    },
    fetchGroups() {
      if (this.organization != null && this.organization['id'] != null) {
        fetchRanchYearBasedGroups({ year: this.year, org_node_id: this.organization['id'] })
          .then(response => {
            if (response['data'] && response['data'][0].length > 0) {
              let results = [];
              const rowResults = response['data'][0];
              const groupResults = response['data'][1];

              for (const row of Object.values(rowResults)) {
                const field_id = row['id'];
                const field_name = row['name'];
                const client_name = row['client']['name'];
                const client_id = row['client']['id'];

                for (const rowData of row['data']) {
                  let result = {
                    id: '',
                    name: '',
                    code: '',
                    group_id: '',
                    client: '',
                    client_id: '',
                    field_name: '',
                    pastures: '',
                    livestock_species: '',
                    grazings_start_date: '',
                    grazings_end_date: '',
                    feed_additive: '',
                    count_animals: '',
                    upload_id: '',
                    year: '',
                    uploads: 0,
                    due_date: '',
                    status: ''
                  };
                  
                  const grazings_start_date = rowData['grazings_start_date'] ?? '';
                  const grazings_end_date = rowData['grazings_end_date'] ?? '';
                  const livestock_species = rowData['livestock_species'] ?? '';
                  const feed_additive = rowData['feed_additive'] ?? '';
                  const due_date = rowData['due_date'];
                  const count_animals = rowData['count_animals'] ?? 0;
                  const status = rowData['status'];
                  const upload_id = rowData['upload_id'];

                  for (const group of groupResults) {
                    if (
                      grazings_start_date == group['grazings_start_date'] &&
                      grazings_end_date == group['grazings_end_date'] &&
                      livestock_species == group['livestock_species'] &&
                      feed_additive == group['feed_additive'] &&
                      due_date == group['due_date'] &&
                      count_animals == group['count_animals'] &&
                      client_name == group['client'] &&
                      field_name == group['field_name']
                    ) {
                      result['group_id'] = group['id'];
                      result['code'] = group['code'];
                      result['name'] = group['name'];
                      result['uploads'] = group['uploads'];
                      result['pastures'] = group['pastures'];
                    }
                  }

                  result['id'] = field_id;
                  result['client'] = client_name;
                  result['client_id'] = client_id;
                  result['field_name'] = field_name;
                  result['livestock_species'] = livestock_species;
                  result['grazings_start_date'] = grazings_start_date;
                  result['grazings_end_date'] = grazings_end_date;
                  result['feed_additive'] = feed_additive;
                  result['count_animals'] = count_animals;
                  result['year'] = this.year;
                  result['due_date'] = due_date;
                  result['status'] = status;
                  result['upload_id'] = upload_id;
                  result['idx'] =
                    result['id'] + 
                    '_' + 
                    result['client'] + 
                    '_' + 
                    result['grazings_start_date'] + 
                    '_' + 
                    result['grazings_end_date'] + 
                    '_' + 
                    result['livestock_species'] + 
                    '_' + 
                    result['feed_additive'] + 
                    '_' + 
                    result['count_animals'];

                  results.push(result);
                }
              }
              this.fetchedGroups = results;
            }
          })
      }
    },
    getGroupCrops(crops) {
      const cropNames = [];
      for (const crop of crops) {
        if (crop != null && crop['name'] != null) {
          if (!cropNames.includes(crop['name'])) {
            cropNames.push(crop['name'])
          }
        } else {
          if (!cropNames.includes('Unspecified Crop')) {
            cropNames.push('Unspecified Crop')
          }
        }
      }
      return cropNames
    },
    viewGroup(id) {
      location.href = `/evidencing/view-group/${id}`;
    },
    editGroup(id) {
      location.href = `/evidencing/edit-group/${id}`;
    },
    checked(idx) {
      this.fieldsToEdit.push(idx);
    },
    async dataChecked(field_id, field_data_type) {
      // this.selectFieldId = field_id;
      // this.selectedDataType = field_data_type;

      // const payload = {
      //   field_id: field_id,
      //   field_data_type: this.selectedDataType,
      // };
      // await FieldsAPI.fetchGeoPkgsByDataType(payload).then(response => {
      //   this.geoPkgsByDataType = response.data;
      // });

      // this.fieldDataTypeDeletionModalOpen = true;
    },
    unchecked(idx) {
      const index = this.fieldsToEdit.indexOf(idx);
      if (index > -1) this.fieldsToEdit.splice(index, 1);
    },

    selectAllCheckBox() {
      const checked = this.$refs.fieldSelectAllCheckbox.checked;
      this.$refs.fieldAllListRows.forEach(fieldListRow => fieldListRow.setCheckbox(checked)
      );

      if (!checked) this.fieldsToEdit = [];
    },

    deselectAllCheckbox() {
      this.$refs.fieldAllListRows.forEach(fieldListRow => fieldListRow.setCheckbox(false)
      );
      this.$refs.fieldSelectAllCheckbox.checked = false;
    },
    exportEvidencingTable() {
      // // pretty hard coded for now, near future we'll have a sidebar for this too
      // const csvHeader = [
      //   "Assignees",
      //   "ID",
      //   "Type",
      //   "Organization",
      //   "Client",
      //   "Farm(s)",
      //   "Field(s)",
      //   "Crop",
      //   "Due",
      //   "Status",
      //   "Evidencing Source",
      //   "Source Details",
      //   "Instructions",
      //   "Notes",
      // ]
      // const csvRows = []

      // const selectedConfirmations = this.sortedFilteredConfirmations.filter(
      //   ({ id }) => this.tableData["selectedRows"].includes(id)
      // )

      // for (const conf of selectedConfirmations) {
      //   const row = []

      //   let assignees = "Not Specified"

      //   if (conf['assignees']) {
      //     if (conf['assignees'] == UNTRACKED_EVIDENCING) assignees = "Untracked";
      //     else if (conf['assignees'].length > 0) {
      //       assignees = `"\" ${conf['assignees'].map(({ first_name, last_name }) => `${first_name} ${last_name}`).join(', ')} \""`;
      //     }
      //   }

      //   row.push(
      //     assignees,
      //     conf["id"],
      //     conf["confirmation_type"] || "Not Specified",
      //     conf["organization_node"]
      //       ? conf["organization_node"]["name"]
      //       : "Not Specified",
      //     conf["client"] ? conf["client"]["name"] : "Not Specified",
      //     conf["farms"] && conf["farms"].length > 0
      //       ? conf["farms"].map(({ name }) => name).join("\n")
      //       : "Not Specified",
      //     conf["fields"] && conf["fields"].length > 0
      //       ? conf["fields"].map(({ name }) => name).join("\n")
      //       : "Not Specified",
      //     conf["crops"] && conf["crops"].length > 0
      //       ? conf["crops"].map(({ name }) => name).join("\n")
      //       : "Not Specified",
      //     conf["year"] ? conf["year"] : "Not Specified",
      //     Boolean(conf["hasUploads"]) ? "Completed" : "Incomplete"
      //   )

      //   if (conf["confirmation_source"] == "other") {
      //     row.push(`Other - ${conf["other_source_manual_specification"]}`)
      //   } else {
      //     row.push(conf["confirmation_source"] || "Not Specified")
      //   }

      //   row.push(conf["confirmation_source_details"])

      //   row.push(
      //     conf["instructions"] || "Not Specified",
      //     conf["notes"] ? `\" Notes: ${conf["notes"]} \"` : "Not Specified"
      //   )

      //   csvRows.push(row)
      // }

      // let csv = "data:text/csv;charset=utf-8,"
      // csv += `${csvHeader}\n`
      // csv += csvRows.join("\n")

      // const encodedUri = encodeURI(csv)
      // const link = document.createElement("a")
      // link.setAttribute("href", encodedUri)
      // link.setAttribute("download", "evidencing_table_export.csv")
      // document.body.appendChild(link)
      // link.click()
      // document.body.removeChild(link)
    },
    async viewEvidencing() {
      if (this.fieldsToEdit.length > 1) {
        this.evidencingViewModalOpenError = true;
        this.evidencingErrorMessage = "View Evidence can only be one row at a time"
      } else {
        let upload_id = '';

      for (const idx of this.fieldsToEdit) {
        for (const d of this.fetchedGroups) {
          const fetchGroupIdx =
            d.id + 
            '_' + 
            d.client + 
            '_' + 
            d.grazings_start_date + 
            '_' + 
            d.grazings_end_date + 
            '_' + 
            d.livestock_species + 
            '_' + 
            d.feed_additive + 
            '_' + 
            d.count_animals;
            
            if (idx == fetchGroupIdx) {
              upload_id = d.upload_id;
              if (d.status == 'Unsubmitted') {
                this.evidencingViewModalOpenError = true;
                this.evidencingErrorMessage = "No Evidence has been submitted for this group."
              }
            }
        }
      }

      if (upload_id && this.evidencingViewModalOpenError == false) {
        try {
          this.loadingEvidencingFile = true;
          getEvidencingFileUrl({ upload_id: upload_id })
            .then(response => {
              if (response['data']) {
                this.fileDetails = response['data']['file_details'];
                this.loadingEvidencingFile = false;
                this.evidencingViewModalOpen = true;
                this.evidencingViewModalOpenError = false;
              } else {
                this.loadingEvidencingFile = false;
                console.error("Error:", data.error);
              }
            })
        } catch (error) {
          console.error("Error fetching the file URL:", error);
        }
      } 
      }
    },
    closeEvidencingViewModal() {
      this.evidencingViewModalOpen = false;
      this.evidencingViewModalOpenError = false;
    },
  },
  watch: {
    organization: {
      handler() {
        this.fetchGroups();
      },
      deep: true
    },
    year: {
      handler() {
        this.fetchGroups();
      },
      deep: true
    },
    showTableActionSidebar(curr) {
      if (!curr) {
        this.tableActionSelection = ""
      }
    },
  },
  mounted() {
    this.fetchGroups();
  }
}
</script>

<style scoped>
.evidencing-title {
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
  margin: 0 0 8px;
  color: #000000;
}
.evidencing-view-selector {
  display: flex;
  justify-content: flex-start;
}
.evidencing-view-selector a {
  margin-right: 8px;
}
.evidencing-view-selector a.selected {
  font-weight: bold;
}
.evidencing-table-wrapper {
  background: #FFFFFF;
  padding: 24px 0 0;
  margin-top: 24px;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 50px;
}
.evidencing-table-wrapper .fxn-wrapper {
  margin-bottom: 24px;
  padding: 0 24px;
}
.evidencing-table-wrapper .fxn-wrapper h3 {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-weight: bold;
}
.evidencing-table {
  /* max-height: 638px; */
  overflow-y: scroll;
}

.groups-search {
  width: 100%;
  max-width: 300px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 4px 12px;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-end;
  margin-left: auto;
  margin-right: 8px;
}
.groups-search i {
  margin-right: 12px;
}
.groups-search input {
  width: 100%;
}
.evidencing-view-btns {
  display: flex;
  margin-left: auto;
}
.create-group-btn {
  width: 32px;
  padding: 0 !important;
  min-width: unset !important;
  opacity: 0.5;
  margin-left: 6px;
  transition: opacity 0.25s;
}
.create-group-btn:hover {
  opacity: 1;
}
</style>

<!-- table styles -->
<style scoped>
.evidencing-table ::v-deep(table) {
  table-layout: fixed;
}
th {
  position: sticky;
  top: 0;
  z-index: 1;
  color: #757575;
  background: #ffffff;
  text-transform: uppercase;
  height: 43px !important;
}
th[data-header="group name"] {
  width: 250px;
}
th[data-header="actions"] {
  width: 200px;
}
th[data-header="crop(s)"] {
  width: 100px;
}
th[data-header="due"] {
  width: 120px;
}
th[data-header="uploads"] {
  width: 105px;
}
th[data-header="client"],
th[data-header="status"] {
  width: 175px;
}
th[data-header="farms"] {
  width: 95px;
}
th a {
  padding: 12px 0;
  font-size: 12px;
  line-height: 20px;
  color: #000000 !important;
  display: flex;
  width: 100%;
}
th a .evidencing-table-sorters {
  margin-left: auto;
  position: relative;
}
th a .evidencing-table-sorters > svg {
  color: #cbd5e0;
}
th a .evidencing-table-sorters > svg.activated-sort {
  color: #687588;
}
th a .evidencing-table-sorters > svg:first-of-type {
  position: absolute;
  top: 1px;
  right: 0;
}
th a .evidencing-table-sorters > svg:last-of-type {
  position: absolute;
  bottom: 1px;
  right: 0;
}
th,
td {
  font-size: 14px;
  line-height: 20px;
}
td * {
  pointer-events: none;
}
tr {
  position: relative;
}
td {
  /* white-space: pre-wrap; */
  vertical-align: top;
  padding: 12px 16px !important;
}
td > button:not(:last-of-type) {
  margin-right: 8px;
}
td p {
  margin: 0;
  color: #000000;
  line-height: 1.5;
  text-transform: capitalize;
}
tbody tr {
  cursor: pointer;
}
.pre-wrap {
  white-space: pre-wrap;
}
.no-decoration {
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.25s;
}
.no-decoration:hover {
  color: #000000;
}
td.progress-cell {
  vertical-align: middle;
}
.v-progress-linear {
  width: calc(100% - 12px) !important;
  border-radius: 6px;
}
.superuser-hoverable-actions {
  position: absolute;
  right: 0;
}
.evidencing-table-filters button {
  padding: 0 !important;
  min-width: unset !important;
  margin: 0 0 0 10px;
}
.evidencing-table-filter-selections {
  padding: 24px 0;
  display: flex;
}
.evidencing-table-filter-selections .fields-search {
  width: 100%;
  max-width: 175px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 4px 12px;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-end;
  margin-left: auto;
}
.evidencing-table-filter-selections .fields-search i {
  margin-right: 12px;
}
.evidencing-table-filter-selections .fields-search input {
  width: 100%;
}
.evidencing-table-filter-selections .table-filter-selector {
  margin-right: 12px;
  width: 200px;
}
.evidencing-table-filter-selections .table-filter-selector > p {
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  margin: 0 0 4px;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-select__selections p) {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-input__control) {
  min-height: 32px !important;
  height: 32px;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-input__control input) {
  cursor: pointer;
}
.evidencing-table-filter-selections .table-filter-selector ::v-deep(.v-label) {
  font-size: 14px;
  line-height: 20px;
  max-width: 75%;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-input__slot) {
  min-height: 32px;
  height: 32px;
  margin: 0;
  box-shadow: none !important;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-text-field__details),
.evidencing-table-filter-selections
  .fields-search
  ::v-deep(.v-text-field__details) {
  display: none;
}
.evidencing-action-bar {
  padding: 12px 24px;
  width: 100%;
  background: #464b58;
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.evidencing-action-bar > h2 {
  font-size: 16px;
  line-height: 20px;
  color: #ededed;
  font-weight: bold;
  width: 236px;
  margin: 0 24px 0 0;
}
.evidencing-action-bar > button {
  margin-right: 20px;
}
</style>