export const getClassForCell = (cell) => {
    if (cell == null || cell == undefined) {
        return {'arva-alert': true};
    }
    return {
        'arva-alert': cell.value == null,
        'pre-gen': cell.source !== 'User Input' && cell.source !== 'shapefile' && cell.value !== null,
        machine: cell.source == 'shapefile' && cell.value !== null,
        complete: cell.source == 'User Input' && cell.value !== null,
    };
}
