<template>
  <b-sidebar
    v-model="sidebarVisible"
    id="sidebar-task-detail-view"
    width="576px"
    :title="taskDetailSidebarTitle"
    right
    shadow
    backdrop
  >
    <div v-if="loading['state'] == true">
      <div
        class="sidebar-loading-wrapper"
        :class="loading['action'] == LOADER_ERROR ? 'error-sizing' : ''"
      >
        <v-progress-circular
          v-if="loading['action'] == LOADER_PROCESSING"
          :size="48"
          color="#79c61c"
          class="sidebar-progress"
          indeterminate
        />
        <v-icon
          v-else-if="loading['action'] == LOADER_SUCCESS"
          :size="48"
          color="#79c61c"
          dark
          >mdi-check-bold</v-icon
        >
        <v-icon
          v-else-if="loading['action'] == LOADER_ERROR"
          :size="48"
          color="#ff3c7e"
          >mdi-alert-circle-outline</v-icon
        >

        <div
          class="sidebar-error-message"
          v-if="loading['action'] == LOADER_ERROR"
        >
          <p class="text-danger">Something went wrong...</p>
          <p>The request could not be processed ({{ loadingErrorMsg }}).</p>
          <v-btn @click="resetLoadingState()">close</v-btn>
        </div>
      </div>
      <div class="progress-overlay" />
    </div>

    <div
      v-if="safeTask['type'] == 'practiceconfirmation'"
      class="notifications-sidebar-body"
    >
      <div>
        <p>
          You are receiving this because you have been assigned to provide
          evidencing for
          {{ safeTask["data"]["model_related"].length }} practices.
        </p>

        <p>
          To provide evidencing for these confirmations, please go to the
          practice confirmations dashboard under sustainability, or use the Arva
          Evidencing mobile application.
        </p>

        <div class="notification-btn-links">
          <p>Go to...</p>
          <v-btn height="44px" outlined>Arva Evidencing (Mobile)</v-btn>
          <v-btn href="/practice-confirmations" height="44px" outlined
            >Confirmations Dashboard</v-btn
          >
        </div>
      </div>
    </div>

    <div
      v-if="safeTask['type'] == 'assignevidencing'"
      class="notifications-sidebar-body"
    >
      <p>
        You recently modified field data using SQR uploads, enrollment wizards,
        or have been noted as a responsible user to be notified when field data
        is modified via an API process.
      </p>

      <p>
        To assign someone as responsible for uploading evidencing, please to go
        the confirmations dashboard.
      </p>

      <div class="notification-btn-links">
        <p>Go to...</p>
        <v-btn href="/practice-confirmations" height="44px" outlined
          >Confirmations Dashboard</v-btn
        >
      </div>
    </div>

    <div
      v-if="safeTask['type'] == 'moveclient'"
      class="notifications-sidebar-body"
    >
      <p>
        <strong>{{
          safeTask["data"]["model_related"][0]["client"]["name"]
        }}</strong
        >, a client affiliated with the "<strong>{{
          safeTask["data"]["model_related"][0]["source_org"]["name"]
        }}</strong
        >" Organization, is the subject of a transfer request to the "<strong>{{
          safeTask["data"]["model_related"][0]["destination_org"]["name"]
        }}</strong
        >" Organization. To initiate this process, {{ initiationText }}
        <strong>review, sign, and return the Release Form to Arva.</strong>
      </p>
      <div class="notification-btn-links">
        <p>You can find the Release Form from the Download button below</p>
        <v-btn height="44px" outlined @click="downloadReleaseForm"
          >Download Release Form</v-btn
        >
      </div>

      <div
        v-for="move_client_action in safeTask['data']['model_related']"
        :key="move_client_action.id"
      >
        <div class="notification-btn-links upload-section">
          <p>{{ getUploadText(move_client_action) }}</p>
          <v-file-input
            :disabled="isTaskComplete"
            class="upload-input"
            v-model="fileMap[move_client_action.id]"
            label="Attach Files"
            outlined
          />

          <div class="footer-errors-and-btns">
            <v-btn
              class="mr-3"
              outlined
              height="44px"
              @click="sidebarVisible = !sidebarVisible"
              >Close</v-btn
            >
            <v-btn
              :disabled="!fileMap[move_client_action.id]"
              outlined
              height="44px"
              @click="uploadReleaseForm(move_client_action.id)"
              >Upload</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import moment from "moment"
import JSZip from "jszip"
import {
  BLANK_TASK_SIDEBAR,
  LOADER_PROCESSING,
  LOADER_SUCCESS,
  LOADER_ERROR,
} from "@/constants/arvaNotifications"
import UserAPI from "@/api/UserAPI"
import { assignUsersToConfirmations } from "@/api/NotificationsAPI"
import { modifyConfirmationNotes } from "@/api/EvidencingAPI"
import { Uploads } from "@/store/modules"
import { mapActions, mapState } from "vuex"
import { CONFIRMATION_TYPE_CHOICES } from "@/constants/defaults"

export default {
  name: "NotificationTaskDetailSidebar",
  props: { task: { required: true } },
  emits: ["manualCompletion", "refetchNotifications"],
  data() {
    return {
      loading: {
        state: false,
        action: LOADER_PROCESSING,
      },
      notes: "",
      files: [],
      fileMap: {},
      sidebarVisible: false,
      loadingErrorMsg: "",
      userSelections: {
        confirmationsForAssignment: [],
        userAssignment: [],
      },
      assignmentStep: "initial",
      userAssignableEmails: [],
      LOADER_ERROR,
      LOADER_PROCESSING,
      LOADER_SUCCESS,
      CONFIRMATION_TYPE_CHOICES,
    }
  },
  computed: {
    ...mapState({
      organization: state => state.Organization.organization,
      user: state => state.User.user,
    }),
    isTaskComplete() {
      return this.task != null ? this.task["status"] === 1 : false
    },
    taskDetailSidebarTitle() {
      return this.task != null ? this.task["title"] : null
    },
    initiationText() {
      const modelRelated = this.safeTask.data?.model_related || []
      if (modelRelated.length > 1) {
        return "both the grower and the prospective Channel Partner are required to"
      } else if (modelRelated.length === 1) {
        const type = modelRelated[0]?.type
        if (type === "GROWER") {
          return "the grower is required to"
        } else if (type === "CHANNEL_PARTNER") {
          return "the Channel Partner is required to"
        }
      }
      return ""
    },
    safeTask() {
      if (this.task == null) return BLANK_TASK_SIDEBAR
      return this.task
    },
    isSaveDisabled() {
      if (this.safeTask["practiceConfirmation"]["notes"] != this.notes)
        return false
      if (this.files.length > 0) return false
      return true
    },
  },
  methods: {
    ...mapActions({
      createUpload: Uploads.Actions.create,
    }),
    getUploadText(moveClientAction) {
      if (moveClientAction?.type === "GROWER") {
        return "Please upload the signed Release Form for the Grower..."
      } else if (moveClientAction?.type === "CHANNEL_PARTNER") {
        return "Please upload the signed Release Form for the Channel Partner..."
      }
      return "Please upload the signed Release Form..."
    },
    getUserName(userId) {
      return this.userAssignableEmails.find(({ value }) => value == userId)[
        "title"
      ]
    },
    getAssignableUsers() {
      UserAPI.fetchAllUserContacts(this.user["id"]).then(response => {
        this.userAssignableEmails = response["data"]["emails"].map(
          ({ name, email, id }) => {
            return {
              title: name ? `${name} (${email})` : email,
              value: id,
            }
          }
        )
      })
    },
    getMultiNameObj(arr) {
      if (arr) {
        return arr.map(a => a["name"]).join(", ")
      }
      return "Not specified"
    },
    assignConfirmations(target) {
      this.loading = { state: true, action: LOADER_PROCESSING }

      // wait 5 seconds to perform the action, if it hasn't worked by then call it
      // a server timeout error
      new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
        if (
          this.loading["state"] &&
          this.loading["action"] == LOADER_PROCESSING
        ) {
          // we were still loading after the timeout, show a timeout error message
          // and get out of loading state
          this.loading = { state: true, action: LOADER_ERROR }
          this.loadingErrorMsg = "Server Error: timeout"
        }
      })

      const confirmation_ids =
        this.userSelections.confirmationsForAssignment.map(c => c["id"])
      const user_ids = []
      const notification_id = this.safeTask["notificationId"]

      if (target == "self-assign") user_ids.push(this.user["id"])
      if (target == "cs-assign") {
        // do the cs team users, maybe a const?
        user_ids.push(this.user["id"])
      }
      if (target == "user-assign") {
        user_ids.push(...this.userSelections["userAssignment"])
      }

      assignUsersToConfirmations({
        confirmation_ids,
        user_ids,
        notification_id,
      })
        .then(response => {
          if (response) {
            this.loading["action"] = LOADER_SUCCESS
            new Promise(resolve => setTimeout(() => resolve(), 500)).then(
              () => {
                this.removeSidebar(true)
              }
            )
          }
        })
        .catch(err => {
          this.loading["action"] = LOADER_ERROR
          this.loadingErrorMsg = `Server Error: ${err}`
        })
    },
    getFormattedConfType(conf) {
      return CONFIRMATION_TYPE_CHOICES.find(
        c => c["value"] == conf["confirmation_type"]
      )["name"]
    },
    async handleSave() {
      this.resetLoadingState()
      this.loading = { state: true, action: LOADER_PROCESSING }

      // wait 5 seconds to perform the action, if it hasn't worked by then call it
      // a server timeout error
      new Promise(resolve => setTimeout(() => resolve(), 5000)).then(() => {
        if (
          this.loading["state"] &&
          this.loading["action"] == LOADER_PROCESSING
        ) {
          // we were still loading after the timeout, show a timeout error message
          // and get out of loading state
          this.loading = { state: true, action: LOADER_ERROR }
          this.loadingErrorMsg = "Server Error: timeout"
        }
      })

      if (this.notes != this.safeTask["practiceConfirmation"]["notes"]) {
        modifyConfirmationNotes({
          confirmationNumber:
            this.safeTask["practiceConfirmation"]["confirmation_number"],
          notes: this.notes,
        })
      }

      const uploadSpec = {
        orgId: this.safeTask["practiceConfirmation"]["organization_node"]["id"],
        datasetType: "practice-confirmations",
        specs: {
          createNewConfirmation: false,
          confirmationNumber:
            this.safeTask["practiceConfirmation"]["confirmation_number"],
        },
      }

      // upload files if there are any more
      // if no files, save notes
      // then close sidebar and refresh the page
      if (this.files.length > 0) {
        const zip = new JSZip()
        for (const file of this.files)
          zip.file(file.name, await file.arrayBuffer())
        const result = await zip.generateAsync({ type: "blob" })
        const datetimeStr = moment().format("YYYY-MM-DD_h:mm:ss")
        const orgName = this.safeTask["practiceConfirmation"][
          "organization_node"
        ]["name"]
          ? this.safeTask["practiceConfirmation"]["organization_node"]["name"]
          : "GenericPracticeConfirmation"
        const newZipName = `${orgName}-practice-confirmations-${datetimeStr}.zip`
        uploadSpec["file"] = new File([result], newZipName)

        this.createUpload(uploadSpec)
          .then(_ => {
            this.loading["action"] = LOADER_SUCCESS
            new Promise(resolve => setTimeout(() => resolve(), 500)).then(
              () => {
                this.removeSidebar(true)
              }
            )
          })
          .catch(err => {
            this.loading["action"] = LOADER_ERROR
            this.loadingErrorMsg = `Server Error: ${err}`
          })
      } else {
        this.loading["action"] = LOADER_SUCCESS
        new Promise(resolve => setTimeout(() => resolve(), 500)).then(() => {
          this.removeSidebar(true)
        })
      }
    },
    setTaskToComplete() {
      this.$emit("manualCompletion")
      this.sidebarVisible = false
    },
    removeSidebar(refresh = false) {
      if (refresh == true) {
        this.$emit("refetchNotifications")
      }
      this.resetLoadingState()
      this.files = []
      this.fileMap = {}
      this.notes = this.safeTask["practiceConfirmation"]
        ? this.safeTask["practiceConfirmation"]["notes"]
        : ""
      this.sidebarVisible = false
      this.assignmentStep = "initial"
      this.userSelections = {
        confirmationsForAssignment: [],
        userAssignment: [],
      }
    },
    resetLoadingState() {
      this.loading["state"] = false
      this.loading["action"] = LOADER_PROCESSING
      this.loadingErrorMsg = ""
    },
    downloadReleaseForm() {
      const link = document.createElement("a")
      link.href =
        "https://portal-uploads.cropforce.com/forms/Transfer_Request_Release_of_Liability_form.pdf"
      link.setAttribute("download", "ReleaseForm.pdf")
      document.body.appendChild(link)
      link.click()
    },
    async uploadReleaseForm(moveClientActionId) {
      this.loading["state"] = true
      const uploadedFiles = this.fileMap[moveClientActionId]
      if (uploadedFiles) {
        const uploadSpec = {
          datasetType: "move-client",
          orgId: this.organization.id,
          specs: {
            userId: this.user["id"],
            moveClientActionId: moveClientActionId,
            clientId: this.safeTask["data"]["model_related"][0]["client"]["id"],
            destinationOrgId:
              this.safeTask["data"]["model_related"][0]["destination_org"][
                "id"
              ],
          },
        }

        const zip = new JSZip()
        zip.file(uploadedFiles.name, await uploadedFiles.arrayBuffer())
        const result = await zip.generateAsync({ type: "blob" })
        const datetimeStr = moment().format("YYYY-MM-DD_h:mm:ss")
        const newZipName = `${uploadSpec.specs.clientId}-move-client-${datetimeStr}.zip`
        uploadSpec["file"] = new File([result], newZipName)

        this.createUpload(uploadSpec)
          .then(_ => {
            this.loading["action"] = LOADER_SUCCESS
            new Promise(resolve => setTimeout(() => resolve(), 500)).then(
              () => {
                this.removeSidebar(true)
              }
            )
          })
          .catch(err => {
            this.loading["action"] = LOADER_ERROR
            this.loadingErrorMsg = `Server Error: ${err}`
          })
      }
    },
  },
  watch: {
    task(curr) {
      if (curr["type"] == "practiceconfirmation") {
        this.notes = curr["practiceConfirmation"]
          ? curr["practiceConfirmation"]["notes"]
          : ""
      }
      if (curr["type"] == "assignmentevidencing") {
        this.getAssignableUsers()
      }
    },
    sidebarVisible(curr, prev) {
      if (prev == true && curr == false) {
        // we were open and just closed, reset everything
        this.removeSidebar()
      }
    },
    userSelections: {
      handler: function (curr) {
        if (curr["confirmationsForAssignment"].length == 0) {
          this.assignmentStep = "initial"
        }
      },
      deep: true,
    },
    user: {
      handler: function (curr) {
        if (curr != null) {
          this.getAssignableUsers()
        }
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
p {
  color: #000000;
}

.sidebar-loading-wrapper {
  position: absolute;
  top: calc(50% - 48px);
  left: calc(50% - 48px);
  width: 96px;
  background-color: white;
  padding: 24px;
  z-index: 10;
  border-radius: 8px;
}

.sidebar-loading-wrapper.error-sizing {
  width: 250px;
  text-align: center;
  left: calc(50% - 125px);
}

.progress-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 9;
}

.notifications-sidebar-body {
  padding: 24px 36px;
}

.whitespace-pre-instructions {
  white-space: pre;
}

button:deep(.v-ripple__container) {
  display: none !important;
}

.notification-btn-links {
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background: #ffffff;
}
.notification-btn-links p {
  width: 100%;
}
.notification-btn-links > a:not(:last-child),
.notification-btn-links > button:not(:last-child) {
  margin: 0 0 12px 0;
}
.footer-errors-and-btns {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
</style>
