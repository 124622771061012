<template>
  <div class="page">
    <div :id="mapID" />

    <Tooltip />
    <div v-if="useMapParams == true">
      <LeafletLayerControl
        :mapParams="mapParams"
        :fields="fields"
        @update-zone="updateZoneGeoJSON"
        @update-map-layer-payload="updateMapLayerPayload"
        @update-synthetic-year-list="updateSyntheticYearList"
        :key="componentKey"
      />
    </div>
  </div>
</template>
<script>
import "leaflet/dist/leaflet.css"
import L from "leaflet"
import "leaflet-boundary-canvas"
import "leaflet-draw/dist/leaflet.draw.css"
import "leaflet-draw"
import { multiPolygon, point } from "@turf/helpers"
import buffer from "@turf/buffer"
import booleanPointInPolygon from "@turf/boolean-point-in-polygon"
import { mapMutations } from "vuex"
import { MAPBOX_TOKEN } from "@/constants/map"
import Tooltip from "@/components/map/Tooltip"
import LeafletLayerControl from "@/components/map/LeafletLayerControl"
import { DROPDOWN } from "@/constants"
import { Filter, Map } from "@/store/modules"
import { getAgtColor, getInsetGeoidColor } from "@/utility"
import { AGT_COLORS_OLD, VIRIDIS } from "@/constants/agts"
import MapAPI from "@/api/MapAPI"

export default {
  name: "LeafletMap",
  props: {
    boundaries: Array,
    fields: Array,
    isYield: Boolean,
    zoneGeoJSON: Array,
    category: String,
    mapParams: Object,
    mapID: {
      default: "map",
      type: String,
    },
    mapStart: Object,
    editing: Boolean,
  },
  components: {
    Tooltip,
    LeafletLayerControl,
  },

  data() {
    return {
      map: null,
      legend: null,
      mapLayerPayload: null,
      componentKey: 0,
      drawControl: null,
    }
  },

  computed: {
    useMapParams() {
      return this.mapParams.useMap
    },
    bounds() {
      const geometry = {
        type: "MultiPolygon",
        coordinates: [],
      }
      for (const field of this.fields) {
        geometry.coordinates = geometry.coordinates.concat(
          field.geometry.coordinates
        )
      }
      return geometry
    },
  },

  methods: {
    ...mapMutations({
      setHoverData: Map.Mutations.setHoverData,
      toggleField: Filter.Mutations.toggleItem,
    }),

    fillFunction(d) {
      if (this.fields.find(field => field.geometry === d.geometry)) {
        return true
      }
      return false
    },

    getColor(d, breaks) {
      return d > breaks[0]
        ? "#00FF00"
        : d > breaks[1]
        ? "#40FF00"
        : d > breaks[2]
        ? "#B4FA00"
        : d > breaks[3]
        ? "#C8E632"
        : d > breaks[4]
        ? "#FFFF00"
        : d > breaks[5]
        ? "#FFD500"
        : d > breaks[6]
        ? "#FF8200"
        : d > breaks[7]
        ? "#FF5C00"
        : "#FF0000"
    },
    getEqualInterval(arr) {
      let arr1 = arr.features.map(x => {
        return x.properties.value
      })
      let n = 8

      let min = arr1[0],
        max = arr1[0]

      for (let i = 1, len = arr1.length; i < len; i++) {
        let v = arr1[i]
        min = v < min ? v : min
        max = v > max ? v : max
      }

      let minMax = [min, max]

      let steps = (minMax[1] - minMax[0]) / n
      let breaks = []
      for (let i = 0; i < n; i++) {
        breaks.push(minMax[1] - i * steps)
      }

      arr.features.forEach(object => {
        object.properties.fillColor = this.getColor(
          object.properties.value,
          breaks
        )
      })

      return [arr, breaks]
    },

    boundsStyle(feature) {
      return {
        color: "#FFCC00",
        weight: 1,
        opacity: 0.85,
        fill: this.fillFunction(feature),
      }
    },

    boundsStyleZone() {
      return {
        weight: 2.5,
        opacity: 1,
        fill: true,
        fillOpacity: 1,
        stroke: false,
        fillColor: "red",
      }
    },

    getVectorTilerLayers() {
      var urlKoppenGeiger = `https://storage.googleapis.com/vector-tilayers-arva/koppen_geiger_complete_1/{z}/{x}/{y}.pbf`

      var vtLayerKoppenGeiger = L.vectorGrid.protobuf(urlKoppenGeiger, {
        maxZoom: 16,
        maxNativeZoom: 9,
        minZoom: 0,
        vectorTileLayerStyles: {
          other_climate_2007_koppen_geiger: function (properties) {
            return {
              fillColor: getAgtColor(properties.color_id),
              opacity: 1,
              fillOpacity: 0.3,
              weight: 1,
              color: "black",
              fill: true,
              zorder: 1,
            }
          },
        },
        attribution:
          "<a href='https://www.britannica.com/science/Koppen-climate-classification' target='_blank'>Koppen Geiger Climate Zones",
      })

      var urlEcoRegion = `https://storage.googleapis.com/vector-tilayers-arva/eco_tiles_complete/{z}/{x}/{y}.pbf`

      var vtLayerEcoRegion = L.vectorGrid.protobuf(urlEcoRegion, {
        maxZoom: 16,
        maxNativeZoom: 9,
        minZoom: 0,
        vectorTileLayerStyles: {
          us_eco: function (properties) {
            return {
              fillColor: getAgtColor(properties.ECO_US_),
              opacity: 1,
              fillOpacity: 0.3,
              weight: 1,
              color: "black",
              fill: true,
              zorder: 1,
            }
          },
        },
        attribution:
          "<a href='https://geospatial.tnc.org/datasets/b1636d640ede4d6ca8f5e369f2dc368b/about' target='_blank'>WWF/TNC Ecoregions",
      })

      var urlCounty = `https://storage.googleapis.com/vector-tilayers-arva/county_tiles/{z}/{x}/{y}.pbf`

      var vtLayerCounty = L.vectorGrid.protobuf(urlCounty, {
        maxZoom: 16,
        maxNativeZoom: 9,
        minZoom: 0,
        vectorTileLayerStyles: {
          us_eco: function () {
            return {
              fillColor: "transparent",
              opacity: 1,
              fillOpacity: 0.1,
              weight: 0.1,
              color: "black",
              fill: true,
              zorder: 1,
            }
          },
        },
        attribution: "TIGER",
      })

      var overlayPane = {
        "TNC Ecoregion Borders": vtLayerEcoRegion,
        "County Borders": vtLayerCounty,
        "Koppen Geiger": vtLayerKoppenGeiger,
      }

      return overlayPane
    },

    getMapLayers() {
      let basemapURL = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`

      if (this.mapParams["useInset"]) {
        basemapURL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      }
      let osmLayer = L.tileLayer(basemapURL, {
        attribution:
          '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        minZoom: 0,
        maxZoom: 16,
      })

      let boundsLayer = []
      for (let boundsLayerIdx in this.boundaries) {
        let thisBoundsLayer = new L.GeoJSON(this.boundaries[boundsLayerIdx], {
          style: this.boundsStyle,
          onEachFeature: (feature, layer) => (layer.myTag = "boundsGeoJSON"),
        })
        boundsLayer.push(thisBoundsLayer)
      }

      let overlayPane = {}
      if (!this.mapParams["useInset"]) {
        overlayPane = this.getVectorTilerLayers()
      }

      let getHandleMapHoverVectorGrid = this.handleMapHoverVectorGrid
      /*
            if mapParams is inset, then instead of bounds layer, create a pbf with colors
            */
      if (this.mapParams["useInset"] && this.mapParams["colorLUT"]) {
        var colorLookup = this.mapParams["colorLUT"]
        //maybe we let the backend do this...

        var urlGeoids = `https://mvt-layer.s3.amazonaws.com/geoid-layers/{z}/{x}/{y}.pbf`

        boundsLayer = L.vectorGrid
          .protobuf(urlGeoids, {
            maxZoom: 16,
            maxNativeZoom: 10,
            minNativeZoom: 6,
            minZoom: 0,
            interactive: true,
            vectorTileLayerStyles: {
              id: function (properties) {
                return {
                  fillColor: getInsetGeoidColor(
                    String(properties.id),
                    colorLookup
                  ),
                  opacity: 0.5,
                  fillOpacity: 0.75,
                  weight: 1,
                  color: "#FFCC00",
                  fill: true,
                  zorder: 1,
                }
              },
            },
            myTag: "vectorGrid",
            attribution:
              "<a href='https://www.britannica.com/science/Koppen-climate-classification' target='_blank'>Koppen Geiger Climate Zones",
          })
          .on("mousemove", function (e) {
            //var properties = e.layer.properties;
            getHandleMapHoverVectorGrid(e)
          })
      }

      return {
        osmLayer,
        //osmPoliticalLayer,
        boundsLayer,
        overlayPane,
      }
    },

    updateMap() {
      if (this.bounds.coordinates.length === 0) {
        if (this.map) {
          this.map.eachLayer(layer => {
            if (layer.options.boundary) this.map.removeLayer(layer)
          })
          if (this.componentKey < 1) {
            this.map.setView([39.8283, -98.5795], 4)
          }
        }

        //return
      }

      let { osmLayer, boundsLayer, overlayPane } = this.getMapLayers()
      let layers = [osmLayer].concat(boundsLayer)

      let getHandleMapHoverVectorGrid = this.handleMapHoverVectorGrid
      if (this.map) {
        this.map.eachLayer(layer => {
          if (layer.myTag == "boundsGeoJSON") {
            layer.setStyle({
              fill: this.fillFunction(layer.feature),
            })
          } else if (layer.options.myTag == "vectorGrid") {
            //console.log("in vector grid tag")
            var colorLookup = this.mapParams["colorLUT"]

            layer.options.vectorTileLayerStyles.id = function (properties) {
              return {
                fillColor: getInsetGeoidColor(
                  String(properties.id),
                  colorLookup
                ),
                opacity: 0.5,
                fillOpacity: 0.75,
                weight: 1,
                color: "#FFCC00",
                fill: true,
                zorder: 1,
              }
            }

            layer.on("mousemove", function (e) {
              getHandleMapHoverVectorGrid(e)
            })
            layer.redraw()
          }
        })
      } else {
        this.map = L.map(document.querySelector("#" + this.mapID), {
          layers,
        })

        if (!this.mapParams["useInset"]) {
          this.map.on("mousemove", this.handleMapHover)

          this.map.on("click", this.handleMapClick)
        }
        if (Object.keys(overlayPane).length > 0) {
          let layerControl = L.control.layers(null, overlayPane, {
            position: "topleft",
          })
          layerControl.addTo(this.map)
        }
      }

      if (this.editing) {
        if (this.drawControl) {
          this.map.removeControl(this.drawControl)
        }
        this.drawControl = new L.Control.Draw({
          draw: {
            marker: false,
            line: false,
            rectangle: false,
            circle: true,
            circlemarker: false,
            polyline: false,
          },
        })
        this.map.addControl(this.drawControl)

        this.map.on("draw:created", function (e) {
          var layer = e.layer

          this.recentlyCreatedLayer = layer
          this.recentlyCreatedLayer.id = L.stamp(layer)
          this.recentlyCreatedLayer.isNewBoundary = true
          this.recentlyCreatedLayer.feature = {
            type: "Feature",
            properties: {
              field: {
                acreage: null,
                id: null,
                farm: {
                  client_id: null,
                  client_name: null,
                  id: null,
                  name: null,
                  org_node_id: null,
                },
                name: null,
              },
            },
          }

          //this.recentlyCreatedLayer = recentlyCreatedLayer.toGeoJSON()
          this.recentlyCreatedLayer = this.recentlyCreatedLayer.toGeoJSON()
          if (e.layerType == "circle") {
            this.recentlyCreatedLayer = buffer(
              this.recentlyCreatedLayer,
              layer._mRadius,
              { units: "meters" }
            )
          }
        })

        this.map.on("draw:created", this.drawCreated)
      } else if (this.editing == false && this.drawControl) {
        this.map.removeControl(this.drawControl)
      }

      osmLayer = null
      //osmPoliticalLayer = null
      boundsLayer = null
      overlayPane = null
      layers = null
    },

    updateSyntheticYearList(d) {
      this.$emit("update-synthetic-year-list", d)
    },

    createCircleMarker(feature, latlng) {
      // Change the values of these options to change the symbol's appearance
      let options = {
        radius: 3,
        fillColor: feature.properties.fillColor,
        color: "black",
        weight: 0.1,
        opacity: 1,
        fillOpacity: 0.5,
      }
      return L.circleMarker(latlng, options)
    },

    buildLegend(map, breaks) {
      if (breaks[0] == breaks[7]) {
        if (this.legend != null) {
          map.removeControl(this.legend)
        }
        return
      }
      let getColor1 = function (d, breaks) {
        return d > breaks[0]
          ? "#00FF00"
          : d > breaks[1]
          ? "#40FF00"
          : d > breaks[2]
          ? "#B4FA00"
          : d > breaks[3]
          ? "#C8E632"
          : d > breaks[4]
          ? "#FFFF00"
          : d > breaks[5]
          ? "#FFD500"
          : d > breaks[6]
          ? "#FF8200"
          : d > breaks[7]
          ? "#FF5C00"
          : "#FF0000"
      }
      if (this.legend != null) {
        map.removeControl(this.legend)
      }

      this.legend = L.control({
        position: "bottomleft",
      })
      this.legend.onAdd = function () {
        var div = L.DomUtil.create("div", "legend_info")

        // loop through our density intervals and generate a label with a colored square for each interval
        let mathRounder = 1
        let breaksRange = breaks[0] - breaks[breaks.length - 1]
        if (breaksRange < 21) {
          mathRounder = 10
        }
        if (breaksRange < 7) {
          mathRounder = 100
        }

        for (var i = 1; i < breaks.length; i++) {
          div.innerHTML +=
            '<i style="background:' +
            getColor1(breaks[i - 1], breaks) +
            '"></i> ' +
            Math.round(breaks[i] * mathRounder) / mathRounder +
            "&ndash;" +
            Math.round(breaks[i - 1] * mathRounder) / mathRounder +
            "<br>"
        }

        return div
      }

      this.legend.addTo(map)
    },

    updateZoneLayer() {
      this.map.eachLayer(layer => {
        if (layer.myTag) {
          if (layer.myTag == "zonesGeoJSON") {
            this.map.removeLayer(layer)
            layer.remove()
          }
        }
      })

      if (this.zoneGeoJSON === null) {
        return
      }
      if (this.zoneGeoJSON.length < 1) {
        return
      }
      //https://gis.stackexchange.com/questions/110402/changing-default-style-on-point-geojson-layer-in-leaflet
      //logic for using cirlce or not
      this.isPoint = false
      if (this.zoneGeoJSON.features[0].geometry.type == "Point") {
        this.isPoint = true
        let output = this.getEqualInterval(this.zoneGeoJSON)
        this.zoneGeoJSON = output[0]
        let breaks = output[1]

        L.geoJSON(this.zoneGeoJSON, {
          pointToLayer: this.createCircleMarker,
          onEachFeature: (feature, layer) => {
            layer.myTag = "zonesGeoJSON"
            if (feature.properties && feature.properties.popupContent) {
              layer.bindTooltip(feature.properties.popupContent, {
                closeButton: false,
                offset: L.point(-20, -50),
                className: "leafletToolTip",
              })
            }
          },
        }).addTo(this.map)
        this.buildLegend(this.map, breaks)
      } else {
        var featString = this.zoneGeoJSON["features"].length.toString()
        var yieldColorScale = AGT_COLORS_OLD
        if (featString in VIRIDIS) {
          yieldColorScale = VIRIDIS[featString]
        }

        L.geoJSON(this.zoneGeoJSON, {
          style: this.boundsStyleZone,

          onEachFeature: (feature, layer) => {
            layer.myTag = "zonesGeoJSON"
            if (this.isYield) {
              layer.setStyle({
                fillColor: yieldColorScale[parseInt(feature.id)],
              })
            } else {
              if (feature.properties && feature.properties.popupContent) {
                layer.bindTooltip(feature.properties.popupContent, {
                  closeButton: false,
                  offset: L.point(0, -20),
                  className: "leafletToolTip",
                })
              }
              layer.setStyle({
                //fillColor: AGT_COLORS_OLD[feature.properties.DN],
                fillColor: getAgtColor(feature.properties.DN),
              })
            }
          },
        }).addTo(this.map)
      }
    },

    zoomToBounds() {
      //maybe only return if not inset?
      if (this.bounds.coordinates.length === 0 || !this.map) {
        return
      } else {
        const geoJSON = L.geoJson(this.bounds)
        this.map.fitBounds(geoJSON.getBounds())
      }
    },

    findFieldFromLatLng(latlng) {
      const { lat, lng } = latlng
      const pt = point([lng, lat])
      return this.boundaries.find(field => {
        const fieldMPoly = multiPolygon(field.geometry.coordinates)
        return booleanPointInPolygon(pt, fieldMPoly)
      })
    },

    findSelectedFieldFromLatLng(latlng) {
      const { lat, lng } = latlng
      const pt = point([lng, lat])
      return this.fields.find(field => {
        const fieldMPoly = multiPolygon(field.geometry.coordinates)
        return booleanPointInPolygon(pt, fieldMPoly)
      })
    },

    handleMapClick(e) {
      const { latlng } = e
      const clickedField = this.findFieldFromLatLng(latlng)
      if (clickedField) {
        const { field } = clickedField.properties
        this.toggleField({
          id: field.id,
          dropdownType: DROPDOWN.Field,
          preventAutozoom: true,
        })
        this.lastMapAction = "click"
      }
    },

    handleMapHoverVectorGrid(e) {
      let hasVal =
        getInsetGeoidColor(
          String(e.layer.properties.id),
          this.mapParams["colorLUT"]
        ) != "transparent"

      if (hasVal) {
        const { id, geoid } = e.layer.properties
        const { x, y } = e.containerPoint

        this.setHoverData({
          x,
          y,
          fieldName: geoid,
          farmName: this.mapParams["colorLUT"][id]["label"],
          acreage: this.mapParams["colorLUT"][id]["supply"],
        })
      } else {
        this.setHoverData()
      }
    },

    handleMapHover(e) {
      const { latlng } = e
      const hoveredField = this.findSelectedFieldFromLatLng(latlng)
      if (hoveredField) {
        const { field } = hoveredField.properties
        const { x, y } = e.containerPoint

        this.setHoverData({
          x,
          y,
          fieldName: field.name,
          farmName: field.farm.name,
          acreage: field.acreage,
        })
      } else {
        this.setHoverData()
      }
    },

    setMapStart() {
      if (this.mapStart != null) {
        this.map.setView(
          [
            this.mapStart.geom.coordinates[1],
            this.mapStart.geom.coordinates[0],
          ],
          17
        )
        this.zoneGeoJSON = this.mapStart.geojson
      }
    },

    updateZoneGeoJSON(d) {
      this.zoneGeoJSON = d
    },
    updateMapLayerPayload(d) {
      this.mapLayerPayload = d
    },
    drawCreated() {
      var createdPolygon = this.map.recentlyCreatedLayer //this.map.recentlyCreatedLayer.toGeoJSON()

      this.$emit("drawCreated", createdPolygon)
    },
    async fetchMapLayerPayload(d) {
      const payload = d
      let field_ids = this.fields.map(x => {
        return x.properties.field.id
      })
      payload["field_ids"] = field_ids
      payload["datatype"] = this.mapParams.category

      await MapAPI.fetchLeafletLayersGeoJSON(payload).then(response => {
        this.zoneGeoJSON = response.data
        //also return latest payload so can recall it from mapview when field
      })
    },
  },

  watch: {
    editing() {
      this.updateMap()
    },
    mapStart() {
      this.setMapStart()
    },
    bounds() {
      if (this.componentKey < 1 || !this.mapParams["useInset"]) {
        this.updateMap()

        if (this.lastMapAction === "click") {
          this.lastMapAction = null
          return
        }
        this.zoomToBounds()
      }
    },

    zoneGeoJSON() {
      if (this.zoneGeoJSON != null) {
        if (this.zoneGeoJSON == []) {
          this.zoneGeoJSON = null
        }
        this.updateZoneLayer()
      }
    },

    mapParams() {
      if (
        (this.mapParams["category"] == "trial_report") |
        (this.mapParams["category"] == "synthetic_yield") |
        (this.mapParams["category"] == "soil_samples") |
        (this.mapParams["category"] == "insets")
      ) {
        this.componentKey += 1
        if (this.mapParams["category"] == "insets") {
          this.updateMap()
        }
      }
      if (this.legend != null) {
        this.map.removeControl(this.legend)
      }
    },
  },

  mounted() {
    let paywayScript = document.createElement("script")
    let self = this

    paywayScript.onload = () => {
      self.updateMap()
      if (self.lastMapAction === "click") {
        self.lastMapAction = null
        return
      }
      self.zoomToBounds()
      self.setMapStart()
    }
    paywayScript.setAttribute(
      "src",
      "https://unpkg.com/leaflet.vectorgrid@1.3.0"
    )
    document.body.appendChild(paywayScript)
  },
  destroyed() {
    this.map.remove()
  },
}
</script>
<style scoped>
body {
  margin: 0;
  padding: 0;
}

.page {
  position: relative;
}

#map {
  height: calc(100vh - 65px);
  position: relative;
  background: #1b1b1d;
  margin: -17px -29px -17px -29px;
}

#modalMap {
  height: calc(40vh - 25px);
  width: calc(40vw - 25px);
  position: relative;
  background: #1b1b1d;
  margin: 10px;
}
</style>

<style>
.leafletToolTip {
  margin: 2em;
  background: rgba(24, 24, 24, 0.9);
  color: rgb(200, 186, 175);
  border: none;
  /*z-index: 1000;*/
}

.legend_info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.legend_info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend_info {
  line-height: 18px;
  color: #555;
}

.legend_info i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.leaflet-top {
  z-index: 400;
}
</style>
