import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAlertNotifications, getTaskNotifications, syncUserFirebaseTokens } from "@/api/NotificationsAPI";
import { Notifications } from "@/store/modules";
import { isMobileUserAgent } from "@/utility";
import store from "@/store";

const state = {
  notificationsEnabled: false,
  canDisplayNotificationsRequest: false,
  alertNotifications: [],
  temporaryAlertNotifications: [],
  taskNotifications: [],
  temporaryActionNotifications: [],
  incompleteTasks: 0,
  unreadAlerts: 0,
  loadingNotifications: {
    tasks: false,
    alerts: false
  }
}
const mutations = {
  [Notifications.Mutations.setAlertNotifications](state, alertNotifications) {
    state.alertNotifications = alertNotifications
  },
  [Notifications.Mutations.addTemporaryAlertNotification](state, alertNotification) {
    state.temporaryAlertNotifications.push(alertNotification);
    state.incompleteTasks += 1;
  },
  [Notifications.Mutations.setTaskNotifications](state, taskNotifications) {
    state.taskNotifications = taskNotifications;
  },
  [Notifications.Mutations.addTemporaryActionNotification](state, taskNotification) {
    state.temporaryActionNotifications.push(taskNotification);
  },
  [Notifications.Mutations.setLoadingStatus](state, { tasks=null, alerts=null }) {
    if (tasks != null) state.loadingNotifications['tasks'] = tasks;
    if (alerts != null) state.loadingNotifications['alerts'] = alerts;
  },
  [Notifications.Mutations.setNotificationEnableState](state, { status }) {
    state.notificationsEnabled = status
  },
  [Notifications.Mutations.setNotificationRequestState](state, { status }) {
    state.canDisplayNotificationsRequest = status
  },
  [Notifications.Mutations.setIncompleteTasks](state, { count }) {
    state.incompleteTasks = count
  },
  [Notifications.Mutations.setUnreadAlerts](state, { count }) {
    state.unreadAlerts = count
  }
}
const actions = {
  [Notifications.Actions.initializeNotifications]({ commit, dispatch }) {
    if (!isMobileUserAgent()) {
      // only initialize firebase if we aren't on mobile
      const firebaseConfig = {
        apiKey: "AIzaSyC55jRaOFHdnw7K8QmLIk8adZFepUuBoJA",
        authDomain: "arva-notifications.firebaseapp.com",
        projectId: "arva-notifications",
        storageBucket: "arva-notifications.appspot.com",
        messagingSenderId: "763439495528",
        appId: "1:763439495528:web:a93b63d31a8846128a3edd",
        measurementId: "G-05W41R9E0R"
      };
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);
      const vapidKey = 'BFejdNzwyl8ZZ0mCZPSfrRLLhRDja3eWnH8imMpRwBiXONKHFcM2gOh8bntMAHIrN7lugsS4Jdg9aCaK16oQ8Og';
      
      onMessage(messaging, (payload) => {
        store.commit("Notifications/addTemporaryAlertNotification", payload);
      });
      // checks for user permissions, requests them if needed
      getToken(messaging, { vapidKey })
      .then(token => {
        if (token != null) {
          // we have notification permissions enabled
          commit(Notifications.Mutations.setNotificationEnableState, { status: true })
          dispatch(Notifications.Actions.syncUserFirebaseTokens, token)
        }
      })
      .catch(_err => {
        // if we're in this catch block, the user has denied notification use
        // allow the snackbar to appear and request the user enable permissions
        // if our parameters allow for it
        commit(Notifications.Mutations.setNotificationRequestState, { status: true })
      });
    }
  },
  [Notifications.Actions.fetchAllNotifications]({ dispatch }) {
    dispatch(Notifications.Actions.fetchAlertNotifications);
    dispatch(Notifications.Actions.fetchTaskNotifications)
  },
  [Notifications.Actions.fetchAlertNotifications]({ commit }) {
    commit(Notifications.Mutations.setLoadingStatus, { alerts: true });

    getAlertNotifications()
		.then(({ data }) => {
      commit(Notifications.Mutations.setAlertNotifications, data);
      commit(Notifications.Mutations.setLoadingStatus, { alerts: false });
    })
		.catch(err => {
      console.log('fetch push notifications err', err);
      commit(Notifications.Mutations.setLoadingStatus, { alerts: false });
    });
  },
  [Notifications.Actions.fetchTaskNotifications]({ commit }) {
    commit(Notifications.Mutations.setLoadingStatus, { tasks: true })

    getTaskNotifications()
    .then(({ data }) => {
      commit(Notifications.Mutations.setTaskNotifications, data);
      commit(Notifications.Mutations.setLoadingStatus, { tasks: false });

      const incompleteTasks = data.filter(({ completed }) => !completed).length;
      commit(Notifications.Mutations.setIncompleteTasks, { count: incompleteTasks });
    })
		.catch(err => {
      console.log('fetch action notifications err', err);
      commit(Notifications.Mutations.setLoadingStatus, { tasks: false })
    });
  },
  [Notifications.Actions.syncUserFirebaseTokens](_, token) {
    const payload = { token, for_platform: 'web' }
    syncUserFirebaseTokens(payload)
    .catch(err => {
      console.log('Error storing token to server:', err)
    })
  }
}
const getters = {
  [Notifications.Getters.getAggregateAlertNotifications](state) {
    // combine the push notifications from the django DB and the temporary push notifications we received
    // while in-app from firebase. The temp push notifs would by definition then be the newest
    // format the temp notifications first
    const formattedTempNotifications = state.temporaryAlertNotifications.map(n => {
      return {
        one_shot_title: n['notification']['title'],
        one_shot_message: n['notification']['body'],
        one_shot_status_level: n['data']['status_level']
      }
    })
    const aggregateAlertNotifications = [...formattedTempNotifications, ...state.alertNotifications];
    return aggregateAlertNotifications;
  },
  [Notifications.Getters.getAggregateTaskNotifications](state) {
    return state.taskNotifications;
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
}
