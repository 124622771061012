<template>
  <div>
    <div class="d-flex">
      <h2 class="ml-2">Climate Connections</h2>

      <button
        class="btn btn-light btn-sm"
        :class="{ disabled: climateURL === null }"
        @click="openClimate"
      >
        <i class="fa fa-plus" />
      </button>

      <div class="action-btn">
        <button
          class="btn btn-primary btn-sm"
          :class="{ disabled: numCheckedAccounts === 0 }"
          @click="handleOpenConfigureOrgModal"
        >
          <span>Update mapping</span>
        </button>

        <button
          class="btn btn-primary btn-sm download-text align-right"
          :class="{ disabled: readyToSync === false }"
          @click="handleOpenOrgImportModal"
        >
          <span>Sync selected orgs</span>
        </button>
      </div>
    </div>
    <div>
      <ClimateIntegrationRow
        v-for="account in climateAccounts"
        :key="account.id"
        :account="account"
        :orgs="orgs"
        @checked="checked"
        @unchecked="unchecked"
      />
    </div>

    <ConfirmModal
      v-if="orgImportModalOpen"
      title-text="Sync selected orgs"
      confirm-text="Choose whether to import field boundaries, field operations, or both."
      @confirm="handleDataImport"
      @close-modal="orgImportModalOpen = false"
    >
      <div class="form-group">
        <label for="datatype">Import</label>
        <v-select-old v-model="dataType" :options="dataTypes" />
      </div>
    </ConfirmModal>

    <ConfirmModal
      v-if="configureOrgModalOpen"
      title-text="Update connection mapping"
      :confirm-text="configureOrgText"
      @confirm="confirmOrgChange"
      @close-modal="configureOrgModalOpen = false"
    >
      <SelectOrg :org-nodes="orgNodes" @change-org-id="changeOrgId" />
    </ConfirmModal>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { Filter } from "@/store/modules";
import ConfirmModal from "@/components/modals/ConfirmModal";
import ClimateAPI from "@/api/ClimateAPI";
import ClimateIntegrationRow from "@/components/uploads/ClimateIntegrationRow";
import SelectOrg from "@/components/uploads/SelectOrg";

export default {
  components: {
    ConfirmModal,
    ClimateIntegrationRow,
    SelectOrg,
  },

  data() {
    return {
      fetchIntervalID: null,
      climateURL: null,
      climateAccounts: [],
      orgImportModalOpen: false,
      configureOrgModalOpen: false,
      configureClientsModal: false,
      checkedAccounts: {},
      dataType: null,
    };
  },

  computed: {
    ...mapState({
      orgs: (state) => state.Filter.orgNodes,
    }),

    ...mapGetters({
      orgNodes: Filter.Getters.getSelectedOrgNodes,
    }),

    numCheckedAccounts() {
      return Object.keys(this.checkedAccounts).length;
    },

    readyToSync() {
      const configured = this.connectedClimateAccounts.filter(
        (account) => account.id in this.checkedAccounts && account.arva_org_node
      );
      return (
        configured.length > 0 && configured.length === this.numCheckedAccounts
      );
    },

    connectedClimateAccounts() {
      return this.climateAccounts.filter(
        (account) => account.climate_access_enabled
      );
    },

    configureOrgText() {
      const plural = this.numCheckedAccounts === 1 ? "" : "s";
      return `Data from ${this.numCheckedAccounts} MyClimate connection${plural} will be loaded into the following location:`;
    },

    configureClientsText() {
      const account = this.climateAccounts.filter((obj) => {
        return obj.pk === this.configureClientsModal;
      });
      const text = `Configure active clients for ${account[0].name}`;
      return text;
    },

    dataTypes() {
      const dts = ["Boundaries", "Operations", "Everything"];
      return dts.map((dt) => ({
        label: dt,
        value: dt.toLowerCase(),
      }));
    },
  },

  async mounted() {
    this.dataType = this.dataTypes[0];
    await this.fetchOrgs();
    this.fetchIntervalID = setInterval(this.fetchOrgs(), 5000);

    const { data: climateUrl } = await ClimateAPI.fetchClimateURLs();
    this.climateURL = climateUrl.connections;
  },

  destroyed() {
    if (this.fetchIntervalID) clearInterval(this.fetchIntervalID);
  },

  methods: {
    fetchOrgs() {
      ClimateAPI.fetchClimateOrgs().then((response) => {
        this.climateAccounts = response.data || [];
      });
    },

    configureClients(climateorg_id) {
      // clear data (trigger loader?)
      this.updateClientsList = undefined;

      // open modal
      this.configureClientsModal = climateorg_id;

      // set data to result of API call
      const account = this.climateAccounts.filter((obj) => {
        return obj.pk === climateorg_id;
      });
      if (account) {
        this.updateClientsList = account[0].clients;
      }
    },

    checked(org_info) {
      const checkedAccounts = { ...this.checkedAccounts };
      checkedAccounts[org_info.id] = { name: org_info.name };
      this.checkedAccounts = checkedAccounts;
    },

    unchecked(org_info) {
      const checkedAccounts = { ...this.checkedAccounts };
      delete checkedAccounts[org_info.id];
      this.checkedAccounts = checkedAccounts;
    },

    openClimate() {
      if (this.climateURL) {
        window.open(this.climateURL);
      }
    },

    changeOrgId(orgId) {
      for (const org in this.checkedAccounts) {
        this.checkedAccounts[org].arva_org_node = orgId;
      }
    },

    handleOpenOrgImportModal() {
      if (this.readyToSync === false) return;
      if (this.numCheckedAccounts > 0) this.orgImportModalOpen = true;
    },

    handleOpenConfigureOrgModal() {
      if (this.numCheckedAccounts > 0) this.configureOrgModalOpen = true;
    },

    async handleDataImport() {
      await ClimateAPI.importClimateData(
        this.dataType.value,
        this.checkedAccounts
      );
      this.orgImportModalOpen = false;
    },

    async confirmOrgChange() {
      await ClimateAPI.configureClimateOrg(this.checkedAccounts);
      this.configureOrgModalOpen = false;
      this.fetchOrgs();
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 21px;
  font-weight: 500;
}

.form-group {
  margin: 0px auto 50px auto;
  padding: 0px 100px;
}

.action-btn {
  flex: 1;
  text-align: right;
}
</style>
