<template>
  <b-sidebar id="evidencing-group-fields-editing-sidebar" width="750px" title="Edit Group Metadata" v-model="show"
    right shadow backdrop>
    <div class="group-loading-modal-overlay" v-if="loading['visible']" />
    <div class="group-loading-modal" v-if="loading['visible']">
      <div class="loading-wrapper">
        <v-progress-circular v-if="loading['status'] == LOADING_PROCESSING" :size="48" color="#79c61c" indeterminate />
        <v-icon v-else-if="loading['status'] == LOADING_SUCCESS" :size="48" color="#79c61c" dark>mdi-check-bold</v-icon>
        <v-icon v-else-if="loading['status'] == LOADING_FAILURE" :size="48"
          color="#ff3c7e">mdi-alert-circle-outline</v-icon>

        <div class="error-message" v-if="loading['status'] == LOADING_FAILURE">
          <p class="text-danger">Something went wrong...</p>
          <p>The request could not be processed ({{ loading['errorMsg'] }}).</p>
        </div>
      </div>
    </div>

    <div class="sidebar-body">
      <div>
        <div class="field-id-submission">
          <p>Add fields by field ID:</p>
          <v-text-field v-model="fieldIdsCSVText" outlined label="Field IDs" />
          <v-btn @click="fetchNewFieldCrops" outlined height="56px">Submit</v-btn>
        </div>

        <p v-if="areFieldCropsSetForAddition">New fields/crops to be added to this grouping</p>
        <v-simple-table v-if="areFieldCropsSetForAddition">
          <thead>
            <tr>
              <th>Field Name</th>
              <th>ID</th>
              <th>Crops</th>
              <th>Planting Dates</th>
              <th>Due Date</th>
              <th class="text-center">Remove?</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="fieldCrops['toRemove'].includes(fieldSpec['id']) ? 'to-remove' : ''"
              v-for="fieldSpec, idx in getFieldCropsToAdd"
              :key="fieldSpec['id'] + '-' + idx"
            >
              <td>{{ fieldSpec['name'] }}</td>
              <td>{{ fieldSpec['id'] }}</td>
              <td class="capitalize">{{ getFormattedCrops(fieldSpec['crops']) }}</td>
              <td>{{ fieldSpec['plantings'].join('\n') || 'None' }}</td>
              <td>{{ fieldSpec['deadlines'].join('\n') || 'None' }}</td>
              <td class="action-cell" @click="handleDeleteField(fieldSpec['id'])">
                <v-icon>mdi-delete-outline</v-icon>
              </td>
            </tr>
            <tr v-for="fieldId, idx in Object.keys(getInvalidFieldCropsToAdd)" :key="fieldId + '-null-' + idx">
              <td>Not Found</td>
              <td>{{ fieldId }}</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td class="action-cell" @click="handleDeleteField(fieldId)">
                <v-icon>mdi-delete-outline</v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>


        <p>Remove fields by selecting in the table</p>
        <v-simple-table>
          <thead>
            <tr>
              <th>Field Name</th>
              <th>ID</th>
              <th>Crops</th>
              <th>Planting Dates</th>
              <th>Due Date</th>
              <th class="text-center">Remove?</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="fieldCrops['toRemove'].includes(fieldSpec['id']) ? 'to-remove' : ''"
              v-for="fieldSpec, idx in getValidExistingFieldCrops"
              :key="fieldSpec['id'] + '-' + idx"
            >
              <td>{{ fieldSpec['name'] }}</td>
              <td>{{ fieldSpec['id'] }}</td>
              <td class="capitalize">{{ getFormattedCrops(fieldSpec['crops']) }}</td>
              <td>{{ fieldSpec['plantings'].join('\n') || 'None' }}</td>
              <td>{{ fieldSpec['deadlines'].join('\n') || 'None' }}</td>
              <td class="action-cell">
                <v-checkbox v-model="fieldCrops['toRemove']" :value="fieldSpec['id']" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>

    <div class="sidebar-footer">
      <v-btn @click="cancel" outlined height="48px">Cancel</v-btn>
      <v-btn :disabled="!isSaveable" @click="apply" outlined height="48px">Save</v-btn>
    </div>
  </b-sidebar>
</template>

<script>
import {
  EVIDENCING_DATE_RULES,
  LOADING_SUCCESS,
  LOADING_FAILURE,
  LOADING_PROCESSING
} from "@/constants";
import { fetchFieldCrops, updateGroupFieldCrops } from "@/api/EvidencingAPI";
import Vue from "vue";
import { mapState } from "vuex";

export default {
  name: "EvidencingGroupMetadatEditingSidebar",
  props: {
    groupId: { required: true },
    visible: { required: true },
    detailSpec: { required: true }
  },
  emits: ["closeSidebar"],
  data() {
    return {
      show: false,
      cancelled: true,
      fieldIdsCSVText: '',
      fieldCrops: {},
      loading: {
        visible: false,
        status: null,
        errorMsg: ''
      },
      LOADING_PROCESSING,
      LOADING_FAILURE,
      LOADING_SUCCESS
    }
  },
  computed: {
    ...mapState({
      year: state => state.Organization.year
    }),
    areFieldCropsSetForRemoval() {
      if (
        this.fieldCrops['toRemove'] != null
        && this.fieldCrops['toRemove'].length > 0
      ) return true
      return false;
    },
    areFieldCropsSetForAddition() {
      if (
        this.fieldCrops['toAdd'] != null
        && Object.keys(this.fieldCrops['toAdd']).length > 0
      ) return true
      return false;
    },
    isSaveable() {
      if (this.areFieldCropsSetForRemoval) return true;
      if (this.areFieldCropsSetForAddition) return true;
      return false;        
    },
    getInvalidFieldCropsToAdd() {
      const fieldCrops = {};

      for (const fieldId in this.fieldCrops['toAdd']) {
        if (this.fieldCrops['toAdd'][fieldId] == null) {
          fieldCrops[fieldId] = this.fieldCrops['toAdd'][fieldId];
        };
      }

      return fieldCrops
    },
    getFieldCropsToAdd() {
      const fieldCrops = {};

      for (const fieldId in this.fieldCrops['toAdd']) {
        if (this.fieldCrops['toAdd'][fieldId] == null) continue;
        fieldCrops[fieldId] = this.fieldCrops['toAdd'][fieldId];
      }

      return fieldCrops
    },
    getValidExistingFieldCrops() {
      const fieldCrops = {};

      for (const key in this.fieldCrops) {
        if (['toRemove', 'toAdd'].includes(key)) continue;
        fieldCrops[key] = this.fieldCrops[key];
      }

      return fieldCrops
    }
  },
  methods: {
    handleDeleteField(fieldId) {
      Vue.delete(this.fieldCrops['toAdd'], fieldId);
    },
    getCropDueDate(cropId) {
      return EVIDENCING_DATE_RULES[cropId] || null
    },
    fetchNewFieldCrops() {
      const fieldIds = this.fieldIdsCSVText.split(',').map(f => f.trim());

      fetchFieldCrops({ fieldIds, year: this.year })
        .then(({ data }) => {
          for (const fieldId in data) {
            if (fieldId in this.detailSpec) {
              // don't re-add existing fields
              continue
            }
            else if (data[fieldId] == null) {
              Vue.set(this.fieldCrops['toAdd'], fieldId, null);
            }
            else {
              const cropsFilteredByPlantingDate = data[fieldId]['crops']
                .filter(({ plantings }) => plantings.length > 0)
                .toSorted((a, b) => a['id'] - b['id']);
              
              const deadlines = cropsFilteredByPlantingDate.map(({ id }) => this.getCropDueDate(id));
              const plantings = cropsFilteredByPlantingDate.map(({ plantings }) => plantings);

              Vue.set(this.fieldCrops['toAdd'], fieldId, {
                id: fieldId,
                name: data[fieldId]['name'],
                client: data[fieldId]['client'],
                crops: cropsFilteredByPlantingDate,
                deadlines,
                plantings
              });
            }
          }
          
          this.loadingFieldsFetch = false;
        })
        .catch(e => {
          console.log('server error: ', e);
          this.fieldsFetchError = e;
          this.loadingFieldsFetch = false;
        });
    },
    getFormattedCrops(crops) {
      const cropNames = [];

      for (const crop of crops) {
        if (!cropNames.includes(crop['name'])) {
          cropNames.push(crop['name']);
        }
      }

      return cropNames.join('\n');
    },
    removeSidebar() {
      this.$emit('closeSidebar', this.cancelled);
      this.cancelled = true;
      this.fieldIdsCSVText = '';
      this.fieldCrops = {};
      this.loading = {
        visible: false,
        status: null,
        errorMsg: ''
      }
    },
    cancel() {
      this.cancelled = true;
      this.show = false;
    },
    apply() {
      const condensedSpec = {
        toRemove: this.fieldCrops['toRemove'],
        toAdd: {}
      };

      for (const fieldId in this.fieldCrops['toAdd']) {
        if (this.fieldCrops['toAdd'][fieldId] != null) {
          condensedSpec['toAdd'][fieldId] = this.fieldCrops['toAdd'][fieldId];
        }
      }

      this.loading['visible'] = true;
      this.loading['status'] = LOADING_PROCESSING;
      // api call to modify the group
      updateGroupFieldCrops({ year: this.year, id: this.groupId, ...condensedSpec})
      .then(_ => {
        this.loading['status'] = LOADING_SUCCESS;
        
        setTimeout(() => {
          this.cancelled = false;
          this.show = false;
        }, 1000);
      })
      .catch(err => {
        this.loading['status'] = LOADING_FAILURE;
        this.loading['errorMsg'] = err;

        setTimeout(() => {
          this.cancelled = true;
          this.show = false;
        }, 4000)
      });
    }
  },
  watch: {
    visible(curr) {
      this.show = curr;
    },
    show(curr) {
      if (!curr) this.removeSidebar();
    },
    detailSpec: {
      handler(curr) {
        if (curr != null) {
          const newSpec = {
            toRemove: [],
            toAdd: {},
            ...curr
          };

          Vue.set(this, 'fieldCrops', newSpec)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="css" scoped>
::v-deep(#evidencing-group-fields-editing-sidebar) {
  background: #FFFFFF !important;
}

p {
  color: #000000;
}

::v-deep #evidencing-group-fields-editing-sidebar header {
  padding: 24px 36px;
}

::v-deep #evidencing-group-fields-editing-sidebar header .close {
  margin-right: 16px !important;
}

::v-deep #evidencing-group-fields-editing-sidebar header strong {
  margin-right: auto;
  text-transform: capitalize;
}

.sidebar-body {
  padding: 0 36px 84px;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  background: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 24px;
  display: flex;
  align-items: center;
}

.sidebar-footer>button {
  float: left;
  margin-right: 16px;
}

.editor-field p {
  margin: 0 0 2px;
}

.group-loading-modal {
  position: fixed;
  left: calc(50% - 150px);
  top: calc(50% - 150px);
  width: 300px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  z-index: 99999;
}

.group-loading-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 99998;
}

.loading-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px;
}

.loading-wrapper i {
  margin-right: 16px;
}

.loading-wrapper p {
  margin: 0 0 8px 0;
}

.loading-wrapper p:last-of-type {
  margin: 0;
}
.field-id-submission {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.field-id-submission p {
  width: 100%;
}
.field-id-submission > button {
  margin-left: 16px;
}

.v-data-table {
  background-color: #F7F8FA;
  max-height: 400px;
  overflow-y: scroll;
  margin-bottom: 24px;
}
td.capitalize {
  text-transform: capitalize;
}
td.action-cell {
  cursor: pointer;
  text-align: center;
}
td.action-cell:hover {
  opacity: 0.5;
}
.v-input {
  margin: 0;
  padding: 0;
}
::v-deep(.v-text-field__details),
::v-deep(.v-messages) {
  display: none;
}
::v-deep(.v-input__slot) {
  margin: 0;
  justify-content: center;
}
::v-deep(.v-input--selection-controls__input) {
  margin: 0;
}
.to-remove td:not(.action-cell) {
  text-decoration: line-through;
}
</style>