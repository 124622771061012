<template>
  <b-tr>
    <!-- removal type -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(biomass.removal_type)"
    >
      <PracticeChoiceDropdown
        clearable
        dense
        hide-details="true"
        :items="removalTypeChoices"
        item-text="display_name"
        item-value="value"
        v-model="removalType"
        choiceName="pasture_biomass_removal_type"
        :append-icon="
          biomass.removal_type.source !== 'User Input' &&
          biomass.removal_type.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('removalType', removalType)"
      />
    </b-td>
    <!-- date -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(biomass.removal_date)"
    >
      <v-menu
        v-model="showDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="removalDate"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="
                  biomass.removal_date.source !== 'User Input' &&
                  biomass.removal_date.value !== null
                    ? 'mdi-check-circle-outline'
                    : ''
                "
                @click:append="handleClickSubmit('removalDate', removalDate)"
              ></v-text-field>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="removalDate"
          :min="year + '-01-01'"
          :max="year + '-12-31'"
          @input="showDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- percent removed -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(biomass.percent_removed)"
    >
      <v-text-field
        clearable
        dense
        v-model="percentRemoved"
        hide-details="true"
        :append-icon="
          biomass.percent_removed.source !== 'User Input' &&
          biomass.percent_removed.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('percentRemoved', percentRemoved)"
      >
      </v-text-field>
    </b-td>
    <b-td class="cell-table white">
      <v-btn icon x-small color="black" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn icon x-small color="black" @click="handleDelete">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>.
    </b-td>
  </b-tr>
</template>
<script>
import { mapMutations, mapGetters } from "vuex"
import { Defaults } from "@/store/modules"
import { getClassForCell } from "../utils"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"

export default {
  name: "RanchBiomassRow",
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    biomass: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
  },
  components: {
    PracticeChoiceDropdown,
  },
  data() {
    return {
      showDate: false,
      getClassForCell,
    }
  },
  computed: {
    ...mapGetters({
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    removalTypeChoices() {
      return this.practiceChoices["pasture_biomass_removal_type"]
    },
    removalType: {
      get() {
        return this.biomass.removal_type.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "biomass",
          dataSetting: "removal_type",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    removalDate: {
      get() {
        if ("removal_date" in this.biomass) {
          const dateReturn = this.biomass.removal_date.value
            ? this.biomass.removal_date.value
            : `${this.year}-01-01`
          return dateReturn
        } else {
          return ""
        }
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "biomass",
          dataSetting: "removal_date",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    percentRemoved: {
      get() {
        return this.biomass.percent_removed.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "biomass",
            dataSetting: "percent_removed",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.biomass)
    },
    handleAdd() {
      const payloadObj = {
        crop_id: { source: "User Input", value: this.cropId },
        removal_type: { source: "User Input", value: null },
        removal_date: { source: "User Input", value: null },
        percent_removed: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "biomass",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.biomass)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "biomass",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleValidateValue(val, type, length = 24) {
      function isNumeric(val) {
        return !isNaN(val) && !isNaN(parseFloat(val));
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      }
    },
    filter(event) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()

      let validVal = this.handleValidateValue(expect, "number", 24)
      if (!validVal) event.preventDefault()
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.cell-table {
  padding: 2px 6px;
}

.pre-gen {
  background-color: #fedb80;
}

.bg-white {
  color: white;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.machine {
  background-color: #c0dce2;
}

.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.v-input {
  font-size: 18px;
}
</style>
