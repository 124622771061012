<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <!-- Irrigation Info -->
      <div id="input-card-container">
        <v-card class="my-auto mx-auto" v-if="showPracticesView || showSaveView || showSummaryView">
          <!-- Add Practices View -->
          <v-card-text class="min-step-height" v-if="showPracticesView">
            <v-row class="mx-1 mt-4 mb-1">
              <div class="text-h5">Add Irrigation for {{ year }}</div>

              <v-btn class="ml-auto" @click="handleCancelClick()">
                <v-icon>mdi-close</v-icon> Cancel
              </v-btn>

              <v-btn
                class="ml-4"
                color="green lighten-4"
                :disabled="allIrrigsComplete().length > 0"
                @click="handlePreSave()"
              >
                Save and Continue
              </v-btn>
            </v-row>

            <v-row class="mx-1 mt-7">
              <v-btn-toggle v-model="hasPracticeData" readonly divided dense mandatory active-class="light-blue lighten-4">
                <v-btn :value="true" class="px-3" @click="handleYesIrrigationClick">
                  Irrigated <v-icon>mdi-check-bold</v-icon>
                </v-btn>
                <v-btn :value="false" class="px-3" @click="handleNoIrrigationClick">
                  No Irrigation <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle class="ml-5" v-model="stackPractices" divided dense active-class="light-blue lighten-4" mandatory>
                <v-btn :value="true" :disabled="!hasPracticeData" class="px-3">Stack Practices</v-btn>
                <v-btn :value="false" class="px-3">Replace Practices</v-btn>
              </v-btn-toggle>
            </v-row>

            <v-row class="mx-1 mt-5 mb-1">

              <v-btn-toggle v-model="drainageChoice" readonly divided dense mandatory active-class="light-blue lighten-4">
                <v-btn :value="true" class="px-3" @click="handleYesDrainageClick">
                  Drainage <v-icon>mdi-check-bold</v-icon>
                </v-btn>
                <v-btn :value="false" class="px-3" @click="handleNoDrainageClick">
                  No Drainage <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-btn-toggle>

            </v-row>

            <v-row v-if="irrigationData.length > 0">
              <v-col md="12">
                <v-card
                  v-for="irrig in irrigationData"
                  :key="irrig.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor blue-grey lighten-5"
                    @click="irrig.showAllInfo = !irrig.showAllInfo"
                    >Irrigation #{{ irrigationData.indexOf(irrig) + 1 }}
                    <v-icon
                      v-if="!irrig.showAllInfo"
                      @click="irrig.showAllInfo = true"
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else @click="irrig.showAllInfo = false"
                      >mdi-chevron-down</v-icon
                    >
                    <AudioRecorder
                       model="irrigation"
                       :practiceIndex="irrigationData.indexOf(irrig)"
                       @practice-response="handleSpeechToPractice"
                       :year="Number(year)"
                      />
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            isIrrigComplete(irrig)
                              ? 'green accent-4'
                              : 'red darken-1'
                          "
                        >
                          {{
                            isIrrigComplete(irrig)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        isIrrigComplete(irrig)
                          ? "Application is good to go!"
                          : "You're missing a few fields, please complete this application to save and continue!"
                      }}</span></v-tooltip
                    >
                    <v-btn
                      class="ml-2"
                      @click.stop="handleRemoveIrrig(irrigationData.indexOf(irrig))"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="irrig.showAllInfo">
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >On which crop was this applied?<span
                              class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Crop"
                            :disabled="
                              cropSelect ? cropSelect.length == 1 : false
                            "
                            :items="cropChoicesFiltered"
                            v-model="irrig.crop_id"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >Describe your drainage<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Drainage"
                            :disabled="drainageChoice == false"
                            :items="drainageChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="irrig.drainage"
                            clearable
                          ></v-select>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >Irrigation Start Date<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="irrig.startMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="irrig.start_date"
                                label="Start Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="irrig.start_date"
                              :picker-date.sync="irrig.startPicker"
                              :min="year + '-01-01'"
                              :max="year + '-12-31'"
                              @input="irrig.startMenu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b class="text-h7"
                            >Irrigation End Date<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="irrig.endMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="irrig.end_date"
                                label="End Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="irrig.end_date"
                              :picker-date.sync="irrig.endPicker"
                              :min="year + '-01-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="irrig.endMenu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b class="text-h7"
                            >Amount<span class="red--text">*</span></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Amount"
                            v-model="irrig.amount"
                            :rules="[numberValidator]"
                            type="number"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b class="text-h7"
                            >Units<span class="red--text">*</span></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Units"
                            :items="irrigationUnitChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="irrig.unit"
                          ></v-select>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b class="text-h7"
                            >Irrigation Method<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <PracticeChoiceDropdown
                            dense
                            outlined
                            hide-details
                            label="Method"
                            :items="irrigationMethodChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="irrig.method"
                            choiceName="irrigation_method"
                            clearable
                          />
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b class="text-h7"
                            >Method Type<span class="red--text">*</span></b
                          >
                        </div>
                        <div class="input-holder">
                          <PracticeChoiceDropdown
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Method Type"
                            :items="methodTypeChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="irrig.method_type"
                            choiceName="irrigation_method_type"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b class="text-h7"
                            >Was this field flooded for wildlife?</b
                          >
                        </div>
                        <div class="pt-2">
                          <v-btn
                            rounded
                            outlined
                            @click="irrig.wildlife_flood = true"
                            :input-value="irrig.wildlife_flood === true"
                            >Yes</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="irrig.wildlife_flood = false"
                            :input-value="irrig.wildlife_flood === false"
                            >No</v-btn
                          >
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b class="text-h7"
                            >(Rice Only) Did you use AWD this year?</b
                          >
                        </div>
                        <div class="input-holder">
                          <v-btn
                            rounded
                            outlined
                            @click="irrig.awd = true"
                            :disabled="irrig.crop_id != 'rice'"
                            :input-value="irrig.awd === true"
                            >Yes</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="irrig.awd = false"
                            :disabled="irrig.crop_id != 'rice'"
                            :input-value="irrig.awd === false"
                            >No</v-btn
                          >
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b class="text-h7"
                            >(Rice Only) How many times was AWD performed?</b
                          >
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="AWD #"
                            :disabled="irrig.crop_id != 'rice'"
                            v-model="irrig.awd_times"
                            type="number"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn class="mt-4" block @click="handleAddEventClick">
                  Add Irrigation Event <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="irrigationData.length == 0">
              <v-card class="mx-auto mt-4" elevation="0">
                <v-card-text>
                  <div class="text-center">
                    <b class="text-h5 text-bold"
                      >No Irrigation for {{ this.year }}</b
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
          <!-- Save Spinner View -->
          <v-card-text class="min-step-height text-center" v-if="showSaveView">
            <v-progress-circular
              class="mt-4"
              :size="100"
              :width="8"
              color="green"
              indeterminate
            >
            </v-progress-circular>
            <div>
              <h3>Saving your irrigation information</h3>
            </div>
          </v-card-text>
          <!-- Summary View -->
          <v-card-text class="min-step-height" v-if="showSummaryView">
            <div class="d-flex">
              <h3>Fields Updated</h3>
              <v-btn class="ml-auto" @click="handleAddMoreIrrigations">
                Add More Irrigation
              </v-btn>
            </div>
            <div class="d-flex">
              <v-chip
                class="mx-2"
                v-for="fieldName in summaryFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3>Irrigation Data Submitted</h3>
            <div>
              <v-simple-table class="mx-4 mb-4">
                <thead>
                  <tr>
                    <th v-for="key in summaryKeys" :key="key.idx">
                      {{ key == "crop_id" ? "Crop" : key | cleanSnake }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in summaryData" :key="data.idx">
                    <td v-for="key in summaryKeys" :key="key.idx">
                      <span v-if="key == 'crop_id'">{{
                        cropDisplay[data[key].value]
                      }}</span>
                      <span v-else>
                        {{
                          data[key].value
                            ? data[key].value
                            : "None" | cleanSnake
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <!-- Fields, Data Comp, and Add Practices -->
      <v-row class="mt-4 mb-2 row-margin-correct" v-if="!showPracticesView && !showSaveView">
        <div>
          <span class="text-h5 ml-1"
            >{{ opsSettingsByField.length }} fields</span
          >
          <span class="text-h5 ml-2"
            >({{ dataCompletionForYear }}% Complete)</span
          >
        </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              class="mx-1"
              text
              v-for="y in yearListModified"
              :key="y.idx"
              @click="handleSeasonSelection(y)"
              :color="year == y ? 'green' : ''"
              >{{ y }}</v-btn
            >
          </div>
          <div class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div class="ml-2">
            <v-btn
              class="white--text"
              color="green"
              x-large
              :disabled="fieldsInSelection.length === 0"
              @click="handleAddPracticesClick"
              >Add Practices</v-btn
            >
          </div>
        </div>
      </v-row>
      <v-row class="mt-4 mb-2 row-margin-correct" v-else>
        <div>
          <span class="text-button ml-1">Receiving fields</span>
        </div>
      </v-row>

      <!-- Quick Filtering Buttons -->
      <v-row class="mt-4 row-margin-correct">
        <v-btn
          class="mr-3 all-button"
          @click="handleSelectAll(true, true)"
          text
          :color="cropSelect === null ? 'green' : ''"
        >
          <span>All</span>
          <span>({{ opsSettingsByField.length }})</span>
        </v-btn>
        <v-btn
          class="mr-3"
          text
          v-for="sharedCropPlan in historicalPlantings"
          :key="sharedCropPlan.idx"
          :color="
            JSON.stringify(cropSelect) ==
            JSON.stringify(sharedCropPlan.crops.split(','))
              ? 'green'
              : ''
          "
          @click="handleCropFilterButton(sharedCropPlan)"
        >
          <span>{{ mapCropNames(sharedCropPlan.crops) }}</span>
          <span>({{ sharedCropPlan.fieldIds.length }})</span>
        </v-btn>
      </v-row>
      <!-- Field Filtering Table -->
      <v-row class="pt-3">
        <v-col>
          <v-card>
            <v-card-text v-if="showSelectedFields">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three">
                      <div class="d-flex">
                        <v-simple-checkbox
                          color="blue"
                          v-model="selectAllBox"
                          @input="handleSelectAll($event)"
                        ></v-simple-checkbox>
                      </div>
                    </th>
                    <th class="ten">Name</th>
                    <th class="three">Farm</th>
                    <th class="three">Locked</th>
                    <th class="three">Crops</th>
                    <th class="three">Drainage</th>
                    <th>Irrigation Events</th>
                    <th class="three">
                      <div>
                        Copy
                        <!-- <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on"
                              >mdi-information</v-icon
                            ></template
                          ><span
                            >Click copy to copy a field's settings quickly to set
                            multiple fields at once.</span
                          ></v-tooltip
                        > -->
                      </div>
                    </th>
                    <th v-if="showPracticesView">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in opsSettingsbyFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <v-simple-checkbox
                        color="blue"
                        :value="
                          fieldsInSelection.includes(parseInt(field.fieldId))
                        "
                        :disabled="
                          disableCheckbox(field.noYearData) || field.fieldLocked
                        "
                        @input="fieldChecked(field, $event)"
                      ></v-simple-checkbox>
                    </td>
                    <td>{{ field.fieldName }}</td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>
                      <v-icon
                        size="20"
                        :color="field.fieldLocked ? 'red' : 'green'"
                      >
                        {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                      </v-icon>
                    </td>
                    <td v-if="'cropNames' in field">
                      <div v-for="crop in field.cropNames" :key="crop">
                        {{ crop }}
                      </div>
                    </td>
                    <td v-else>
                      <div>No Crop</div>
                    </td>
                    <td>
                      <div
                        v-for="opsSingle in field.opsInfo"
                        :key="opsSingle.idx"
                      >
                        {{
                          opsSingle.drainage.drainage_exists.value != null
                            ? opsSingle.drainage.drainage_exists.value.toString()
                            : "None" | capitalize
                        }}
                      </div>
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                      <div
                        v-for="opsSingle in field.opsInfo"
                        :key="opsSingle.idx"
                      >
                        <v-row
                          class="mx-0"
                          :class="
                            opsSingle.irrigations.indexOf(irrigInfo) !=
                            opsSingle.length - 1
                              ? 'row-bottom'
                              : ''
                          "
                          dense
                          v-for="irrigInfo in opsSingle.irrigations.sort(
                            (a, b) => a.crop_id.value - b.crop_id.value
                          )"
                          :key="irrigInfo.idx"
                        >
                          <v-col md="2">
                            <span
                              :class="
                                irrigInfo.start_date.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                irrigInfo.start_date.value
                                  ? irrigInfo.start_date.value
                                  : "No Start"
                              }}
                            </span>
                          </v-col>
                          <v-col md="2">
                            <span
                              :class="
                                irrigInfo.end_date.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                irrigInfo.end_date.value
                                  ? irrigInfo.end_date.value
                                  : "No End"
                              }}
                            </span>
                          </v-col>
                          <v-col md="2">
                            <span
                              :class="
                                irrigInfo.method.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                irrigInfo.method.value
                                  ? irrigInfo.method.value
                                  : "No Method" | cleanSnake
                              }}
                            </span>
                          </v-col>
                          <v-col md="3">
                            <span
                              :class="
                                irrigInfo.amount.value !== null
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                irrigInfo.amount.value !== null
                                  ? irrigInfo.amount.value
                                  : "No Amount"
                              }}
                            </span>
                            <span
                              :class="
                                irrigInfo.unit.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                irrigInfo.unit.value
                                  ? irrigInfo.unit.value
                                  : "No Units"
                              }}
                            </span>
                          </v-col>
                          <!-- <v-col md="2">{{
                          irrigInfo.unit.value
                            ? irrigInfo.unit.value
                            : "No Units" | cleanSnake
                        }}</v-col> -->
                          <v-col md="3">
                            <div
                              class="d-flex"
                              v-if="irrigationComplete(irrigInfo)"
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="success"
                                    >mdi-check-circle</v-icon
                                  ></template
                                ><span
                                  >You've filled out enough information for Arva
                                  data models to run. Thank you!</span
                                ></v-tooltip
                              >
                              <span class="ml-2">Data Complete</span>
                            </div>
                            <div
                              class="d-flex"
                              v-if="irrigationNeedsConfirm(irrigInfo)"
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon v-bind="attrs" v-on="on" color="red"
                                    >mdi-alert-circle</v-icon
                                  ></template
                                ><span
                                  >This information is coming from a public
                                  source. Please include this field when setting
                                  information to confirm it's data!</span
                                ></v-tooltip
                              >
                              <span
                                class="ml-2 cursor blue--text lighten-1 text-bold"
                                @click="handleConfirm(field)"
                                >Confirm Data</span
                              >
                            </div>
                            <div
                              class="d-flex"
                              v-if="irrigationMissingData(irrigInfo)"
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon v-bind="attrs" v-on="on" color="red"
                                    >mdi-alert-circle</v-icon
                                  ></template
                                ><span
                                  >This field is missing data. Please fill in
                                  its data using the wizard!</span
                                ></v-tooltip
                              >
                              <span class="ml-2">Missing Data</span>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </td>
                    <td v-else>
                      <div @click="handleShowRedirect(field)">
                        <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on" color="red"
                              >mdi-alert-circle</v-icon
                            ></template
                          ><span
                            >This field doesn't have data for this year. Add
                            another year in the data spreadsheet or upload data
                            from this year.</span
                          ></v-tooltip
                        ><span class="ml-2 cursor red--text text-bold"
                          >No Data for Year</span
                        >
                      </div>
                    </td>
                    <td class="cursor" @click="handleCopy(field)">
                      <span class="blue--text lighten-1 text-bold">Copy</span>
                    </td>
                    <td v-if="showPracticesView">
                      <span class="py-2" v-if="!field.noYearData && allIrrigsComplete().length === 0">
                        <div v-for="opsSingle in field.opsInfo" :key="opsSingle.idx">
                        <v-row
                          class="mx-0"
                          :class="
                            opsSingle.irrigations.indexOf(irrigInfo) !=
                            opsSingle.length - 1
                              ? 'row-bottom'
                              : ''
                          "
                          dense
                          v-for="irrigInfo in opsSingle.irrigations.sort(
                            (a, b) => a.crop_id.value - b.crop_id.value
                          )"
                          :key="irrigInfo.idx"
                        >
                          <v-tooltip v-if="stackPractices && isAnyDuplicate(irrigInfo)" top max-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="orange">mdi-alert</v-icon>
                            </template>
                            <span>Can not stack duplicate practice on this field.</span>
                          </v-tooltip>
                          <v-tooltip v-if="isUntargetedCrop(irrigInfo)" top max-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="orange">mdi-selection-ellipse-remove</v-icon>
                            </template>
                            <span>No practice data is currently being added for {{ cropDisplay[irrigInfo.crop_id.value] }}.</span>
                          </v-tooltip>
                        </v-row>
                      </div>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />

      <!-- Bad Irrigation Dates -->
      <AlertErrorModal
        v-if="irrigDatesModalOpen"
        titleText="Error When Saving Irrigation"
        :errorMessage="irrigDatesMessage"
        @close-modal="irrigDatesModalOpen = false"
      />

      <!-- Wizard Confirm Modal -->
      <WizardConfirmModal
        v-if="showConfirmModal"
        :confirmationField="confirmationField"
        :confirmationData="confirmationIrrigation"
        :wantedKeys="[
          'drainage',
          'start_date',
          'end_date',
          'amount',
          'unit',
          'method',
        ]"
        :year="year"
        :loadState="loadState"
        @close-template-modal="handleCloseConfirm"
        @confirm-data="handleModalConfirm"
      />

      <ConfirmModal
        v-if="showSaveConfirmModal"
        titleText="Confirm Practice Replacement"
        :confirmText="saveConfirmText"
        @confirm="handleSave"
        @close-modal="showSaveConfirmModal = false"
      />

      <!-- Redirect -->
      <WizardRedirectModal
        v-if="showRedirectModal"
        :redirectField="redirectField"
        :year="year"
        @close-template-modal="handleCloseRedirect"
        @redirect-confirm="handleRedirectConfirm"
      />
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Defaults, Organization } from "@/store/modules"
import {
  CROP_KEY,
  CROP_ID_KEY,
  CROP_DISPLAY_NAME,
} from "@/constants"
import {
  MAX_DEFAULT_FIELDS,
} from "@/constants/defaults"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardConfirmModal from "@/components/modals/WizardConfirmModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import ConfirmModal from "@/components/modals/ConfirmModal"
import { numberValidator } from "@/utility"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"
import { mapPayloadKeysToUserInput, dupeCheck } from "./utils"
import AudioRecorder from './AudioRecorder.vue';
import Vue from "vue"

export default {
  name: "NewIrrigationWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
    dataCompletion: { type: Array },
    dataCompletionMaster: { type: Object },
  },
  components: {
    AlertErrorModal,
    WizardConfirmModal,
    WizardRedirectModal,
    PracticeChoiceDropdown,
    ConfirmModal,
    AudioRecorder
  },
  data() {
    return {
      multipleIrrigations: null, // looking for either total or multiple description
      // constants
      cropDisplay: CROP_DISPLAY_NAME,
      //   data values
      drainageChoice: false,
      methodChoice: null,
      cropChoices: Object.values(CROP_KEY),
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,
      // new data format
      irrigationData: [],
      stackPractices: true,
      //   show secondary question toggles
      showDrainageSelect: false,
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      tableFilterText: "",
      selectAllBox: true,
      // input rules
      numberValidator,
      // error modal
      alertErrorModalOpen: false,
      // full validation for irrigation completeness
      showIncompleteModal: false,
      incompleteIrrigations: null,
      // confirmation
      confirmationField: null,
      confirmationIrrigation: null,
      showConfirmModal: false,
      showSaveConfirmModal: false,
      loadState: null,
      // redirection
      showRedirectModal: false,
      redirectField: null,
      // select crop type UX
      cropSelect: null,
      // irrig dates window UX
      irrigDatesModalOpen: false,
      irrigDatesMessage: null,
      //   new UI
      showPracticesView: false,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      summaryKeys: null,
      summaryData: null,
    }
  },
  computed: {
    ...mapState({
      opsSettings: state => state.Defaults.newFieldSettings,
    }),
    ...mapGetters({
      recentWizardData: Defaults.Getters.getRecentWizardData,
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    drainageChoices() {
      return this.practiceChoices["drainage_method"]
    },
    irrigationMethodChoices() {
      return this.practiceChoices["irrigation_method"]
    },
    methodTypeChoices() {
      return this.practiceChoices["irrigation_method_type"]
    },
    irrigationUnitChoices() {
      return this.practiceChoices["irrigation_units"]
    },
    cropChoicesFiltered() {
      if (this.cropSelect != null) {
        const filteredCropList = Object.entries(CROP_KEY)
          .filter(o => {
            if (this.cropSelect.length > 1) {
              return this.cropSelect.includes(o[0])
            } else {
              return true
            }
          })
          .map(list => list[1])
        return filteredCropList
      } else {
        const selectedOpSettings = this.opsSettingsByField.filter(field =>
          this.fieldsInSelection.includes(parseInt(field.fieldId))
        )
        const selectedCrops = new Set(
          selectedOpSettings.flatMap(field => field.crops)
        )
        const selectedCropNames = Array.from(selectedCrops).map(
          c => CROP_KEY[c]
        )
        return selectedCropNames.filter(i => i != undefined)
      }
    },
    hasPracticeData() {
      return this.irrigationData.length > 0 ? true : false;
    },
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0) {
        return 0
      }
      let yearDataComps = this.dataCompletionMaster["Irrigation"].map(o => {
        if ("by_year" in o.data_comp) {
          let temp = o.data_comp.by_year.find(
            yearObj => yearObj.year == this.year
          )
          if (temp) {
            return temp.avg_for_year
          }
        } else {
          return 0
        }
      })
      let average = array => array.reduce((a, b) => a + b) / array.length
      if (yearDataComps.length > 0) {
        return Math.ceil(average(yearDataComps))
      } else {
        return 0
      }
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.opsSettingsByField) {
        fieldLocks[field.fieldId] = field.fieldLocked
      }
      return fieldLocks
    },
    saveConfirmText() {
      let countText;
      if (this.irrigationData.length) {
        countText = `${this.irrigationData.length} irrigation` + (this.irrigationData.length > 1 ? "s" : "")
      } else {
        countText = `No Irrigation`
      }
      return `
        Are you sure you want to replace practices on the ${this.fieldsInSelection.length} selected fields for ${this.year}?
        <br /><br />
        Existing irrigation will be removed and replaced with ${countText}.`
    },
    opsSettingsByField() {
      let opsDict = {}
      let yearInConsderation = this.year
      Object.entries(this.opsSettings).forEach(opsDefault => {
        const fieldKey = opsDefault[0]

        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        opsDict[fieldKey] = {}
        const fieldSettingObj = opsDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          opsId: fieldSettingObj.operation_id,
        }

        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsderation
        )

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = ["No Data"]
            opsDict[fieldKey]["cropNames"] = ["No Data"]
            opsDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsderation) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = []
            opsDict[fieldKey]["cropNames"] = []
            opsDict[fieldKey]["cropSearch"] = ""
            opsDict[fieldKey]["plantings"] = []
            opsDict[fieldKey]["harvests"] = []
            opsDict[fieldKey]["opsInfo"] = []

            opsDict[fieldKey]["isSelected"] = this.fieldsInSelection.includes(
              parseInt(fieldKey)
            )
              ? true
              : false

            const cultivations = yearData.cultivations
            cultivations.forEach(cultivation => {
              opsDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
              opsDict[fieldKey]["cropNames"].push(
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              )
              opsDict[fieldKey]["cropSearch"] =
                opsDict[fieldKey]["cropSearch"] +
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              opsDict[fieldKey]["plantings"] = opsDict[fieldKey][
                "plantings"
              ].concat(cultivation.plantings)
              opsDict[fieldKey]["harvests"] = opsDict[fieldKey][
                "harvests"
              ].concat(cultivation.harvests)
              opsDict[fieldKey]["opsInfo"].push(cultivation.operations)

              opsDict[fieldKey]["crops"].sort()
              opsDict[fieldKey]["cropNames"].sort()
              opsDict[fieldKey]["fieldLocked"] = cultivation.year_data_locked
              opsDict[fieldKey]["noYearData"] = false
            })
          }
        })
      })
      return Object.values(opsDict)
    },
    opsSettingsbyFieldFiltered() {
      let arrayToReturn = _.cloneDeep(this.opsSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        arrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
      }

      const sortColumns = ["fieldName"]
      const sortDirections = ["desc", "asc"]

      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    historicalPlantings() {
      const something = _(this.opsSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
        }))
        .value()
      return something
    },
    filterCropList() {
      return [
        ...new Set(this.opsSettingsByField.map(f => f.cropNames.join(", "))),
      ]
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updateIrrigation: Defaults.Actions.updateIrrigation,
    }),
    ...mapMutations({
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    isAnyDuplicate(existingIrrig) {
      for (const newIrrig of this.irrigationData) {
        if (this.isIrrigDuplicate(existingIrrig, mapPayloadKeysToUserInput(newIrrig))) {
          return true
        }
      }
      return false
    },
    isUntargetedCrop(irrig) {
      if (this.irrigationData.length == 0) {
        return false
      }
      const targetedCrops = this.irrigationData.map(irr => CROP_ID_KEY[irr.crop_id]);
      return irrig.crop_id.value && !targetedCrops.includes(irrig.crop_id.value);
    },
    isIrrigDuplicate(irrig1, irrig2) {
      const duplicateItems = [
        dupeCheck(irrig1.amount.value, irrig2.amount.value),
        irrig1.unit.value == irrig2.unit.value,
        irrig1.method.value == irrig2.method.value,
        irrig1.method_type.value == irrig2.method_type.value,
        irrig1.drainage.value == irrig2.drainage.value,
        irrig1.awd.value == irrig2.awd.value,
        irrig1.awd_times.value == irrig2.awd_times.value,
        irrig1.wildlife_flood.value == irrig2.wildlife_flood.value,
        irrig1.source_of_water.value == irrig2.source_of_water.value,
      ];
      const numDuplicateItems = duplicateItems.filter(Boolean).length;

      return (
        irrig1.crop_id.value == irrig2.crop_id.value &&
        irrig1.start_date.value == irrig2.start_date.value &&
        irrig1.end_date.value == irrig2.end_date.value &&
        numDuplicateItems >= duplicateItems.length - 1
      )
    },
    isIrrigNone(irrig) {
      return (
        (irrig.amount == 0 || irrig.amount == null) &&
        (irrig.method == "none" || irrig.method == null) &&
        (irrig.method_type == "none" || irrig.method_type == null)
      )
    },
    mergeFieldPayload(field, irrigPayload) {
      if (this.irrigationData.length == 0 || !this.stackPractices) {
        return irrigPayload;
      }
      let fieldIrrigData = this.extractIrrigData(field);
      fieldIrrigData = fieldIrrigData.filter(cp => !this.isIrrigNone(cp));
      let mappedData = fieldIrrigData.map(cp => {
        return mapPayloadKeysToUserInput(cp);
      });
      mappedData = mappedData.filter(existingIrrig => !irrigPayload.some(newIrrig => this.isIrrigDuplicate(existingIrrig, newIrrig)));

      const mergedPayload = irrigPayload.concat(mappedData);
      return mergedPayload;
    },
    extractIrrigData(field) {
      const fieldIrrigations = []
      field.opsInfo.forEach(totalOps => {
        totalOps.irrigations.forEach(irrig => fieldIrrigations.push(irrig))
      })

      const extractedData = []
      fieldIrrigations.forEach(irrig => {
        extractedData.push({
          amount: irrig.amount.value,
          awd: irrig.awd.value,
          awd_times: irrig.awd_times.value,
          crop_id: CROP_KEY[irrig.crop_id.value],
          drainage: irrig.drainage.value,
          efficiency: irrig.efficiency.value,
          end_date: irrig.end_date.value,
          method: irrig.method.value,
          method_type: irrig.method_type.value,
          source_of_water: irrig.source_of_water.value,
          start_date: irrig.start_date.value,
          unit: irrig.unit.value,
          wildlife_flood: irrig.wildlife_flood.value,
          showAllInfo: true,
          startMenu: false,
          startPicker: this.year + "-01",
          endMenu: false,
          endPicker: this.year + "-01",
        })
      })
      return extractedData
    },
    handleCopy(field) {
      this.cropSelect = null
      this.drainageChoice = false

      const copiedData = this.extractIrrigData(field)
      for (const practice of copiedData) {
        if (!this.cropChoicesFiltered.includes(practice.crop_id)) {
          practice.crop_id = null
        }
      }
      this.irrigationData = copiedData


      const drainageExists = field.opsInfo[0].drainage.drainage_exists.value
        ? field.opsInfo[0].drainage.drainage_exists.value
        : false

      this.drainageChoice = drainageExists

      this.showSummaryView = false
      this.showPracticesView = true

      const myElement = document.getElementById("input-card-container")
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    },
    handleConfirm(field) {
      this.confirmationField = _.cloneDeep(field)
      this.confirmationIrrigation = []
      field.opsInfo.forEach(ops => {
        ops.irrigations.forEach(irri =>
          this.confirmationIrrigation.push(_.cloneDeep(irri))
        )
      })
      this.confirmationIrrigation.forEach(irri => {
        for (const key in irri) {
          irri[key].source = "User Input"
        }
      })
      this.showConfirmModal = true
    },
    async handleModalConfirm() {
      this.loadState = "loading"
      const drainageConfirm =
        this.confirmationIrrigation[0]["drainage"]["value"]
      await this.updateIrrigation({
        fieldId: this.confirmationField.fieldId,
        year: this.year,
        irrigation: this.confirmationIrrigation,
        drainage: {
          drainage_exists: { value: drainageConfirm, source: "User Input" },
        },
      })
      this.loadState = "confirmed"
      this.$emit("update-data-complete")
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.handleCloseConfirm()
    },
    handleCloseConfirm() {
      this.showConfirmModal = false
      this.confirmationField = null
      this.confirmationIrrigation = null
      this.loadState = null
    },

    handleDrainageChoice(bool) {
      this.drainageChoice = bool
    },
    handleRemoveIrrig(irrigIndex) {
      this.irrigationData.splice(irrigIndex, 1)
    },
    handleCancelClick() {
      this.irrigationData = []
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.irrigationData.push(this.blankIrrigationData(cropValue))
      this.showSaveView = false
      this.showSummaryView = false
      this.showPracticesView = false
    },
    handleSpeechToPractice(practiceResponse) {
      const practiceIndex = practiceResponse["index"]
      const practiceData = practiceResponse["response"]
      const blankPractice = this.blankIrrigationData()
      const newPractice = {...blankPractice, ...practiceData}
      if (!this.cropChoicesFiltered.includes(newPractice.crop_id)) {
        newPractice.crop_id = null;
      }      
      Vue.set(this.irrigationData, practiceIndex, newPractice)
    },
    handlePreSave() {
      if (this.stackPractices) {
        this.handleSave()
      } else {
        this.showSaveConfirmModal = true
      }
    },
    async handleSave() {
      this.showSaveConfirmModal = false
      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }

      const fieldIds = this.opsSettingsbyFieldFiltered.map(f => f.fieldId)
      let irrigPayload = null

      if (this.irrigationData.length == 0) {
        const fieldsBeingSaved = this.opsSettingsbyFieldFiltered.filter(f =>
          this.fieldsInSelection.includes(Number(f.fieldId))
        )
        const cropsBeingSaved = fieldsBeingSaved
          .map(f => f.crops)
          .reduce((acc, arr) => acc.concat(arr), [])
          .filter((item, index, self) => self.indexOf(item) === index)
        irrigPayload = []
        cropsBeingSaved.forEach(cropId =>
          irrigPayload.push({
            amount: { value: 0, source: "User Input" },
            awd: { value: null, source: "User Input" },
            awd_times: { value: null, source: "User Input" },
            crop_id: { value: cropId, source: "User Input" },
            drainage: { value: null, source: "User Input" },
            efficiency: { value: null, source: "User Input" },
            end_date: { value: `${this.year}-01-01`, source: "User Input" },
            method: { value: "none", source: "User Input" },
            method_type: { value: "none", source: "User Input" },
            source_of_water: { value: null, source: "User Input" },
            start_date: { value: `${this.year}-01-01`, source: "User Input" },
            unit: { value: "in/ac", source: "User Input" },
            wildlife_flood: { value: null, source: "User Input" },
          })
        )
      } else {
        const indexOfMissing = this.allIrrigsComplete()
        if (indexOfMissing.length > 0) {
          this.incompleteIrrigations = indexOfMissing
          this.showIncompleteModal = true
          return
        } else {
          let dataClone = _.cloneDeep(this.irrigationData)
          irrigPayload = dataClone.map(irrig => {
            delete irrig["showAllInfo"]
            delete irrig["startMenu"]
            delete irrig["endMenu"]
            delete irrig["startPicker"]
            delete irrig["endPicker"]

            irrig = mapPayloadKeysToUserInput(irrig)

            return irrig
          })
        }
      }

      let submitPayload = {
        fieldIds: fieldIds,
        irrigation: irrigPayload,
        drainage: {
          drainage_exists: { value: this.drainageChoice, source: "User Input" },
        },
      }

      // switch to loading view
      this.showPracticesView = false
      this.showSaveView = true

      // submit irrigation data
      const updateIrrigations = []
      this.opsSettingsbyFieldFiltered.forEach(async field => {
        if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {
          // submit irrigation data
          if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {
            if (!field.noYearData && !field.fieldLocked) {
              updateIrrigations.push(
                this.updateIrrigation({
                  fieldId: field.fieldId,
                  year: this.year,
                  irrigation: this.mergeFieldPayload(field, submitPayload.irrigation),
                  drainage: submitPayload.drainage,
                })
              )
            }
          }
        }
      })
      await Promise.all(updateIrrigations)
      this.$emit("update-data-complete")

      // end loading view, return to irrigation
      const fieldNames = this.opsSettingsbyFieldFiltered
        .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
        .map(f => f.fieldName)
      const irrigationWantedKeys = [
        "crop_id",
        "drainage",
        "start_date",
        "end_date",
        "amount",
        "unit",
        "method",
      ]
      this.setRecentWizardData({
        wizard: "irrigation",
        data: this.irrigationData,
        drainage: this.drainageChoice,
      })

      this.summaryKeys = irrigationWantedKeys
      this.summaryData = submitPayload.irrigation
      this.summaryFields = fieldNames
      this.showSaveView = false
      this.showSummaryView = true
      this.stackPractices = true
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.irrigationData = [this.blankIrrigationData(cropValue)]
    },
    // completion for summary cards
    isIrrigComplete(irrig) {
      // NOTE: if the vue template validation fails (e.g. if they delete a previously entered amount),
      //  this can leave the model value in place
      const complete = (
        irrig.crop_id &&
        irrig.amount !== null &&
        irrig.start_date &&
        irrig.end_date &&
        irrig.method &&
        irrig.unit
      )
      return complete
    },
    allIrrigsComplete() {
      if (!this.irrigationData) return [0]
      const irrigCompleteness = this.irrigationData.map(irrig =>
        this.isIrrigComplete(irrig)
      )
      const irrigsMissing = irrigCompleteness.map((value, index) =>
        (value == null || value == false) ? index : ""
      )
      const indexOfMissing = irrigsMissing.filter(String)
      return indexOfMissing
    },
    // completion for table view
    irrigationComplete(irrig) {
      if (!irrig) return null
      return (
        irrig.amount.source == "User Input" &&
        irrig.amount.value !== null &&
        irrig.start_date.source == "User Input" &&
        irrig.start_date.value != null &&
        irrig.end_date.source == "User Input" &&
        irrig.end_date.value != null &&
        irrig.method.source == "User Input" &&
        irrig.method.value != null &&
        irrig.unit.source == "User Input" &&
        irrig.unit.value != null
      )
    },
    irrigationNeedsConfirm(irrig) {
      if (!irrig) return null
      return (
        irrig.amount.source != "User Input" &&
        irrig.amount.value !== null &&
        irrig.start_date.source != "User Input" &&
        irrig.start_date.value != null &&
        irrig.end_date.source != "User Input" &&
        irrig.end_date.value != null &&
        irrig.method.source != "User Input" &&
        irrig.method.value != null &&
        irrig.unit.source != "User Input" &&
        irrig.unit.value != null
      )
    },
    irrigationMissingData(irrig) {
      if (!irrig) return null
      return (
        irrig.amount.source != "User Input" ||
        irrig.amount.value == null ||
        irrig.start_date.source != "User Input" ||
        irrig.start_date.value == null ||
        irrig.end_date.source != "User Input" ||
        irrig.end_date.value == null ||
        irrig.method.source != "User Input" ||
        irrig.method.value == null ||
        irrig.unit.source != "User Input" ||
        irrig.unit.value == null
      )
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (e == true) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    handleShowRedirect(field) {
      this.redirectField = field
      this.showRedirectModal = true
    },
    handleCloseRedirect() {
      this.showRedirectModal = false
      this.redirectField = null
    },
    handleRedirectConfirm() {
      this.$router.push(`/data-spreadsheet/${this.redirectField.fieldId}`)
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    disableCheckbox(noYearData) {
      if (noYearData) {
        return true
      } else {
        return false
      }
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
        if (this.irrigationData.length > 0) {
          const cropValue =
            this.cropSelect != null && this.cropSelect.length == 1
              ? CROP_KEY[parseInt(this.cropSelect[0])]
              : null
          this.irrigationData.forEach(o => {
            o.crop_id = cropValue
          })
        }
      }
    },
    blankIrrigationData(cropValue = null) {
      return {
        amount: null,
        awd: null,
        awd_times: null,
        crop_id: cropValue,
        drainage: this.drainageChoice == false ? "none" : null,
        efficiency: null,
        end_date: null,
        method: null,
        method_type: null,
        source_of_water: null,
        start_date: null,
        unit: null,
        wildlife_flood: null,
        // for UI control
        showAllInfo: true,
        startMenu: false, // boolean for v-menu
        startPicker: this.year + "-01",
        endPicker: this.year + "-01",
        endMenu: false,
      }
    },
    handleAddPracticesClick() {
      if (this.showPracticesView == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        this.irrigationData = [this.blankIrrigationData(cropValue)]
      }
    },
    handleAddEventClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.irrigationData.push(this.blankIrrigationData(cropValue))
    },
    handleNoIrrigationClick() {
      this.irrigationData = []
      this.stackPractices = false
      this.drainageChoice = false
    },
    handleYesIrrigationClick() {
      this.irrigationData = []
      this.handleAddEventClick()
      this.stackPractices = true
    },
    handleNoDrainageClick() {
      this.drainageChoice = false
      if (this.irrigationData.length > 0) {
        this.irrigationData.forEach(o => (o.drainage = "none"))
      }
    },
    handleYesDrainageClick() {
      this.drainageChoice = true
    },
    handleAddMoreIrrigations() {
      this.showSummaryView = false
      this.showPracticesView = true
    },
  },
  mounted() {
    this.fieldsInSelection = this.selectedFields
      .map(f => f.id)
      .filter(f => !this.fieldLocks[f])
    if (this.recentWizardData.wizard == "irrigation") {
      this.irrigationData = _.cloneDeep(this.recentWizardData.data)
      this.drainageChoice = this.recentWizardData.drainage
    }
  },
  watch: {
    cropChoicesFiltered(newChoices) {
      for (const practice of this.irrigationData) {
        const existsInNewChoices = newChoices.some(choice => choice === practice.crop_id);
        if (!existsInNewChoices) {
          practice.crop_id = null;
        }
      }
    },
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.opsSettingsByField)

        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }

        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
  },
}
</script>
<style scoped>
.second-q {
  padding-top: 48px;
}

.select-container {
  width: 200px;
}

.small-container {
  width: 60px;
}

.text-container {
  width: 240px;
}

.step-height {
  min-height: 400px;
  max-height: 400px;
  height: 400px;
}

.min-step-height {
  min-height: 400px;
}

.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}

.fixed-table {
  table-layout: fixed;
}

.three {
  width: 3%;
}

.five {
  width: 5%;
}

.ten {
  width: 10%;
}

.twenty {
  width: 20%;
}

.cursor {
  cursor: pointer;
}
</style>
