export const DOUBLE_CROP_CHOICES = [
  { value: "primary-crop", display_name: "Primary Crop" },
  { value: "secondary-crop", display_name: "Secondary Crop" },
  { value: "cover-crop", display_name: "Cover Crop" },
  { value: "trial", display_name: "Trial" },
].sort(dropdownSort)

export const LEASE_TYPE_CHOICES = [
  { value: "non-lease", display_name: "Non-Lease" },
  { value: "per-acre-agreement", display_name: "Per Acre Agreement" },
  { value: "crop-share-payment", display_name: "Crop Share Payment" },
].sort(dropdownSort)

export const MONITOR_CHOICES = [
  { value: "gs2-2600", display_name: "GS2 2600" },
  { value: "gs3-2630", display_name: "GS3 2630" },
  { value: "ag-leader-integra", display_name: "Ag Leader Integra" },
  { value: "ag-leader-incommand", display_name: "Ag Leader Incommand" },
  { value: "trimble-fmx-1000", display_name: "Trimble FMX 1000" },
  { value: "trimble-fmx-2000", display_name: "Trimble FMX 2000" },
  { value: "claas-cebis", display_name: "Claas Cebis" },
  { value: "afs-pro-600", display_name: "AFS Pro 600" },
  { value: "afs-pro-700", display_name: "AFS Pro 700" },
  { value: "other", display_name: "Other" },
].sort(dropdownSort)

export const STARTER_FERTILIZER_CHOICES = [
  { value: "6-24-6", display_name: "6-24-6" },
  { value: "10-34-0", display_name: "10-34-0" },
  { value: "9-18-9", display_name: "9-18-9" },
  { value: "9-24-3", display_name: "9-24-3" },
  { value: "other", display_name: "Other" },
].sort(dropdownSort)

export const APPLICATION_METHOD_CHOICES = [
  { value: "dry", display_name: "Dry" },
  { value: "wet", display_name: "Wet" },
  { value: "manure", display_name: "Manure" },
].sort(dropdownSort)

export const WET_METHOD_TYPE_CHOICES = [
  { value: "injected", display_name: "Injected" },
  { value: "sprayed", display_name: "Sprayed" },
  { value: "other", display_name: "Other" },
  { value: "none", display_name: "None" },
].sort(dropdownSort)

export const DRY_METHOD_TYPE_CHOCIES = [
  { value: "broadcast", display_name: "Broadcast" },
  { value: "incorporated", display_name: "Incorporated" },
  { value: "strip till", display_name: "Strip Till" },
  { value: "banded", display_name: "Banded" },
  { value: "other", display_name: "Other" },
  { value: "none", display_name: "None" },
].sort(dropdownSort)


export const DRY_FERT_PRODUCT_CHOICES = [
  {
    value: "tsp-0-46-0",
    display_name: "Triple Superphosphate (TSP 0-46-0)",
  },
  {
    value: "dap-18-46-0",
    display_name: "Diammonium phosphate (DAP 18-46-0)",
  },
  {
    value: "map-11-48-0",
    display_name: "Monoammonium phosphate (MAP 11-48-0)",
  },
  { value: "urea-46-0-0", display_name: "Urea (46-0-0)" },
  {
    value: "anhydrous-ammonia-82-0-0",
    display_name: "Anhydrous ammonia (82-0-0)",
  },
  {
    value: "ammonium-nitrate-34-0-0",
    display_name: "Ammonium nitrate (34-0-0)",
  },
  {
    value: "ammonium-sulfate-21-0-0-24",
    display_name: "Ammonium sulfate (21-0-0-24)",
  },
  {
    value: "ammonium-phosphate-dry-15-62-0",
    display_name: "Ammonium Phosphate dry (15-62-0)",
  },
  { value: "potash-0-0-60", display_name: "Potash (0-0-60)" },
  {
    value: "potassium-chloride-0-0-60",
    display_name: "Potassium chloride (0-0-60)",
  },
  {
    value: "potassium-magnesium-sulfate-0-0-22-22-11",
    display_name: "Potassium-magnesium sulfate (0-0-22-22-11(Mg))",
  },
  {
    value: "thiosulfate-0-0-25-17",
    display_name: "Potassium thiosulfate (0-0-25-17)",
  },
  {
    value: "potassium-nitrate-3-0-44",
    display_name: "Potassium nitrate (3-0-44)",
  },
  { value: "sulfate-0-0-50-18", display_name: "Sulfate (0-0-50-18)" },
  { value: "other", display_name: "Other" },
].sort(dropdownSort)

export const WET_FERT_PRODUCT_CHOICES = [
  { value: "uan32-32-0-0-0", display_name: "UAN32 (32-0-0-0)" },
  { value: "uan28-28-0-0-0", display_name: "UAN28 (28-0-0-0)" },
  {
    value: "ammonium-polyphosphate-liquid-10-34-0",
    display_name: "Ammonium polyphosphate liquid (10-34-0)",
  },
  { value: "28-0-0-5", display_name: "28-0-0-5" },
  { value: "32-0-0-5", display_name: "32-0-0-5" },
  { value: "aqua-ammonia-20-0-0", display_name: "Aqua ammonia (20-0-0)" },
  {
    value: "potassium-magnesium-sulfate-0-0-22-22-11",
    display_name: "Potassium magnesium sulfate (0-0-22-22-11Mg)",
  },
  {
    value: "potassium-nitrate-13-0-44",
    display_name: "Potassium nitrate (13-0-44)",
  },
  {
    value: "PGR",
    display_name: "Plant Growth Regulator (PGR)",
  },
  { value: "other", display_name: "Other" },
].sort(dropdownSort)

export const MANURE_PRODUCT_CHOICES = [
  { value: "cow-manure", display_name: "Manure - Liquid Cattle" },
  { value: "pig-manure", display_name: "Manure - Liquid Hog" },
  { value: "chicken_litter", display_name: "Manure - Chicken Litter" },
  { value: "turkey-manure", display_name: "Manure - Turkey" },
  {
    value: "pelletized_chicken_litter",
    display_name: "Pelletized Chicken Litter",
  },
  { value: "other", display_name: "Other" },
  { value: "none", display_name: "None" },
].sort(dropdownSort)

export const RATE_UNIT_CHOICES = [
  // { value: "lb", display_name: "lbs/acre" },
  // { value: "gal", display_name: "gal/acre" },
  { value: "lb/ac", display_name: "lb/ac" },
  { value: "gal/acre", display_name: "gal/acre" },
  { value: "none", display_name: "None" },
].sort(dropdownSort)

export const HEADER_WIDTH_CHOICES = [
  { value: "12", display_name: "12'" },
  { value: "24", display_name: "24'" },
  { value: "48", display_name: "48'" },
  { value: "64", display_name: "64'" },
  { value: "other", display_name: "Other" },
].sort(dropdownSort)

export const EQUIPMENT_CHOICES = [
  { value: "john-deere", display_name: "John Deere" },
  { value: "case", display_name: "Case" },
  { value: "new-holland", display_name: "New Holland" },
  { value: "kubota", display_name: "Kubota" },
  { value: "raven", display_name: "Raven" },
  { value: "other", display_name: "Other" },
].sort(dropdownSort)

export const LAB_CHOICES = [
  { value: "midwest", display_name: "Midwest" },
  { value: "waypoint", display_name: "Waypoint" },
  { value: "international-ag", display_name: "International Ag" },
  { value: "crop-services", display_name: "Crop Services" },
  {
    value: "university-of-arkansas",
    display_name: "University of Arkansas",
  },
  {
    value: "mississippi-state-university",
    display_name: "Mississippi State University",
  },
  { value: "a-and-l", display_name: "A & L" },
  { value: "brookside", display_name: "Brookside" },
  { value: "frontier", display_name: "Frontier" },
  { value: "waters", display_name: "Waters" },
  { value: "agvise", display_name: "AgVise" },
  { value: "other", display_name: "Other" },
].sort(dropdownSort)

export const SAMPLE_DENSITY_CHOICES = [
  { value: "none", display_name: "No Soil Samples" },
  { value: "<1", display_name: "< 1 Acre" },
  { value: "1-2", display_name: "1 to 2 Acres" },
  { value: "2-3", display_name: "2 to 3 Acres" },
  { value: "3-4", display_name: "3 to 4 Acres" },
  { value: "4-5", display_name: "4 to 5 Acres" },
  { value: "5-10", display_name: "5 to 10 Acres" },
  { value: ">10", display_name: "> 10 Acres" },
].sort(dropdownSort)

export const SAMPLE_NUTRIENT_CHOICES = [
  { value: "nitrate", display_name: "Nitrate" },
  { value: "phosphorus", display_name: "Phosphorus" },
  { value: "potassium", display_name: "Potassium" },
  { value: "chloride", display_name: "Chloride" },
  { value: "sulfur", display_name: "Sulfur" },
  { value: "boron", display_name: "Boron" },
  { value: "zinc", display_name: "Zinc" },
  { value: "iron", display_name: "Iron" },
].sort(dropdownSort)

export const SAMPLE_UNITS_CHOICES = [
  { value: "ppm", display_name: "ppm" },
  { value: "lb/ac", display_name: "lb/ac" },
].sort(dropdownSort)

export const SEEDING_RATE_UNIT_CHOICES = [
  { value: "seeds/ac", display_name: "seeds/ac" },
  { value: "lb/ac", display_name: "lb/ac" },
].sort(dropdownSort)

export const IRRIGATION_SOURCE_CHOICES = [
  { value: "surface", display_name: "Surface" },
  { value: "well", display_name: "Well" },
]

export const ALL_FERT_CHOICES = STARTER_FERTILIZER_CHOICES.concat(
  DRY_FERT_PRODUCT_CHOICES
)
  .concat(WET_FERT_PRODUCT_CHOICES)
  .concat(MANURE_PRODUCT_CHOICES)
  .sort(dropdownSort)
  .filter((v, i, a) => a.findIndex(t => t.value === v.value) === i)

export const CROP_PROTECTION_FUNGICIDE_CHOICES = [
  "Captan",
  "Chlorothalonil",
  "Mancozeb"
].sort((a, b) => a.localeCompare(b));

export const CROP_PROTECTION_INSECTICIDE_CHOICES = [
  "Imidacloprid",
  "Malathion",
  "Neonicotinoids"
].sort((a, b) => a.localeCompare(b));

export const CROP_PROTECTION_HERBICIDE_CHOICES = [
  "Glyphosate",
  "Atrazine",
  "2,4-D"
].sort((a, b) => a.localeCompare(b));

export const cropProtectionChoices = (method, type) => {

  if (type === "herbicide") {
    return CROP_PROTECTION_HERBICIDE_CHOICES;
  }
  if (type === "fungicide") {
    return CROP_PROTECTION_FUNGICIDE_CHOICES;
  }
  if (type === "insecticide") {
    return CROP_PROTECTION_INSECTICIDE_CHOICES;
  }

  return [];
}

export const SOIL_SAMPLE_TYPE_CHOICES = [
  { value: "composite", display_name: "Composite" },
  { value: "grid", display_name: "Grid" },
  { value: "zone", display_name: "Zone" },
]

function dropdownSort(a, b) {
  const exceptions = {
    None: -2,
    Other: -1,
  }
  if (exceptions[a.display_name] && exceptions[b.display_name]) {
    return exceptions[a.display_name] - exceptions[b.display_name]
  } else if (exceptions[a.display_name]) {
    return 1
  } else if (exceptions[b.display_name]) {
    return -1
  } else {
    return a.display_name.localeCompare(b.display_name)
  }
}

export const CONFIRMATION_TYPE_CHOICES = [
  {
    name: "Irrigation Practices - VRI",
    value: "irrigation_vri",
  },
  {
    name: "Irrigation Practices - AWD",
    value: "awd",
  },
  {
    name: "Irrigation Practices - AWD", // duplicate, evidencing enum changed in the backend, here for legacy support
    value: "irrigation_awd"
  },
  {
    name: "Tillage - Conventional",
    value: "tillage_conventional",
  },
  {
    name: "Tillage - Minimal",
    value: "tillage_minimal",
  },
  {
    name: "Tillage - Reduced",
    value: "tillage_reduced",
  },
  {
    name: "Tillage - None",
    value: "tillage_none",
  },
  {
    name: "Nutrient Management Plan",
    value: "nutrient_mgmt_plan",
  },
  {
    name: "N - Split Apply",
    value: "split_n_application",
  },
  {
    name: "N - Split Apply", // duplicate, evidencing enum changed in the backend, here for legacy support
    value: "n_split_apply"
  },
  {
    name: "N - Inhibitor",
    value: "n_inhibitor",
  },
  {
    name: "Organic Fertilizer",
    value: "organic_fertilizer",
  },
  {
    name: "VRT Fertilizer",
    value: "vrt_application",
  },
  {
    name: "VRT Fertilizer", // duplicate, evidencing enum changed in the backend, here for legacy support
    value: "vrt_fert"
  },
  {
    name: "Crop Residue - Removal",
    value: "residue_removal",
  },
  {
    name: "Crop Residue - Burn",
    value: "residue_burn",
  },
  {
    name: "Crop Residue - Burn", // duplicate, evidencing enum changed in the backend, here for legacy support
    value: "crop_residue_burn"
  },
  {
    name: "Soil Sampling - Prior 3 Years",
    value: "soil_sample_prior_3_year",
  },
  {
    name: "Crop Advisors Recommendations",
    value: "crop_advisor_rec",
  },
  {
    name: "Livestock Integration",
    value: "livestock",
  },
  {
    name: "PGR & Desiccant MGT",
    value: "pgr_desiccant_mgt",
  },
  {
    name: "Soil Amendments",
    value: "soil_amendments",
  },
  {
    name: "Crop Rotation",
    value: "crop_rotation",
  },
  {
    name: "Cover Crops",
    value: "cover_crop",
  },
  {
    name: "Biochar",
    value: "biochar",
  },
  {
    name: "IPM",
    value: "ipm",
  },
  {
    name: "Liming",
    value: "lime"
  },
  {
    name: "Planting",
    value: "planting"
  },
  {
    name: "Biological Planting Additions",
    value: "biological_planting_additions"
  },
  {
    name: "Crop Protection",
    value: "crop_protection"
  },
  {
    name: "IDM/IPM/IWM Protection",
    value: "idm_ipm_iwm_protection"
  },
  {
    name: "Irrigation Management",
    value: "irrigation_management"
  },
  {
    name: "MIRI Irrigation",
    value: "miri_irrigation"
  },
  {
    name: "Harvest",
    value: "harvest"
  },
  {
    name: "4R Fertilizer",
    value: "four_r_fertilizer"
  },
  {
    name: "General Confirmation",
    value: "general_confirmation",
  },
]

export const CONFIRMATION_TYPE_CHOICES_RANCH = [
  {
    name: "Feed Additive",
    value: "feed_additive",
  },
  {
    name: "Nutrition Plan",
    value: "nutrition_plan",
  },
  {
    name: "Stocking Rate",
    value: "stocking_rate",
  },
  {
    name: "Primary Forage",
    value: "primary_forage",
  },
  {
    name: "Grazing Start",
    value: "grazing_start",
  },
  {
    name: "Grazing End",
    value: "grazing_end",
  },
  {
    name: "Grazing Rotation",
    value: "grazing_rotation",
  },
  {
    name: "% Invaded",
    value: "invaded_percent",
  },
  {
    name: "Biomass Removal",
    value: "biomass_removal",
  },
  {
    name: "Fertilizer Application",
    value: "fertilizer_application",
  },
  {
    name: "Slow Release",
    value: "slow_release",
  },
  {
    name: "Nitrogen Inhibitor",
    value: "n_inhibitor",
  },
  {
    name: "VRT",
    value: "vrt",
  },
  {
    name: "Nutrient Management Plan",
    value: "nutrient_mgmt_plan",
  },
  {
    name: "4Rs",
    value: "four_rs",
  },
  {
    name: "Liming",
    value: "liming",
  },
  {
    name: "Burn",
    value: "burn",
  },
  {
    name: "Irrigation",
    value: "irrigation",
  },
  {
    name: "Seeding/Interseeding",
    value: "seeding_interseeding",
  },
  {
    name: "Soil Sample",
    value: "soil_sample",
  },
  {
    name: "Forage Sample",
    value: "forage_sample",
  },
  {
    name: "Supply Shed",
    value: "supply_shed",
  },
  {
    name: "Beef Quality Assurance",
    value: "beef_quality_assurance",
  },
  {
    name: "Other Programs",
    value: "other_programs",
  },
]

export const MAX_DEFAULT_FIELDS = 300
