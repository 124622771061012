import API from "./API"

const resource = "/notifications";

export const getAlertNotifications = () =>
  API.get(`${resource}/events/`)

export const getTaskNotifications = () => 
  API.get(`${resource}/fetch_action_notifications_web/`)

export const syncUserFirebaseTokens = (payload) => 
  API.post(`${resource}/store_firebase_device_token/`, payload)

export const assignUsersToConfirmations = (payload) => 
  API.post(`${resource}/assign_users_to_confirmations/`, payload);

export const getAllPracticeConfirmationActions = (payload) => 
  API.post(`${resource}/get_practice_confirmation_notifications/`, payload);

export const getAllPasturePracticeConfirmationActions = (payload) => 
  API.post(`${resource}/get_pasture_practice_confirmation_notifications/`, payload);
