<template>
  <div>
    <EvidencingGroupConfigurationSidebar
      :visible="showSidebarDetail"
      :detailSpec="sidebarDetailSpecification"
      @closeSidebar="handleClosedSidebar"
    />

    <EvidencingGroupDueDateSidebar
      :visible="showDueDateSidebar"
      :detailSpec="dueDateDetailSpecification"
      @closeSidebar="handleDueDateSidebarClose"
    />

    <EvidencingFieldCropEditingSidebar
      :visible="showFieldCropEditingSidebar"
      :detailSpec="fieldCropSidebarDetailSpecification"
      @closeSidebar="handleFieldCropEditingSidebarClose"
    />

    <div class="group-loading-modal" v-if="loadingGroupCreation['visible']">
      <div class="loading-wrapper">
        <v-progress-circular v-if="loadingGroupCreation['status'] == LOADING_PROCESSING" :size="48" color="#79c61c" indeterminate />
        <v-icon v-else-if="loadingGroupCreation['status'] == LOADING_SUCCESS" :size="48" color="#79c61c" dark>mdi-check-bold</v-icon>
        <v-icon v-else-if="loadingGroupCreation['status'] == LOADING_FAILURE" :size="48" color="#ff3c7e">mdi-alert-circle-outline</v-icon>

        <div class="error-message" v-if="loadingGroupCreation['status'] == LOADING_FAILURE">
          <p class="text-danger">Something went wrong...</p>
          <p>The request could not be processed ({{ loadingGroupCreation['errorMsg'] }}).</p>
        </div>
      </div>

      </div>
    <div class="group-loading-modal-overlay" v-if="loadingGroupCreation['visible']" />

    <div :key="$route['path']">
      <h1 class="evidencing-title">Evidencing</h1>
      <h2 class="evidencing-subtitle">Create Evidencing Group</h2>
    </div>

    <div v-if="groupStep=='fields'">
      <div class="add-fields-input">
        <div class="submit-section">
          <v-text-field
            placeholder="Field IDs (comma separated)"
            outlined
            v-model="fieldIDTextCSV"
          />
          <v-btn :disabled="fieldIDTextCSV.length == 0" class="fields-submission-btn" @click="fetchFieldCropsByFieldIds" outlined>Submit</v-btn>
  
          <v-progress-circular v-if="loadingFieldsFetch" indeterminate :size="48" color="#79c61c" />
  
          <p v-if="fieldsFetchError">Error fetching fields: {{ fieldsFetchError }}</p>
        </div>
        <div class="group-option">
          <v-btn
            class="ml-auto mr-4"
            :class="{ active: !selectedGroup }"
            @click="handleSelectGroup(false)"
          >
            Group by Feed Additive Type
          </v-btn>
          <v-btn
            class="ml-auto mr-4"
            :class="{ active: selectedGroup }"
            @click="handleSelectGroup(true)"
          >
            Group by other means
          </v-btn>
        </div>
        <div></div>
      </div>

      <!-- <div v-if="Object.keys(allValidFieldCrops).length > 0 || Object.keys(allExcludedFieldCrops).length > 0" class="table-editing-btns">
        <v-btn @click="openFieldCropEditingSidebar" outlined height="36px">Edit Field/Crops</v-btn>
      </div> -->
  
      <div class="field-configuration-wrapper">
        <div class="fields-display">
          <v-simple-table>
            <thead>
              <tr>
                <th>Ranch Name</th>
                <th>ID #</th>
                <th>Client</th>
                <th class="dates-head">Grazing Start Date</th>
                <th class="dates-head">Grazing End Date</th>
                <th>Species</th>
                <th>Feed Additive</th>
                <th class="dates-head">Due Date</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="[fieldId, fieldSpec], idx in Object.entries(allValidFieldCrops)" :key="fieldSpec['field_id'] + '-' + idx + '-'">
                <td>{{ fieldSpec['farm']['name'] || 'Not Found' }}</td>
                <td>{{ fieldSpec['field_id'] || '-' }}</td>
                <td>{{ fieldSpec['client'] && fieldSpec['client']['name'] ? fieldSpec['client']['name'] : '-' }}</td>
                <td>{{ fieldSpec['grazings_start_date'] || '-' }}</td>
                <td>{{ fieldSpec['grazings_end_date'] || '-' }}</td>
                <td>{{ fieldSpec['livestock_species'] || '-' }}</td>
                <td>{{ fieldSpec['feed_additive'] || '-' }}</td>
                <td>{{ fieldSpec['due_date'] || '-' }}</td>
              </tr>
              <tr v-for="fieldId, idx in allNullValueFieldCropIds" :key="'invalid' + fieldId + '-' + idx">
                <td>-</td>
                <td>{{ fieldId }}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        
        <!-- <div class="fields-disambiguation">
          <div>
            <div class="disambiguation-text-container" v-if="Object.keys(allValidFieldCrops).length > 0">
              <p>From the provided field IDs, the following client-based groups will be created:</p>
  
              <ul>
                <li v-for="client, clientIdx in allUniqueClients" :key="client['name'] + '-' + clientIdx">
                  {{ client['name'] }} - {{ client['count'] }} Fields
                </li>
              </ul>
            </div>
  
            <div class="disambiguation-text-container" v-else>
              <p>Please submit a comma-separated list of field IDs.</p>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div v-else-if="groupStep=='evidencing'" class="evidencing-step">
      <!-- <div v-if="loadingEvidencingFetch">
        <v-progress-circular indeterminate :size="48" color="#79c61c" />
      </div>
      <div v-else>
        <p class="evidencing-step-info">Configure evidencing requests:</p>
        <p class="evidencing-step-info">(Prefilled items are inferred from our database.)</p>
  
        <div class="evidencing-step-crop-wrapper">
          <div class="evidencing-step-crop" v-for="cropSpec, csIdx in formattedAggregatedEvidencing" :key="cropSpec['name'] + '__-' + csIdx">
            <div class="info-head">
              <h4>{{ cropSpec['name'] }}</h4>
            </div>
  
            <v-simple-table>
              <thead>
                <tr>
                  <th>Evidencing types</th>
                  <th>Fields w/ Requests</th>
                </tr>
              </thead>
  
              <tbody>
                <tr
                  class="clickable-evidencing-configuration"
                  v-for="[evidencingType, evidencingCt], eIdx in cropSpec['evidencing']"
                  :key="evidencingType + '-' + eIdx"
                  @click="openEvidencingDetailView(cropSpec['id'], evidencingType)"
                >
                  <td>
                    <svg class="evidencing-row-spacer broken-check" v-if="evidencingHasItems(cropSpec['id'], evidencingType)" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M7.43359 9L10.4336 12L20.4336 2M14.4336 1H6.23359C4.55344 1 3.71336 1 3.07162 1.32698C2.50714 1.6146 2.04819 2.07354 1.76057 2.63803C1.43359 3.27976 1.43359 4.11984 1.43359 5.8V14.2C1.43359 15.8802 1.43359 16.7202 1.76057 17.362C2.04819 17.9265 2.50714 18.3854 3.07162 18.673C3.71336 19 4.55344 19 6.23359 19H14.6336C16.3138 19 17.1538 19 17.7956 18.673C18.3601 18.3854 18.819 17.9265 19.1066 17.362C19.4336 16.7202 19.4336 15.8802 19.4336 14.2V10" />
                    </svg>
                    <div class="evidencing-row-spacer" v-else></div>
                    
                    {{ getEvidencingName(evidencingType) }}
                  </td>
                  <td>{{ evidencingCt }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
      </div> -->
      <div class="table-editing-btns">
        <v-btn
          :disabled="fieldsToEdit.length == 0"
          outlined
          height="36px"
          @click="openGroupSaveModal"
        >Create New Group</v-btn>
      </div>
      <div class="field-configuration-wrapper">
        <div class="fields-display">
          <v-simple-table>
            <thead>
              <tr>
                <th>
                  <input
                    @click="selectAllCheckBox"
                    type="checkbox"
                    name="selected"
                    ref="fieldSelectAllCheckbox"
                  />
                </th>
                <th>Group Name</th>
                <th>Group UUID</th>
                <th>Ranch Name</th>
                <th>Field ID</th>
                <th>Client</th>
                <th class="dates-head">Grazing Start Date</th>
                <th class="dates-head">Grazing End Date</th>
                <th>Species</th>
                <th>Feed Additive</th>
                <th class="dates-head">Due Date</th>
              </tr>
            </thead>

            <tbody>
              <EvidencingListRow
                v-for="[fieldId, fieldSpec], idx in Object.entries(allValidFieldCrops)"
                :key="fieldSpec['field_id'] + '-' + idx + '-'"
                :fieldSpec="fieldSpec"
                @checked="checked"
                @data-checked="dataChecked"
                @unchecked="unchecked"
                @step=""
                ref="fieldListRows"
              />
              <tr v-for="fieldId, idx in allNullValueFieldCropIds" :key="'invalid' + fieldId + '-' + idx">
                <td>Not Found</td>
                <td>{{ fieldId }}</td>
                <td>Not Found</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        
        <!-- <div class="fields-disambiguation">
          <div>
            <div class="disambiguation-text-container" v-if="Object.keys(allValidFieldCrops).length > 0">
              <p>From the provided field IDs, the following client-based groups will be created:</p>
  
              <ul>
                <li v-for="client, clientIdx in allUniqueClients" :key="client['name'] + '-' + clientIdx">
                  {{ client['name'] }} - {{ client['count'] }} Fields
                </li>
              </ul>
            </div>
  
            <div class="disambiguation-text-container" v-else>
              <p>Please submit a comma-separated list of field IDs.</p>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <GroupConfirmModal
      v-if="showSaveConfirmModal"
      titleText="Save Group"
      :spec="spec"
      @confirm="handleSave"
      @close-modal="showSaveConfirmModal = false"
    />

    <div class="stepwise-footer">
      <v-btn outlined color="#FFFFFF" height="44px" @click="handleFirstStep()">Cancel</v-btn>
      <v-btn :disabled="!navigationButtonsEnabled" @click="handlePreviousStep()" outlined color="#FFFFFF" height="44px" v-if="['evidencing'].includes(groupStep)">Previous</v-btn>
      <v-btn :disabled="!navigationButtonsEnabled" @click="handleNextStep()" outlined color="#FFFFFF" height="44px" v-if="['fields'].includes(groupStep)">Next</v-btn>
      <v-btn :disabled="!navigationButtonsEnabled" @click="handleFirstStep()" outlined color="#FFFFFF" height="44px" v-if="['evidencing'].includes(groupStep)">Next</v-btn>
    </div>
  </div>
</template>

<script>
import EvidencingGroupConfigurationSidebar from "@/components/evidencing/EvidencingGroupConfigurationSidebar.vue";
import EvidencingGroupDueDateSidebar from "@/components/evidencing/EvidencingGroupDueDateSidebar.vue";
import EvidencingFieldCropEditingSidebar from "@/components/evidencing/EvidencingFieldCropEditingSidebar.vue";
import EvidencingListRow from "@/components/evidencing/EvidencingListRow"
import GroupConfirmModal from "@/components/modals/GroupConfirmModal"
import {
  EVIDENCING_REMOVAL,
  EVIDENCING_CREATION,
  EVIDENCING_DATE_RULES,
  LOADING_SUCCESS,
  LOADING_FAILURE,
  LOADING_PROCESSING,
  IS_RANCHFORCE
} from "@/constants";
import { CONFIRMATION_TYPE_CHOICES } from "@/constants/defaults";
import {
  fetchFieldCrops,
  getEvidencingByFieldIds,
  createClientBasedGroups,
  fetchPastureCrops,
  getRanchEvidencingByFieldIds,
  createRanchClientBasedGroups,
  createRanchGroups,
  fetchRanchEvidencingGroup
} from "@/api/EvidencingAPI";
import { mapState } from "vuex";
import Vue from "vue";

export default {
  name: "EvidencingGroupCreationRanch",
  components: {
    EvidencingGroupConfigurationSidebar,
    EvidencingGroupDueDateSidebar,
    EvidencingFieldCropEditingSidebar,
    EvidencingListRow,
    GroupConfirmModal
  },
  data() {
    return {
      date: '6-22-2024',
      groupStep: "fields",
      fieldIDTextCSV: "",
      showSidebarDetail: false,
      showDueDateSidebar: false,
      showFieldCropEditingSidebar: false,
      loadingGroupCreation: {
        visible: false,
        status: null,
        errorMsg: ''
      },
      loadingEvidencingFetch: false,
      loadingFieldsFetch: false,
      fieldsFetchError: '',
      fieldCropSpecification: {},
      evidencingCropSpecification: {},
      sidebarDetailSpecification: {},
      dueDateDetailSpecification: {},
      fieldCropSidebarDetailSpecification: {},
      CONFIRMATION_TYPE_CHOICES,
      LOADING_SUCCESS,
      LOADING_FAILURE,
      LOADING_PROCESSING,
      isRanchForce: IS_RANCHFORCE,
      selectedGroup: false,
      fieldsToEdit: [],
      showSaveConfirmModal: false,
      spec: [],
    }
  },
  computed: {
    ...mapState({
      user: state => state.User.user,
      year: state => state.Organization.year,
    }),
    isSuperUser() {
      if (this.user != null) {
        return this.user['is_superuser'];
      }
      
      return false;
    },
    navigationButtonsEnabled() {
      // if (this.groupStep == 'fields') {
      //   if (this.loadingFieldsFetch) return false

      //   let anyAreSelected = false;
  
      //   for (const fieldId in this.allValidFieldCrops) {
      //     if (this.allValidFieldCrops[fieldId]['selectedCrops'].length > 0) {
      //       anyAreSelected = true;
      //     }
      //   }
  
      //   return anyAreSelected
      // }
      // else if (this.groupStep == 'evidencing') {
      //   if (this.loadingEvidencingFetch) {
      //     return false
      //   }
      //   else {
      //     return true
      //   }
      // }

      return true
    },
    selectedEvidencingForDueDateApplication() {
      const result = {};

      for (const cropId in this.evidencingCropSpecification) {
        if (cropId == 'clientGroupings') continue

        const cropEvidencingTypes = Object.entries(this.evidencingCropSpecification[cropId]['selectedEvidencing'])
          .filter(([_, ct]) => ct > 0)
          .map(([ evidencingType, _]) => evidencingType);
        
        result[cropId] = { id: cropId, name: this.evidencingCropSpecification[cropId]['name'], cropEvidencingTypes }
      }

      return result
    },
    formattedAggregatedEvidencing() {
      const result = {};

      for (const cropId in this.evidencingCropSpecification) {
        if (cropId == 'clientGroupings') continue
        
        result[cropId] = {
          id: cropId,
          name: this.evidencingCropSpecification[cropId]['name'],
          evidencing: []
        };

        for (const { value } of CONFIRMATION_TYPE_CHOICES) {
          result[cropId]['evidencing'].push([value, this.evidencingCropSpecification[cropId]['selectedEvidencing'][value]]);
        }

        const sortedEvidencingArrByVolume = result[cropId]['evidencing'].toSorted((a,b) => b[1]-a[1]);
        result[cropId]['evidencing'] = sortedEvidencingArrByVolume;
      }

      return result;
    },
    allUniqueClients() {
      const clients = [];

      for (const fieldId in this.allValidFieldCrops) {
        const clientSpec = this.allValidFieldCrops[fieldId]['client'];
        if (clientSpec != null && clientSpec['id'] != null) {
          const foundIdx = clients.findIndex(({ id }) => id == clientSpec['id']);
          if (foundIdx == -1) clients.push({ count: 1, ...clientSpec });
          else clients[foundIdx]['count'] += 1;
        }
      }

      return clients
    },
    allUniqueCrops() {
      const crops = [];

      for (const fieldId in this.fieldCropSpecification) {
        if (this.fieldCropSpecification[fieldId] != null && 'crops' in this.fieldCropSpecification[fieldId]) {
          const cropSpecs = this.fieldCropSpecification[fieldId]['crops'];
  
          for (const cropSpec of cropSpecs) {
            const existsIdx = crops.findIndex(({ id }) => id == cropSpec['id']);
            if (existsIdx == -1) {
              crops.push(cropSpec);
            }
            else {
              // it already exists, see if we need to add a new planting date
              for (const plantingDate of cropSpec['plantings']) {
                if (!crops[existsIdx]['plantings'].includes(plantingDate)) {
                  crops[existsIdx]['plantings'].push(plantingDate);
                }
              }
            }
          }
        }
      }

      return crops
    },
    // allFieldCrops() {
    //   const fieldCrops = [];

    //   for (const fieldId in this.allValidFieldCrops) {
    //     const cropSpec = this.allValidFieldCrops[fieldId]['crops'];
    //     fieldCrops.push([fieldId, cropSpec.map(({ id }) => id)]);
    //   }

    //   return fieldCrops;
    // },
    allEvidencing() {
      const evidencing = [];

      for (const fieldId in this.allValidFieldCrops) {
        if (this.allValidFieldCrops[fieldId] != null && this.allValidFieldCrops[fieldId]['evidencing'] != null) {
          evidencing.push(...this.allValidFieldCrops[fieldId]['evidencing']);
        }
      }

      return evidencing;
    },
    allValidFieldCrops() {
      const newObj = {};

      // only include field crops that
      //  1. have valid data
      //  2. have crops with planting dates that are not excluded from the grouping
      const validKeys = Object.keys(this.fieldCropSpecification)
        .filter(i => {
          return !['toExclude'].includes(i)
          && this.fieldCropSpecification[i] != null
          // && this.atLeastOneCropIncluded(this.fieldCropSpecification[i]['crop'])
        })

      for (const key of validKeys) {
        newObj[key] = this.fieldCropSpecification[key];
      }

      return newObj;
    },
    allExcludedFieldCrops() {
      const a = Object.keys(this.fieldCropSpecification)
        .filter(k => {
          return !['toExclude'].includes(k) 
          && !(k in this.allValidFieldCrops)
          && this.fieldCropSpecification[k] != null
        })
        .map(id => {
          return { id, ...this.fieldCropSpecification[id] }
        });
      return a
    },
    allNullValueFieldCropIds() {
      return Object.keys(this.fieldCropSpecification)
        .filter(k => {
          return !['toExclude'].includes(k) 
          && !(k in this.allValidFieldCrops)
          && this.fieldCropSpecification[k] == null
        });
    }
  },
  _methods: {
    getCropDueDate(cropId) {
      return EVIDENCING_DATE_RULES[cropId] || null;
    },
    handlePreviousStep() {
      if (this.groupStep == 'evidencing') this.groupStep = 'fields';
      else if (this.groupStep == 'dueDates') this.groupStep = 'evidencing';
    },
    handleNextStep() {
      if (this.groupStep == 'fields') this.groupStep = 'evidencing';
      else if (this.groupStep == 'evidencing') this.groupStep = 'dueDates';
    },
    handleFirstStep() {
      this.$router.push(`/ranch-evidencing`)
    },
    evidencingHasItems(cropId, evidencingType) {
      return this.evidencingCropSpecification[cropId]['selectedEvidencing'][evidencingType] > 0;
    },
    atLeastOneCropIncluded(crops) {
      // for (const { id, name, plantings } of crops) {
      //   for (const plantingDate of plantings) {
      //     if (!this.fieldCropSpecification['toExclude'][name].includes(plantingDate)) {
      //       return true
      //     }
      //   }
      // }
      // return false
      return true;
    },
    checked(id) {
      this.fieldsToEdit.push(id);
    },
    async dataChecked(field_id, field_data_type) {
      this.selectFieldId = field_id;
      this.selectedDataType = field_data_type;

      const payload = {
        field_id: field_id,
        field_data_type: this.selectedDataType,
      };
      await FieldsAPI.fetchGeoPkgsByDataType(payload).then(response => {
        this.geoPkgsByDataType = response.data;
      });

      this.fieldDataTypeDeletionModalOpen = true;
    },
    unchecked(id) {
      const index = this.fieldsToEdit.indexOf(id);
      if (index > -1) this.fieldsToEdit.splice(index, 1);
    },

    selectAllCheckBox() {
      const checked = this.$refs.fieldSelectAllCheckbox.checked;
      this.$refs.fieldListRows.forEach(fieldListRow => fieldListRow.setCheckbox(checked)
      );

      if (!checked) this.fieldsToEdit = [];
    },

    deselectAllCheckbox() {
      this.$refs.fieldListRows.forEach(fieldListRow => fieldListRow.setCheckbox(false)
      );
      this.$refs.fieldSelectAllCheckbox.checked = false;
    },
    openGroupSaveModal() {
      this.spec = [];
      for (const idx of this.fieldsToEdit) {
        const fieldData = this.allValidFieldCrops[idx];
        this.spec.push(fieldData);
      }
      this.showSaveConfirmModal = true;
    },
    handleSave(response) {
      this.showSaveConfirmModal = false;
      for (const data of response.data) {
        this.allValidFieldCrops[data.id] = data;
      }
      this.deselectAllCheckbox()
    },
    handleSelectGroup(cond) {
      this.selectedGroup = cond;
    },
    openDueDateSidebarView(cropSpec) {
      const allEvidencingTypes = this.getAllEvidencingTypesForCrop(cropSpec['id']);
      Vue.set(this, 'dueDateDetailSpecification', { cropId: cropSpec['id'], name: cropSpec['name'], evidencing: allEvidencingTypes });
      this.showDueDateSidebar = true;
    },
    openFieldCropEditingSidebar() {
      const plantingsByCropName = {};

      for (const crop of this.allUniqueCrops) {
        for (const plantingDate of crop['plantings']) {
          if (!(crop['name'] in plantingsByCropName)) {
            plantingsByCropName[crop['name']] = [];
          }

          const found = this.fieldCropSpecification['toExclude'][crop['name']].includes(plantingDate);
          if (!found) {
            // this crop + planting date is not set to be excluded from the group,
            // ensure it is shown as selected in the sidebar
            plantingsByCropName[crop['name']].push({ plantingDate, selected: true });
          }
          else {
            plantingsByCropName[crop['name']].push({ plantingDate, selected: false });
          }
        }
      }

      this.fieldCropSidebarDetailSpecification = plantingsByCropName;
      this.showFieldCropEditingSidebar = true;
    },
    openEvidencingDetailView(cropId, selectedEvidencingType) {
      const { relevantFields, name, evidencing } = this.evidencingCropSpecification[cropId];

      const fieldCrops = [];

      for (const fieldId of relevantFields) {
        const fieldCropSpec = this.fieldCropSpecification[fieldId];
        const relevantCrop = fieldCropSpec['crops'].find(({ id }) => id == cropId);

        const relevantEvidencing = evidencing
          .map(id => this.getEvidencingInClientGroupingByID(id))
          .filter(({ confirmation_type, fields }) => confirmation_type == selectedEvidencingType && fields.some(({ id }) => id == fieldId)
          );
        const fieldEvidencing = relevantEvidencing.find(({ fields }) => fields.some(({ id }) => id == fieldId));

        const toAdd = {
          id: fieldId,
          name: this.fieldCropSpecification[fieldId]['name'],
          plantings: relevantCrop['plantings'],
          selected: false,
        };

        if (fieldEvidencing != null) {
          toAdd['dueDate'] = fieldEvidencing['deadline'] || null;
          toAdd['evidencingId'] = fieldEvidencing['id'] || null;
        }

        // specify whether or not this fieldcrop should be selected in the sidebar
        // the rules are:
        // if the evidencing exists in the system already, it should be checked
        // if the evidencing didn't exist but the user added it (toCreate)
        // if the evidencing did exist but the user unchecked it (toRemove)
        if (toAdd['evidencingId'] == null) {
          // check if this fieldId/evidencingType combo exists in any of the toCreate properties
          const evidencingIsToBeCreated = this.isFieldEvidencingTypeToBeCreated(toAdd['id'], selectedEvidencingType);
          if (evidencingIsToBeCreated) {
            toAdd['selected'] = true;
          }
        }
        else {
          // we have an evidencing ID, see if it is to be removed or not
          const evidencingIsToBeRemoved = this.isFieldEvidencingToBeRemoved(toAdd['evidencingId']);
          if (!evidencingIsToBeRemoved) {
            // we have an evidencing ID and it isn't set to be removed, it should be selected
            toAdd['selected'] = true;
          }
        }

        fieldCrops.push(toAdd);
      }

      const newSidebarSpec = {
        cropId,
        cropName: name,
        fieldCrops,
        selectedEvidencingType
      };

      Vue.set(this, 'sidebarDetailSpecification', newSidebarSpec);
      this.showSidebarDetail = true;
    },
    handleFieldCropEditingSidebarClose(cancelled, modifiedPlantingDates) {
      if (!cancelled) {
        this.reconcileFieldCropPlantingDates(modifiedPlantingDates);
      }

      this.showFieldCropEditingSidebar = false;
      this.fieldCropSidebarDetailSpecification = {};
    },
    handleDueDateSidebarClose(cancelled, dueDate, selectedEvidencingTypes) {
      if (!cancelled) {
        const { cropId } = this.dueDateDetailSpecification;

        // set the due dates for all evidencing across clients that matches the crop + evidencing type
        this.setDueDatesForEvidencing(cropId, dueDate, selectedEvidencingTypes);
      }

      this.showDueDateSidebar = false;
      this.dueDateDetailSpecification = {};
    },
    handleClosedSidebar(cancelled, cropId, modifiedFieldIds) {
      if (!cancelled) {
        for (const modifiedSpec of modifiedFieldIds) {
          this.reconcileEvidencing(modifiedSpec);
        }

        // now that the existing evidencing / to create evidencing / to remove are reconciled
        // update our spec object to reflect the UI changes necessary on the evidencing crop tables
      }

      this.showSidebarDetail = false;
      this.sidebarDetailSpecification = {};
    },
    isFieldEvidencingToBeRemoved(evidencingId) {
      for (const clientId in this.evidencingCropSpecification['clientGroupings']) {
        const toRemove = this.evidencingCropSpecification['clientGroupings'][clientId]['toRemove'];

        if (toRemove != null && toRemove.length > 0) {
          const found = toRemove.find(entry => entry == evidencingId);

          if (found != null) {
            return true;
          }
        }
      }

      return false;
    },
    isFieldEvidencingTypeToBeCreated(fieldId, evidencingType) {
      for (const clientId in this.evidencingCropSpecification['clientGroupings']) {
        const toCreate = this.evidencingCropSpecification['clientGroupings'][clientId]['toCreate'];

        if (toCreate != null && toCreate.length > 0) {
          const found = toCreate.find(entry => fieldId == entry['fieldId'] && evidencingType == entry['evidencingType']);

          if (found != null) {
            return true;
          }
        }
      }

      return false;
    },
    getEvidencingInClientGroupingByID(id) {
      for (const clientId in this.evidencingCropSpecification['clientGroupings']) {
        const evidencingSet = this.evidencingCropSpecification['clientGroupings'][clientId]['evidencing'];

        for (const evidencing of evidencingSet) {
          if (evidencing['id'] == id) return evidencing;
        }
      }

      return null;
    },
    reconcileFieldCropPlantingDates(modifiedPlantingDates) {
      const cropExclusions = { ...this.fieldCropSpecification['toExclude'] };

      for (const cropName in modifiedPlantingDates) {
        for (const { action, plantingDate } of modifiedPlantingDates[cropName]) {
          const exclusionIdx = cropExclusions[cropName].findIndex(p => p == plantingDate);

          if (cropExclusions[cropName].includes(plantingDate)) {
            if (action == EVIDENCING_CREATION) {
              if (exclusionIdx != -1) {
                // it's set to be excluded and the sidebar specified it should be 'created'
                // just remove it from the exclusions
                cropExclusions[cropName].splice(exclusionIdx, 1);
              }
            }
          }
          else {
            if (action == EVIDENCING_REMOVAL) {
              if (exclusionIdx == -1) {
                // it's not set to be removed and the sidebar specified that 
                // it should be removed, add it to exclusions
                cropExclusions[cropName].push(plantingDate);
              }
            }
          }
        }
      }

      Vue.set(this.fieldCropSpecification, 'toExclude', cropExclusions);
    },
    reconcileEvidencing({ id, action, evidencingId = null }) {
      // reconcile the properties tracking creation/removal states, cross referencing the
      // bulk list of all client-based evidencing
      const clientId = this.fieldCropSpecification[id]['client']['id'];
      const cropId = this.sidebarDetailSpecification['cropId'];
      const evidencingType = this.sidebarDetailSpecification['selectedEvidencingType'];

      const cropGroup = this.evidencingCropSpecification[cropId];
      const clientGroup = this.evidencingCropSpecification['clientGroupings'][clientId];

      // check if we received an evidencingId. if we did, we know this evidencing exists in the bulk list
      // so we just need to reference the removal tracker
      if (evidencingId != null) {
        const removalList = clientGroup['toRemove'];

        if (removalList.includes(evidencingId)) {
          if (action == EVIDENCING_CREATION) {
            // the evidencing exists, was set for removal, and is now set for creation. so just remove the evidencing
            // id from the removal tracking list and it should take care of itself
            const idx = removalList.findIndex(eId => eId == evidencingId);
            removalList.splice(idx, 1);

            // we also need to re-increment the evidencing ui table
            cropGroup['selectedEvidencing'][evidencingType] += 1;
          }
        }
        else {
          if (action == EVIDENCING_REMOVAL) {
            // the evidencing exists, was not set for removal, but is now. add the id to the
            // removal tracking list
            removalList.push(evidencingId);

            // decrement the evidencing ui table
            cropGroup['selectedEvidencing'][evidencingType] -= 1;
          }
        }
      }
      else {
        // we didn't get an evidencing ID, check the cases for rectifying generating new evidencing
        // for field/farm/crop/client specifications
        const creationList = clientGroup['toCreate'];
        const creationListIdx = creationList.findIndex(c => {
          return c['fieldId'] == id
            && c['clientId'] == clientId
            && c['cropId'] == cropId
            && c['evidencingType'] == evidencingType;
        });

        if (creationListIdx != -1) {
          if (action == EVIDENCING_REMOVAL) {
            // this spec was found in the creation list, and is set for removal, so just 
            // remove it from the creation list tracker
            creationList.splice(creationListIdx, 1);

            // re-decrement the evidencing ui table
            cropGroup['selectedEvidencing'][evidencingType] -= 1;
          }
        }
        else {
          if (action == EVIDENCING_CREATION) {
            // this spec was not found in the creation list, and is set for creation
            // so just add it to the creation list tracker
            creationList.push({ fieldId: id, clientId, cropId, evidencingType, dueDate: cropGroup['dueDate'] });

            // increment the evidencing ui table
            cropGroup['selectedEvidencing'][evidencingType] += 1;
          }
        }
      }
    },
    setDueDatesForEvidencing(cropId, dueDate, selectedEvidencingTypes) {
      // set due dates via id tracker + evidencing set to be created
      for (const clientId in this.evidencingCropSpecification['clientGroupings']) {
        const clientGroup = this.evidencingCropSpecification['clientGroupings'][clientId];

        // filter the evidencing to apply due dates for
        // only include matching crops, matching evidencing types, and those evidencing requests
        // which are not set to be removed from the group
        const filteredEvidencingIds = clientGroup['evidencing']
          .filter(({ confirmation_type, id, crops }) => {
            return crops.some(crop => crop['id'] == cropId)
              && selectedEvidencingTypes.includes(confirmation_type)
              && !clientGroup['toRemove'].includes(id);
          })
          .map(({ id }) => id);

        if (filteredEvidencingIds.length > 0) {
          const dueDateSpec = this.evidencingCropSpecification['clientGroupings'][clientId]['dueDateModifications'];

          for (const evidencingId of filteredEvidencingIds) {
            dueDateSpec[evidencingId] = dueDate;
          }

          Vue.set(
            this.evidencingCropSpecification['clientGroupings'][clientId],
            'dueDateModifications',
            dueDateSpec
          );
        }

        for (const evidencingToBeCreated of clientGroup['toCreate']) {
          if (evidencingToBeCreated['cropId'] == cropId
            && selectedEvidencingTypes.includes(evidencingToBeCreated['evidencingType'])) {
            evidencingToBeCreated['dueDate'] = dueDate;
          }
        }
      }
    },
    getEvidencingName(evidencingType) {
      const found = CONFIRMATION_TYPE_CHOICES.find(({ name, value }) => value == evidencingType);
      if (found != null) return found['name'];
      return 'Not Specified';
    },
    fetchFieldCropsByFieldIds() {
      this.selectedGroup = false;
      // regex test?
      const fieldIds = this.fieldIDTextCSV.split(',');

      // fetch field ids, field crops, etc.
      this.loadingFieldsFetch = true;
      this.fieldsFetchError = '';

      const fileCropAPI = this.isRanchForce ? fetchPastureCrops : fetchFieldCrops;

      fileCropAPI({ fieldIds, year: this.year })
        .then(({ data }) => {
          try {
            const allCropNames = [];
            const newFieldCropSpec = [];
            let idx = 0;

            for (const fieldId in data) {
              if (data[fieldId] == null) {
              }
              else {
                data[fieldId]['data'].map((cropData) => {
                  newFieldCropSpec.push({
                    id: idx,
                    year: this.year,
                    field_id: fieldId,
                    name: data[fieldId]['name'],
                    client: data[fieldId]['client'],
                    farm: data[fieldId]['farm'],
                    crop: data[fieldId]['crop'],
                    grazings_start_date: cropData['grazings_start_date'] ? cropData['grazings_start_date'] : '',
                    grazings_end_date: cropData['grazings_end_date'] ? cropData['grazings_end_date'] : '',
                    livestock_species: cropData['livestock_species'],
                    feed_additive: cropData['feed_additive'],
                    count_animals: cropData['count_animals'],
                    due_date: cropData['due_date'],
                  });
                  idx += 1;
                });
              }
            }

            newFieldCropSpec.sort((a, b) => {
              if (a.additive < b.additive) return -1;
              if (a.additive > b.additive) return 1;
              return 0;
            });

            fetchRanchEvidencingGroup({ spec: newFieldCropSpec })
              .then(({ data }) => {
                Vue.set(this, 'fieldCropSpecification', data);
                this.loadingFieldsFetch = false;
              })
              .catch(error => {
                console.log(error);
                this.loadingFieldsFetch = false;
              });
          }
          catch (e) {
            console.log('error fetching fields:', e);
          }
        })
        .catch(e => {
          console.log('server error: ', e);
          this.fieldsFetchError = e;
          this.loadingFieldsFetch = false;
        });
    },
    getAllEvidencingTypesForCrop(cropId) {
      // fetch all evidencing types:
      //    include existing evidencing types that match this cropId
      //    include evidencing types that don't exist but are set to be created for this cropId
      //    exclude evidencing types that exist but are set to be removed
      const allEvidencing = [];
      const toCreate = [];

      for (const clientId in this.evidencingCropSpecification['clientGroupings']) {
        const clientSpec = this.evidencingCropSpecification['clientGroupings'][clientId];

        for (const evidencing of clientSpec['evidencing']) {
          // the existing evidencing need to be referenced agains the removal lists
          if (clientSpec['toRemove'].includes(evidencing['id'])) continue;
          if (evidencing['crops'].some(({ id }) => id == cropId)) {
            // the id is not set to be removed, safely add it to the holistic list
            allEvidencing.push(evidencing);
          }
        }

        for (const creationSet of clientSpec['toCreate']) {
          if (creationSet['cropId'] == cropId) {
            toCreate.push(creationSet['evidencingType']);
          }
        }
      }

      const allEvidencingTypes = [];

      for (const { confirmation_type } of allEvidencing) {
        if (!allEvidencingTypes.includes(confirmation_type)) {
          allEvidencingTypes.push(confirmation_type);
        }
      }

      for (const evidencingType of toCreate) {
        if (!allEvidencingTypes.includes(evidencingType)) {
          allEvidencingTypes.push(evidencingType);
        }
      }

      return allEvidencingTypes;
    }
  },
  get methods() {
    return this._methods;
  },
  set methods(value) {
    this._methods = value;
  },
  watch: {
    isSuperUser: {
      handler(curr) {
        if (curr != null) {
          if (!curr) {
            // if the user isn't a superuser, reroute them
            location.href = "/evidencing";
          }
        }
      },
      deep: true
    },
    groupStep(curr) {
      if (curr == 'evidencing') {
        this.loadingEvidencingFetch = true;

        // const getEvidencingByFieldIdsAPI = this.isRanchForce ? getRanchEvidencingByFieldIds : getEvidencingByFieldIds;
        // getEvidencingByFieldIdsAPI({
        //   allFieldIds: Object.keys(this.allValidFieldCrops),
        //   fieldCrops: this.allFieldCrops,
        //   year: this.year
        // })
        // .then(({ data }) => {
        //   for (const fieldId in data) {
        //     Vue.set(this.fieldCropSpecification[fieldId], 'evidencing', data[fieldId])
        //   }

        //   this.loadingEvidencingFetch = false;
        // });
      }
    },
    selectedGroup: {
      handler(curr) {
        if (curr) {
          // sort by id
          this.fieldCropSpecification.sort((a, b) => a.id - b.id);
        } else {
          this.fieldCropSpecification.sort((a, b) => {
            if (a.additive < b.additive) return -1;
            if (a.additive > b.additive) return 1;
            return 0;
          });
        }
      }
    },
    // fieldCropSpecification: {
    //   handler(curr) {
    //     Vue.delete(this.evidencingCropSpecification, 'clientBasedGrouping');
    //     const allEvidencing = [];

    //     for (const crop of this.allUniqueCrops) {
    //       // TODO better fxn here, this will cause all progress lost if they do evidencing then
    //       // return to the field setup step and change something
    //       if (crop['id'] in this.evidencingCropSpecification) {
    //         Vue.delete(this.evidencingCropSpecification, crop['id']);
    //       }

    //       const cropSpec = {
    //         name: crop['name'],
    //         relevantFields: [],
    //         evidencing: [],
    //         selectedEvidencing: {},
    //         dueDate: this.getCropDueDate(crop['id'])
    //       };

    //       // set up the evidencing types counter
    //       for (const { value } of CONFIRMATION_TYPE_CHOICES) {
    //         cropSpec['selectedEvidencing'][value] = 0;
    //       }

    //       for (const fieldId in curr) {
    //         if (curr[fieldId] == null || ['toExclude'].includes(fieldId)) continue;

    //         // get the crops selected in the fields setup step per field
    //         // const selectedCrops = curr[fieldId]['crops'].filter(({ id }) => curr[fieldId]['selectedCrops'].includes(String(id)));
    //         const selectedCrops = [];

    //         for (const fieldCrop of curr[fieldId]['crops']) {
    //           for (const plantingDate of fieldCrop['plantings']) {
    //             if (curr['toExclude'][fieldCrop['name']].includes(plantingDate)) continue

    //             if (!selectedCrops.includes(fieldCrop['id'])) {
    //               selectedCrops.push(fieldCrop);
    //             }
    //           }
    //         }

    //         const foundCrop = selectedCrops.find(({ id }) => id == crop['id']);
            
    //         // if the current crop isn't relevant to this field, or this field doesn't have any pre-generated evidencing, skip it
    //         if (foundCrop == null || curr[fieldId]['evidencing'] == null) continue

    //         // this field has a crop, keep track of all possible fields which can receive evidencing per crop
    //         cropSpec['relevantFields'].push(fieldId);

    //         // filter the field's pre-generated evidencing based on the current crop
    //         const fieldCropEvidencing = curr[fieldId]['evidencing'].filter(({ crops }) => crops.some(({ id }) => crop['id'] == id));
    //         if (fieldCropEvidencing.length > 0) {
    //           for (const evidencing of fieldCropEvidencing) {
    //             if (!cropSpec['evidencing'].includes(evidencing['id'])) {
    //               cropSpec['evidencing'].push(evidencing['id'])
    //               cropSpec['selectedEvidencing'][evidencing['confirmation_type']] += 1;

    //               // add all of the evidencing for client-based aggregating later
    //               allEvidencing.push(evidencing);
    //             }
    //           }
    //         }
    //       }

    //       // only add to the spec if we found evidencing for this crop
    //       if (cropSpec['evidencing'].length > 0) {
    //         Vue.set(this.evidencingCropSpecification, crop['id'], cropSpec);
    //       }
    //     }

    //     // the editing views are crop-based, but ultimately the groups will be client-based
    //     // so the group-based references also need to be created
    //     const clientGroupings = {};

    //     for (const evidencing of allEvidencing) {
    //       const client = evidencing['client'] != null ? evidencing['client'] : null;

    //       if (client != null) {
    //         if (client['id'] in clientGroupings) clientGroupings[client['id']]['evidencing'].push(evidencing);
    //         else clientGroupings[client['id']] = {
    //           name: client['name'],
    //           evidencing: [evidencing],
    //           dueDateModifications: {},
    //           toCreate: [],
    //           toRemove: []
    //         }
    //       }
    //     }

    //     Vue.set(this.evidencingCropSpecification, 'clientGroupings', clientGroupings)
    //   },
    //   deep: true
    // }
  }
}
</script>

<style scoped>
.add-fields-input {
  display: flex;
  margin: 24px 0 15px 0;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.table-editing-btns {
  margin: 16px 0 8px;
  /* width: calc(100% - 16px); */
  display: flex;
  justify-content: flex-end;
}

.v-text-field {
  max-width: 400px;
  margin-right: 8px;
}

.evidencing-title,
.evidencing-subtitle {
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
  margin: 0 0 8px;
  color: #000000;
}

.evidencing-subtitle {
  font-weight: normal;
}

.fields-display {
  width: 100%;
}

.fields-display .v-data-table {
  height: 400px;
  overflow-y: scroll;
}

.fields-display th {
  background: #FFFFFF;
  position: sticky;
  top: 0;
  z-index: 9;
}

.field-crop-cell span {
  display: flex;
  align-items: flex-start;
}

.field-crop-cell span:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.field-crop-cell .v-input {
  margin: 4px 0;
  padding: 0;
}

.evidencing-step-crop .v-input {
  margin: 0;
  padding: 0;
}

.field-crop-cell ::v-deep(.v-messages),
.evidencing-step-crop ::v-deep(.v-messages) {
  display: none;
}

.field-crop-cell ::v-deep(.v-input__slot),
.field-crop-cell ::v-deep(.v-input--selection-controls__input),
.field-crop-cell ::v-deep(.v-label),
.evidencing-step-crop ::v-deep(.v-input__slot),
.evidencing-step-crop ::v-deep(.v-input--selection-controls__input),
.evidencing-step-crop ::v-deep(.v-label) {
  margin: 0;
}

.field-crop-cell ::v-deep(.v-input--selection-controls__ripple),
.evidencing-step-crop ::v-deep(.v-input--selection-controls__ripple) {
  display: none;
}

.field-crop-cell ::v-deep(.v-label) {
  margin-left: 8px;
  white-space: nowrap;
  color: #000000;
}

.field-configuration-wrapper {
  display: flex;
  justify-content: flex-start;
  height: 400px;
}

.fields-disambiguation {
  width: 35%;
  margin-left: 24px;
  border-radius: 5px;
  background: #FFFFFF;
}

.disambiguation-text-container {
  padding: 24px;
}

.disambiguation-text-container p {
  color: #000000;
}

.evidencing-cta {
  margin-top: 16px;
  font-weight: bold;
}

.group-buttons-toggle > button {
  text-transform: none;
  padding: 6px 16px;
  letter-spacing: normal;
  border-radius: 4px !important;
}

.group-buttons-toggle > button.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: none !important;
}

.evidencing-step-info {
  color: #000000;
  margin: 0;
}

.evidencing-step-info:first-of-type {
  margin-top: 16px;
}

.evidencing-step-info:last-of-type {
  font-size: 12px;
  line-height: 1;
}

.evidencing-step-crop-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
  overflow-x: scroll;
  padding-bottom: 96px;
}

.evidencing-step-crop {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0;
  border-radius: 5px;
  margin-right: 36px;
  min-width: 450px;
  max-width: 450px;
}

.evidencing-step-crop .info-head {
  padding: 8px 0;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.evidencing-step-crop .info-head h4 {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  text-transform: capitalize;
}

.evidencing-step-crop .v-data-table {
  background: #FFFFFF;
  width: 100%;
}

.planting-date-wrapper {
  width: 100%;
}

.planting-date {
  display: flex;
  color: #000000;
  justify-content: flex-start;
  margin: 0;
  width: 100%;
}

.evidencing-step-crop th {
  color: #000000 !important;
}

.evidencing-step-crop td:not(:first-of-type) {
  border-left: 1px solid #D9D9D9;
}

.clickable-evidencing-configuration {
  cursor: pointer;
}

.stepwise-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 68px;
  right: 0;
  background-color: #464B58;
  padding-right: 96px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.stepwise-footer > button {
  margin-left: 16px;
}
.fields-submission-btn {
  margin-right: 16px;
}

.not-found-fields-alert {
  background: #FF9090;
  padding: 8px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  align-self: flex-start;
  height: 56px;
}
.not-found-fields-alert p {
  margin: 0 0 0 8px;
  color: #000000;
  font-weight: bold;
}
.broken-check path {
  stroke: #288418;
}
.evidencing-row-spacer {
  width: 22px;
  margin-right: 16px;
  display: inline-block;
}
::v-deep(.v-text-field__details) {
  display: none;
}
.excluded-field-crop-row td {
  text-decoration: line-through;
}
.dates-head {
  min-width: 120px;
}
.group-loading-modal {
  position: fixed;
  left: calc(50% - 300px);
  top: calc(50% - 150px);
  width: 600px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  z-index: 99999;
}
.group-loading-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 99998;
}
.loading-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.loading-wrapper i {
  margin-right: 16px;
}
.loading-wrapper p {
  margin: 0 0 8px 0;
}
.loading-wrapper p:last-of-type {
  margin: 0;
}
.submit-section {
  display: flex;
  flex: 0 1 auto;
}

.group-option {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.group-option button.active {
  background-color: black;
  color: white;
}

.submit-section ::v-deep(.v-input__slot) {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.submit-section ::v-deep(.v-input__slot fieldset) {
  height: 40px;
}
.submit-section ::v-deep(.v-input__slot input) {
  width: 200px;
  font-size: 14px;
}
</style>
