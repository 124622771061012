<template>
    <ArvaModal
      name="groupConfirmModal"
      :title="titleText"
      @close-modal="handleCloseModal"
    >
      <div v-if="isProcessing" class="zero-state span-text">
        <div class="spinner-border spinner-border-sm ml-1" role="status"></div>
        <span> The system is processing your request. Please wait... </span>
      </div>
  
      <div v-else>
        <!-- <div class="confirm-text" v-html="confirmText"></div>
        <slot /> -->
        <div class="row modal-pad">
          <div class="col-12">
            <div class="d-flex justify-content-around my-2">
              <div class="select-container">
                <v-text-field
                  dense
                  outlined
                  label="Group Name"
                  v-model="groupName"
                  type="text"
                ></v-text-field>
              </div>
            </div>
            <div class="d-flex justify-content-around">
              <v-btn
                @click="handleSaveGroup"
                :disabled="!groupName"
                >Save</v-btn
              >
            </div>
          </div>
        </div>
  
        <!-- <ArvaModalSaveFooter
          confirmText="Save"
          cancelText="Cancel"
          :isClosingWithoutSaving="isClosingWithoutSaving"
          @on-close-without-saving="handleCloseWithoutSaving"
          @on-cancel="handleCloseModal"
          @on-confirm="handleConfirm"
        /> -->
      </div>
    </ArvaModal>
  </template>
  
  <script>
  import ArvaModal from "./ArvaModal"
  import ArvaModalSaveFooter from "./ArvaModalSaveFooter"
  
  import {
    createRanchGroups
  } from "@/api/EvidencingAPI";
  
  export default {
    name: "GroupConfirmModal",
    props: {
      titleText: { type: String },
      isProcessing: { type: Boolean },
      isClosingWithoutSaving: { type: Boolean },
      spec: { type: Array },
    },
    components: { ArvaModal, ArvaModalSaveFooter },
    data() {
      return {
        groupName: null
      }
    },
  
    methods: {
      handleCloseModal() {
        this.$emit("close-modal")
      },
      handleSaveGroup() {
        const payload = {
          name: this.groupName,
          spec: this.spec,
        }
        createRanchGroups(payload)
          .then(response => {
            console.log(response)
            // this.state = "client-result"
            this.$emit("confirm", response)
          })
          .catch(error => {
            console.log(error)
            this.$emit("confirm")
          })
      },
      // handleConfirm() {
      //   this.$emit("confirm")
      // },
  
      handleCloseWithoutSaving() {
        this.$emit("close-without-saving")
      }
    },
  }
  </script>
  
  <style scoped>
  .confirm-text {
    font-size: 15px;
    font-weight: 500;
    padding: 30px 30px;
  }
  </style>
  