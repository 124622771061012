<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <!-- Crop Protection Info -->
      <div id="input-card-container">
        <v-card class="my-auto mx-auto" v-if="showPracticesView || showSaveView || showSummaryView">
          <v-card-text class="min-step-height" v-if="showPracticesView">
            <v-row class="mx-1 mt-7">
              <div class="text-h5">Add Crop Protection for {{ year }}</div>
              <v-btn class="ml-auto" @click="handleCancelClick()">
                <v-icon>mdi-close</v-icon> Cancel
              </v-btn>
              <v-btn
                class="ml-4"
                color="green lighten-4"
                :disabled="allProtectionComplete().length > 0"
                @click="handlePreSave()"
                >Save and Continue</v-btn
              >
            </v-row>

            <v-row class="mx-1 mt-7">
              <v-btn-toggle v-model="hasPracticeData" readonly divided dense mandatory active-class="light-blue lighten-4">
                <v-btn :value="true" class="px-3" @click="handleYesProtectionClick">
                  Applied Protection <v-icon>mdi-check-bold</v-icon>
                </v-btn>
                <v-btn :value="false" class="px-3" @click="handleNoProtectionClick">
                  No Protection <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle class="ml-5" v-model="stackPractices" divided dense active-class="light-blue lighten-4" mandatory>
                <v-btn :value="true" :disabled="!hasPracticeData" class="px-3">Stack Practices</v-btn>
                <v-btn :value="false" class="px-3">Replace Practices</v-btn>
              </v-btn-toggle>
            </v-row>

            <v-row v-if="protectionData.length > 0">
              <v-col md="12">
                <v-card
                  v-for="cp in protectionData"
                  :key="cp.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor blue-grey lighten-5"
                    @click="cp.showAllInfo = !cp.showAllInfo"
                  >
                    Protection #{{ protectionData.indexOf(cp) + 1 }}
                    <v-icon
                      v-if="!cp.showAllInfo"
                      @click="cp.showAllInfo = true"
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else @click="cp.showAllInfo = false"
                      >mdi-chevron-down</v-icon
                    >
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            isCPComplete(cp) ? 'green accent-4' : 'red darken-1'
                          "
                        >
                          {{
                            isCPComplete(cp)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        isCPComplete(cp)
                          ? "Application is good to go!"
                          : "You're missing a few fields, please complete this application to save and continue!"
                      }}</span></v-tooltip
                    >
                    <v-btn
                      class="ml-2"
                      @click.stop="handleRemoveCP(protectionData.indexOf(cp))"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="cp.showAllInfo">
                    <!-- crop and date -->
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >On which crop was this applied?<span
                              class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Crop"
                            :disabled="
                              cropSelect ? cropSelect.length == 1 : false
                            "
                            :items="cropChoicesFiltered"
                            v-model="cp.crop_id"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="mt-3 mb-2">
                          <b>Protection Date</b>
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="cp.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="cp.date"
                                label="Protection Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="cp.date"
                              :picker-date.sync="cp.pickerDate"
                              :min="yearPriorOne + '-01-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="cp.menu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Method, Type, and Product -->
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7">
                            Describe your protection method, type, and
                            product:<span class="red--text">*</span>
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Method"
                            :items="methodChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="cp.method"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="input-holder">
                          <PracticeChoiceDropdown
                            dense
                            outlined
                            hide-details
                            label="Type"
                            :items="typesByMethod(cp.method)"
                            item-text="display_name"
                            item-value="value"
                            v-model="cp.type"
                            choiceName="crop_protection_type"
                            clearable
                            dependentChoiceName="crop_protection_method"
                            :dependentChoiceValue="cp.method"
                          />
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="input-holder">
                          <v-combobox
                            dense
                            outlined
                            hide-details
                            :disabled="cp.type ? false : true"
                            :label="cp.type ? 'Product' : 'Select a Type'"
                            :items="cropProtectionChoices(cp.method, cp.type)"
                            v-model="cp.product"
                            clearable
                          ></v-combobox>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Rate and Units -->
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7"
                            >What was your protection application rate?</b
                          >
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            label="Rate"
                            :rules="[numberValidator]"
                            v-model="cp.rate"
                            type="number"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="input-holder">
                          <b class="text-h7"></b>
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Units"
                            :items="unitsByMethod(cp.method)"
                            item-text="display_name"
                            item-value="value"
                            v-model="cp.unit"
                          >
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <div>
                          <b class="text-h7">
                            Integrated Pest Management (IPM)?
                          </b>
                          <div class="pt-2">
                            <v-btn
                              rounded
                              outlined
                              @click="cp.ipm = true"
                              :input-value="cp.ipm === true"
                              >Yes</v-btn
                            >
                            <v-btn
                              class="ml-4"
                              rounded
                              outlined
                              @click="cp.ipm = false"
                              :input-value="cp.ipm === false"
                              >No</v-btn
                            >
                          </div>
                        </div>
                      </v-col>
                      <v-col>
                        <div>
                          <b class="text-h7">
                            Integrated Weed Management (IWM)?
                          </b>
                          <div class="pt-2">
                            <v-btn
                              rounded
                              outlined
                              @click="cp.iwm = true"
                              :input-value="cp.iwm === true"
                              >Yes</v-btn
                            >
                            <v-btn
                              class="ml-4"
                              rounded
                              outlined
                              @click="cp.iwm = false"
                              :input-value="cp.iwm === false"
                              >No</v-btn
                            >
                          </div>
                        </div>
                      </v-col>
                      <v-col>
                        <div>
                          <b class="text-h7">
                            Integrated Disease Management (IDM)?
                          </b>
                          <div class="pt-2">
                            <v-btn
                              rounded
                              outlined
                              @click="cp.idm = true"
                              :input-value="cp.idm === true"
                              >Yes</v-btn
                            >
                            <v-btn
                              class="ml-4"
                              rounded
                              outlined
                              @click="cp.idm = false"
                              :input-value="cp.idm === false"
                              >No</v-btn
                            >
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn class="mt-4" block @click="handleAddEventClick">
                  Add Crop Protection Application<v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="protectionData.length == 0">
              <v-card class="mx-auto mt-4" elevation="0">
                <v-card-text>
                  <div class="text-center">
                    <b class="text-h5 text-bold">
                      No Crop Protection Applied for {{ this.year }}
                    </b>
                  </div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
          <!-- Save Spinner View -->
          <v-card-text class="min-step-height text-center" v-if="showSaveView">
            <v-progress-circular
              class="mt-4"
              :size="100"
              :width="8"
              color="green"
              indeterminate
            >
            </v-progress-circular>
            <div>
              <h3>Saving your crop protection information</h3>
            </div>
          </v-card-text>
          <!-- Summary View -->
          <v-card-text class="min-step-height" v-if="showSummaryView">
            <div class="d-flex">
              <h3>Fields Updated</h3>
              <v-btn class="ml-auto" @click="handleAddMoreProtection">
                Add More Protection
              </v-btn>
            </div>
            <div class="d-flex">
              <v-chip
                class="mx-2"
                v-for="fieldName in summaryFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3>Crop Protection Data Submitted</h3>
            <div>
              <v-simple-table class="mx-4 mb-4">
                <thead>
                  <tr>
                    <th v-for="key in summaryKeys" :key="key.idx">
                      {{ key == "crop_id" ? "Crop" : key | cleanSnake }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in summaryData" :key="data.idx">
                    <td v-for="key in summaryKeys" :key="key.idx">
                      <span v-if="key == 'crop_id'">{{
                        cropDisplay[data[key].value]
                      }}</span>
                      <span v-else>
                        {{
                          data[key].value
                            ? data[key].value
                            : "None" | cleanSnake
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <!-- Fields, Data Comp, and Add Practices -->
      <v-row class="mt-4 mb-2 row-margin-correct" v-if="!showPracticesView && !showSaveView">
        <div>
          <span class="text-h5 ml-1"
            >{{ protectionSettingsByField.length }} fields</span
          >
          <span class="text-h5 ml-2"
            >({{ dataCompletionForYear }}% Complete)</span
          >
        </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              class="mx-1"
              text
              v-for="y in yearListModified"
              :key="y.idx"
              @click="handleSeasonSelection(y)"
              :color="year == y ? 'green' : ''"
              >{{ y }}</v-btn
            >
          </div>
          <div class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div class="ml-2">
            <v-btn v-if="showPracticesView === false && showSaveView === false && showSummaryView === false"
              :class="'white--text'"
              :color="'green'"
              :disabled="fieldsInSelection.length === 0"
              x-large
              @click="handleAddPracticesClick"
              >Add Practices</v-btn
            >
          </div>
        </div>
      </v-row>
      <v-row class="mt-4 mb-2 row-margin-correct" v-else>
        <div>
          <span class="text-button ml-1">Receiving fields</span>
        </div>
      </v-row>

      <!-- Quick Filtering Buttons -->
      <v-row class="mt-4 row-margin-correct">
        <v-btn
         class="mr-3"
         @click="handleSelectAll(true, true)"
         text
         :color="cropSelect === null ? 'green' : ''"
        >
          <span>All</span>
          <span>({{ protectionSettingsByField.length }})</span>
        </v-btn>
        <v-btn
          class="mr-3"
          text
          v-for="sharedCropPlan in historicalPlantings"
          :key="sharedCropPlan.idx"
          :color="
            JSON.stringify(cropSelect) ==
            JSON.stringify(sharedCropPlan.crops.split(','))
              ? 'green'
              : ''
          "
          @click="handleCropFilterButton(sharedCropPlan)"
        >
          <span>{{ mapCropNames(sharedCropPlan.crops) }}</span>
          <span>({{ sharedCropPlan.fieldIds.length }})</span>
        </v-btn>
      </v-row>

      <!-- Field Filtering Table -->
      <v-row class="pt-3">
        <v-col>
          <v-card>
            <v-card-text v-if="showSelectedFields">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three">
                      <div class="d-flex">
                        <v-simple-checkbox
                          color="blue"
                          v-model="selectAllBox"
                          @input="handleSelectAll($event)"
                        >
                        </v-simple-checkbox>
                      </div>
                    </th>
                    <th class="ten">Name</th>
                    <th class="three">Farm</th>
                    <th class="three">Locked</th>
                    <th class="three">Crops</th>
                    <th>Crop Protection</th>
                    <th class="three">
                      <div>
                        Copy
                        <!-- <v-tooltip top
                        ><template v-slot:activator="{ on, attrs }"
                          ><v-icon v-bind="attrs" v-on="on"
                            >mdi-information</v-icon
                          ></template
                        ><span
                          >Click copy to copy a field's settings quickly to set
                          multiple fields at once.</span
                        ></v-tooltip
                      > -->
                      </div>
                    </th>
                    <th v-if="showPracticesView">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in protectionSettingsByFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <v-simple-checkbox
                        color="blue"
                        :value="
                          fieldsInSelection.includes(parseInt(field.fieldId))
                        "
                        :disabled="
                          disableCheckbox(field.noYearData) || field.fieldLocked
                        "
                        @input="fieldChecked(field, $event)"
                      ></v-simple-checkbox>
                    </td>
                    <td>{{ field.fieldName }}</td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>
                      <v-icon
                        size="20"
                        :color="field.fieldLocked ? 'red' : 'green'"
                      >
                        {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                      </v-icon>
                    </td>
                    <td v-if="'cropNames' in field">
                      <div v-for="crop in field.cropNames" :key="crop">
                        {{ crop }}
                      </div>
                    </td>
                    <td v-else>
                      <div>No Crop</div>
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                      <v-row
                        class="mx-0"
                        :class="
                          field.protectionInfo.indexOf(protectionSingle) !=
                          field.protectionInfo.length - 1
                            ? 'row-bottom'
                            : ''
                        "
                        dense
                        v-for="protectionSingle in field.protectionInfo"
                        :key="protectionSingle.idx"
                      >
                        <v-col md="2">
                          <span
                            :class="
                              protectionSingle.date.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              protectionSingle.date.value
                                ? protectionSingle.date.value
                                : "No Date"
                            }}
                          </span>
                        </v-col>
                        <v-col md="2">
                          <span
                            :class="
                              protectionSingle.method.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                            >{{
                              protectionSingle.method.value
                                ? protectionSingle.method.value
                                : "No Method"
                            }}
                          </span>
                          <span
                            :class="
                              protectionSingle.type.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              protectionSingle.type.value
                                ? protectionSingle.type.value
                                : "No Type"
                            }}
                          </span>
                        </v-col>
                        <v-col md="2">
                          <span
                            :class="
                              protectionSingle.product.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              protectionSingle.product.value
                                ? protectionSingle.product.value
                                : "No Product"
                            }}
                          </span>
                        </v-col>
                        <v-col md="2">
                          <span
                            :class="
                              protectionSingle.rate.value !== null
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              protectionSingle.rate.value !== null
                                ? protectionSingle.rate.value
                                : "No Rate"
                            }}
                          </span>
                          <span
                            :class="
                              protectionSingle.unit.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                            >{{
                              protectionSingle.unit.value
                                ? protectionSingle.unit.value
                                : "No Unit"
                            }}</span
                          >
                        </v-col>
                        <v-col md="1">
                          <span v-if="protectionSingle.ipm?.value === true" class="black--text">IPM&nbsp;</span>
                          <span v-if="protectionSingle.iwm?.value === true" class="black--text">IWM&nbsp;</span>
                          <span v-if="protectionSingle.idm?.value === true" class="black--text">IDM</span>
                        </v-col>
                        <v-col md="2"
                          ><!-- Data Complete -->
                          <div
                            class="d-flex"
                            v-if="protectionComplete(protectionSingle)"
                          >
                            <v-tooltip top
                              ><template v-slot:activator="{ on, attrs }"
                                ><v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  color="success"
                                  >mdi-check-circle</v-icon
                                ></template
                              ><span
                                >You've filled out enough information for Arva
                                data models to run. Thank you!</span
                              ></v-tooltip
                            >
                            <span class="ml-2">Data Complete</span>
                          </div>
                          <!-- Needs Confirmation -->
                          <div
                            class="d-flex"
                            v-else-if="protectionNeedsConfirm(protectionSingle)"
                          >
                            <v-tooltip top
                              ><template v-slot:activator="{ on, attrs }"
                                ><v-icon v-bind="attrs" v-on="on" color="yellow"
                                  >mdi-alert-rhombus</v-icon
                                ></template
                              ><span
                                >This information is coming from a public
                                source. Please include this field when setting
                                information to confirm it's data!</span
                              ></v-tooltip
                            >
                            <span
                              class="ml-2 cursor blue--text lighten-1 text-bold"
                              @click="handleConfirm(field)"
                              >Confirm Data</span
                            >
                          </div>
                          <!-- Missing Data -->
                          <div
                            class="d-flex"
                            v-else-if="protectionMissingData(protectionSingle)"
                          >
                            <v-tooltip top
                              ><template v-slot:activator="{ on, attrs }"
                                ><v-icon v-bind="attrs" v-on="on" color="red"
                                  >mdi-alert-circle</v-icon
                                ></template
                              ><span
                                >This field is missing data. Please fill in its
                                data using the wizard!</span
                              ></v-tooltip
                            >
                            <span class="ml-2">Missing Data</span>
                          </div></v-col
                        >
                      </v-row>
                    </td>
                    <td class="py-2" v-else>
                      <div @click="handleShowRedirect(field)">
                        <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on" color="red"
                              >mdi-alert-circle</v-icon
                            ></template
                          ><span
                            >This field doesn't have data for this year. Add
                            another year in the data spreadsheet or upload data
                            from this year.</span
                          ></v-tooltip
                        ><span class="ml-2 cursor red--text text-bold"
                          >No Data for Year</span
                        >
                      </div>
                    </td>
                    <td class="cursor" @click="handleCopy(field)">
                      <span class="blue--text lighten-1 text-bold">Copy</span>
                    </td>
                    <!-- Status Icons -->
                    <td v-if="showPracticesView">
                      <span class="py-2" v-if="!field.noYearData && allProtectionComplete().length === 0">
                        <v-row
                          class="mx-0"
                          :class="
                            field.protectionInfo.indexOf(protectionSingle) !=
                            field.protectionInfo.length - 1
                              ? 'row-bottom'
                              : ''
                          "
                          dense
                          v-for="protectionSingle in field.protectionInfo"
                          :key="protectionSingle.idx"
                        >
                          <v-tooltip v-if="stackPractices && isAnyDuplicate(protectionSingle)" top max-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="orange">mdi-alert</v-icon>
                            </template>
                            <span>Can not stack duplicate practice on this field.</span>
                          </v-tooltip>
                          <v-tooltip v-if="isUntargetedCrop(protectionSingle)" top max-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="orange">mdi-selection-ellipse-remove</v-icon>
                            </template>
                            <span>No practice data is currently being added for {{ cropDisplay[protectionSingle.crop_id.value] }}.</span>
                          </v-tooltip>
                        </v-row>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />

      <ConfirmModal
        v-if="showSaveConfirmModal"
        titleText="Confirm Practice Replacement"
        :confirmText="saveConfirmText"
        @confirm="handleSave"
        @close-modal="showSaveConfirmModal = false"
      />

      <!-- Wizard Confirm Modal -->
      <WizardConfirmModal
        v-if="showConfirmModal"
        :confirmationField="confirmationField"
        :confirmationData="confirmationApplications"
        :wantedKeys="[
          'crop_id',
          'date',
          'product',
          'method',
          'type',
          'rate',
          'unit',
        ]"
        :year="year"
        :loadState="loadState"
        @close-template-modal="handleCloseConfirm"
        @confirm-data="handleModalConfirm"
      />

      <!-- Redirect -->
      <WizardRedirectModal
        v-if="showRedirectModal"
        :redirectField="redirectField"
        :year="year"
        @close-template-modal="handleCloseRedirect"
        @redirect-confirm="handleRedirectConfirm"
      />
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapMutations, mapState, mapGetters } from "vuex"
import {
  cropProtectionChoices,
  MAX_DEFAULT_FIELDS,
} from "@/constants/defaults"
import { Defaults, Organization } from "@/store/modules"
import {
  CROP_DISPLAY_NAME,
  CROP_KEY,
  CROP_ID_KEY,
} from "@/constants"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardConfirmModal from "@/components/modals/WizardConfirmModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import ConfirmModal from "@/components/modals/ConfirmModal"
import { numberValidator } from "@/utility"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"
import { dupeCheck, mapPayloadKeysToUserInput } from "./utils"

export default {
  name: "ProtectionWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
    dataCompletion: { type: Array },
    dataCompletionMaster: { type: Object },
  },
  components: {
    AlertErrorModal,
    WizardConfirmModal,
    WizardRedirectModal,
    PracticeChoiceDropdown,
    ConfirmModal
  },
  data() {
    return {
      cropDisplay: CROP_DISPLAY_NAME,
      cropChoices: Object.values(CROP_KEY),
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,

      //   crop protection data
      protectionData: [],
      stackPractices: true,
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      tableFilterText: "",
      selectAllBox: true,
      // full validation for application completeness
      showIncompleteModal: false,
      incompleteProtections: null,
      alertErrorModalOpen: false,
      // confirmation
      confirmationField: null,
      confirmationApplications: null,
      showConfirmModal: false,
      showSaveConfirmModal: false,
      loadState: null,
      // redirection
      showRedirectModal: false,
      redirectField: null,
      // table year for copying from historical info
      tableYear: null,
      rules: {
        date: value => {
          var dateRegex = /^\d{4}-\d{2}-\d{2}$/
          return dateRegex.test(value) || "YYYY-MM-DD"
        },
      },
      numberValidator,
      //   select crop type UX, disables selecting new fields when selecting by crop tile
      cropSelect: null,
      //   new UI
      showPracticesView: false,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      summaryKeys: null,
      summaryData: null,
    }
  },
  computed: {
    ...mapState({
      protectionSettings: state => state.Defaults.newFieldSettings,
    }),
    ...mapGetters({
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    methodChoices() {
      return this.practiceChoices["crop_protection_method"]
    },
    typeChoices() {
      return this.practiceChoices["crop_protection_type"]
    },
    unitChoices() {
      return this.practiceChoices["crop_protection_units"]
    },
    cropChoicesFiltered() {
      if (this.cropSelect != null) {
        const filteredCropList = Object.entries(CROP_KEY)
          .filter(o => {
            if (this.cropSelect.length > 1) {
              return this.cropSelect.includes(o[0])
            } else {
              return true
            }
          })
          .map(list => list[1])
        return filteredCropList
      } else {
        const selectedProtSettings = this.protectionSettingsByField.filter(
          field => this.fieldsInSelection.includes(parseInt(field.fieldId))
        )
        const selectedCrops = new Set(
          selectedProtSettings.flatMap(field => field.crops)
        )
        const selectedCropNames = Array.from(selectedCrops).map(
          c => CROP_KEY[c]
        )
        return selectedCropNames.filter(i => i != undefined)
      }
    },
    hasPracticeData() {
      return this.protectionData.length > 0 ? true : false;
    },
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0) {
        return 0
      }
      let yearDataComps = this.dataCompletionMaster["Crop Protection"].map(
        o => {
          if ("by_year" in o.data_comp) {
            let temp = o.data_comp.by_year.find(
              yearObj => yearObj.year == this.year
            )
            if (temp) {
              return temp.avg_for_year
            }
          } else {
            return 0
          }
        }
      )
      let average = array => array.reduce((a, b) => a + b) / array.length
      if (yearDataComps.length > 0) {
        return Math.ceil(average(yearDataComps))
      } else {
        return 0
      }
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.protectionSettingsByField) {
        fieldLocks[field.fieldId] = field.fieldLocked
      }
      return fieldLocks
    },
    saveConfirmText() {
      let countText;
      if (this.protectionData.length) {
        countText = `${this.protectionData.length} crop protection application` + (this.protectionData.length > 1 ? "s" : "")
      } else {
        countText = `No Crop Protection`
      }
      return `
        Are you sure you want to replace practices on the ${this.fieldsInSelection.length} selected fields for ${this.year}?
        <br /><br />
        Existing applications will be removed and replaced with ${countText}.`
    },
    protectionSettingsByField() {
      let protectionDict = {}
      let yearInConsideration = this.year
      Object.entries(this.protectionSettings).forEach(protDefault => {
        const fieldKey = protDefault[0]
        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        protectionDict[fieldKey] = {}
        const fieldSettingObj = protDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          opsId: fieldSettingObj.operation_id,
        }
        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsideration
        )
        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            protectionDict[fieldKey] = idSettings
            protectionDict[fieldKey]["crops"] = ["No Data"]
            protectionDict[fieldKey]["cropNames"] = ["No Data"]
            protectionDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsideration) {
            protectionDict[fieldKey] = idSettings
            protectionDict[fieldKey]["crops"] = []
            protectionDict[fieldKey]["crops"] = []
            protectionDict[fieldKey]["cropNames"] = []
            protectionDict[fieldKey]["cropSearch"] = ""
            protectionDict[fieldKey]["plantings"] = []
            protectionDict[fieldKey]["protectionInfo"] = []

            protectionDict[fieldKey]["isSelected"] =
              this.fieldsInSelection.includes(parseInt(fieldKey)) ? true : false

            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )
            cultivations.forEach(cultivation => {
              protectionDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
              protectionDict[fieldKey]["cropNames"].push(
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              )
              protectionDict[fieldKey]["cropSearch"] =
                protectionDict[fieldKey]["cropSearch"] +
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              protectionDict[fieldKey]["plantings"] = protectionDict[fieldKey][
                "plantings"
              ].concat(cultivation.plantings)
              cultivation.crop_protection.forEach(cp =>
                protectionDict[fieldKey]["protectionInfo"].push(cp)
              )

              protectionDict[fieldKey]["crops"].sort()
              protectionDict[fieldKey]["cropNames"].sort()
              protectionDict[fieldKey]["fieldLocked"] =
                cultivation.year_data_locked
              protectionDict[fieldKey]["noYearData"] = false
            })
          }
        })
      })
      return Object.values(protectionDict)
    },
    protectionSettingsByFieldFiltered() {
      let arrayToReturn = _.cloneDeep(this.protectionSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        arrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
      }

      const sortColumns = ["fieldName"]
      const sortDirections = ["desc", "asc"]

      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    historicalPlantings() {
      const plantingsGrouped = _(this.protectionSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
          noYearData: _.map(items, "noYearData")[0],
        }))
        .value()
      return plantingsGrouped
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updateProtection: Defaults.Actions.updateProtection,
    }),
    ...mapMutations({
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    cropProtectionChoices(method, type) {
      return cropProtectionChoices(method, type)
    },
    disableCheckbox(noYearData) {
      if (noYearData) {
        return true
      } else {
        return false
      }
    },
    typesByMethod(method) {
      if (["IPM", "IWM", "IDM"].includes(method)) {
        return this.typeChoices.filter(
          c => c.dependent_value === method || c.value === "other"
        )
      }
      return this.typeChoices.filter(
          c => c.dependent_value === method || !c.dependent_value
      )
    },
    unitsByMethod(method) {
      return this.unitChoices.filter(
        c => c.dependent_value === method || !c.dependent_value
      )
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (
        e == true &&
        this.fieldsInSelection.indexOf(parseInt(field.fieldId)) == -1
      ) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    handleAddEventClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.protectionData.push(this.blankProtectionData());
    },
    handleAddMoreProtection() {
      this.showSummaryView = false
      this.showPracticesView = true
    },
    handleAddPracticesClick() {
      if (this.showPracticesView == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        const newProtectionData = this.blankProtectionData()
        newProtectionData.crop_id = cropValue
        this.protectionData = [newProtectionData];
      }
    },
    handleCancelClick() {
      this.showSaveView = false
      this.showSummaryView = false
      this.showPracticesView = false
      this.protectionData = []
    },
    handleCopy(field) {
      this.cropSelect = null;

      const copiedData = this.extractProtectionData(field)
      for (const practice of copiedData) {
        if (!this.cropChoicesFiltered.includes(practice.crop_id)) {
          practice.crop_id = null
        }
      }
      this.protectionData = copiedData

      this.showSummaryView = false
      this.showPracticesView = true

      const myElement = document.getElementById("input-card-container")
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    },
    extractProtectionData(field) {
      const numFieldProtections = field.protectionInfo.length

      let fieldProtectionData = [];
      for (let i = 0; i < numFieldProtections; i++) {
        fieldProtectionData.push({
          crop_id: CROP_KEY[field.protectionInfo[i].crop_id.value],
          date: field.protectionInfo[i].date.value,
          rate: field.protectionInfo[i].rate.value,
          unit: field.protectionInfo[i].unit.value,
          method: field.protectionInfo[i].method.value,
          product: field.protectionInfo[i].product.value,
          type: field.protectionInfo[i].type.value,
          ipm: field.protectionInfo[i].ipm ? field.protectionInfo[i].ipm.value : null,
          iwm: field.protectionInfo[i].iwm ? field.protectionInfo[i].iwm.value : null,
          idm: field.protectionInfo[i].idm ? field.protectionInfo[i].idm.value : null,
          showAllInfo: true,
          menu: false,
          pickerDate: this.year + "-01",
        })
      }
      return fieldProtectionData;
    },
    isAnyDuplicate(existingProtection) {
      for (const newProtection of this.protectionData) {
        if (this.isProtectionDuplicate(existingProtection, mapPayloadKeysToUserInput(newProtection))) {
          return true
        }
      }
      return false
    },
    isUntargetedCrop(protection) {
      if (this.protectionData.length == 0) {
        return false
      }
      const targetedCrops = this.protectionData.map(cp => CROP_ID_KEY[cp.crop_id]);
      return protection.crop_id.value && !targetedCrops.includes(protection.crop_id.value);
    },
    isProtectionDuplicate(protection1, protection2) {
      const duplicateItems = [
        dupeCheck(protection1.rate.value, protection2.rate.value),
        protection1.unit.value == protection2.unit.value,
        protection1.method.value == protection2.method.value,
        protection1.product.value == protection2.product.value,
        protection1.type.value == protection2.type.value
      ];
      const numDuplicateItems = duplicateItems.filter(Boolean).length;

      return (
        protection1.crop_id.value == protection2.crop_id.value &&
        protection1.date.value == protection2.date.value &&
        numDuplicateItems >= duplicateItems.length - 1
      )
    },
    isProtectionNone(protection) {
      return (
        (protection.rate == 0 || protection.rate == null) &&
        (protection.method == "none" || protection.method == null) &&
        (protection.product == "none" || protection.product == null) &&
        (protection.type == "none" || protection.type == null)
      )
    },
    mergeFieldPayload(field, protectionPayload) {
      if (this.protectionData.length == 0 || !this.stackPractices) {
        return protectionPayload;
      }
      let fieldProtectionData = this.extractProtectionData(field);
      fieldProtectionData = fieldProtectionData.filter(cp => !this.isProtectionNone(cp));
      let mappedData = fieldProtectionData.map(cp => {
        return mapPayloadKeysToUserInput(cp);
      });
      mappedData = mappedData.filter(existingCp => !protectionPayload.some(newCp => this.isProtectionDuplicate(existingCp, newCp)));

      const mergedPayload = protectionPayload.concat(mappedData);
      return mergedPayload;
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
        if (this.protectionData.length > 0) {
          const cropValue =
            this.cropSelect != null && this.cropSelect.length == 1
              ? CROP_KEY[parseInt(this.cropSelect[0])]
              : null
          this.protectionData.forEach(o => {
            o.crop_id = cropValue
          })
        }
      }
    },
    handleRemoveCP(cpIndex) {
      this.protectionData.splice(cpIndex, 1)
      if (this.protectionData.length == 0) {
        this.stackPractices = false
      }
    },
    blankProtectionData(cropValue = null) {
      return {
        crop_id: cropValue,
        date: null,
        rate: null,
        unit: null,
        method: null,
        product: null,
        type: null,
        ipm: null,
        iwm: null,
        idm: null,
        showAllInfo: true,
        // menu is boolean for v-menu model
        menu: false,
        pickerDate: this.year + "-01",
      }
    },
    handlePreSave() {
      if (this.stackPractices) {
        this.handleSave()
      } else {
        this.showSaveConfirmModal = true
      }
    },
    async handleSave() {
      this.showSaveConfirmModal = false
      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }

      const cpCompleteness = this.protectionData.map(cp =>
        this.isCPComplete(cp)
      )
      const cpMissing = cpCompleteness.map((value, index) =>
        value == null ? index : ""
      )
      const indexOfMissing = cpMissing.filter(String)

      //   which are missing info
      if (indexOfMissing.length > 0) {
        this.incompleteProtections = indexOfMissing
        this.showIncompleteModal = true
      } else {
        // save cp to db
        let dataClone = _.cloneDeep(this.protectionData)
        let protectionPayload = null
        if (this.protectionData.length > 0) {
          protectionPayload = dataClone.map(cp => {
            // remove UI properties
            delete cp["showAllInfo"]
            delete cp["menu"]
            delete cp["pickerDate"]
            cp = mapPayloadKeysToUserInput(cp)

            return cp
          })
        } else {
          const fieldsBeingSaved =
            this.protectionSettingsByFieldFiltered.filter(f =>
              this.fieldsInSelection.includes(Number(f.fieldId))
            )
          const cropsBeingSaved = fieldsBeingSaved
            .map(f => f.crops)
            .reduce((acc, arr) => acc.concat(arr), [])
            .filter((item, index, self) => self.indexOf(item) === index)
          protectionPayload = []
          cropsBeingSaved.forEach(cropId => {
            protectionPayload.push({
              crop_id: { value: cropId, source: "User Input" },
              date: { value: this.year + "-01-01", source: "User Input" },
              rate: { value: 0, source: "User Input" },
              unit: { value: "none", source: "User Input" },
              method: { value: "none", source: "User Input" },
              product: { value: "none", source: "User Input" },
              type: { value: "none", source: "User Input" },
            })
          })
        }

        this.showPracticesView = false
        this.showSaveView = true

        let protectionUpdates = []

        this.protectionSettingsByFieldFiltered.forEach(async field => {
          if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {
            if (!field.noYearData && !field.fieldLocked) {
              protectionUpdates.push(
                this.updateProtection({
                  fieldId: field.fieldId,
                  year: this.year,
                  protectionPayload: this.mergeFieldPayload(field, protectionPayload),
                })
              )
            } else {
              console.log("no year data for ", field)
            }
          }
        })

        await Promise.all(protectionUpdates)
        this.$emit("update-data-complete")

        const fieldNames = this.protectionSettingsByFieldFiltered
          .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
          .map(f => f.fieldName)
        const protWantedKeys = [
          "crop_id",
          "date",
          "method",
          "product",
          "type",
          "rate",
          "unit",
          "ipm",
          "iwm",
          "idm",
        ]
        this.setRecentWizardData({
          wizard: "protection",
          data: this.protectionData,
        })

        this.summaryKeys = protWantedKeys
        this.summaryData = protectionPayload
        this.summaryFields = fieldNames
        this.showSaveView = false
        this.showSummaryView = true
        const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
        this.protectionData = [this.blankProtectionData(cropValue)];
        this.stackPractices = true
      }
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    handleShowRedirect(field) {
      this.redirectField = field
      this.showRedirectModal = true
    },
    handleRedirectConfirm() {
      this.$router.push(`/data-spreadsheet/${this.redirectField.fieldId}`)
    },
    handleCloseRedirect() {
      this.showRedirectModal = false
      this.redirectField = null
    },
    handleNoProtectionClick() {
      this.protectionData = []
      this.stackPractices = false;
    },
    handleYesProtectionClick() {
      if (this.protectionData.length == 0) {
        this.stackPractices = true
      }
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      const newProtectionData = this.blankProtectionData()
      newProtectionData.crop_id = cropValue
      this.protectionData = [newProtectionData]
    },
    isCPComplete(cp) {
      return (
        cp.crop_id &&
        cp.method &&
        cp.product &&
        cp.type
      )
    },
    allProtectionComplete() {
      if (!this.protectionData) return [0]
      const protCompleteness = this.protectionData.map(cp =>
        this.isCPComplete(cp)
      )

      const protMissing = protCompleteness.map((value, index) =>
        (value == null || value == false) ? index : ""
      )
      const indexOfMissing = protMissing.filter(String)
      return indexOfMissing
    },
    protectionComplete(cp) {
      if (!cp) return null
      return (
        cp.method.source == "User Input" &&
        cp.method.value != null &&
        cp.product.source == "User Input" &&
        cp.product.value != null &&
        cp.type.source == "User Input" &&
        cp.type.value != null
      )
    },
    protectionNeedsConfirm(cp) {
      if (!cp) return null
      return (
        cp.method.source != "User Input" &&
        cp.method.value != null &&
        cp.product.source != "User Input" &&
        cp.product.value != null &&
        cp.type.source != "User Input" &&
        cp.type.value != null
      )
    },
    protectionMissingData(cp) {
      if (!cp) return null
      return (
        cp.method.source != "User Input" ||
        cp.method.value == null ||
        cp.product.source != "User Input" ||
        cp.product.value == null ||
        cp.type.source != "User Input" ||
        cp.type.value == null
      )
    },
    handleConfirm(field) {
      this.confirmationField = _.cloneDeep(field)
      this.confirmationApplications = _.cloneDeep(field.protectionInfo)
      this.confirmationApplications.forEach(cp => {
        for (const key in cp) {
          cp[key].source = "User Input"
        }
      })
      this.showConfirmModal = true
    },
    async handleModalConfirm() {
      this.loadState = "loading"
      await this.updateProtection({
        fieldId: this.confirmationField.fieldId,
        year: this.year,
        protectionPayload: this.confirmationApplications,
      })
      this.loadState = "confirmed"
      this.$emit("update-data-complete")
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.handleCloseConfirm()
    },
    handleCloseConfirm() {
      this.showConfirmModal = false
      this.confirmationField = null
      this.confirmationApplications = null
      this.loadState = null
    },

  },
  mounted() {
    this.tableYear = this.year
    this.fieldsInSelection = this.selectedFields
      .map(f => f.id)
      .filter(f => !this.fieldLocks[f])
  },
  watch: {
    cropChoicesFiltered(newChoices) {
      for (const practice of this.protectionData) {
        const existsInNewChoices = newChoices.some(choice => choice === practice.crop_id);
        if (!existsInNewChoices) {
          practice.crop_id = null;
        }
      }
    },
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.protectionSettingsByField)

        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }

        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
  },
}
</script>
<style scoped>
.input-holder {
  width: 320px;
}
.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}
.min-step-height {
  min-height: 400px;
}

.text-container {
  width: 200px;
}

.all-button {
  margin-left: 86px;
}

.three {
  width: 3%;
}
.ten {
  width: 10%;
}

.cursor {
  cursor: pointer;
}
</style>
