import API from "./API"

const getEnrolledFields = () => API.get("/enrollment-fields/")
const getEnrollmentHistoryByField = payload =>
  API.get("/enrollment-history/", {
    params: payload,
  })
const listEnrollments = () => API.get("/enroll/")
const enrollFields = payload => API.post("/enroll/", payload)
const unenrollField = payload => API.put(`/enroll/${payload.id}/`, { payload })
const triggerDaycentSimulation = "/trigger-daycent-simulation/"
const additionalSpreadsheetYear = "/add-carbon-spreadsheet-year/"
const postTriggerDaycentSimulation = payload =>
  API.post(triggerDaycentSimulation, payload)
const postAdditionalSpreadsheetYear = payload =>
  API.post(additionalSpreadsheetYear, payload)
const fieldSelectionPdf = fieldSelectionJson =>
  API.post("/field-selection-pdf-data/", {
    field_selection_data: fieldSelectionJson,
  })
const fetchProgramExport = payload =>
  API.post("/fetch-ea-program-export/", payload)
const getRegenPractice = () => API.get("/regen-practice/")
const postPracticePreference = payload =>
  API.post("/update-practices/", payload)
const postInsetFilter = payload => API.post("/ea-order-search/", payload)

const postInsetOrder = payload => API.post("/ea-orders/", payload)

const updateInsetOrder = payload =>
  API.put(`/ea-orders/${payload.order_id}/`, payload)

const deleteInsetOrder = payload =>
  API.delete(`/ea-orders/${payload.id}`, { payload })

const getInsetOrder = payload =>
  API.get(`/ea-orders/${payload.id}`, { payload })

const getInsetOrders = () => API.get("/ea-orders/")

const deleteRequestedEnrollment = payload =>
  API.delete(`/enroll/${payload.id}`, { payload })

const postProjectReports = payload => API.post("/project-report/", payload)
const postOrderRerun = payload => API.post("/request-plan-cp-rerun/", payload)
const postAllocationData = payload => API.post("/retrieve_allocation/", payload)

const getEnrollmentSamplingSummary = payload =>
  API.get("/request-plan-cp-rerun/", { params: payload })

const getFieldSupplySummary = payload =>
  API.get("/field-supply-summary/", { params: payload })

const enrollFieldsInset = payload => API.post("/field-supply-summary/", payload)
const postInsetEnrollmentConfirmation = payload =>
  API.post("/inset_confirm_enrollment/", payload)
const generateLOI = payload => API.post("/generate-loi/", payload)

export default {
  getEnrolledFields,
  getEnrollmentHistoryByField,
  getRegenPractice,
  enrollFields,
  fetchProgramExport,
  unenrollField,
  postTriggerDaycentSimulation,
  postAdditionalSpreadsheetYear,
  postPracticePreference,
  fieldSelectionPdf,
  listEnrollments,
  deleteRequestedEnrollment,
  postProjectReports,
  postOrderRerun,
  getEnrollmentSamplingSummary,
  getFieldSupplySummary,
  enrollFieldsInset,
  postInsetFilter,
  postInsetOrder,
  postAllocationData,
  updateInsetOrder,
  getInsetOrder,
  getInsetOrders,
  deleteInsetOrder,
  postInsetEnrollmentConfirmation,
  generateLOI,
}
