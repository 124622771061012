<template>
  <b-sidebar id="evidencing-group-metadata-editing-sidebar" width="534px" title="Edit Group Metadata" v-model="show" right shadow backdrop>
    <div class="group-loading-modal" v-if="loading['visible']">
      <div class="loading-wrapper">
        <v-progress-circular v-if="loading['status'] == LOADING_PROCESSING" :size="48" color="#79c61c" indeterminate />
        <v-icon v-else-if="loading['status'] == LOADING_SUCCESS" :size="48" color="#79c61c" dark>mdi-check-bold</v-icon>
        <v-icon v-else-if="loading['status'] == LOADING_FAILURE" :size="48" color="#ff3c7e">mdi-alert-circle-outline</v-icon>

        <div class="error-message" v-if="loading['status'] == LOADING_FAILURE">
          <p class="text-danger">Something went wrong...</p>
          <p>The request could not be processed ({{ loading['errorMsg'] }}).</p>
        </div>
      </div>

      </div>
    <div class="group-loading-modal-overlay" v-if="loading['visible']" />
    
    <div class="sidebar-body">
      <div class="editor-field">
        <p>Group Name</p>
        <v-text-field
          outlined
          label="Name"
          v-model="formInputs['name']"
        />
      </div>

      <div class="editor-field">
        <p>Group Notes</p>
        <v-textarea
          outlined
          label="Notes"
          v-model="formInputs['notes']"
        />
      </div>
    </div>

    <div class="sidebar-footer">
      <v-btn @click="cancel" outlined height="48px">Cancel</v-btn>
      <v-btn :disabled="!isSaveable" @click="saveNewMetadata" outlined height="48px">Save</v-btn>
    </div>
  </b-sidebar>
</template>

<script>
import Vue from 'vue';
import { updateGroupMetadata } from "@/api/EvidencingAPI";
import {
  LOADING_SUCCESS,
  LOADING_FAILURE,
  LOADING_PROCESSING
} from "@/constants";

export default {
  name: "EvidencingGroupMetadatEditingSidebar",
  props: {
    visible: { required: true },
    detailSpec: { required: true }
  },
  emits: ["closeSidebar"],
  data() {
    return {
      show: false,
      cancelled: true,
      formInputs: {},
      modifiedConfiguration: {},
      savedConfiguration: {},
      loading: {
        visible: false,
        status: null,
        errorMsg: ''
      },
      LOADING_PROCESSING,
      LOADING_FAILURE,
      LOADING_SUCCESS
    }
  },
  methods: {
    saveNewMetadata() {
      this.loading['visible'] = true;
      this.loading['status'] = LOADING_PROCESSING;
      
      const newGroupSpecification = { ...this.detailSpec };

      for (const key in this.modifiedConfiguration) {
        newGroupSpecification[key] = this.modifiedConfiguration[key]
      }

      updateGroupMetadata(newGroupSpecification)
      .then(response => {
        if (response.status == 200) {
          // close the sidebar with the new data
          this.savedConfiguration = { ...this.detailSpec };

          for (const key in response.data) {
            this.savedConfiguration[key] = response.data[key]
          }

          this.loading['status'] = LOADING_SUCCESS;

          setTimeout(() => {
            this.cancelled = false;
            this.show = false;
          }, 1000);
        }
      })
      .catch(err => {
        this.loading['status'] = LOADING_FAILURE;
        this.loading['errorMsg'] = err
        setTimeout(() => {
          this.cancelled = true;
          this.show = false;
        }, 4000);
      })
    },
    removeSidebar() {
      this.$emit('closeSidebar', this.cancelled, this.savedConfiguration);
      this.cancelled = true;
      this.formInputs = {};
      this.modifiedConfiguration = {};
      this.loading = {
        visible: false,
        status: null,
        errorMsg: ''
      }
    },
    cancel() {
      this.cancelled = true;
      this.show = false;
    },
  },
  computed: {
    isSaveable() {
      for (const key in this.modifiedConfiguration) {
        if (this.modifiedConfiguration[key] != null) {
          return true
        }
      }

      return false
    }
  },
  watch: {
    visible(curr) {
      this.show = curr;
    },
    show(curr) {
      if (!curr) this.removeSidebar();
    },
    detailSpec: {
      handler(curr) {
        if (curr != null && 'client' in curr && 'id' in curr && 'name' in curr && 'notes' in curr) {
          console.log('wack', curr);
          const newSpec = {
            client: curr['client']['name'],
            id: curr['id'],
            name: curr['name'],
            notes: curr['notes']
          };
          Vue.set(this, 'formInputs', newSpec);
        }
      },
      deep: true
    },
    formInputs: {
      handler(curr) {
        if (curr != null) {
          const newModifiedSpec = {};

          for (const key in curr) {
            if (['client', 'id'].includes(key)) continue;

            if (curr[key] != this.detailSpec[key]) {
              newModifiedSpec[key] = curr[key];
            }
          }

          Vue.set(this, 'modifiedConfiguration', newModifiedSpec);
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="css" scoped>
::v-deep(#evidencing-group-metadata-editing-sidebar) {
  background: #FFFFFF !important;
}

p {
  color: #000000;
}

::v-deep #evidencing-group-metadata-editing-sidebar header {
  padding: 24px 36px;
}

::v-deep #evidencing-group-metadata-editing-sidebar header .close {
  margin-right: 16px !important;
}

::v-deep #evidencing-group-metadata-editing-sidebar header strong {
  margin-right: auto;
  text-transform: capitalize;
}

.sidebar-body {
  padding: 0 36px 36px;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  background: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 24px;
  display: flex;
  align-items: center;
}

.sidebar-footer>button {
  float: left;
  margin-right: 16px;
}

.editor-field p {
  margin: 0 0 2px;
}

.group-loading-modal {
  position: fixed;
  left: calc(50% - 150px);
  top: calc(50% - 150px);
  width: 300px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  z-index: 99999;
}
.group-loading-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 99998;
}
.loading-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px;
}
.loading-wrapper i {
  margin-right: 16px;
}
.loading-wrapper p {
  margin: 0 0 8px 0;
}
.loading-wrapper p:last-of-type {
  margin: 0;
}
</style>