<template>
  <ArvaModal
    name="UploadMappingModal"
    title="Add Column Mappings"
    @close-modal="handleClose"
  >
    <div class="row">
      <div class="col-12 inputs">
        <div v-if="isEC" class="form-group">
          Implement Type
          <v-select-old
            :options="implementTypes"
            label="label"
            :value="mapping.mapping['implement_type']"
            @input="implementChanged"
          />
        </div>

        <div v-if="previouslyMappedUploads.length > 0" class="form-group">
          Optional - Use Mapping From a Previously Imported Soil Sample:
          <v-select-old
            :options="previouslyMappedUploads"
            :select-on-key-codes="[13]"
            label="label"
            @input="val => updatePreviousMapping(val)"
            @keypress.enter.native.prevent="val => updatePreviousMapping(val)"
          />
        </div>

        <div
          class="form-group"
          v-for="(_mapping, name, index) in columnMapper"
          :key="index"
        >
          <div v-if="name !== 'id' && name !== 'implement_type'">
            {{ updateLabel(name) }}:
            <v-select-old
              :options="
                new RegExp('units', 'i').test(name.slice(-5))
                  ? unitList
                  : mappingList
              "
              :select-on-key-codes="[13]"
              label="label"
              :value="_mapping"
              @input="val => updateMappingInput(val, name)"
              @keypress.enter.native.prevent="
                val => updateMappingInput(val, name)
              "
            />
            <div
              v-if="_mapping === 'Other'"
              class="form-group overrides"
              :ref="name"
            >
              <label :for="name"
                >Please specify value for {{ updateLabel(name) }}</label
              >
              <input
                :value="mapping.overrides[name]"
                @input="val => updateOverride(val, name)"
                type="text"
                class="form-control"
                :id="name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!--<div
        class="col-12 d-flex justify-content-end btn-padding"
        v-if="isBulkDensity && columnMapper === null"
      >
        <span class="font-weight-bold pt-2 pr-3"
          >Data columns are missing to support bulk density.</span
        >
        <button class="btn btn-default" @click="handleClose">Cancel</button>
      </div>-->
      <div class="col-8 m-auto" v-if="bdScreenWarning">
        <h5>
          Cannot Submit. Please ensure you have selected columns denoting date,
          depth and all other values necessary to calculate Bulk Density
        </h5>
      </div>

      <div class="col-12 d-flex justify-content-end btn-padding">
        <button class="btn btn-default" @click="handleClose">Cancel</button>
        <button class="btn btn-primary" @click="handleSave">Save</button>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import { mapState } from "vuex"
import UploadMappingAPI from "@/api/UploadMappingAPI"
import ArvaModal from "./ArvaModal"
import { IS_RANCHFORCE } from "@/constants";

export default {
  name: "UploadMappingModal",

  components: { ArvaModal },

  data() {
    return {
      upload: null,
      mapping: {},
      implementTypes: ["GeoProspectors", "Veris", "Dual EM"],
      unitList: [
        { label: "Grams (g)", mappingValue: "g" },
        { label: "Milliliters (mL)", mappingValue: "mL" },
        { label: "Liters (L)", mappingValue: "L" },
        { label: "Inches (in)", mappingValue: "inch" },
        { label: "Feet (ft)", mappingValue: "ft" },
        { label: "Centimeters (cm)", mappingValue: "cm" },
        { label: "Meters (m)", mappingValue: "m" },
      ],
      bdScreenWarning: false,
      isRanchForce: IS_RANCHFORCE,
    }
  },

  computed: {
    ...mapState({
      uploadID: state => state.Uploads.mappingModalUploadID,
      uploads: state => state.Uploads.uploads,
      initialOrgID: state => state.Filter.initializedOrgNodeId,
    }),

    mappingList() {
      if (!this.mapping) return []
      const sample = this.mapping.sample
      const sampleList = []
      sampleList.push({ label: "No Match", mappingValue: "No Match" })
      for (let key in sample) {
        let sampleData = {}
        let whiteSpace = " | "
        let label = key.replace(/field/gi, 'Field (Pasture)').replace(/farm/gi, 'Farm (Ranch)').replace(/name/gi, 'Name (Pasture)')
        sampleData.label = label.concat(whiteSpace, sample[key])
        sampleData.mappingValue = key
        sampleList.push(sampleData)
      }
      return sampleList
    },

    isEC() {
      return this.upload && this.upload.dataset_type === "ec"
    },

    isBulkDensity() {
      return this.upload && this.upload.dataset_type === "bulk-density"
    },

    bdColumnMapperCheck() {
      const mapping = this.mapping.mapping

      if ((mapping["date"] == "No Match") | (mapping["depth"] == "No Match")) {
        return false
      }

      if (mapping["bulk_density"] != "No Match") {
        return true
      }

      if (mapping["dry_weight"] != "No Match") {
        if (mapping["volume"] != "No Match") {
          return true
        }

        if (mapping["diameter"] != "No Match") {
          if (mapping["length"] != "No Match") {
            return true
          }

          if (
            mapping["depth_start"] != "No Match" &&
            mapping["depth_stop"] != "No Match"
          ) {
            return true
          }
        }
      }

      return false
    },
    columnMapper() {
      const order = ['client', 'farm', 'field'];
      const reorderedObject = {};

      order.forEach(key => {
          if (this.mapping.mapping.hasOwnProperty(key)) {
              reorderedObject[key] = this.mapping.mapping[key];
          }
      });

      return reorderedObject
    },

    previouslyMappedUploads() {
      if (!this.upload || this.upload.dataset_type !== "soil-sample") return []
      return this.uploads
        .filter(
          upload =>
            upload.dataset_type === this.upload.dataset_type &&
            upload.id !== this.upload.id &&
            upload.stage === "complete"
        )
        .map(upload => ({ label: upload.name, upload }))
    },
  },

  methods: {
    handleClose() {
      this.$emit("close-modal")
    },

    async handleSave() {
      if (this.upload.dataset_type === "bulk-density") {
        Object.keys(this.mapping.mapping).forEach(key => {
          if (this.mapping.mapping[key] === null) {
            this.mapping.mapping[key] = "No Match"
          }
        })
        if (this.bdColumnMapperCheck == false) {
          this.bdScreenWarning = true
          return
        } else {
          this.bdScreenWarning = false
        }
      } else {
        this.bdScreenWarning = false
      }

      await UploadMappingAPI.put(this.mapping.id, this.mapping).then(() => {
        if (this.upload.dataset_type == "boundary" || this.upload.dataset_type == "boundary-ranch") {
          localStorage.setItem(
            `${this.initialOrgID}-filters`,
            JSON.stringify({})
          )
        }
      })

      this.handleClose()
    },

    updateLabel(value) {
      if (this.isRanchForce) {
        if (value == "farm") {
          return "Ranch";
        } else if (value == "field") {
          return "Pasture";
        }
      }

      return value.charAt(0).toUpperCase() + value.slice(1);
    },

    updatePreviousMapping(value) {
      if (!value) {
        this.mapping = this.upload.mapping
        return
      }

      const { upload } = value
      const { mapping } = upload
      const sampleKeys = Object.keys(this.mapping.sample).concat("No Match")
      for (const key in mapping.mapping) {
        const mappingValue = mapping.mapping[key]
        if (sampleKeys.includes(mappingValue)) {
          this.mapping.mapping[key] = mappingValue
        }
      }
    },

    updateMappingInput(val, name) {
      this.mapping.mapping[name] = val !== null ? val.mappingValue.toString() : ""
    },

    updateOverride(val, name) {
      this.mapping.overrides[name] = val.target.value
    },

    implementChanged(value) {
      if (value === "GeoProspectors") {
        this.mapping.mapping = {
          implement_type: value,
          r1: null,
          r2: null,
          r3: null,
          r4: null,
          rwtc: null,
          d2i: null,
        }
      } else if (value === "Veris") {
        this.mapping.mapping = {
          implement_type: value,
          red: null,
          ir: null,
          altitude: null,
          speed: null,
          temp: null,
          depth: null,
          ec_sh: null,
          ec_dp: null,
          ec_ratio: null,
          slope: null,
          curve: null,
          ec_0_2: null,
        }
      } else if (value === "Dual EM") {
        this.mapping.mapping = {
          implement_type: value,
          date: null,
          time: null,
          logging: null,
          gps: null,
          height_met: null,
          speed_kmh_: null,
          heading: null,
          sensorinput1: null,
          sensorinput2: null,
          sensorinput3: null,
          sensorinput4: null,
        }
      }
    },
  },

  mounted() {
    const upload = this.uploads.find(upload => upload.id === this.uploadID)
    if (upload) {
      this.upload = upload
      this.mapping = upload.mapping
    }
  },
}
</script>

<style scoped>
.inputs {
  padding: 20px 65px;
}

.overrides {
  margin-top: 15px;
}

.btn-padding {
  padding-right: 65px;
}
</style>
