<template>
  <b-sidebar id="evidencing-group-configuration-sidebar" width="534px" :title="getTitle" v-model="show"
    right shadow backdrop>
    <div class="sidebar-body">
      <p class="group-configuration-explainer">
        Select which fields should have {{ getEvidencing }} evidencing requests. 
        If the system did not create evidencing automatically, it will be created for you.
      </p>

      <div class="table-wrapper">
        <v-simple-table>
          <thead>
            <tr>
              <th><v-checkbox @click="handleAllClick" v-model="allAreSelected" /></th>
              <th>Fields</th>
              <th>Field ID</th>
              <th>Due</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="fieldCrop, idx in getFieldCrops" :key="fieldCrop['name'] + '--' + idx">
              <td><v-checkbox v-model="selectedFieldIds" :value="fieldCrop['id']" /></td>
              <td>{{ fieldCrop['name'] }}</td>
              <td>{{ fieldCrop['id'] }}</td>
              <td>{{ getFormattedDueDate(fieldCrop['evidencingDueDate']) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>

    <div class="sidebar-footer">
      <v-btn @click="cancel" outlined height="48px">Cancel</v-btn>
      <v-btn @click="applyEvidencingToFields" outlined height="48px">Save</v-btn>
    </div>
  </b-sidebar>
</template>

<script>
import { EVIDENCING_REMOVAL, EVIDENCING_CREATION } from "@/constants";
import { CONFIRMATION_TYPE_CHOICES } from "@/constants/defaults";

export default {
  name: "EvidencingGroupConfigurationSidebar",
  props: {
    visible: { required: true },
    detailSpec: { required: true }
  },
  emits: ["closeSidebar"],
  data() {
    return {
      cancelled: true,
      show: false,
      allAreSelected: false,
      modifiedFieldIds: [],
      selectedFieldIds: [],
      CONFIRMATION_TYPE_CHOICES
    }
  },
  methods: {
    handleAllClick() {
      if (this.selectedFieldIds.length < this.detailSpec['fieldCrops'].length) {
        this.selectedFieldIds = this.detailSpec['fieldCrops'].map(({ id }) => id);
      }
      else {
        this.selectedFieldIds = [];
      }
    },
    removeSidebar() {
      this.$emit('closeSidebar', this.cancelled, this.detailSpec['cropId'], this.modifiedFieldIds);
      this.modifiedFieldIds = [];
      this.selectedFieldIds = [];
      this.allAreSelected = false;
      this.cancelled = true;
    },
    getFormattedDueDate(date) {
      if (date == null) return "Not Specified"
      return "There is one!"
    },
    cancel() {
      this.cancelled = true;
      this.show = false;
    },
    applyEvidencingToFields() {
      this.cancelled = false;
      this.show = false;
    }
  },
  computed: {
    getTitle() {
      const { selectedEvidencingType, cropName } = this.detailSpec;
      const foundType = CONFIRMATION_TYPE_CHOICES.find(({ value }) => value == selectedEvidencingType);
      if (foundType != null) {
        return `${foundType['name']} (${cropName})`;
      }

      return 'Not Specified';
    },
    getEvidencing() {
      const { selectedEvidencingType } = this.detailSpec;
      const foundType = CONFIRMATION_TYPE_CHOICES.find(({ value }) => value == selectedEvidencingType);

      if (foundType != null) {
        return foundType['name'];
      }

      return "Not Specified";
    },
    getFieldCrops() {
      return this.detailSpec['fieldCrops'];
    }
  },
  watch: {
    visible(curr) {
      this.show = curr;
    },
    show(curr) {
      if (!curr) this.removeSidebar();
    },
    detailSpec: {
      handler(curr) {
        if (curr != null && curr['fieldCrops'] != null && curr['fieldCrops'].length > 0) {
          for (const { id, selected } of curr['fieldCrops']) {
            if (selected) {
              this.selectedFieldIds.push(id)
            }
          }
        }
      },
      deep: true
    },
    selectedFieldIds: {
      handler(curr) {
        if (
          curr != null
          && this.detailSpec != null 
          && this.detailSpec['fieldCrops'] != null
          && this.detailSpec['fieldCrops'].length > 0
        ) {
          this.modifiedFieldIds = [];

          for (const { id, selected, evidencingId } of this.detailSpec['fieldCrops']) {
            // see if this fieldId was originally set to be selected
            if (selected) {
              // if it was selected and it is not currently selected, it is being set for removal
              if (!this.selectedFieldIds.includes(id)) {
                this.modifiedFieldIds.push({ id, evidencingId, action: EVIDENCING_REMOVAL });
              }
            }
            else {
              // if it wasn't selected and now it is, it is being set for creation
              if (this.selectedFieldIds.includes(id)) {
                this.modifiedFieldIds.push({ id, evidencingId, action: EVIDENCING_CREATION });
              }
            }
          }

          if (curr.length == this.detailSpec['fieldCrops'].length) {
            this.allAreSelected = true;
          }
          else {
            this.allAreSelected = false;
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="css" scoped>
::v-deep(#evidencing-group-configuration-sidebar) {
  background: #FFFFFF !important;
}

p {
  color: #000000;
}

::v-deep #evidencing-group-configuration-sidebar header {
  padding: 24px 36px;
}

::v-deep #evidencing-group-configuration-sidebar header .close {
  margin-right: 16px !important;
}

::v-deep #evidencing-group-configuration-sidebar header strong {
  margin-right: auto;
  text-transform: capitalize;
}

.sidebar-body {
  padding: 0 36px 36px;
}
.group-configuration-explainer {
  margin: 0 0 24px 0;
  font-weight: bold;
  color: #000000;
  font-size: 16px;
  line-height: 1.5;
}
.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  background: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 24px;
  display: flex;
  align-items: center;
}
.sidebar-footer > button {
  float: left;
  margin-right: 16px;
}

.table-wrapper {
  padding: 0;
  margin: 0;
  background-color: #F7F8FA;
}
.table-wrapper .v-data-table {
  background-color: #F7F8FA;
  height: 500px;
  overflow-y: scroll;
}
.table-wrapper .v-data-table th {
  position: sticky;
  top: 0;
  background: #F7F8FA;
  z-index: 9;
}
.table-wrapper .v-data-table td {
  border: none !important;
}
.table-wrapper .v-data-table td .v-input,
.table-wrapper .v-data-table td ::v-deep(.v-input__slot) {
  padding: 0;
  margin: 0;
}
.table-wrapper .v-data-table td ::v-deep(.v-messages) {
  display: none;
}

.checkbox-head {
  width: 75px;
}
</style>