<template>
  <b-tr>
    <!-- species -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(livestock.species)"
    >
      <PracticeChoiceDropdown
        clearable
        dense
        hide-details="true"
        :items="speciesChoices"
        item-text="display_name"
        item-value="value"
        v-model="species"
        choiceName="pasture_livestock_species"
        :append-icon="
          livestock.species.source !== 'User Input' &&
          livestock.species.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('species', species)"
      />
    </b-td>
    <!-- animal detail -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(livestock.animal_details)"
    >
      <PracticeChoiceDropdown
        clearable
        dense
        hide-details="true"
        :items="animalDetailChoices"
        item-text="display_name"
        item-value="value"
        v-model="animalDetail"
        choiceName="pasture_livestock_animal_details"
        :append-icon="
          livestock.animal_details.source !== 'User Input' &&
          livestock.animal_details.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('animalDetail', animalDetail)"
      />
    </b-td>
    <!-- average weight -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(livestock.average_weight)"
    >
      <v-text-field
        clearable
        dense
        v-model="averageWeight"
        hide-details="true"
        :append-icon="
          livestock.average_weight.source !== 'User Input' &&
          livestock.average_weight.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('averageWeight', averageWeight)"
      >
      </v-text-field>
    </b-td>
    <!-- unit -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(livestock.weight_unit)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="weightUnitChoices"
        item-text="display_name"
        item-value="value"
        v-model="weightUnit"
        :append-icon="
          livestock.weight_unit.source !== 'User Input' &&
          livestock.weight_unit.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('weightUnit', weightUnit)"
      />
    </b-td>
    <b-td class="cell-table white">
      <v-btn icon x-small color="black" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn icon x-small color="black" @click="handleDelete">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>.
    </b-td>
  </b-tr>
</template>
<script>
import { mapMutations, mapGetters } from "vuex"
import { Defaults } from "@/store/modules"
import { getClassForCell } from "../utils"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"

export default {
  name: "RanchLivestockRow",
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    livestock: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
  },
  components: {
    PracticeChoiceDropdown,
  },
  data() {
    return {
      getClassForCell,
    }
  },
  computed: {
    ...mapGetters({
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    speciesChoices() {
      return this.practiceChoices["pasture_livestock_species"]
    },
    animalDetailChoices() {
      return this.practiceChoices["pasture_livestock_animal_details"]
    },
    weightUnitChoices() {
      return this.practiceChoices["pasture_livestock_weight_units"]
    },
    species: {
      get() {
        return this.livestock.species.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "livestock",
          dataSetting: "species",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    animalDetail: {
      get() {
        return this.livestock.animal_details.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "livestock",
          dataSetting: "animal_details",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    averageWeight: {
      get() {
        return this.livestock.average_weight.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "livestock",
          dataSetting: "average_weight",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    weightUnit: {
      get() {
        return this.livestock.weight_unit.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "livestock",
          dataSetting: "weight_unit",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    // typesByMethod(method, existingType) {
    //   if (["IPM", "IWM", "IDM"].includes(method)) {
    //     return this.typeChoices.filter(
    //       c => c.dependent_value === method || c.value === "other"
    //     )
    //   }
    //   return this.typeChoices.filter(
    //       c => c.dependent_value === method || !c.dependent_value
    //   )
    // },
    // unitsByMethod(method) {
    //   return this.unitChoices.filter(
    //     c => c.dependent_value === method || !c.dependent_value
    //   )
    // },
    // cropProtectionChoices(method, type) {
    //   const choices = cropProtectionChoices(method, type)
    //   return choices
    // },
    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.livestock)
    },
    handleAdd() {
      const payloadObj = {
        crop_id: { source: "User Input", value: this.cropId },
        species: { source: "User Input", value: null },
        animal_details: { source: "User Input", value: null },
        average_weight: { source: "User Input", value: null },
        weight_unit: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "livestock",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.livestock)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "livestock",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleValidateValue(val, type, length = 24) {
      function isNumeric(val) {
        return !isNaN(val) && !isNaN(parseFloat(val));
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      }
    },
    filter(event) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()

      let validVal = this.handleValidateValue(expect, "number", 24)
      if (!validVal) event.preventDefault()
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.cell-table {
  padding: 2px 6px;
}

.pre-gen {
  background-color: #fedb80;
}

.bg-white {
  color: white;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.machine {
  background-color: #c0dce2;
}

.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.medium-column {
  width: 140px;
  max-width: 140px;
}

.large-column {
  width: 240px;
  max-width: 240px;
}

.marge-column {
  width: 180px;
  max-width: 180px;
}

.v-input {
  font-size: 18px;
}
</style>
