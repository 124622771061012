<template>
  <b-td
    :id="'forage-table-' + rowNum"
    class="cell-table forage-col"
    :class="{
      'gray-bg': rowNum % 2 !== 0,
    }"
  >
    <div>
      <b-table-simple class="mb-0" width="100%">
        <b-tbody>
          <RanchForageRow
            v-for="forage in forageData"
            :addition-view="false"
            :cropId="cropId"
            :field="fieldId"
            :forage="forage"
            :key="forage.idx"
            :rowIndex="forageData.indexOf(forage)"
            :year="year"
            @handle-copy="handlCopyForage($event)"
            @bad-input="handleBadInput"
          />
        </b-tbody>
      </b-table-simple>
      <!-- <div
        v-if="!showAddition"
        class="btn btn-primary add-icon icon-column mr-0 pull-right"
        @click="showAddition = true"
      >
        <i class="fa fa-plus" />
      </div> -->
      <div v-if="showAddition" class="border">
        <div class="float-right mr-1 mt-1">
          <i
            class="fa fa-times-circle fa-2x"
            @click="resetForageToAdd"
          />
        </div>
        <div class="addition-container">
          <b-table-simple class="mt-4" width="100%">
            <tbody>
              <RanchForageRow
                :addition-view="true"
                :forage="forageToAdd"
                :year="year"
              />
            </tbody>
          </b-table-simple>
          <div class="d-flex flex-row justify-content-between my-3">
            <div class="add-text">
              {{ showCopy ? "Copy" : "Add" }} to
              <!-- Re-enable for multifield add/copy -->
              <!-- {{ fieldYearToCopy.length }} -->
              additional years:
            </div>
            <div>
              <v-select-old
                multiple
                class="year-select"
                :options="years"
                v-model="yearSelected"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        v-if="showAddition"
        type="button"
        class="btn btn-success btn-lg btn-block"
        @click="handleSubmitForage"
      >
        {{ showCopy ? "Copy" : "Submit" }} Forage
        <i class="fa fa-plus" />
      </button>
    </div>
  </b-td>
</template>
<script>
import { cloneDeep } from "lodash"
import { mapActions, mapGetters } from "vuex"

import RanchForageRow from "@/components/spreadsheet/rows/RanchForageRow"
import { Defaults, Filter } from "@/store/modules"

export default {
  name: "RanchForageTable",
  components: {
    RanchForageRow,
  },
  props: {
    cropId: { type: Number },
    fieldId: { type: String },
    forageData: { type: Array },
    rowNum: { type: Number },
    year: { type: String },
    years: { type: Array },
  },
  data() {
    return {
      showAddition: false,
      showCopy: false,
      forageToAdd: {
        primary_forage: { source: "User Input", value: null },
      },
      fieldYearToCopy: [],
      yearSelected: [],
    }
  },
  computed: {
    ...mapGetters({
      allFields: Filter.Getters.getFields,
      selectedFields: Filter.Getters.getSelectedFields,
    }),
  },
  methods: {
    ...mapActions({
      updateMultipleFieldSettings: Defaults.Actions.updateMultipleFieldSettings,
    }),
    handleAddFieldsInFocus() {
      this.selectedFields.forEach(field => {
        if (
          !this.fieldYearToCopy.includes(this.fieldId) &&
          field.id !== this.fieldId
        ) {
          this.fieldYearToCopy.push(field.id)
        }
      })
    },
    handlCopyForage(cpToCopy) {
      this.showAddition = true
      this.showCopy = true

      this.forageToAdd = cloneDeep(cpToCopy)
    },
    handleSubmitForage() {
      this.fieldYearToCopy.push(this.fieldId)
      this.updateMultipleFieldSettings({
        cropId: this.cropId,
        dataCategory: "forage",
        fieldIds: this.fieldYearToCopy,
        rowData: this.forageToAdd,
        years: this.yearSelected,
      })
      this.resetForageToAdd()
    },
    resetForageToAdd() {
      this.forageToAdd = {
        primary_forage: { source: "User Input", value: null },
      }
      this.showAddition = false
      this.showCopy = false
      this.fieldYearToCopy = []
      this.yearSelected = [this.year]
    },
    handleBadInput(errorText) {
      this.$emit("bad-input", errorText)
    },
  },
  mounted() {
    if (this.year !== null) this.yearSelected.push(this.year)
  },
}
</script>
<style scoped>
.cc-bg {
  background-color: #edf9dc;
  /* background-color: #bbd686; */
}

.cc-bg-dark {
  background-color: #c9ed96;
  /* background-color: #8eb63e; */
}

.gray-bg {
  background-color: #f6f7fb !important;
}

.addition-container {
  padding-left: 40px;
  padding-right: 84px;
}

.add-icon {
  background-color: #7774e7;
  color: white;
  cursor: pointer;
  padding-left: 14px;
}

.add-icon:hover,
.add-icon:focus {
  color: #ffffff;
  background-color: #8c8aeb;
}

.add-text {
  font-weight: 500;
  font-size: 14px;
}

.cell-table {
  padding: 0;
}

.complete {
  background-color: #00a200;
}

.arva-generated {
  background-color: #fedb80;
}

.delete {
  color: #cc0101;
}

/* v-select-old widths */
.field-select {
  width: 160px;
}

.year-select {
  background-color: white !important;
  width: 110px;
  font-size: 13px;
}

/* table-width */
.forage-col {
  width: 580px;
}

/* column sizing */
.date-column {
  min-width: 172px;
  max-width: 172px;
}

.type-column {
  min-width: 220px;
  max-width: 220px;
}

.amount-column {
  min-width: 160px;
  max-width: 160px;
}

.icon-column {
  min-width: 40px;
  max-width: 40px;
}

.icon-copy-column {
  min-width: 44px;
  max-width: 44px;
}
</style>
