<template>
  <v-card>
    <div v-if="chartSeries != null && this.crops.length > 0">
      <h4 class="pa-1">
        Program Allocations and Total Allotment for {{ year }}
      </h4>
      <apexchart
        :type="chartType"
        height="175"
        :options="chartOptions"
        :series="chartSeries"
      />
    </div>
    <ReviewAllocationByProgram
      v-if="showModal"
      :items="items"
      :title="`Program Allocations for ${selectedCrop} in ${year} `"
      @close-modal="showModal = false"
    />
    <v-snackbar v-model="snackbar" timeout="4000" color="red lighten-1">
      No programs currently matched with this commodity
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import _ from "lodash"
import ReviewAllocationByProgram from "@/components/insets/modals/ReviewAllocationByProgramModal"
import { mapState } from "vuex"

export default {
  name: "AllocationVisualizer",
  components: { ReviewAllocationByProgram },
  data() {
    return {
      chartOptions: null,
      chartType: "bar",
      chartSeries: null,
      allotment: [],
      allocation: [],
      crops: [],
      crop_ids: [],
      showModal: false,
      selectedCrop: null,
      snackbar: false,
    }
  },
  methods: {
    navigateChartClick(event) {
      let crop_id = this.crop_ids[event.dataPointIndex]
      this.selectedCrop = this.crops[event.dataPointIndex]
      CarbonReadyAPI.postAllocationData({
        year: this.year,
        crop_id: crop_id,
      })
        .then(response => {
          if (response.status == 200) {
            this.items = response.data
            this.showModal = true
          }
        })
        .catch(() => {
          this.snackbar = true
        })
    },
    buildChart() {
      this.crops = []
      this.crop_ids = []
      this.allotment = []
      this.allocation = []
      CarbonReadyAPI.postAllocationData({ year: this.year }).then(response => {
        response.data.forEach(x => {
          this.allotment.push(x.total_allotment)
          this.allocation.push(x.allocated)
          this.crops.push(x.crop_name)
          this.crop_ids.push(x.crop)
        })

        this.chartSeries = [
          {
            name: "Allotment",
            data: this.allotment,
          },
          {
            name: "Allocation",
            data: this.allocation,
          },
        ]
        let navigateChartClick = this.navigateChartClick

        this.chartOptions = {
          chart: {
            type: "bar",
            height: 175,
            events: {
              dataPointSelection: function (event, chartContext, config) {
                navigateChartClick(config)
              },
            },
          },

          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: this.crops,
          },
        }
      })
    },
  },
  computed: {
    ...mapState({
      year: state => state.Organization.year,
    }),
  },

  watch: {
    year() {
      this.buildChart()
    },
  },

  mounted() {
    this.buildChart()
  },
}
</script>
