<template>
  <div>
    <Permissions package="sustainability" />
    <EvidencingActionSidebar
      :action="tableActionSelection"
      :confirmations="
        sortedFilteredConfirmations.filter(({ id }) =>
          tableData['selectedRows'].includes(id)
        )
      "
      :visible="showTableActionSidebar"
      @closeSidebar="handleClosedSidebar"
    />

    <div v-if="showSustainability" class="map-wrapper">
      <div
        :class="`evidencing-btns ${
          showConfirmationTableView ? '' : 'map-view-active'
        }`"
      >

        <v-btn
          v-if="isSuperUser && !viewingAll"
          outlined
          height="32"
          :href="`/evidencing/validate-group/${$route.params['groupId']}`"
        >
          <img :src="validateIcon" />
        </v-btn>

        <v-btn
          v-if="isSuperUser && !viewingAll"
          outlined
          height="32"
          :href="`/evidencing/edit-group/${$route.params['groupId']}`"
        >
          <img :src="editIcon" />
        </v-btn>
      
        <v-btn
          outlined
          height="32"
          href="/uploads?openUpload=practice-confirmations&initialState=select-data-type"
        >
          <img :src="uploadIcon" />
        </v-btn>

        <v-btn
          @click="showConfirmationTableView = false"
          outlined
          height="32"
          :class="showConfirmationTableView ? '' : 'activated'"
        >
          <img :src="mapIcon" />
        </v-btn>

        <v-btn
          @click="showConfirmationTableView = true"
          outlined
          height="32"
          :class="showConfirmationTableView ? 'activated' : ''"
        >
          <img :src="tableIcon" />
        </v-btn>
      </div>

      <div
        id="evidencing-map"
        :class="showConfirmationTableView ? '' : 'display-map'"
      />

      <div v-if="!showConfirmationTableView" class="evidencing-table-filters map-evidencing-filters">
          <div class="evidencing-table-filter-selections activated">
            <!-- <div class="table-filter-selector">
              <p>Assigned</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['assignees']"
                :items="totalAssignees"
                item-text="name"
                item-value="id"
                label="Users"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">
                    {{ tableData["filters"]["assignees"].length }}
                  </p>
                </template>
              </v-select>
            </div> -->

            <div class="table-filter-selector">
              <p>Crop</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['crop']"
                :items="totalConfirmationCrops"
                label="Crop"
                clearable
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">
                    {{ tableData["filters"]["crop"] ? "1" : "" }}
                  </p>
                </template>
              </v-select>
            </div>

            <div class="table-filter-selector">
              <p>Uploads</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['uploads']"
                :items="uploadItems"
                label="Uploads"
                clearable
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">
                    {{ tableData["filters"]["uploads"] ? "1" : "" }}
                  </p>
                </template>
              </v-select>
            </div>

            <div class="table-filter-selector">
              <p>Type</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['confirmationTypes']"
                :items="totalConfirmationTypes"
                label="Confirmation Type"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">
                    {{ tableData["filters"]["confirmationTypes"].length }}
                  </p>
                </template>
              </v-select>
            </div>

            <div class="table-filter-selector">
              <p>Status</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                :items="[]"
                label="Status"
                clearable
                multiple
                dense
                solo
                disabled
              />
            </div>

            <div class="table-filter-selector">
              <p>Due</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                :items="[]"
                label="Due"
                clearable
                multiple
                dense
                solo
                disabled
              />
            </div>

            <div class="table-filter-selector">
              <p>Detail</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                :items="[CLIENT_SPECIFICITY, FARM_SPECIFICITY, FIELD_SPECIFICITY]"
                v-model="tableData['filters']['detail']"
                label="Detail"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">{{ tableData['filters']['detail'].length }}</p>
                </template>
              </v-select>
            </div>

            <div class="table-filter-selector">
              <p v-if="!isRanchForce">Fields</p>
              <p v-if="isRanchForce">Pastures</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['fields']"
                :items="totalConfirmationFields"
                label="Fields"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">
                    {{ tableData["filters"]["fields"].length }}
                  </p>
                </template>
              </v-select>
            </div>

            <div class="fields-search">
              <v-icon>mdi-magnify</v-icon>
              <input
                type="text"
                placeholder="Search by field..."
                v-model="tableData['fieldSearch']"
              />
            </div>
          </div>
        </div>

      <div class="evidencing-table-view">
        <div
          class="evidencing-table-view-overlay"
          @click="showConfirmationTableView = false"
        />

        <div class="evidencing-table-view-header-wrapper">
          <h2>View Evidencing Group</h2>
          <h3>{{ $route.params['groupId'] == 'all' ? 'All Evidencing' : getGroupName }}</h3>
        </div>

        <div class="evidencing-table-filters">
          <div class="evidencing-table-filter-selections activated">
            <!-- <div class="table-filter-selector">
              <p>Assigned</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['assignees']"
                :items="totalAssignees"
                item-text="name"
                item-value="id"
                label="Users"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">
                    {{ tableData["filters"]["assignees"].length }}
                  </p>
                </template>
              </v-select>
            </div> -->

            <div class="table-filter-selector">
              <p>Crop</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['crop']"
                :items="totalConfirmationCrops"
                label="Crop"
                clearable
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">
                    {{ tableData["filters"]["crop"] ? "1" : "" }}
                  </p>
                </template>
              </v-select>
            </div>

            <div class="table-filter-selector">
              <p>Uploads</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['uploads']"
                :items="uploadItems"
                label="Uploads"
                clearable
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">
                    {{ tableData["filters"]["uploads"] ? "1" : "" }}
                  </p>
                </template>
              </v-select>
            </div>

            <div class="table-filter-selector">
              <p>Type</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['confirmationTypes']"
                :items="totalConfirmationTypes"
                label="Confirmation Type"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">
                    {{ tableData["filters"]["confirmationTypes"].length }}
                  </p>
                </template>
              </v-select>
            </div>

            <div class="table-filter-selector">
              <p>Status</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                :items="[]"
                label="Status"
                clearable
                multiple
                dense
                solo
                disabled
              />
            </div>

            <div class="table-filter-selector">
              <p>Due</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                :items="[]"
                label="Due"
                clearable
                multiple
                dense
                solo
                disabled
              />
            </div>

            <div class="table-filter-selector">
              <p>Detail</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                :items="[CLIENT_SPECIFICITY, FARM_SPECIFICITY, FIELD_SPECIFICITY]"
                v-model="tableData['filters']['detail']"
                label="Detail"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">{{ tableData['filters']['detail'].length }}</p>
                </template>
              </v-select>
            </div>

            <div class="table-filter-selector">
              <p v-if="!isRanchForce">Fields</p>
              <p v-if="isRanchForce">Pastures</p>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="tableData['filters']['fields']"
                :items="totalConfirmationFields"
                label="Fields"
                clearable
                multiple
                dense
                solo
              >
                <template v-slot:selection="{ item, index }">
                  <p v-if="index == 0">
                    {{ tableData["filters"]["fields"].length }}
                  </p>
                </template>
              </v-select>
            </div>

            <div class="fields-search">
              <v-icon>mdi-magnify</v-icon>
              <input
                type="text"
                placeholder="Search by field..."
                v-model="tableData['fieldSearch']"
              />
            </div>
          </div>
        </div>

        <div class="evidencing-table-wrapper" v-if="showConfirmationTableView">
          <div
            v-if="tableData['selectedRows'].length > 0"
            class="evidencing-action-bar"
          >
            <h2>
              {{ tableData["selectedRows"].length }} Request{{
                tableData["selectedRows"].length == 1 ? "" : "s"
              }}
            </h2>

            <v-btn
              @click="handleActionSelect(UPLOAD_DATA_ACTION)"
              color="#FFFFFF"
              outlined
              height="44px"
            >
              Upload Evidencing
            </v-btn>

            <v-btn
              @click="
                isAssignmentActionDisabled
                  ? null
                  : handleActionSelect(ASSIGN_USERS_ACTION)
              "
              :class="isAssignmentActionDisabled ? 'assign-btn-disabled' : ''"
              color="#FFFFFF"
              outlined
              height="44px"
              @mouseenter="assignHovered = true"
              @mouseleave="assignHovered = false"
            >
              Assign
            </v-btn>

            <v-btn
              @click="exportEvidencingTable"
              color="#FFFFFF"
              outlined
              height="44px"
            >
              Export
            </v-btn>

            <div
              class="assign-btn-disabled-tooltip"
              :class="assignHovered ? 'visible' : ''"
            >
              Untracked evidencing requests cannot be assigned to users.
            </div>
          </div>

          <div class="loading-wrapper" v-if="loading">
            <v-progress-circular indeterminate :size="48" color="#79c61c" />
          </div>

          <div
            class="evidencing-group"
            v-else-if="sortedFilteredConfirmations.length > 0"
          >
            <h2 v-if="tableData['selectedRows'].length == 0">
              {{ sortedFilteredConfirmations.length }} Requests
            </h2>

            <v-simple-table class="evidencing-dashboard-table">
              <thead>
                <tr>
                  <th class="checkbox-head">
                    <v-checkbox
                      @click="allRowSelectClick"
                      v-model="tableData['allRowSelection']"
                    />
                  </th>
                  <th
                    v-for="(head, idx) in tableData['headers']"
                    :key="head + idx"
                    :class="`text-left ${idx == 0 ? 'assigned-head' : ''}`"
                    :data-header="head.toLowerCase()"
                  >
                    <a @click="sortTable(head.toLowerCase())">
                      {{ head }}

                      <div class="evidencing-table-sorters">
                        <font-awesome-icon
                          :icon="['fas', 'caret-up']"
                          :class="getSortingState(head.toLowerCase(), 0)"
                        />
                        <font-awesome-icon
                          :icon="['fas', 'caret-down']"
                          :class="getSortingState(head.toLowerCase(), 1)"
                        />
                      </div>
                    </a>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="c in sortedFilteredConfirmations"
                  :key="c['id'] + c['confirmation_number']"
                  :class="getHighlightedClassState(c)"
                >
                  <td class="has-checkbox">
                    <v-checkbox
                      :value="c['id']"
                      v-model="tableData['selectedRows']"
                    />
                  </td>
                  <td>{{ c["id"] }}</td>
                  <td>
                    {{ truncateByChars(c["confirmation_type"], 21) || "None" }}
                  </td>
                  <td>{{ truncateByChars(c["client"]["name"], 6) }}</td>
                  <td>{{ truncateByChars(getConfirmationFarm(c), 12) }}</td>
                  <td
                    :class="
                      c['fields'] && c['fields'].length > 2
                        ? 'field-has-tooltip'
                        : ''
                    "
                  >
                    {{ truncateByChars(getConfirmationField(c), 14) }}
                  </td>
                  <td>{{ getFormattedCrop(c["crops"]) }}</td>
                  <td>{{ c['deadline'] ? new Date(c["deadline"]).toLocaleDateString() : 'None' }}</td>
                  <td>
                    <a
                      @click="handleIndividualConfirmationUploadClick(c['id'])"
                      :class="
                        c['uploads'] && c['uploads'].length > 0
                          ? 'confirmation-added'
                          : 'needs-confirmation'
                      "
                      class="confirmation-upload-status"
                      >{{
                        c["uploads"] && c["uploads"].length > 0
                          ? "Added"
                          : "Add Confirmation"
                      }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div class="no-data-found" v-else>
            <p>No evidencing data to display. If you have filters enabled, try clearing them.</p>
            <p>You can also try changing the year or navbar selections (Client, Farms, Fields, etc.)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import { MAPBOX_TOKEN } from "@/constants/map";
import { Filter } from "@/store/modules";
import { multiPolygon, point } from "@turf/helpers";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { mapGetters, mapMutations, mapState } from "vuex";
import { fetchGroupData } from "@/api/EvidencingAPI";
import { getAllPracticeConfirmationActions, getAllPasturePracticeConfirmationActions } from "@/api/NotificationsAPI";
import { CONFIRMATION_TYPE_CHOICES } from "@/constants/defaults";
import {
  UPLOAD_DATA_ACTION,
  ASSIGN_USERS_ACTION,
  UNTRACKED_EVIDENCING,
  CLIENT_SPECIFICITY,
  FARM_SPECIFICITY,
  FIELD_SPECIFICITY,
  IS_RANCHFORCE,
} from "@/constants";
import { DROPDOWN } from "@/constants";
import editIcon from "@/assets/images/edit-icon.svg";
import validateIcon from "@/assets/images/validate-icon.svg";
import fileIcon from "@/assets/images/file-icon.svg";
import mapIcon from "@/assets/images/map-icon.svg";
import tableIcon from "@/assets/images/table-icon.svg";
import uploadIcon from "@/assets/images/upload-icon.svg";
import Permissions from "@/components/permissions/Permissions";
import EvidencingActionSidebar from "@/components/evidencing/EvidencingActionSidebar";
import Vue from "vue";

export default {
  name: "EvidencingGroupView",
  components: {
    Permissions,
    EvidencingActionSidebar,
  },
  data() {
    return {
      assignHovered: false,
      loading: true,
      showConfirmationTableView: true,
      showTableActionSidebar: false,
      tableActionSelection: "",
      confirmationGroup: "all",
      confirmationData: [],
      fieldMarkerMap: {},
      groupMetaData: {},
      markerList: [],
      highlightedField: null,
      map: null,
      zoomControlEl: null,
      tableData: {
        page: 0,
        editingFilters: false,
        fieldSearch: "",
        filters: {
          crop: null,
          detail: [],
          uploads: null,
          assignees: [],
          confirmationTypes: [],
          fields: []
        },
        selectedRows: [],
        allRowSelection: false,
        sort: {
          key: null,
          state: 0,
        },
        headers: [
          // "Assignees",
          "Id",
          "Type",
          "Client",
          "Farm",
          "Field",
          "Crop",
          "Due",
          "Status",
        ],
      },
      uploadItems: [
        {
          text: "Has Uploads",
          value: 1,
        },
        {
          text: "No Uploads",
          value: 2,
        },
      ],
      mapIcon,
      fileIcon,
      tableIcon,
      uploadIcon,
      editIcon,
      validateIcon,
      UPLOAD_DATA_ACTION,
      ASSIGN_USERS_ACTION,
      CLIENT_SPECIFICITY,
      FARM_SPECIFICITY,
      FIELD_SPECIFICITY,
      isRanchForce: IS_RANCHFORCE,
    }
  },
  methods: {
    ...mapMutations({
      toggleField: Filter.Mutations.toggleItem,
    }),
    getAssignees(assignees) {
      if (assignees == UNTRACKED_EVIDENCING) return "Untracked"
      if (assignees.length == 0) return "Unassigned"
      if (assignees.length == 1)
        return `${assignees[0]["first_name"]} ${assignees[0]["last_name"]}`
      return `${assignees.length} Users`
    },
    truncateByChars(text, charLimit) {
      if (text != null) {
        if (text.length <= charLimit) return text
        return `${text.slice(0, charLimit)}...`
      }
    },
    handleClosedSidebar() {
      this.showTableActionSidebar = false
      this.fetchPracticeConfirmations()
    },
    handleIndividualConfirmationUploadClick(confirmationId) {
      this.tableData["selectedRows"] = [confirmationId]
      this.tableActionSelection = "Upload Data"
      this.showTableActionSidebar = true
    },
    handleActionSelect(action) {
      this.tableActionSelection = action
      this.showTableActionSidebar = true
    },
    allRowSelectClick() {
      if (!this.tableData["allRowSelection"]) {
        this.tableData["selectedRows"] = []
      } else {
        this.tableData["selectedRows"] = this.sortedFilteredConfirmations.map(
          ({ id }) => id
        )
      }
    },
    getAssignedUsersCol(usersArr) {
      if (usersArr.length == 0) return "Unassigned"
    },
    getSortingState(header, state) {
      return this.tableData["sort"]["key"] == header &&
        this.tableData["sort"]["state"] == state
        ? "activated-sort"
        : ""
    },
    sortTable(header) {
      if (this.tableData["sort"]["key"] != null) {
        if (this.tableData["sort"]["key"] == header) {
          if (this.tableData["sort"]["state"] == 0) {
            this.tableData["sort"]["state"] += 1
          } else {
            this.tableData["sort"]["key"] = null
            this.tableData["sort"]["state"] = 0
          }
        } else {
          this.tableData["sort"]["key"] = header
          this.tableData["sort"]["state"] = 0
        }
      } else {
        this.tableData["sort"]["key"] = header
        this.tableData["sort"]["state"] = 0
      }
    },
    getConfirmationField(confirmation) {
      if (confirmation["fields"].length > 2) {
        return `${confirmation["fields"]
          .slice(0, 2)
          .map(({ name }) => name)
          .join(", ")} + ${confirmation["fields"].length} more`
      } else if (confirmation["fields"].length > 0) {
        return confirmation["fields"].map(({ name }) => name).join(", ")
      }

      return "Not Specified"
    },
    getConfirmationFarm(confirmation) {
      if (
        confirmation["farms"] != undefined &&
        confirmation["farms"].length > 0
      )
        return confirmation["farms"][0]["name"]

      return "Not Specified"
    },
    createMap() {
      if (this.map) return

      if (this.fieldBoundaries.length == 0 && this.map) {
        // if we don't have any boundaries, remove map layers
        this.map.eachLayer(
          layer => layer.options.boundary && this.map.removeLayer(layer)
        )
        return
      }

      // create our base map layer
      const osmLayer = L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
        {
          attribution:
            '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          minZoom: 0,
          maxZoom: 16,
        }
      )

      // create our map
      this.map = L.map(document.querySelector("#evidencing-map"), {
        layers: [osmLayer],
        zoomControl: false,
        attributionControl: false,
      }).on("click", this.handleMapClick)

      // move zoom controls to bottom left, fit our map to our field bounds and zoom in
      this.zoomControl = L.control.zoom({ position: "bottomleft" })
      this.zoomControl.addTo(this.map)
      this.fitMapToBounds()

      // our map was just created, update it with appropriate markers + field bounds
      this.updateMap()
    },
    updateMap() {
      if (!this.map) return

      this.calculateFieldMarkers()

      // clear our existing markers so we can repopulate the map with new ones
      this.markerList.forEach(marker => this.map.removeLayer(marker))
      this.markerList = []

      // clear our existing bounds layers
      this.map.eachLayer(layer => {
        if (layer.myTag && layer.myTag === "boundsGeoJSON")
          this.map.removeLayer(layer)
      })

      // create our layers from our field geojson
      if (this.fieldBoundaries) {
        // add our bounds layers
        this.fieldBoundaries.forEach(boundary => {
          const {
            centroid: { coordinates },
            properties: { field },
          } = boundary
          const boundsAreActive = this.activeFields.find(
            ({ id }) => field["id"] == id
          )

          const layer = new L.GeoJSON(boundary, {
            style: {
              color: "#FFCC00",
              weight: 1,
              opacity: 0.85,
              fill: boundsAreActive,
            },
            onEachFeature: (_feature, layer) => (layer.myTag = "boundsGeoJSON"),
          })

          this.map.addLayer(layer)

          // add our markers
          const fieldId =
            field["id"] && field["id"] in this.fieldMarkerMap
              ? field["id"]
              : null
          if (!fieldId || !boundsAreActive) return

          const completeCount = this.fieldMarkerMap[field["id"]]["completed"]
          const incompleteCount = this.fieldMarkerMap[field["id"]]["incomplete"]
          const markerHtml = `
            <span>${incompleteCount ? "+" : completeCount}</span>${this.iconSvg}
          `

          const newMark = L.marker([coordinates[1], coordinates[0]], {
            icon: L.divIcon({
              html: markerHtml,
              iconSize: [27, 42],
              className: `evidencing-div-icon ${
                completeCount ? "complete-icon" : ""
              } ${incompleteCount ? "incomplete-icon" : ""}`,
              popupAnchor: [184, 128],
            }),
          })

          newMark
            .bindPopup(this.buildPopupContent({ field }))
            .on("click", () => this.highlightTable({ fieldId }))
            .on("mouseover", function () {
              this.openPopup()
            }) // 'this' is the marker instance
            .on("mouseout", function () {
              this.closePopup()
            })
            .addTo(this.map)

          this.markerList.push(newMark)
        })
      }
    },
    buildPopupContent({ field }) {
      let confirmationsTemplate = ``

      const complete = { detail: "Completed Confirmation", confirmations: [] }
      const incomplete = {
        detail: "Incomplete Confirmation",
        confirmations: [],
      }

      this.fieldMarkerMap[field["id"]]["confirmations"].forEach(
        confirmation => {
          if (confirmation["hasUploads"])
            complete["confirmations"].push(confirmation)
          else incomplete["confirmations"].push(confirmation)
        }
      )
      ;[complete, incomplete].forEach(({ detail, confirmations }) => {
        if (confirmations.length == 0) return

        // we're making a complete marker popup
        confirmationsTemplate += `
        <b>${confirmations.length} ${detail}${
          confirmations.length == 1 ? "" : "s"
        }</b><br>
        `

        const confirmationsMap = {}
        confirmations.forEach(({ confirmation_type }) => {
          if (confirmation_type in confirmationsMap) {
            confirmationsMap[confirmation_type] += 1
          } else {
            confirmationsMap[confirmation_type] = 1
          }
        })

        confirmationsTemplate += "<ul>"
        for (const confType in confirmationsMap) {
          if (confirmationsMap[confType] > 1) {
            confirmationsTemplate += `<li>${confType} (${confirmationsMap[confType]})</li>`
          } else {
            confirmationsTemplate += `<li>${confType}</li>`
          }
        }
        confirmationsTemplate += "</ul>"
      })

      return `
        <b>Field ${field["name"]}</b>
        <br><ul>
          <li>${field["farm"]["name"]}
          <li>${field["acreage"]} AC
        </ul

        <br>${confirmationsTemplate}
      `
    },
    calculateFieldMarkers() {
      // reset our field marker map and recalculate what fields should have markers
      this.fieldMarkerMap = {}

      this.confirmationsTableFiltered.forEach(confirmation => {
        const fieldsByFarm = []
        const fieldsByClient = []

        if (confirmation["fields"].length == 0) {
          if (confirmation["farm"] != undefined) {
            // we don't have the field ids, get our farm id and crops and infer which field ids should be represented with markers
            // filter the active fields by farm id, then those fields' crops by confirmation-specific year and crop id
            if (this.activeFields) {
              this.activeFields.forEach(({ farm, id, field_crops }) => {
                if (farm["id"] == confirmation["farm"]["id"]) {
                  const relevantCropsByYear = field_crops.filter(
                    ({ year }) => year == confirmation["year"]
                  )
                  if (
                    confirmation["crops"].every(crop =>
                      relevantCropsByYear.find(
                        ({ crop_id }) => crop["id"] == crop_id
                      )
                    )
                  ) {
                    // we found all of the specified crops in this fields field_crop list
                    fieldsByFarm.push({ id })
                  }
                }
              })
            }
          } else if (confirmation["client"] != undefined) {
            // no farm is specified, no fields, but we have a client, so this is a client-wide confirmation
            // set markers to all fields in all farms related to the client
            if (this.activeFarms && this.activeFields) {
              const relevantFarms = this.activeFarms
              .filter(f => f["clientId"] == confirmation["client"]["id"])
              .map(f => f["id"])
              const relevantFields = this.activeFields.filter(f =>
              relevantFarms.includes(f["farm"]["id"])
            )
              fieldsByClient.push(...relevantFields)
            }
          }
        }

        // if we had fields tied to the confirmations, fieldsByFarm will be null
        // thus this array will only be the field-specific selections or the entirety of a farm's fields
        const fieldsNeedingMarkers = [
          ...confirmation["fields"],
          ...fieldsByFarm,
          ...fieldsByClient,
        ]

        for (const { id } of fieldsNeedingMarkers) {
          if (id in this.fieldMarkerMap) {
            this.fieldMarkerMap[id]["completed"] += confirmation["hasUploads"]
              ? 1
              : 0
            this.fieldMarkerMap[id]["incomplete"] += confirmation["hasUploads"]
              ? 0
              : 1
            this.fieldMarkerMap[id]["confirmations"].push(confirmation)
          } else {
            this.fieldMarkerMap[id] = {
              completed: confirmation["hasUploads"] ? 1 : 0,
              incomplete: confirmation["hasUploads"] ? 0 : 1,
              confirmations: [confirmation],
            }
          }
        }
      })
    },
    findFieldFromLatLng(latlng) {
      const { lat, lng } = latlng
      const pt = point([lng, lat])
      return this.fieldBoundaries.find(field => {
        const fieldMPoly = multiPolygon(field.geometry.coordinates)
        return booleanPointInPolygon(pt, fieldMPoly)
      })
    },
    handleMapClick(e) {
      const { latlng } = e
      const clickedField = this.findFieldFromLatLng(latlng)
      if (clickedField) {
        const { field } = clickedField.properties
        this.toggleField({
          id: field.id,
          dropdownType: DROPDOWN.Field,
          preventAutozoom: true,
        })
        this.lastMapAction = "click"
      }
    },
    fitMapToBounds() {
      if (this.map && this.fieldBoundaries.length) {
        this.map.fitBounds(L.geoJSON(this.fieldBoundaries).getBounds())
      }
    },
    highlightTable({ fieldId = null }) {
      this.showConfirmationTableView = true
      this.highlightedField = fieldId
    },
    getFormattedCrop(crops) {
      if (crops != undefined && crops.length > 0) {
        let cropStr = ""

        for (let i = 0; i < crops.length; i++) {
          const { name } = crops[i]
          cropStr += `${name[0].toUpperCase()}${name.slice(1)}`
          if (i != crops.length - 1) cropStr += "\n"
        }

        return cropStr
      }

      return "Not Specified"
    },
    getFormattedFields(fields) {
      let fieldStr = ""

      for (let i = 0; i < fields.length; i++) {
        const { name } = fields[i]
        fieldStr += `${name}`
        if (i != fields.length - 1) fieldStr += "\n"
      }

      return fieldStr
    },
    getFormattedCreatedDate(dateStr) {
      return dateStr
        ? new Date(dateStr).toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        : "None"
    },
    getHighlightedClassState(confirmation) {
      if (confirmation["fields"] != null && confirmation["fields"].length > 0) {
        return this.highlightedField &&
          confirmation["fields"].find(({ id }) => id == this.highlightedField)
          ? "highlighted"
          : ""
      }

      return ""
    },
    handleUploadRoute() {
      this.$router.push(`/practice-confirmations-uploader`)
    },
    formatConfirmationAPIData(confirmation) {
      const formattedType = CONFIRMATION_TYPE_CHOICES.find(
        ct => ct["value"] == confirmation["confirmation_type"]
      )

      confirmation["confirmation_type"] = formattedType != null
        ? formattedType["name"]
        : "None"

      confirmation["hasUploads"] = confirmation["uploads"].length > 0
      return confirmation
    },
    async fetchPracticeConfirmations() {
      try {
        const confirmations = []

        if (this.$route.params['groupId'] != null) {
          const groupId = this.$route.params['groupId'];

          if (groupId == 'all') {
            // view all
            if (this.organization && this.organization.id) {

              const getAllPracticeConfirmationActionsAPI = isRanchForce ? getAllPasturePracticeConfirmationActions : getAllPracticeConfirmationActions;
              const { data: { tracked_evidencing, untracked_evidencing } } = await getAllPracticeConfirmationActionsAPI({
                org_node_id: this.organization.id,
              })

              for (const notification of tracked_evidencing) {
                for (const pcAction of notification["model_related"]) {
                  const confirmation = this.formatConfirmationAPIData(
                    pcAction["practice_confirmation"]
                  )
                  confirmation["assignees"] = pcAction["assigned_to"]
                  confirmations.push(confirmation)
                }
              }
            }
          }
          else {
            // view individual grouping
            const { data } = await fetchGroupData({ groupId, year: this.year });

            Vue.set(this, 'groupMetaData', {
              id: data['id'],
              name: data['name'],
              notes: data['notes']
            })

            for (const evidencing of data['confirmations']) {
              const confirmation = this.formatConfirmationAPIData(evidencing)
              confirmation["assignees"] = UNTRACKED_EVIDENCING
              confirmations.push(confirmation)
            }
          }
        }

        // run through all evidencing and assign specificity levels
        for (const evidencing of confirmations) {
          const hasClient = evidencing['client'] && evidencing['client']['id'] != null
          const hasFarm = evidencing['farms'].length > 0;
          const hasField = evidencing['fields'].length > 0;

          // very much a stop gap. field specificity is not defined as client->farm->field,
          // it's just if a field exists on this evidencing or not. similar to the other properties
          evidencing['specificity'] = null
          if (hasField) evidencing['specificity'] = FIELD_SPECIFICITY;
          else if (hasFarm) evidencing['specificity'] = FARM_SPECIFICITY;
          else if (hasClient) evidencing['specificity'] = CLIENT_SPECIFICITY;
        }

        this.confirmationData = confirmations;
        this.loading = false;
      } catch (e) {
        console.log("ope", e)
        this.loading = false
      }
    },
    exportEvidencingTable() {
      // pretty hard coded for now, near future we'll have a sidebar for this too
      const csvHeader = [
        "Assignees",
        "ID",
        "Type",
        "Organization",
        "Client",
        "Farm(s)",
        "Field(s)",
        "Crop",
        "Due",
        "Status",
        "Evidencing Source",
        "Source Details",
        "Instructions",
        "Notes",
      ]
      const csvRows = []

      const selectedConfirmations = this.sortedFilteredConfirmations.filter(
        ({ id }) => this.tableData["selectedRows"].includes(id)
      )

      for (const conf of selectedConfirmations) {
        const row = []

        let assignees = "Not Specified"

        if (conf['assignees']) {
          if (conf['assignees'] == UNTRACKED_EVIDENCING) assignees = "Untracked";
          else if (conf['assignees'].length > 0) {
            assignees = `"\" ${conf['assignees'].map(({ first_name, last_name }) => `${first_name} ${last_name}`).join(', ')} \""`;
          }
        }

        row.push(
          assignees,
          conf["id"],
          conf["confirmation_type"] || "Not Specified",
          conf["organization_node"]
            ? conf["organization_node"]["name"]
            : "Not Specified",
          conf["client"] ? conf["client"]["name"] : "Not Specified",
          conf["farms"] && conf["farms"].length > 0
            ? conf["farms"].map(({ name }) => name).join("\n")
            : "Not Specified",
          conf["fields"] && conf["fields"].length > 0
            ? conf["fields"].map(({ name }) => name).join("\n")
            : "Not Specified",
          conf["crops"] && conf["crops"].length > 0
            ? conf["crops"].map(({ name }) => name).join("\n")
            : "Not Specified",
          conf["year"] ? conf["year"] : "Not Specified",
          Boolean(conf["hasUploads"]) ? "Completed" : "Incomplete"
        )

        if (conf["confirmation_source"] == "other") {
          row.push(`Other - ${conf["other_source_manual_specification"]}`)
        } else {
          row.push(conf["confirmation_source"] || "Not Specified")
        }

        row.push(conf["confirmation_source_details"])

        row.push(
          conf["instructions"] || "Not Specified",
          conf["notes"] ? `\" Notes: ${conf["notes"]} \"` : "Not Specified"
        )

        csvRows.push(row)
      }

      let csv = "data:text/csv;charset=utf-8,"
      csv += `${csvHeader}\n`
      csv += csvRows.join("\n")

      const encodedUri = encodeURI(csv)
      const link = document.createElement("a")
      link.setAttribute("href", encodedUri)
      link.setAttribute("download", "evidencing_table_export.csv")
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
      activeFarms: Filter.Getters.getFarms,
      activeClients: Filter.Getters.getClients,
    }),
    ...mapState({
      fieldBoundaries: state => state.Map.fieldBoundaries,
      organization: state => state.Organization.organization,
      year: state => state.Organization.year,
      showSustainability: state =>
        state.Organization.organization.showSustainability,
      user: state => state.User.user
    }),
    viewingAll() {
      return this.$route.fullPath.includes('/all');
    },
    isSuperUser() {
      if (this.user != null) {
        return this.user['is_superuser'];
      }
      
      return false;
    },
    getGroupName() {
      if (this.groupMetaData != null && this.groupMetaData['name'] != null) {
        return `${this.groupMetaData['name']} (id: ${this.groupMetaData['id']})`;
      }

      return 'Not Specified';
    },
    isAssignmentActionDisabled() {
      if (
        this.sortedFilteredConfirmations.some(
          ({ assignees }) => assignees == UNTRACKED_EVIDENCING
        )
      ) {
        return true
      }

      return false
    },
    sortedFilteredConfirmations() {
      if (this.tableData["sort"]["key"] == null)
        return this.confirmationsTableFiltered

      return this.confirmationsTableFiltered.toSorted((a,b) => {
        const sortKey = this.tableData['sort']['key'];
        const sortDir = this.tableData['sort']['state'];

        if (sortKey == 'type') {
          if (a['confirmation_type'] != null && b['confirmation_type'] != null) {
            return sortDir
              ? b['confirmation_type'].localeCompare(a['confirmation_type'])
              : a['confirmation_type'].localeCompare(b['confirmation_type']);
          }
        }
        if (sortKey == 'status') {
          if (a['completed'] != null && b['completed'] != null)
            return sortDir ? b['completed'] - a['completed'] : a['completed'] - b['completed'];
        }
        if (sortKey == 'id') {
          if (a['id'] != null && b['id'] != null)
            return sortDir ? b['id'] - a['id'] : a['id'] - b['id']
        }
        if (sortKey == 'farm') {
          const aFarm = a['farms'] != null && a['farms'].length > 0 ? a['farms'][0]['name'] : null;
          const bFarm = b['farms'] != null && b['farms'].length > 0 ? b['farms'][0]['name'] : null;

          if (aFarm != null && bFarm != null) 
            return sortDir ? bFarm.localeCompare(aFarm) : aFarm.localeCompare(bFarm);
        }
        if (sortKey == 'client') {
          const aClient = a['client'] != null ? a['client']['name'] : null;
          const bClient = b['client'] != null ? b['client']['name'] : null;

          if (aClient != null && bClient != null) 
            return sortDir ? bClient.localeCompare(aClient) : aClient.localeCompare(bClient);
        }
        if (sortKey == 'crop') {
          const aCrop = a['crops'] != null && a['crops'].length > 0 ? a['crops'][0]['name'] : null;
          const bCrop = b['crops'] != null && b['crops'].length > 0 ? b['crops'][0]['name'] : null;

          if (aCrop != null && bCrop != null)
            return sortDir ? bCrop.localeCompare(aCrop) : aCrop.localeCompare(bCrop);
        }
        if (sortKey == 'field') {
          const aField = a['fields'] != null && a['fields'].length > 0 ? a['fields'][0]['name'] : null;
          const bField = b['fields'] != null && b['fields'].length > 0 ? b['fields'][0]['name'] : null;

          if (aField != null && bField != null)
            return sortDir ? bField.localeCompare(aField) : aField.localeCompare(bField);
        }
        if (sortKey == 'assignees') {
          const aAssign = a['assignees'] == null || a['assignees'] == UNTRACKED_EVIDENCING ? 0 : a['assignees'].length;
          const bAssign = b['assignees'] == null || b['assignees'] == UNTRACKED_EVIDENCING ? 0 : b['assignees'].length;
          
          return sortDir ? bAssign.length - aAssign.length : aAssign.length - bAssign.length
        }

        return 0
      });
    },
    confirmationsTableFiltered() {
      const filteredByTableOrNav = this.confirmationsInViewFiltered.filter(({
        crops,
        fields,
        uploads,
        confirmation_type,
        specificity
      }) => {
        if (this.tableData['filters']['fields'] != null && this.tableData['filters']['fields'].length > 0) {
          if (!fields.some(({ id }) => this.tableData['filters']['fields'].includes(id)) || fields == null) {
            return false;
          }
        }

        if (this.tableData['filters']['crop'] != null) {
          if (!crops.some(({ name }) => this.tableData['filters']['crop'] == name) || crops == null) {
            return false;
          }
        }

        // if (this.tableData['filters']['assignees'].length > 0) {
        //   if (assignees == null) return false

        //   if (assignees == UNTRACKED_EVIDENCING) {
        //     if (!this.tableData['filters']['assignees'].includes(UNTRACKED_EVIDENCING)) {
        //       return false;
        //     }
        //   }
        // }

        // if (this.tableData["filters"]["assignees"].length > 0) {
        //   if (assignees == UNTRACKED_EVIDENCING) {
        //     if (!this.tableData["filters"]["assignees"].includes(UNTRACKED_EVIDENCING)) {
        //       return false
        //     }
        //   }
        //   else {
        //     if (!assignees.some(({ id }) => this.tableData["filters"]["assignees"].includes(id))) {
        //       return false
        //     }
        //   }
        // }

        if (this.tableData["filters"]["uploads"] != null) {
          if (this.tableData["filters"]["uploads"] == 1) {
            if (uploads.length == 0) return false
          }
          if (this.tableData["filters"]["uploads"] == 2) {
            if (uploads.length > 0) return false
          }
        }

        if (this.tableData["filters"]["confirmationTypes"].length != 0) {
          if (!this.tableData["filters"]["confirmationTypes"].includes(confirmation_type)) {
            return false
          }
        }

        if (this.tableData['filters']['detail'].length > 0) {
          if (!this.tableData['filters']['detail'].includes(specificity)) {
            return false
          }
        }

        return true;
      });

      if (this.tableData['fieldSearch'].length == 0) return filteredByTableOrNav;

      return filteredByTableOrNav.filter(({ fields }) => {
        let includesMatchingField = false
        for (const field of fields) {
          if (
            field["name"]
              .toLowerCase()
              .includes(this.tableData["fieldSearch"].toLowerCase())
          )
            includesMatchingField = true
        }
        return includesMatchingField
      })
    },
    confirmationsInViewFiltered() {
      return this.confirmationData.filter(c => {
        if (c["year"] == this.year) {
          if (c["fields"] != undefined && c["fields"].length > 0) {
            return c["fields"].every(({ id }) =>
              this.activeFields.find(field => id == field["id"])
            )
          } else if (c["farm"] != undefined) {
            // no fields are specified, see if the farm is included in the nav
            return this.activeFarms.find(
              farm => farm["selected"] && farm["id"] == c["farm"]["id"]
            )
          } else if (c["client"] != undefined) {
            // no fields and no farms, but a client, so it's a client-level confirmation
            // include the confirmation if the client is included in active clients
            return this.activeClients.find(
              client => client["selected"] && client["id"] == c["client"]["id"]
            )
          }
        }
      })
    },
    totalAssignees() {
      const allAssignees = []

      for (const { assignees, year } of this.confirmationData) {
        if (year != this.year) continue

        if (assignees == UNTRACKED_EVIDENCING) {
          if (
            allAssignees.find(({ id }) => id == UNTRACKED_EVIDENCING) == null
          ) {
            allAssignees.push({
              id: UNTRACKED_EVIDENCING,
              name: "Untracked",
            })
          }
        } else {
          for (const assignee of assignees) {
            if (allAssignees.find(({ id }) => id == assignee["id"]) == null) {
              const nameEmail = `${assignee["first_name"]} ${
                assignee["last_name"]
              } ${assignee["email"] ? `(${assignee["email"]})` : ""}`
              allAssignees.push({
                id: assignee["id"],
                name: nameEmail,
              })
            }
          }
        }
      }

      return allAssignees
    },
    totalConfirmationTypes() {
      const allTypes = []

      for (const { confirmation_type, year } of this.confirmationData) {
        if (!allTypes.includes(confirmation_type) && year == this.year) {
          allTypes.push(confirmation_type)
        }
      }

      return allTypes
    },
    totalConfirmationFields() {
      const allFields = []

      for (const { fields, year } of this.confirmationData) {
        if (year != this.year) continue

        for (const { id, name } of fields) {
          if (!allFields.includes(name)) {
            allFields.push({id, name})
          }
        }
      }

      return allFields.map(({ id, name }) => {
        return {
          text: `${name.slice(0, 1).toUpperCase()}${name.slice(1)}`,
          value: id,
        }
      })
    },
    totalConfirmationCrops() {
      const allCrops = [];

      for (const { crops, year } of this.confirmationData) {
        if (year != this.year) continue

        for (const { name } of crops) {
          if (!allCrops.includes(name)) {
            allCrops.push(name)
          }
        }
      }

      return allCrops.map(n => {
        return {
          text: `${n.slice(0, 1).toUpperCase()}${n.slice(1)}`,
          value: n,
        }
      })
    },
    iconSvg() {
      return `
        <svg viewBox="0 0 384 512">
          <path d="M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z"/>
        </svg>
      `
    },
    numActiveFilters() {
      return Object.values(this.tableData["filters"]).filter(v => {
        if (v != null) {
          if (v.length == 0) {
            return false
          }
          return true
        }
        return false
      }).length
    },
  },
  async mounted() {
    await this.fetchPracticeConfirmations()

    // given we've parsed (or failed to parse) our confirmations data
    // create or update the map in case field boundaries are already loaded
    if (this.confirmationData.length > 0 && this.fieldBoundaries.length) {
      if (!this.map) {
        this.createMap()
        return
      }

      this.updateMap()
    }
  },
  watch: {
    showTableActionSidebar(curr) {
      if (!curr) {
        this.tableActionSelection = ""
      }
    },
    tableData: {
      handler(curr) {
        if (
          curr["selectedRows"].length != this.sortedFilteredConfirmations.length
        ) {
          this.tableData["allRowSelection"] = false
        }

        this.updateMap();
      },
      deep: true,
    },
    fieldBoundaries() {
      // we have boundaries to work with, create our map instance
      if (!this.map) {
        this.createMap()
        return
      }

      // if the map is already created, just update it
      this.updateMap()
    },
    activeFields() {
      this.updateMap()
    },
    async organization(curr) {
      if (curr && curr["id"]) {
        this.fetchPracticeConfirmations()
      }
    },
    showConfirmationTableView(curr) {
      if (curr && this.zoomControl) {
        this.map.removeControl(this.zoomControl)
        this.zoomControl = null
        // this.map.removeControl(L.control.zoom({ position: 'bottomleft' }));
      } else {
        if (!this.zoomControl) {
          this.zoomControl = L.control.zoom({ position: "bottomleft" })
          this.zoomControl.addTo(this.map)
        }
      }

      this.updateMap()
    }
  },
}
</script>

<style scoped>
.map-wrapper {
  position: relative;
  background: #1b1b1d;
  height: calc(100vh - 65px);
  width: calc(100% + 60px);
  left: -30px;
  overflow: hidden;
  margin-top: -20px;
}
.evidencing-table-view {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;
  padding: 36px 24px;
  z-index: 2;
}
.evidencing-table-view-header-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.evidencing-table-view-header-wrapper h2 {
  color: #171725;
  font-weight: bold;
  margin: 0;
  width: 100%;
}
.evidencing-btns {
  display: flex;
  position: absolute;
  top: 48px;
  right: 24px;
  padding: 8px;
  z-index: 4;
  transition: top 0.25s ease-in-out;
}
.evidencing-btns.map-view-active {
  background: #ffffff;
  border-radius: 6px;
  top: 96px;
}
.evidencing-btns > button,
.evidencing-btns > a {
  width: 32px;
  padding: 0 !important;
  min-width: unset !important;
  opacity: 0.5;
  margin-left: 6px;
}
.evidencing-btns > :first-child {
  margin-left: 0;
}
.evidencing-btns > button:hover {
  opacity: 1;
}
.evidencing-btns > button.activated {
  background-color: #001039;
  opacity: 1;
}
.evidencing-btns > button > ::v-deep(.v-btn__content img) {
  transition: filter 0.25s;
}
.evidencing-btns > button.activated > ::v-deep(.v-btn__content img) {
  filter: invert(1);
}
.evidencing-table-wrapper {
  height: calc(100% - 200px); /* 100% - title/btns height - filters height */
}
.evidencing-table-view-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100%;
  top: 0;
  cursor: pointer;
}
.evidencing-group {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  border-radius: 8px;
  background: #ffffff;
}
.evidencing-group > h2 {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 24px;
  font-weight: bold;
  margin: 0;
}

table {
  width: 100%;
  border: 1px solid #d9d9d9;
  box-shadow: 0 4px 4px 0 #d9d9d9;
  padding: 16px;
  table-layout: fixed;
}
tr {
  vertical-align: middle;
}
th,
td {
  padding: 0 6px 0 16px !important;
  box-sizing: border-box;
  border-top: none;
  border-bottom: 1px solid #d9d9d9 !important;
}
th:last-of-type {
  padding-right: 16px !important;
}
th.checkbox-head,
td.has-checkbox {
  padding: 0 12px 0 16px !important;
}
th.checkbox-head ::v-deep(i.primary--text),
td.has-checkbox ::v-deep(i.primary--text) {
  color: rgba(0, 0, 0, 0.6) !important;
}
th.checkbox-head ::v-deep(.v-input--selection-controls__ripple),
td.has-checkbox ::v-deep(.v-input--selection-controls__ripple) {
  display: none;
}
th.checkbox-head {
  width: 24px;
  border-right: none;
}
th.assigned-head {
  padding-left: 10px !important;
}
th {
  position: sticky;
  top: 0;
  z-index: 1;
  color: #757575;
  background: #ffffff;
  text-transform: uppercase;
  height: 43px !important;
}
th[data-header="farm"],
th[data-header="crop"] {
  width: 75px;
}
th[data-header="client"] {
  width: 87px;
}
th[data-header="field"] {
  width: 93px;
}
th[data-header="id"] {
  width: 95px;
}
th[data-header="due"] {
  width: 100px;
}
th[data-header="type"],
th[data-header="assignees"] {
  width: 138px;
}
th[data-header="status"] {
  width: 160px;
}
th a {
  padding: 12px 0;
  font-size: 12px;
  line-height: 20px;
  color: #000000 !important;
  display: flex;
  width: 100%;
}
th a .evidencing-table-sorters {
  margin-left: auto;
  position: relative;
}
th a .evidencing-table-sorters > svg {
  color: #cbd5e0;
}
th a .evidencing-table-sorters > svg.activated-sort {
  color: #687588;
}
th a .evidencing-table-sorters > svg:first-of-type {
  position: absolute;
  top: 1px;
  right: 0;
}
th a .evidencing-table-sorters > svg:last-of-type {
  position: absolute;
  bottom: 1px;
  right: 0;
}
th,
td {
  font-size: 14px;
  line-height: 20px;
}
td {
  white-space: pre-wrap;
}
td.has-checkbox {
  border-right: 1px solid #d9d9d9;
}
td.field-has-tooltip,
td.cell-has-tooltip {
  color: #054789;
  position: relative;
  cursor: pointer;
}
td.field-has-tooltip .confirmation-fields-tooltip,
td.cell-has-tooltip .confirmation-cell-tooltip {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  z-index: 1;
  top: -50%;
  right: -275px;
  background: white;
  border-radius: 4px;
  padding: 16px 12px;
  width: 300px;
  max-height: 400px;
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
  transition: opacity 0.25s;
  opacity: 0;
  pointer-events: none;
  overflow-y: scroll;
  overflow-x: hidden;
}
td.field-has-tooltip .confirmation-fields-tooltip > p,
td.cell-has-tooltip .confirmation-cell-tooltip > p {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  line-height: 1.5;
  white-space: nowrap;
}
td.field-has-tooltip:hover .confirmation-fields-tooltip,
td.cell-has-tooltip:hover .confirmation-cell-tooltip {
  opacity: 1;
  pointer-events: all;
}
td.confirmation-number-column {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
td a.confirmation-upload-status {
  display: flex;
  align-items: center;
  width: min-content;
  padding: 2px 12px;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  border-radius: 4px;
  white-space: nowrap;
}
td a.confirmation-upload-status.needs-confirmation {
  color: #d22f19;
  background: rgba(255, 122, 0, 0.2);
}
td a.confirmation-upload-status.confirmation-added {
  color: rgba(0, 0, 0, 0.87);
  background: #c7e3c1;
  cursor: default;
}
.evidencing-dashboard-table ::v-deep(.v-input--checkbox),
.evidencing-dashboard-table
  ::v-deep(.v-input--checkbox .v-input--selection-controls__input),
.evidencing-dashboard-table ::v-deep(.v-input--checkbox .v-input__slot) {
  margin: 0;
  padding: 0;
}
.evidencing-dashboard-table ::v-deep(.v-input--checkbox .v-messages) {
  display: none;
}
.evidencing-table-filters button {
  padding: 0 !important;
  min-width: unset !important;
  margin: 0 0 0 10px;
}
.evidencing-table-filter-selections {
  padding: 24px 0;
  display: flex;
}
.evidencing-table-filter-selections .fields-search {
  width: 100%;
  max-width: 175px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 4px 12px;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-end;
  margin-left: auto;
}
.evidencing-table-filter-selections .fields-search i {
  margin-right: 12px;
}
.evidencing-table-filter-selections .fields-search input {
  width: 100%;
}
.evidencing-table-filter-selections .table-filter-selector {
  margin-right: 12px;
  width: 110px;
}
.evidencing-table-filter-selections .table-filter-selector > p {
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  margin: 0 0 4px;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-select__selections p) {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-input__control) {
  min-height: 32px !important;
  height: 32px;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-input__control input) {
  cursor: pointer;
}
.evidencing-table-filter-selections .table-filter-selector ::v-deep(.v-label) {
  font-size: 14px;
  line-height: 20px;
  max-width: 75%;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-input__slot) {
  min-height: 32px;
  height: 32px;
  margin: 0;
  box-shadow: none !important;
}
.evidencing-table-filter-selections
  .table-filter-selector
  ::v-deep(.v-text-field__details),
.evidencing-table-filter-selections
  .fields-search
  ::v-deep(.v-text-field__details) {
  display: none;
}
.evidencing-action-bar {
  padding: 12px 24px;
  width: 100%;
  background: #464b58;
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.evidencing-action-bar > h2 {
  font-size: 16px;
  line-height: 20px;
  color: #ededed;
  font-weight: bold;
  width: 236px;
  margin: 0 24px 0 0;
}
.evidencing-action-bar > button {
  margin-right: 20px;
}
.no-cursor-link {
  cursor: default;
}
.highlighted {
  background: rgba(25, 118, 210, 0.1);
}
#evidencing-map {
  height: calc(100vh - 65px);
  position: relative;
  background: #1b1b1d;
  margin: -17px 0;
  z-index: 1;
}
#evidencing-map.display-map {
  z-index: 3;
}
#evidencing-map ::v-deep(.leaflet-popup-tip-container),
#evidencing-map ::v-deep(.leaflet-popup-close-button) {
  display: none;
}
#evidencing-map /deep/ .leaflet-popup .leaflet-popup-content-wrapper {
  background: rgba(4, 20, 35, 0.89);
  border-radius: 0;
  pointer-events: none;
  width: 300px;
}

#evidencing-map
  /deep/
  .leaflet-popup
  .leaflet-popup-content-wrapper
  .leaflet-popup-content {
  color: white;
  font-family: "Roboto";
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
}

#evidencing-map
  /deep/
  .leaflet-popup
  .leaflet-popup-content-wrapper
  .leaflet-popup-content
  b {
  font-size: 16px;
  margin-bottom: 6px;
  width: 100%;
}

#evidencing-map
  /deep/
  .leaflet-popup
  .leaflet-popup-content-wrapper
  .leaflet-popup-content
  ul {
  margin-bottom: 24px;
}
</style>

<style>
.evidencing-div-icon {
  position: absolute;
}
.evidencing-div-icon > span {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  font-size: 16px;
  color: white;
  position: relative;
  z-index: 4;
}
.evidencing-div-icon > svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.evidencing-div-icon:hover > svg {
  fill: #1976d2 !important;
}
.evidencing-div-icon.complete-icon > svg {
  fill: #67ac5b;
}
.evidencing-div-icon.complete-icon.incomplete-icon > svg {
  fill: #357f8f;
}
.evidencing-div-icon.incomplete-icon > svg {
  fill: #054789;
}
.no-data-found {
  padding: 16px;
}
.no-data-found p {
  color: #000000;
  font-size: 18px;
}
.loading-wrapper {
  padding-top: 64px;
  display: flex;
  justify-content: center;
}
.assign-btn-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.assign-btn-disabled .v-ripple__container {
  display: none !important;
}
.assign-btn-disabled-tooltip {
  opacity: 0;
  transition: opacity 0.25s;
  position: absolute;
  z-index: 2;
  top: -84px;
  left: 458px;
  width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.assign-btn-disabled-tooltip.visible {
  opacity: 1;
}
.v-data-table__wrapper {
  overflow: unset;
}
.v-list-item__action {
  margin-right: 12px !important;
}
.map-evidencing-filters {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 16px 0 52px;
  background: #FFFFFF;
  z-index: 3;
}
.map-evidencing-filters .evidencing-table-filter-selections {
  padding: 8px 0 12px;
}
.map-evidencing-filters .fields-search {
  align-self: center !important;
  margin-top: 8px;
}
</style>
