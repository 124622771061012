<template>
    <ArvaModal
      name="confirmModal"
      :title="titleText"
      @close-modal="handleCloseModal"
    >
      <div v-if="isProcessing" class="zero-state span-text">
        <div class="spinner-border spinner-border-sm ml-1" role="status"></div>
        <span> The system is processing your request. Please wait... </span>
      </div>
  
      <div v-else>
        <div class="confirm-text" v-html="confirmText"></div>
        <slot />
  
        <ArvaModalSaveFooter
          confirmText="Confirm"
          cancelText="Cancel"
          @on-cancel="handleCloseModal"
          @on-confirm="handleConfirm"
        />
      </div>
    </ArvaModal>
  </template>
  
  <script>
  import ArvaModal from "./ArvaModal"
  import ArvaModalSaveFooter from "./ArvaModalSaveFooter"
  
  export default {
    name: "ArvaAlertModal",
    props: {
      titleText: { type: String },
      confirmText: { type: String },
    },
    components: { ArvaModal, ArvaModalSaveFooter },
  
    methods: {
      handleCloseModal() {
        this.$emit("close-modal")
      },
  
      handleConfirm() {
        this.$emit("confirm")
      },
  
      handleCloseWithoutSaving() {
        this.$emit("close-without-saving")
      }
    },
  }
  </script>
  
  <style scoped>
  .confirm-text {
    font-size: 15px;
    font-weight: 500;
    padding: 30px 30px;
  }
  .evidencing-file-names {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 25px;
  }
  .evidencing-file-name {
    margin-top: 10px;
  }
  .file-name-link {
    margin-left: 5px;
  }
  </style>
  