export const LOADER_PROCESSING = 0
export const LOADER_SUCCESS = 1
export const LOADER_ERROR = 2

export const NEEDS_REVIEW = 0
export const DONE = 1
export const NOT_VIEWED = 2
export const CRITICAL = 3
export const PROCESSING = 4

export const SUCCESS = 0
export const WARNING = 1
export const FAILURE = 2
export const UNDETERMINED = 3
export const NOTIFICATION_STATUS_LEVELS = {
  0: SUCCESS,
  1: WARNING,
  2: FAILURE,
  3: UNDETERMINED,
}

export const CONFIRMATION_NUMBER = "Confirmation Number"
export const CONFIRMATION_GROUP = "Confirmation Group"
export const INSTRUCTIONS = "Instructions"
export const DONE_PERCENT = "% Done"
export const ASSIGNED = "Assigned"
export const MESSAGE = "Message"
export const SENT_DATE = "Sent"
export const CLIENT = "Client"
export const FIELDS = "Fields"
export const STATUS = "Status"
export const ACTION = "Action"
export const DUE_DATE = "Due"
export const NOTES = "Notes"
export const DETAIL = "Detail"
export const FILES = "Files"
export const TITLE = "Title"
export const FARM = "Farm"
export const TYPE = "Type"

export const ACTIONS_TABLE_COLUMNS = [
  CONFIRMATION_NUMBER,
  CONFIRMATION_GROUP,
  INSTRUCTIONS,
  DONE_PERCENT,
  SENT_DATE,
  DUE_DATE,
  ASSIGNED,
  FIELDS,
  CLIENT,
  STATUS,
  NOTES,
  FILES,
  FARM,
]
export const DEFAULT_ACTIVE_ACTIONS_COLUMNS = [TITLE, ASSIGNED, DETAIL]
export const ALERTS_COLUMNS = [STATUS, TITLE, MESSAGE, ACTION]
export const DEFAULT_ACTIVE_ALERTS_COLUMNS = [STATUS, TITLE, MESSAGE, ACTION]

export const BLANK_TASK_SIDEBAR = {
  type: null,
  notes: "",
  due: null,
  sent: null,
  farm: null,
  fields: null,
  client: null,
  year: null,
  files: [],
  donePercent: 0,
  status: NOT_VIEWED,
  instructions: "",
  confirmationNumber: null,
  confirmationGroup: null,
  alertTitle: null,
  field: null,
  orgNode: {
    id: null,
    name: "",
  },
  assigned: {
    name: "",
    contact: "",
  },
}

export const NOTIFICATION_TITLES = {
  assignevidencing: "Assign Evidencing",
  practiceconfirmation: "Practice Confirmation",
  companywide: "Announcements",
  moveclient: "Move Client",
}
