<template>
  <canvas id="deck-canvas" />
</template>

<script>
import { Deck } from "@deck.gl/core"
import { GeoJsonLayer, TextLayer, ScatterplotLayer } from "@deck.gl/layers";
import _ from "lodash"
import { mapMutations, mapState, mapActions, mapGetters} from "vuex"
import * as turf from "@turf/turf";
import { Map, Defaults } from "@/store/modules"

export default {
  name: "VueDeckGL",
  data() {
    return {
      deck: {},
      zoomLevel: 1,
    }
  },

  props: {
    settings: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: false,
    },
    layers: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
      extentBounds: state => state.Map.extentBounds,
      singleFieldExtentBounds: state => state.Map.singleFieldExtentBounds,
      fieldBoundaries: state => state.Map.fieldBoundaries,
      fieldSettings: state => state.Map.fieldSettings,
      includeAnimalCounts: state => state.Map.includeAnimalCounts,
    }),

    ...mapGetters({
      currentFieldSettings: Defaults.Getters.getOneFieldSettings,
    })
  },

  methods: {
    ...mapMutations({
      updateBearingPitch: Map.Mutations.updateBearingPitch,
      updateZoom: Map.Mutations.updateZoom,
    }),

    ...mapActions({
      fetchCarbonSettingsByField: Defaults.Actions.fetchCarbonSettingsByField,
    }),

    moveMap({ viewState }) {
      this.deck.setProps({ viewState })
      this.map.jumpTo({
        center: [viewState.longitude, viewState.latitude],
        zoom: viewState.zoom,
        bearing: viewState.bearing,
        pitch: viewState.pitch,
      })

      this.zoomLevel = viewState.zoom;
      this.updateZoom(viewState.zoom)
      this.updateBearingPitch(viewState)

      this.updateLayers();
    },

    autoZoom(resetView = true) {
      this.map.on("move", () => {
        const viewState = {
          latitude: this.map.getCenter().lat,
          longitude: this.map.getCenter().lng,
          zoom: this.map.getZoom(),
          bearing: this.map.getBearing(),
          pitch: this.map.getPitch(),
        }

        this.deck.setProps({ viewState })
        this.updateZoom(viewState.zoom)
      })

      if (!_.isEmpty(this.singleFieldExtentBounds)) {
        const {
          minLongitude,
          maxLongitude,
          minLatitude,
          maxLatitude,
        } = this.singleFieldExtentBounds

        this.map.fitBounds([
          [minLongitude, minLatitude],
          [maxLongitude, maxLatitude],
        ])
      } else if (!_.isEmpty(this.extentBounds) && resetView) {
        const {
          minLongitude,
          maxLongitude,
          minLatitude,
          maxLatitude,
        } = this.extentBounds

        this.map.fitBounds([
          [minLongitude, minLatitude],
          [maxLongitude, maxLatitude],
        ])
      }
    },

    createBubbleLayer(features) {
      const centroids = features.map((feature) => {
        const centroid = turf.centroid(feature);
        return {
          coordinates: centroid.geometry.coordinates,
        };
      });

      const radiusScale = this.zoomLevel > 10 ? 2 : 10 / this.zoomLevel;

      return new ScatterplotLayer({
        id: "bubble-layer",
        data: centroids,
        getPosition: (d) => d.coordinates, // Position of the bubble
        getRadius: 10, // Radius of the bubble (adjust as needed)
        getFillColor: [255, 122, 255, 150], // Background color (bubble effect)
        getLineWidth: 2, // Border width
        getLineColor: [255, 255, 255], // Border color
        stroked: true, // Enable stroke (border)
        radiusMinPixels: 20,
        radiusMaxPixels: 20,
      });
    },

    // Create TextLayer to add bubbles at centroids
    createTextLayer(features, animalCounts) {
      console.log(animalCounts);
      const centroids = features.map((feature, index) => {
        console.log(animalCounts[index] !== null && animalCounts[index] !== undefined);
        // Calculate centroid of each polygon
        const centroid = turf.centroid(feature);
        return {
          coordinates: centroid.geometry.coordinates,
          label: animalCounts[index] !== null && animalCounts[index] !== undefined ? animalCounts[index].toString() : "Area", // Default label for bubbles
        };
      });

      // Return a new TextLayer
      return new TextLayer({
        id: "text-layer",
        data: centroids,
        getPosition: (d) => d.coordinates, // Position (centroid)
        getText: (d) => d.label, // Text label
        getSize: 24, // Size of text
        getColor: [255, 255, 255], // Text color
        getTextAnchor: "middle",
        getAlignmentBaseline: "center",
      });
    },

    async updateLayers() {
      const layers = _.flatMap(this.layers);
      const features = this.fieldBoundaries;
      let fieldIds = [];
      let animalCounts = [];

      if (this.includeAnimalCounts === true) {
        if (features.length > 0) {
          fieldIds = features.map(feature => feature.properties.field.id);
        }

        await this.fetchCarbonSettingsByField({ fieldIds: fieldIds });
        if (fieldIds.length > 0) {
          for (let i  = 0; i < fieldIds.length; i ++) {
            const oneYearAgo = new Date();
            oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

            const fieldId = fieldIds[i];
            let fieldData = this.currentFieldSettings(fieldId)[0][1].year_data;
            let latestLivestock = [];
            let totalAnimals = 0;
            fieldData.sort((a, b) => b.year - a.year);

            fieldData.forEach(yearData => {
              if (yearData.year >= oneYearAgo.getFullYear()) {
                yearData.cultivations.forEach(cultivation => {
                  let dateEnd = null;
                  cultivation.operations && cultivation.operations.grazings.forEach((grazingEntry, gIndex) => {
                    if (new Date(grazingEntry.date_end.value) >= oneYearAgo) {
                      const tempEndDate = grazingEntry.date_end.value;
                      const livestockEntry = cultivation.livestock[gIndex];
                      const species = livestockEntry.species.value;
                      let animalCount = 0;

                      if (species !== null) {
                        if (!latestLivestock.some(item => item[0] === species)) {
                          animalCount = cultivation.stocking[gIndex].count_animals.value === null 
                              ? 0 :parseInt(cultivation.stocking[gIndex].count_animals.value, 10);
                          dateEnd = tempEndDate;
                          latestLivestock.push([species, animalCount ]);
                        } else {
                          if ((dateEnd == null || (dateEnd !== null && dateEnd <= tempEndDate))) {
                            animalCount = cultivation.stocking[gIndex].count_animals.value === null 
                              ? 0 :parseInt(cultivation.stocking[gIndex].count_animals.value, 10);
                            dateEnd = tempEndDate;
                            latestLivestock.push([species, animalCount ]);
                          }
                        }
                      }
                    }
                  });
                })
              }
            });

            if (latestLivestock.length > 0) {
              totalAnimals = latestLivestock.reduce((sum, stock) => {
                return sum + (parseInt(stock[1]) || 0);
              }, 0);
            }

            animalCounts.push(totalAnimals);
          }
          
        }
        
        // await this.fetchCarbonSettingsByField({ fieldIds: [fieldId] })
        // Create TextLayer for centroids
        const bubbleLayer = this.createBubbleLayer(features);
        const textLayer = this.createTextLayer(features, animalCounts);

        // Update the DeckGL layers with the original layers + the textLayer
        this.deck.setProps({ layers: [...layers, bubbleLayer, textLayer] });
      } else {
        this.deck.setProps({ layers: [...layers] });
      }
    },

    setFullPitch() {
      const viewState = {
        latitude: this.map.getCenter().lat,
        longitude: this.map.getCenter().lng,
        zoom: this.map.getZoom(),
        bearing: this.map.getBearing(),
        pitch: 60,
      }
      this.map.jumpTo({ pitch: 60 })
      this.deck.setProps({ viewState })
    },

    setOverheadView() {
      const viewState = {
        latitude: this.map.getCenter().lat,
        longitude: this.map.getCenter().lng,
        zoom: this.map.getZoom(),
        bearing: 0,
        pitch: 0,
      }
      this.map.jumpTo({ bearing: 0, pitch: 0 })
      this.deck.setProps({ viewState })
    },
  },

  watch: {
    layers: {
      deep: true,
      handler() {
        this.updateLayers();
      },
    },
    includeAnimalCounts: {
      deep: true,
      handler() {
        this.updateLayers();
      },
    },
  },

  mounted() {
    this.deck = new Deck({
      ...this.settings,
      onViewStateChange: this.moveMap,
    })

    this.$emit("created", this.deck);
    this.updateLayers();
  },
}
</script>
