<template>
  <ArvaModal
    name="confirmInsetOrderModal"
    title="Confirm Inset Supply Order"
    :wide="true"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-5">
      <form @submit.prevent="handleSubmit">
        <div>
          <h4>Requested Order Filters:</h4>

          <v-data-table
            v-if="previewDataFrame"
            :headers="headers"
            :items="previewDataFrame"
          ></v-data-table>

          <h4>Finalize and Confirm Order Parameters:</h4>

          <div v-if="showError" class="pw-error pa-2">{{ errorText }}</div>

          <v-text-field
            v-if="currentOrderName == null"
            v-model="orderName"
            label="Order Name"
            placeholder="A descriptive name"
            type="text"
          ></v-text-field>

          <v-text-field
            v-else
            v-model="currentOrderName"
            label="Order Name"
            type="text"
            disabled
          ></v-text-field>

          <v-text-field
            v-if="currentSupplyTarget"
            label="Current Supply Target"
            v-model="currentSupplyTarget"
            type="number"
          ></v-text-field>

          <v-text-field
            v-else
            label="Supply Target"
            v-model="supplyTarget"
            type="number"
          ></v-text-field>

          <v-select
            label="Select Aggregation Metric"
            v-if="currentAggregationMetric"
            v-model="currentAggregationMetric"
            :items="aggregationMetrics"
          ></v-select>
          <v-select
            label="Select Aggregation Metric"
            v-else
            v-model="aggregationMetric"
            :items="aggregationMetrics"
          ></v-select>

          <div v-if="currentOrderPrice != null">
            <v-slider
              class="mt-3"
              label="Order Price"
              v-model="currentOrderPrice"
              max="100"
              min="5"
              step=".1"
              ticks="always"
              thumb-label="always"
            ></v-slider>
          </div>
          <div v-else>
            <v-slider
              class="mt-3"
              label="Order Price"
              v-model="orderPrice"
              max="100"
              min="5"
              step=".1"
              ticks="always"
              thumb-label="always"
            ></v-slider>
          </div>

          <ArvaModalSaveFooter
            confirmText="Save"
            cancelText="Cancel"
            @on-cancel="handleCloseModal"
            @on-confirm="handleSubmit"
          />
        </div>
      </form>
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

export default {
  name: "ConfirmInsetOrderModal",
  props: [
    "filterParams",
    "headers",
    "items",
    "currentOrderName",
    "currentOrderID",
    "status",
    "currentOrderPrice",
    "currentSupplyTarget",
    "currentAggregationMetric",
  ],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      previewDataFrame: null,
      orderName: null,
      showError: false,
      orderPrice: 15,
      orderGeoids: [],
      supplyTarget: 10000,
      errorText: "Please Provide an Unique Order Name",
      aggregationMetric: "acreage",
      aggregationMetrics: ["acreage", "harvest_amount", "scope3_emission"],
    }
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    handleSubmit() {
      if (this.orderName == null && this.currentOrderName == null) {
        this.showError = true
        return
      } else {
        this.showError = false
      }
      if ("geoids" in this.filterParams["filter_params"]) {
        this.orderGeoids = this.filterParams["filter_params"]["geoids"]
      }

      let payload_params = this.filterParams["filter_params"]
      if ("min_das" in payload_params) {
        payload_params = Object.assign(payload_params, {
          das: payload_params["min_das"],
        })
        delete payload_params["min_das"]
      }
      if ("max_price" in payload_params) {
        payload_params = Object.assign(payload_params, {
          price: payload_params["max_price"],
        })
        delete payload_params["max_price"]
      }

      if (this.currentOrderID != null) {
        let thisPrice = this.currentOrderPrice
        if (this.currentOrderPrice == null) {
          thisPrice = this.orderPrice
        }

        let thisSupply = this.currentSupplyTarget
        if (this.currentSupplyTarget == null) {
          thisSupply = this.supplyTarget
        }

        payload_params["supply_target"] = {
          type: this.currentAggregationMetric,
          value: parseFloat(thisSupply),
        }
        let payload = {
          filter_params: payload_params,
          name: this.currentOrderName,
          price: thisPrice,
          geoids: this.orderGeoids,
          order_id: this.currentOrderID,
          status: this.status,
        }

        CarbonReadyAPI.updateInsetOrder(payload)
          .then(response => {
            if (response.status == 200) {
              this.$emit("save-from-modal")
            } else {
              this.errorText = response.data
              this.showError = true
            }
          })
          .catch(err => {
            this.errorText = err.response.data
            this.showError = true
          })
      } else {
        let thisSupply = this.supplyTarget
        if (this.currentSupplyTarget != null) {
          thisSupply = this.currentSupplyTarget
        }

        payload_params["supply_target"] = {
          type: this.aggregationMetric,
          value: parseFloat(thisSupply),
        }

        let payload = {
          filter_params: payload_params,
          name: this.orderName,
          price: this.orderPrice,
          geoids: this.orderGeoids,
        }
        CarbonReadyAPI.postInsetOrder(payload)
          .then(response => {
            if (response.status == 200) {
              this.$emit("save-from-modal")
            } else {
              this.errorText = response.data
              this.showError = true
            }
          })
          .catch(err => {
            this.errorText = err.response.data
            this.showError = true
          })
      }
    },
  },
  mounted() {
    this.previewDataFrame = []
    for (const [key, value] of Object.entries(
      this.filterParams["filter_params"]
    )) {
      if (typeof value === "object" && value !== null) {
        this.previewDataFrame.push({
          category: key,
          value: JSON.stringify(value),
        })
      } else {
        this.previewDataFrame.push({ category: key, value: value })
      }
    }
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
