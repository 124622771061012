<template>
  <ArvaModal
    name="confirmModal"
    :title="titleText"
    @close-modal="handleCloseModal"
  >
    <div v-if="isProcessing" class="zero-state span-text">
      <div class="spinner-border spinner-border-sm ml-1" role="status"></div>
      <span> The system is processing your request. Please wait... </span>
    </div>

    <div v-else class="evidencing-file-names">
      <div class="evidencing-file-name" v-for="fileDetail in fileDetails">
        <i v-if="fileDetail.file_name.includes('.pdf')" class="fa fa-file-pdf-o" />
        <i v-else class="fa fa-image" />
        <a
          class="file-name-link"
          :href="fileDetail.file_url"
          target="blank"
          >{{ fileDetail.file_name }}
        </a>
      </div>
      <div class="confirm-text" v-html="confirmText"></div>
      <slot />

      <ArvaModalSaveFooter
        confirmText="Confirm"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
        @on-confirm="handleConfirm"
      />
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "./ArvaModalSaveFooter"

export default {
  name: "EvidencingViewModal",
  props: {
    titleText: { type: String },
    fileDetails: { type: Array },
  },
  components: { ArvaModal, ArvaModalSaveFooter },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },

    handleConfirm() {
      this.$emit("confirm")
    },

    handleCloseWithoutSaving() {
      this.$emit("close-without-saving")
    }
  },
}
</script>

<style scoped>
.confirm-text {
  font-size: 15px;
  font-weight: 500;
  padding: 30px 30px;
}
.evidencing-file-names {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 25px;
}
.evidencing-file-name {
  margin-top: 10px;
}
.file-name-link {
  margin-left: 5px;
}
</style>
