<template>
  <b-sidebar id="evidencing-field-crop-editing-sidebar" width="534px" title="Configure Field/Crops" v-model="show" right shadow backdrop>
    <div class="sidebar-body">
      <div class="field-crop-editor-explainer">
        <p>Configure which crops + respective planting dates should be included in this evidencing grouping.</p>
      </div>

      <div class="table-wrapper">
        <v-simple-table>
          <thead>
            <tr>
              <th><v-checkbox v-model="allAreSelected" @click=handleAllClick /></th>
              <th>Crop</th>
              <th>Planting Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="[cropName, plantingDate], idx in allPlantingDates" :key="cropName + '-' + idx">
              <td><v-checkbox v-model="cropPlantingDateSelections[cropName]" :value="plantingDate" /></td>
              <td class="crop-name-cell">{{ cropName }}</td>
              <td>{{ getFormattedPlantingDate(plantingDate) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>

    <div class="sidebar-footer">
      <v-btn @click="cancel" outlined height="48px">Cancel</v-btn>
      <v-btn @click="apply" :disabled="!areAnyModifications" outlined height="48px">Save</v-btn>
    </div>
  </b-sidebar>
</template>

<script>
import { CONFIRMATION_TYPE_CHOICES } from "@/constants/defaults";
import { EVIDENCING_REMOVAL, EVIDENCING_CREATION } from "@/constants";
import Vue from "vue";

export default {
  name: "EvidencingFieldCropEditingSidebar",
  props: {
    visible: { required: true },
    detailSpec: { required: true }
  },
  emits: ["closeSidebar"],
  data() {
    return {
      show: false,
      cancelled: true,
      allAreSelected: false,
      modifiedCropPlantingDates: {},
      cropPlantingDateSelections: {},
      CONFIRMATION_TYPE_CHOICES
    }
  },
  methods: {
    handleAllClick() {
      for (const cropName in this.detailSpec) {
        if (!this.detailSpec[cropName].every(({ plantingDate }) => this.cropPlantingDateSelections[cropName].includes(plantingDate))) {
          const newSpec = {}
          
          for (const cropName in this.detailSpec) {
            newSpec[cropName] = this.detailSpec[cropName].map(({ plantingDate }) => plantingDate);
          }

          Vue.set(this, 'cropPlantingDateSelections', newSpec);
          return
        }
      }

      const newSpec = {};

      for (const cropName in this.detailSpec) {
        newSpec[cropName] = [];
      }

      Vue.set(this, 'cropPlantingDateSelections', newSpec);
    },
    getFormattedPlantingDate(date) {
      if (date != null) {
        return new Date(date).toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
      }

      return 'Not a valid date'
    },
    removeSidebar() {
      this.$emit('closeSidebar', this.cancelled, this.modifiedCropPlantingDates);
      this.modifiedCropPlantingDates = {};
      this.cropPlantingDateSelections = {};
      this.allAreSelected = false;
      this.cancelled = true;
    },
    cancel() {
      this.cancelled = true;
      this.show = false;
    },
    apply() {
      this.cancelled = false;
      this.show = false;
    }
  },
  computed: {
    areAnyModifications() {
      for (const cropName in this.modifiedCropPlantingDates) {
        if (this.modifiedCropPlantingDates[cropName].length > 0) {
          return true
        }
      }
      
      return false
    },
    numCrops() {
      return Object.keys(this.detailSpec).length;
    },
    numPlantingDates() {
      return this.allPlantingDates.length
    },
    allPlantingDates() {
      const dates = [];

      for (const cropName in this.detailSpec) {
        for (const { plantingDate } of this.detailSpec[cropName]) {
          dates.push([cropName, plantingDate]);
        }
      }

      const sortedDates = dates.toSorted((a, b) => a[0].localeCompare(b[0]))
      return sortedDates
    },
  },
  watch: {
    visible(curr) {
      this.show = curr;
    },
    show(curr) {
      if (!curr) this.removeSidebar(true);
    },
    detailSpec: {
      handler(curr) {
        if (curr != null) {
          const newSelections = {};
          const newModifiedSpec = {};
          
          for (const cropName in curr) {
            if (!(cropName in newSelections)) {
              newSelections[cropName] = [];
              newModifiedSpec[cropName] = [];
            }

            for (const { selected, plantingDate } of curr[cropName]) {
              if (selected) newSelections[cropName].push(plantingDate);
            }
          }
          
          Vue.set(this, 'modifiedCropPlantingDates', newModifiedSpec);
          Vue.set(this, 'cropPlantingDateSelections', newSelections);
        }
      },
      deep: true
    },
    cropPlantingDateSelections: {
      handler(curr) {
        if (curr != null && this.detailSpec != null) {
          for (const cropName in this.detailSpec) {
            for (const { plantingDate, selected } of this.detailSpec[cropName]) {
              const foundIdx = this.modifiedCropPlantingDates[cropName].findIndex(p => p['plantingDate'] == plantingDate);

              if (selected) {
                if (!curr[cropName].includes(plantingDate)) {
                  // we entered the sidebar with this crop+planting date selected already
                  // but it is not selected, the user has set this crop+date to be removed
                  if (foundIdx == -1) {
                    Vue.set(
                      this.modifiedCropPlantingDates,
                      cropName,
                      [...this.modifiedCropPlantingDates[cropName], { plantingDate, action: EVIDENCING_REMOVAL }]
                    )
                  }
                }
                else {
                  // we entered w/ it selected and it is selected, update the modified
                  // array tracker if necessary
                  if (foundIdx != -1) {
                    Vue.set(
                      this.modifiedCropPlantingDates,
                      cropName,
                      [...this.modifiedCropPlantingDates[cropName].toSpliced(foundIdx, 1)]
                    )
                  }
                }
              }
              else {
                if (curr[cropName].includes(plantingDate)) {
                  // we entered the sidebar with this crop+date unselected but it is now selected
                  // the user has set this crop+date to be included
                  if (foundIdx == -1) {
                    Vue.set(
                      this.modifiedCropPlantingDates,
                      cropName,
                      [...this.modifiedCropPlantingDates[cropName], { plantingDate, action: EVIDENCING_CREATION }]
                    )
                  }
                }
                else {
                  // we entered w/ not selected and it is still unselected
                  // update the tracker if necessary
                  if (foundIdx != -1) {
                    Vue.set(
                      this.modifiedCropPlantingDates,
                      cropName,
                      [...this.modifiedCropPlantingDates[cropName].toSpliced(foundIdx, 1)]
                    )
                  }
                }
              }
            }
          }

          let mismatch = false;
          for (const cropName in this.detailSpec) {
            if (!this.detailSpec[cropName].every(({ plantingDate }) => curr[cropName].includes(plantingDate))) {
              mismatch = true;
              break
            }
          }

          this.allAreSelected = !mismatch;
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="css" scoped>
::v-deep(#evidencing-field-crop-editing-sidebar) {
  background: #FFFFFF !important;
}

p {
  color: #000000;
}

::v-deep #evidencing-field-crop-editing-sidebar header {
  padding: 24px 36px;
}

::v-deep #evidencing-field-crop-editing-sidebar header .close {
  margin-right: 16px !important;
}

::v-deep #evidencing-field-crop-editing-sidebar header strong {
  margin-right: auto;
  text-transform: capitalize;
}

.sidebar-body {
  padding: 0 36px 36px;
  position: relative;
}

.field-crop-editor-explainer p {
  color: #000000;
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 8px 0;
  padding: 0;
}

.table-wrapper {
  padding: 0;
  margin: 0;
  background-color: #F7F8FA;
}

.table-wrapper .v-data-table {
  background-color: #F7F8FA;
  height: 500px;
  overflow-y: scroll;
}

.table-wrapper .v-data-table th {
  position: sticky;
  top: 0;
  background: #F7F8FA;
  z-index: 9;
}

.table-wrapper .v-data-table td {
  border: none !important;
}

.table-wrapper .v-data-table td .v-input,
.table-wrapper .v-data-table td ::v-deep(.v-input__slot) {
  padding: 0;
  margin: 0;
}

.table-wrapper .v-data-table td ::v-deep(.v-messages) {
  display: none;
}

.checkbox-head {
  width: 75px;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  background: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 36px;
  display: flex;
  align-items: center;
}

.sidebar-footer>button {
  float: left;
  margin-right: 16px;
}

.crop-name-cell {
  text-transform: capitalize;
}
</style>