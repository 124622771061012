import API from "./API"

const resource = "/pastures/"

const moveClientResource = "/move-client-task-view/"

const list = () => API.get(resource)

const get = fieldId => API.get(`${resource}?requested_field_id=${fieldId}`)

const fieldDelete = fieldId => API.post(`${resource}${fieldId}/field_delete/`)

const batchDelete = fieldIds =>
  API.post(`${resource}batch_delete/`, { field_ids: fieldIds })

const batchUpdate = payload => API.post(`${resource}batch_update/`, payload)

const ignoreGeoPackages = payload =>
  API.post(`${resource}ignore_geo_packages/`, payload)

const fetchGeoPkgsByDataType = payload =>
  API.post(`${resource}fetch_geo_packages_by_data_type/`, payload)

const downloadDataSpreadsheet = ({ fieldId, payload }) =>
  API.post(`${resource}${fieldId}/download_data_spreadsheet/`, payload)

const updateFieldName = payload =>
  API.post(`${resource}update_field_name/`, payload)

const deleteYearData = payload =>
  API.post(`${resource}delete_year_data/`, payload)

const lockYearData = payload => API.post(`${resource}lock_year_data/`, payload)

const fetchClientOrgOptions = () =>
  API.post(`${moveClientResource}fetch_client_org_options/`)

const fetchTargetCorpOptions = () =>
  API.post(`${moveClientResource}fetch_target_corp_options/`)

const getMoveClientTasks = () => API.get(moveClientResource)

const createMoveClientTask = payload =>
  API.post(`${moveClientResource}`, payload)

const cancelMoveClientJob = jobId => API.delete(`${moveClientResource}${jobId}`)

const getFeatureFlags = () => API.get("/feature-flags/")

const fetchUneditableFields = () =>
  API.post(`${resource}fetch_uneditable_fields/`)

export default {
  list,
  get,
  fieldDelete,
  batchDelete,
  batchUpdate,
  fetchGeoPkgsByDataType,
  ignoreGeoPackages,
  downloadDataSpreadsheet,
  updateFieldName,
  deleteYearData,
  lockYearData,
  fetchClientOrgOptions,
  getMoveClientTasks,
  createMoveClientTask,
  cancelMoveClientJob,
  getFeatureFlags,
  fetchTargetCorpOptions,
  fetchUneditableFields,
}
