<template>
  <div class="login">
    <div class="theme">
      <div class="authentication">
        <div class="sign-in">
          <div class="row no-mrg-horizon">
            <div class="col-md-8 no-pdd-horizon d-none d-md-block">
              <div
                class="full-height bg"
                style="background-image: url('assets/images/logos/cattle.jpg')"
              >
                <div class="img-caption">
                  <h1 class="caption-title">
                    Ranch Potential Realized
                  </h1>
                  <p class="caption-text">
                    Transform Ranch data into enhanced profit and stewardship
                    through AI
                  </p>
                </div>
              </div>
            </div>
            <div v-if="!userLoading" class="col-md-4 no-pdd-horizon">
              <div class="full-height bg-white height-100">
                <div class="vertical-align full-height pdd-horizon-70">
                  <div class="table-cell">
                    <div class="pdd-horizon-15">
                      <h1 class="text-bold">RanchForce</h1>
                      <h2>Login</h2>
                      <p
                        v-if="authErrors && authErrors.detail"
                        class="text-danger"
                      >
                        {{ authErrors.detail }}
                      </p>
                      <p class="mrg-btm-15 font-size-13">
                        Please enter your user name and password to login
                      </p>
                      <form @submit.prevent="login">
                        <!-- valid email -->
                        <div v-if="!authErrors.email" class="form-group">
                          <input
                            v-model="loginFormData.email"
                            type="email"
                            class="form-control"
                            placeholder="User name"
                          />
                        </div>

                        <!-- invalid email -->
                        <div v-else class="form-group">
                          <input
                            v-model="loginFormData.email"
                            type="email"
                            class="form-control is-invalid"
                            placeholder="User name"
                          />
                          <div v-if="authErrors.email" class="invalid-feedback">
                            <span
                              v-for="(error, index) in authErrors.email"
                              :key="index"
                              >{{ error }}</span
                            >
                          </div>
                        </div>

                        <!-- valid password -->
                        <div v-if="!authErrors.password" class="form-group">
                          <input
                            v-model="loginFormData.password"
                            type="password"
                            class="form-control"
                            placeholder="Password"
                          />
                        </div>

                        <!-- invalid password -->
                        <div v-else class="form-group">
                          <input
                            v-model="loginFormData.password"
                            type="password"
                            class="form-control is-invalid"
                            placeholder="Password"
                          />
                          <div
                            v-if="authErrors.password"
                            class="invalid-feedback"
                          >
                            <span
                              v-for="(error, index) in authErrors.password"
                              :key="index"
                              >{{ error }}</span
                            >
                          </div>
                        </div>

                        <button class="btn btn-block btn-success">Login</button>

                        <p class="text-right">
                          <a href="#" @click.prevent="passwordReset()">Forgotten Your Password?</a>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="login-footer">
                  <img
                    class="img-responsive inline-block"
                    src="assets/images/logos/arva-logo.png"
                    width="100"
                    alt
                  />
                </div>
              </div>
            </div>
            <div class="box col-md-4" v-else>
              <LoginLogo />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import LoginLogo from "../components/badges/LoginLogo"
import { BASE_URL, ARVA_WEB_APP } from "@/constants"

export default {
  name: "login",
  components: { LoginLogo },
  data() {
    return {
      loginFormData: { email: "", password: "" },
    }
  },

  computed: {
    ...mapState({
      authErrors: state => state.User.authErrors,
      userLoading: state => state.User.loading,
    }),
  },
  methods: {
    ...mapActions({
      authenticate: "User/authenticate",
    }),

    login() {
      this.authenticate({
        email: this.loginFormData.email,
        password: this.loginFormData.password,
        requesting_from_platform: ARVA_WEB_APP
      })
    },

    passwordReset() {
      window.location.href = `${BASE_URL}api/v1/reset-password/`;
    },
  },
}
</script>

<style scoped>
.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-pdd-horizon {
  padding-top: 0;
  padding-bottom: 0;
}

.text-bold {
  font-weight: bold;
}
</style>
