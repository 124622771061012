<template>
    <!-- Tasks Table -->
    <div v-if="table == 'tasks' && columnLabel == 'Assigned'">
        <p class="notifications-table-cell-name">{{ columnValue['name'] }}</p>
        <p class="notifications-table-cell-contact">{{ columnValue['contact'] }}</p>
    </div>
    <v-chip v-else-if="table == 'tasks' && columnLabel == '% Done'" :class="getPercentageColors(columnValue)">{{ columnValue * 100 }}%</v-chip>
    <v-chip v-else-if="table == 'tasks' && columnLabel == 'Status'" :class="`chip-status ${taskStatusMap[columnValue]['color']}`">{{ taskStatusMap[columnValue]['label'] }}</v-chip>
    <v-chip v-else-if="table == 'tasks' && columnLabel == 'Type'" :class="`chip-status ${notifTypeMap[columnValue]['color']}`">{{ notifTypeMap[columnValue]['label'] }}</v-chip>
    <!-- Alerts Table -->
    <p v-else-if="table == 'alerts' && columnLabel == 'Title'" class="basic-cell cell-nowrap">{{ columnValue }}</p>
    <v-chip
        v-else-if="table == 'alerts' && columnLabel == 'Status'"
        :class="`
            chip-status 
            ${columnValue != null && alertStatusMap[columnValue]['color']}
            ${columnValue == null ? 'cell-hidden' : ''}
        `"
    >
        {{ columnValue != null ? alertStatusMap[columnValue]['label'] : null }}
    </v-chip>
    <a v-else-if="table == 'alerts' && columnLabel == 'Action' && columnValue != null">
        <v-chip @click="handleActionClick(columnValue)">{{ getActionTypeCopy(columnValue) }}</v-chip>
    </a>

    <p class="basic-cell" v-else>{{ columnValue != null ? columnValue : 'None' }}</p>
</template>

<script>
export default {
    name: "NotificationsCell",
    props: {
        table: { required: true },
        columnLabel: { required: true },
        columnValue: { required: true }
    },
    data() {
        return {
            notifTypeMap: {
                assignevidencing: { label: 'Assign Evidencing', color: 'chip-attention-state' },
                practiceconfirmation: { label: 'Practice Confirmation', color: 'chip-caution-state' },
                companywide: { label: "Arva Announcement", color: 'chip-attention-state' }
            },
            taskStatusMap: {
                0: { label: 'NEEDS REVIEW', color: 'chip-attention-state' },
                1: { label: 'DONE', color: 'chip-good-state', },
                2: { label: 'NOT VIEWED', color: 'chip-caution-state', },
                3: { label: 'CRITICAL', color: 'chip-danger-state' },
                4: { label: 'PROCESSING', color: 'chip-attention-state' }
            },
            alertStatusMap: {
                0: { label: 'GOOD', color: 'chip-good-state' },
                1: { label: 'MAYBE?', color: 'chip-caution-state' },
                2: { label: 'BAD', color: 'chip-danger-state' }
            }
        }
    },
    methods: {
        getPercentageColors(percentage) {
            if (percentage < 0.33) return "chip-danger-state";
            if (percentage < 0.66) return "chip-caution-state";
            return "chip-good-state";
        },
        getActionTypeCopy(type) {
            if (type == 'contact') return 'Contact';
            if (type == 'view_uploads') return 'View Uploads';
        },
        handleActionClick(type) {
            if (type == 'contact') window.location.href = 'mailto:a.lyons@arvaintelligence.com';
            if (type == 'view_uploads') window.location.href = '/uploads';
        }
    }
}
</script>

<style scoped>
.v-chip {
    justify-content: center;
    height: 24px;
    min-width: 90px;
    font-size: 10px;
    line-height: 1.6;
    font-weight: bold;
}

.v-chip.chip-status {
    min-width: 108px;
}

.basic-cell {
    margin: 0;
    color: #111827;
}

.basic-cell.cell-nowrap {
    white-space: nowrap;
}

.cell-hidden {
    opacity: 0;
}


.chip-danger-state {
    background-color: #FFEDEC !important;
    color: #E03137;
}

.chip-caution-state {
    background-color: #FFF6D3 !important;
    color: #E6BB20;
}

.chip-good-state {
    background-color: #E7F7EF !important;
    color: #0CAF60;
}

.chip-attention-state {
    background-color: #F4F0FF !important;
    color: #8C62FF;
}</style>