<template>
  <section class="notifications-view">
    <NotificationTaskDetailSidebar
      @refetchNotifications="fetchAllNotifications"
      @manualCompletion="handleManualCompletion()"
      :task="tableData['tasks']['detail']"
    />

    <h2>Notifications</h2>

    <div>
      <v-btn-toggle
        v-model="tableData['currentView']"
        tile
        color="#000000"
        class="d-flex notifications-toggler"
        group
        mandatory
      >
        <v-btn height="32px" text value="tasks">Tasks</v-btn>
        <v-btn height="32px" text value="alerts" disabled>Alerts</v-btn>
        <v-btn height="32px" text value="settings" disabled>Settings</v-btn>
      </v-btn-toggle>
    </div>

    <div
      class="notifications-table-wrapper"
      v-if="tableData['currentView'] == 'tasks'"
    >
      <div class="notifications-table-nav">
        <p class="notifications-table-nav-subhead">
          {{ taskTableHeader }}
        </p>

        <div class="notifications-table-nav-buttons">
          <v-btn-toggle
            v-model="taskTableFilterKey"
            tile
            color="#000000"
            class="tasks-toggler"
            group
            mandatory
          >
            <v-btn height="32px" text value="all"
              >All ({{ numIncompletedTaskRows + numCompletedTaskRows }})</v-btn
            >
            <v-btn height="32px" text value="incomplete"
              >Incomplete ({{ numIncompletedTaskRows }})</v-btn
            >
            <v-btn height="32px" text value="complete"
              >Completed ({{ numCompletedTaskRows }})</v-btn
            >
          </v-btn-toggle>

          <v-btn
            class="table-editing-btn"
            text
            width="36px"
            height="32px"
            @click="
              tableData['tasks']['rowFilters']['editing'] =
                !tableData['tasks']['rowFilters']['editing']
            "
          >
            <v-icon dark>mdi-filter-variant</v-icon>
          </v-btn>
          <v-btn class="table-editing-btn" text width="36px" height="32px">
            <v-icon dark>mdi-cog-outline</v-icon>
          </v-btn>
        </div>
      </div>

      <div
        :class="`notifications-table-row-filters ${
          tableData['tasks']['rowFilters']['editing'] ? 'shown' : ''
        }`"
      >
        <div>
          <label>Assignee</label>
          <v-select
            :items="[]"
            v-model="tableData['tasks']['rowFilters']['assignees']"
            height="36px"
            outlined
            chips
            multiple
            placeholder="Assignee"
          >
          </v-select>
        </div>

        <div>
          <label>Notification Type</label>
          <v-select
            :items="allNotificationTypes"
            v-model="tableData['tasks']['rowFilters']['type']"
            height="36px"
            placeholder="Assignee"
            outlined
            chips
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2">
                <span>{{ item }}</span>
              </v-chip>

              <span
                v-if="index === 2"
                class="text-grey text-caption align-self-center"
              >
                (+{{
                  tableData["tasks"]["rowFilters"]["type"].length - 2
                }}
                other{{
                  tableData["tasks"]["rowFilters"]["type"].length == 3
                    ? ""
                    : "s"
                }})
              </span>
            </template>
          </v-select>
        </div>
      </div>

      <v-simple-table
        class="notifications-table"
        v-if="filteredSortedTaskRows.length > 0"
      >
        <thead>
          <th
            v-for="(action, idx) in tableData['tasks']['headers']"
            :key="action + idx"
            :data-header-content="action"
          >
            <a @click="sortTableRows('tasks', action)">
              <span>{{ action }}</span>
              <div
                class="notifications-filter-icons"
                v-if="!['detail', 'status'].includes(action.toLowerCase())"
              >
                <font-awesome-icon
                  :icon="['fas', 'caret-up']"
                  :class="getSortingState('tasks', action, 0)"
                />
                <font-awesome-icon
                  :icon="['fas', 'caret-down']"
                  :class="getSortingState('tasks', action, 1)"
                />
              </div>
            </a>
          </th>
        </thead>
        <tbody>
          <tr
            v-for="(dataset, datasetIdx) in paginatedTaskRows"
            :key="dataset['assigned'] + datasetIdx"
            class="notifications-table-row"
            @click="setDetail('tasks', dataset)"
            v-b-toggle.sidebar-task-detail-view
          >
            <td
              v-for="(column, columnIdx) in tableData['tasks']['headers']"
              :key="column + dataset['assigned'] + columnIdx"
            >
              <NotificationsCell
                table="tasks"
                :columnLabel="column"
                :columnValue="dataset[column.toLowerCase().replace(' ', '')]"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="empty-table-placeholder" v-else>
        <p>Nothing to display.</p>
      </div>

      <div
        class="pagination-wrapper"
        v-if="filteredSortedTaskRows.length > rowsPerPage"
      >
        <v-btn
          class="decrement-btn"
          fab
          small
          :disabled="!isTableDecrementEnabled('tasks')"
          @click="decrementPage('tasks')"
        >
          <v-icon dark>mdi-chevron-left</v-icon>
        </v-btn>

        <span
          v-for="(pageNum, idx) in getPageNumbers('tasks')"
          :key="`page-${pageNum}-${idx}`"
          :class="`${
            tableData['tasks']['page'] == idx ? 'active' : 'inactive'
          } pagination-page`"
          @click="tableData['tasks']['page'] = idx"
        >
          {{ pageNum }}
        </span>

        <v-btn
          class="increment-btn"
          fab
          small
          :disabled="!isTableIncrementEnabled('tasks')"
          @click="incrementPage('tasks')"
        >
          <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>

    <div
      class="notifications-table-wrapper"
      v-if="tableData['currentView'] == 'alerts'"
    >
      <div class="notifications-table-nav">
        <p class="notifications-table-nav-subhead">
          {{ alertTableHeader }}
        </p>

        <div class="notifications-table-nav-buttons">
          <v-btn-toggle
            v-model="alertTableFilterKey"
            tile
            color="#000000"
            class="tasks-toggler"
            group
            mandatory
          >
            <v-btn height="32px" text value="all"
              >All ({{ numUnreadAlerts + numReadAlerts }})</v-btn
            >
            <v-btn height="32px" text value="unread"
              >Unread ({{ numUnreadAlerts }})</v-btn
            >
            <v-btn height="32px" text value="read"
              >Read ({{ numReadAlerts }})</v-btn
            >
          </v-btn-toggle>

          <v-btn class="table-editing-btn" text width="36px" height="32px">
            <v-icon dark>mdi-filter-variant</v-icon>
          </v-btn>
          <v-btn class="table-editing-btn" text width="36px" height="32px">
            <v-icon dark>mdi-cog-outline</v-icon>
          </v-btn>
        </div>
      </div>

      <v-simple-table
        class="notifications-table"
        v-if="sortedAlertRows.length > 0"
      >
        <thead>
          <th
            v-for="(action, idx) in tableData['alerts']['headers']"
            :key="action + idx"
          >
            <a @click="sortTableRows('alerts', action)">
              <span>{{ action }}</span>
              <div class="notifications-filter-icons">
                <font-awesome-icon
                  :icon="['fas', 'caret-up']"
                  :class="getSortingState('alerts', action, 0)"
                />
                <font-awesome-icon
                  :icon="['fas', 'caret-down']"
                  :class="getSortingState('alerts', action, 1)"
                />
              </div>
            </a>
          </th>
        </thead>
        <tbody>
          <tr
            v-for="(dataset, datasetIdx) in sortedAlertRows"
            :key="dataset['title'] + datasetIdx"
            class="notifications-table-row"
          >
            <td
              v-for="(column, columnIdx) in tableData['alerts']['headers']"
              :key="column + dataset['title'] + columnIdx"
            >
              <NotificationsCell
                table="alerts"
                :columnLabel="column"
                :columnValue="dataset[column.toLowerCase().replace(' ', '')]"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="empty-table-placeholder" v-else>
        <p>Nothing to display.</p>
      </div>

      <div class="pagination-wrapper" v-if="alertRows.length > rowsPerPage">
        <v-btn
          class="decrement-btn"
          fab
          small
          :disabled="!isTableDecrementEnabled('alerts')"
          @click="decrementPage('alerts')"
        >
          <v-icon dark>mdi-chevron-left</v-icon>
        </v-btn>

        <span
          v-for="(pageNum, idx) in getPageNumbers('alerts')"
          :key="`page-${pageNum}-${idx}`"
          :class="`${
            tableData['alerts']['page'] == pageNum - 1 ? 'active' : 'inactive'
          } pagination-page`"
          @click="tableData['alerts']['page'] = idx"
        >
          {{ pageNum }}
        </span>

        <v-btn
          class="increment-btn"
          fab
          small
          :disabled="!isTableIncrementEnabled('alerts')"
          @click="incrementPage('alerts')"
        >
          <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </section>
</template>

<script>
import { CONFIRMATION_TYPE_CHOICES } from "@/constants/defaults"
import { Notifications } from "@/store/modules"
import { mapState, mapActions, mapGetters } from "vuex"
import NotificationsCell from "@/components/notifications/NotificationsCell.vue"
import NotificationTaskDetailSidebar from "@/components/notifications/NotificationTaskDetailSidebar.vue"
import {
  DEFAULT_ACTIVE_ACTIONS_COLUMNS,
  DEFAULT_ACTIVE_ALERTS_COLUMNS,
  ACTIONS_TABLE_COLUMNS,
  NOTIFICATION_TITLES,
  ALERTS_COLUMNS,
  NOTIFICATION_STATUS_LEVELS,
  UNDETERMINED,
  NOT_VIEWED,
  NEEDS_REVIEW,
  CRITICAL,
  PROCESSING,
  DONE,
} from "@/constants/arvaNotifications"

export default {
  name: "NotificationsView",
  data() {
    return {
      taskTableFilterKey: "incomplete",
      alertTableFilterKey: "all",
      tableData: {
        currentView: "tasks",
        tasks: {
          sort: { key: null, direction: 0 },
          headers: DEFAULT_ACTIVE_ACTIONS_COLUMNS,
          page: 0,
          stateFilters: [NOT_VIEWED, NEEDS_REVIEW, CRITICAL],
          rowFilters: {
            type: [],
            assignees: [],
            editing: false,
          },
          detail: null,
        },
        alerts: {
          sort: { key: null, direction: 0 },
          headers: DEFAULT_ACTIVE_ALERTS_COLUMNS,
          page: 0,
          stateFilters: [],
        },
      },
      notifications: [],
      rowsPerPage: 6,
    }
  },
  components: { NotificationsCell, NotificationTaskDetailSidebar },
  mounted() {
    this.fetchAllNotifications()
  },
  methods: {
    ...mapActions({
      fetchAllNotifications: "Notifications/fetchAllNotifications",
    }),
    handleManualCompletion() {},
    setDetail(table, obj) {
      this.tableData[table]["detail"] = obj
    },
    getPageNumbers(table) {
      const len =
        table == "alerts" ? this.alertRows.length : this.taskRows.length
      return Array.from(
        { length: Math.ceil(len / this.rowsPerPage) },
        (_, i) => i + 1
      )
    },
    decrementPage(table) {
      if (this.isTableDecrementEnabled(table))
        this.tableData[table]["page"] -= 1
    },
    incrementPage(table) {
      if (this.isTableIncrementEnabled(table))
        this.tableData[table]["page"] += 1
    },
    isTableIncrementEnabled(table) {
      const len =
        table == "alerts" ? this.alertRows.length : this.taskRows.length
      return (this.tableData[table]["page"] + 1) * this.rowsPerPage < len
    },
    isTableDecrementEnabled(table) {
      return this.tableData[table]["page"] > 0
    },
    sortTableRows(table, action) {
      if (["Detail", "Status"].includes(action)) return
      if (this.tableData[table]["sort"]["key"] == null) {
        this.tableData[table]["sort"]["key"] = action
        this.tableData[table]["sort"]["direction"] = 0
      } else if (this.tableData[table]["sort"]["key"] == action) {
        if (this.tableData[table]["sort"]["direction"] == 0) {
          this.tableData[table]["sort"]["direction"] += 1
        } else if (this.tableData[table]["sort"]["direction"] == 1) {
          this.tableData[table]["sort"]["key"] = null
          this.tableData[table]["sort"]["direction"] = 0
        }
      } else {
        this.tableData[table]["sort"]["key"] = action
        this.tableData[table]["sort"]["direction"] = 0
      }
    },
    getSortingState(table, action, direction) {
      return this.tableData[table]["sort"]["key"] == action &&
        this.tableData[table]["sort"]["direction"] == direction
        ? "activated-sort"
        : ""
    },
    getNotificationDescription(notification) {
      let description = ""
      if (notification["notification_type"] == "assignevidencing") {
        const { fields, numConfirmations } = notification[
          "model_related"
        ].reduce(
          (accum, action) => {
            if (!accum["fields"].includes(action["field"]["name"])) {
              accum["fields"].push(action["field"]["name"])
            }
            accum["numConfirmations"] += action["practice_confirmations"].length
            return accum
          },
          { fields: [], years: [], numConfirmations: 0 }
        )

        description += `${numConfirmations} confirmations are awaiting assignment`

        if (fields.length <= 2) {
          description += ` (for fields: ${fields.join(", ")})`
        } else {
          description += ` (for ${fields.length} fields)`
        }
      }

      if (notification["notification_type"] == "practiceconfirmation") {
        const { confirmationTypes } = notification["model_related"].reduce(
          (accum, action) => {
            if (
              !accum["confirmationTypes"].includes(
                action["practice_confirmation"]["confirmation_type"]
              )
            ) {
              accum["confirmationTypes"].push(
                CONFIRMATION_TYPE_CHOICES.find(
                  ({ value }) =>
                    value ==
                    action["practice_confirmation"]["confirmation_type"]
                )["name"]
              )
            }
            return accum
          },
          { confirmationTypes: [] }
        )

        if (confirmationTypes.length <= 2) {
          description += `${confirmationTypes.join(", ")}`
        } else {
          description += `${confirmationTypes.slice(0, 2).join(", ")} and ${
            confirmationTypes.length - 2
          } more`
        }
      }

      if (notification["notification_type"] == "companywide") {
        description += `Announcement (${new Date(
          notification["created_at"]
        ).toLocaleDateString()})`
      }

      if (notification["notification_type"] == "moveclient") {
        if (notification["model_related"].length == 0) return
        var client_name = notification["model_related"][0]["client"]["name"]
        var source_org = notification["model_related"][0]["source_org"]["name"]
        var destination_org =
          notification["model_related"][0]["destination_org"]["name"]
        description = `${source_org}/${client_name} -> ${destination_org}/${client_name}`
      }

      return description
    },
  },
  computed: {
    ...mapState({
      alertNotifications: state => state.Notifications.alertNotifications,
      taskNotifications: state => state.Notifications.taskNotifications,
      user: state => state.User.user,
    }),
    ...mapGetters({
      getAggregateAlertNotifications:
        Notifications.Getters.getAggregateAlertNotifications,
      getAggregateTaskNotifications:
        Notifications.Getters.getAggregateTaskNotifications,
    }),
    allNotificationTypes() {
      const types = []
      this.filteredSortedTaskRows.forEach(n => {
        if (!types.includes(n["type"])) types.push(n["type"])
      })
      return types
    },
    alertTableHeader() {
      if (this.alertTableFilterKey == "all") return "All Alerts"
      if (this.alertTableFilterKey == "read")
        return `${this.numReadAlerts} Read Alert${
          this.numReadAlerts == 1 ? "" : "s"
        }`
      if (this.alertTableFilterKey == "unread")
        return `${this.numUnreadAlerts} Unread Alert${
          this.numUnreadAlerts == 1 ? "" : "s"
        }`
    },
    taskTableHeader() {
      if (this.taskTableFilterKey == "all") return "All Tasks"
      if (this.taskTableFilterKey == "incomplete")
        return `${this.numIncompletedTaskRows} Incomplete Task${
          this.numIncompletedTaskRows == 1 ? "" : "s"
        }`
      if (this.taskTableFilterKey == "complete")
        return `${this.numCompletedTaskRows} Complete Task${
          this.numCompletedTaskRows == 1 ? "" : "s"
        }`
    },
    numCompletedTaskRows() {
      return this.taskRows.filter(a => a["status"] == DONE).length
    },
    numIncompletedTaskRows() {
      return this.taskRows.filter(a =>
        [NOT_VIEWED, NEEDS_REVIEW, CRITICAL].includes(a["status"])
      ).length
    },
    taskRows() {
      const notifs = this.getAggregateTaskNotifications.map(n => {
        const notifType = n["notification_type"]
        const { id, first_name, last_name, email } = n["targeted_users"][0]
        const status = n["completed"] ? DONE : NEEDS_REVIEW
        const assigned = {
          name: id == this.user["id"] ? "You" : `${first_name} ${last_name}`,
          contact: email,
        }
        return {
          notificationId: n["id"],
          type: notifType,
          title: NOTIFICATION_TITLES[notifType],
          detail: this.getNotificationDescription(n),
          assigned,
          status,
          data: n,
        }
      })
      return notifs
    },
    filteredSortedTaskRows() {
      // prior to sorting, filter out
      let filteredActionRows = this.taskRows

      if (this.tableData["tasks"]["stateFilters"].length > 0) {
        filteredActionRows = this.taskRows.filter(a =>
          this.tableData["tasks"]["stateFilters"].includes(a["status"])
        )
      }

      if (this.tableData["tasks"]["sort"]["key"] == null)
        return filteredActionRows

      const sortKey = this.tableData["tasks"]["sort"]["key"]
        .toLowerCase()
        .replace(" ", "")
      const direction = this.tableData["tasks"]["sort"]["direction"]
      return filteredActionRows.toSorted((a, b) => {
        let aVal = a[sortKey]
        let bVal = b[sortKey]

        if (
          [
            "assigned",
            "client",
            "alerttitle",
            "sent",
            "due",
            "confirmationnumber",
            "fields",
            "title",
          ].includes(sortKey)
        ) {
          if (sortKey == "assigned") {
            return direction == 0
              ? aVal["name"][0].localeCompare(bVal["name"][0])
              : bVal["name"][0].localeCompare(aVal["name"][0])
          } else if (
            sortKey == "client" ||
            sortKey == "alerttitle" ||
            sortKey == "confirmationnumber" ||
            sortKey == "fields" ||
            sortKey == "title"
          ) {
            return direction == 0
              ? aVal[0].localeCompare(bVal[0])
              : bVal[0].localeCompare(aVal[0])
          } else return
        }

        return direction == 0 ? aVal - bVal : bVal - aVal
      })
    },
    paginatedTaskRows() {
      return this.filteredSortedTaskRows.slice(
        this.tableData["tasks"]["page"] * this.rowsPerPage,
        (this.tableData["tasks"]["page"] + 1) * this.rowsPerPage
      )
    },
    numUnreadAlerts() {
      return 0
    },
    numReadAlerts() {
      return 0
    },
    sortedAlertRows() {
      const p = this.tableData["alerts"]["page"]
      if (this.tableData["alerts"]["sort"]["key"] == null) {
        return this.alertRows.slice(
          p * this.rowsPerPage,
          (p + 1) * this.rowsPerPage
        )
      }

      const sortKey = this.tableData["alerts"]["sort"]["key"]
        .toLowerCase()
        .replace(" ", "")
      const direction = this.tableData["alerts"]["sort"]["direction"]

      const sorted = this.alertRows.toSorted((a, b) => {
        let aVal = a[sortKey]
        let bVal = b[sortKey]

        if (["title", "message", "action"].includes(sortKey)) {
          if (sortKey == "title") {
            return direction == 0
              ? aVal[0].localeCompare(bVal[0])
              : bVal[0].localeCompare(aVal[0])
          }
          if (sortKey == "message") {
            return direction == 0
              ? aVal.length - bVal.length
              : bVal.length - aVal.length
          } else return
        }

        return direction == 0 ? aVal - bVal : bVal - aVal
      })
      return sorted.slice(p * this.rowsPerPage, (p + 1) * this.rowsPerPage)
    },
    alertRows() {
      return this.getAggregateAlertNotifications.map(n => {
        return {
          title: n["one_shot_title"],
          message: n["one_shot_message"],
          status:
            n["one_shot_status_level"] == UNDETERMINED
              ? null
              : NOTIFICATION_STATUS_LEVELS[n["one_shot_status_level"]],
          action: null,
        }
      })
    },
  },
  watch: {
    taskTableFilterKey(curr) {
      if (curr == "complete") this.tableData["tasks"]["stateFilters"] = [DONE]
      if (curr == "incomplete")
        this.tableData["tasks"]["stateFilters"] = [
          NOT_VIEWED,
          NEEDS_REVIEW,
          CRITICAL,
        ]
      if (curr == "all" || curr == null)
        this.tableData["tasks"]["stateFilters"] = []
    },
  },
}
</script>

<style scoped>
.notifications-view {
  position: relative;
  min-height: 800px;
  padding: 24px;
}

.notifications-toggler {
  padding: 9px 0;
  margin: 32px 0px;
}

.notifications-toggler > button,
.tasks-toggler > button,
.table-editing-btn {
  text-transform: none;
  letter-spacing: normal;
  padding: 6px 16px !important;
  opacity: 1;
  transition: opacity 0.25s;
  border-radius: 4px !important;
  margin-left: 0 !important;
  margin-right: 8px !important;
}

.tasks-toggler > button {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.table-editing-btn {
  padding: 0 !important;
  min-width: unset !important;
}

.notifications-toggler > button:deep(.v-ripple__container),
.tasks-toggler > button:deep(.v-ripple__container),
.table-editing-btn:deep(.v-ripple__container) {
  display: none;
}

.notifications-table-wrapper {
  border: 1px solid rgba(211, 211, 211, 0.5);
  box-shadow: 0 0 5px 0 lightgrey;
  border-radius: 16px;
  padding: 24px;
}

.notifications-view > h2 {
  width: 100%;
  margin: 0;
  font-size: 24px;
  line-height: 1.5;
}

.notifications-table-wrapper > p {
  color: #687588;
  line-height: 1.6;
  margin: 8px 0 24px;
  position: relative;
}

.notifications-table,
.empty-table-placeholder {
  height: 440px;
  margin-bottom: 24px;
}

.empty-table-placeholder {
  padding: 24px;
}

.empty-table-placeholder > p {
  font-size: 20px;
  line-height: 1.6;
  color: #000000;
}

.notifications-table-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0 24px;
}

.notifications-table-nav-subhead {
  font-size: 16px;
  line-height: 1.25;
  color: #000000;
  font-weight: bold;
  margin: 0;
}

.notifications-table-nav-buttons {
  display: flex;
  align-items: center;
}

.notifications-table th {
  background-color: #fafafa;
}

.notifications-table th:first-of-type {
  border-radius: 10px 0 0 10px;
}

.notifications-table th:last-of-type {
  border-radius: 0 10px 10px 0;
}

.notifications-table th[data-header-content="% Done"] {
  width: 120px;
}

.notifications-table th[data-header-content="Status"] {
  width: 140px;
}

.notifications-table th[data-header-content="Due"],
.notifications-table th[data-header-content="Sent"] {
  width: 150px;
}

.notifications-table th[data-header-content="Type"],
.notifications-table th[data-header-content="Assigned"],
.notifications-table th[data-header-content="Client"],
.notifications-table th[data-header-content="Title"] {
  width: 200px;
}

.notifications-table th[data-header-content="Confirmation Number"] {
  width: 225px;
}

.notifications-table th > a {
  padding: 18px 16px;
  font-size: 12px;
  line-height: 1.6;
  color: #687588;
  display: flex;
  width: 100%;
}

.notifications-table th > a svg {
  color: #cbd5e0;
}

.notifications-table th > a svg.activated-sort {
  color: #687588;
}

.notifications-filter-icons {
  margin-left: auto;
  position: relative;
}

.notifications-filter-icons > svg:first-of-type {
  position: absolute;
  top: 1px;
  right: 0;
}

.notifications-filter-icons > svg:last-of-type {
  position: absolute;
  bottom: 1px;
  right: 0;
}

.notifications-table tr {
  cursor: pointer;
}

.notifications-table-row:nth-child(6n) {
  border-bottom: none;
}

.notifications-table td {
  padding: 18px 16px;
}

td:deep(.notifications-table-cell-name) {
  margin: 0;
  font-size: 12px;
  line-height: 1.6;
  color: #111827;
}

td:deep(.notifications-table-cell-contact) {
  margin: 0;
  font-size: 10px;
  line-height: 1.6;
  color: #a0aec0;
}

tr.notifications-table-row {
  height: 64px;
  border-bottom: 1px solid #f1f2f4;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
}

.decrement-btn {
  margin-right: 12px;
}

.increment-btn {
  margin-left: 12px;
}

.pagination-page {
  font-size: 20px;
  padding: 6px 12px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s;
}

.pagination-page:hover {
  opacity: 0.5;
}

.pagination-page.active {
  text-decoration: underline;
}

::v-deep .b-sidebar-outer {
  z-index: 9999999999;
}

::v-deep .b-sidebar {
  overflow: hidden;
}

::v-deep header.b-sidebar-header {
  margin: 36px 36px 0;
  padding: 0 0 24px;
  position: relative;
  border-bottom: 1px solid #b8bdcc;
}

::v-deep header.b-sidebar-header > button.close {
  font-size: 32px;
  margin-right: 12px !important;
}

::v-deep header.b-sidebar-header > strong {
  width: 100%;
  text-align: left;
}

::v-deep .b-sidebar-backdrop {
  cursor: pointer;
}

.notifications-table-row-filters {
  height: 0px;
  overflow: hidden;
  transition: height 0.25s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.notifications-table-row-filters.shown {
  height: 96px;
}
</style>
