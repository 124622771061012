<template>
  <div>
    <DashboardTitle
      title="SuperUser Admin"
      imgSrc="/assets/images/side-nav/admin.png"
    />

    <div v-if="!user.is_superuser" class="row">
      <div class="col-12">
        <v-alert type="error" dismissible>
          You do not have permission to view this page.
        </v-alert>
      </div>
    </div>
    <div v-else class="col-12 scroll-col">
      <v-card>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="mb-4">
            <v-expansion-panel-header
              class="primary text-white font-weight-bold"
              >DJANGO ADMIN INTERFACE</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-btn class="mt-3" @click="loginToDjangoAdmin">
                Click to login
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              class="primary text-white font-weight-bold"
              >MOVE CLIENT</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <div class="row mt-3 mb-3">
                <v-btn
                  class="float-right"
                  :loading="loading"
                  @click="clickCreateBtn"
                >
                  <v-icon>mdi-plus</v-icon>
                  Create New Task
                </v-btn>
              </div>
              <div class="row">
                <v-simple-table
                  class="selector-table"
                  fixed-header
                  height="380px"
                  overflow="auto"
                >
                  <thead>
                    <tr>
                      <th width="300">Client</th>

                      <th width="400">Source Organization</th>
                      <th width="400">Target Organization</th>
                      <th width="300">Status</th>
                      <th width="300"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <MoveClientListRow
                      v-for="job in moveClientJobs"
                      :key="job.client_id"
                      :job="job"
                      @cancel-job="confirmCancelingJob"
                    />
                  </tbody>
                </v-simple-table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>

    <CreateNewMoveClientTaskModal
      v-if="showCreateNewMoveClientTaskModal"
      :clientOrgOptions="clientOrgOptions"
      :targetCorpOptions="targetCorpOptions"
      @close-modal="clickCloseBtnInCreateModal"
      @save-changes="confirmCreateNewMoveClientTask"
    />

    <ConfirmModal
      v-if="confirmCancelJobModalOpen"
      titleText="Confirm Cancel Move Client Job"
      confirmText="Are you sure you want to cancel this job? This action cannot be undone."
      @confirm="cancelMoveClientJob"
      @close-modal="confirmCancelJobModalOpen = false"
    />

    <ConfirmModal
      v-if="confirmCreateJobModalOpen"
      titleText="Confirm Request New Move Client Job"
      confirmText="Are you sure you want to create this job? The Release Form must be manually signed by both the Grower and the new Channel Partner before moving the selected client onto the new organization."
      @confirm="handleCreateNewMoveClientTask"
      @close-modal="confirmCreateJobModalOpen = false"
    />

    <AlertErrorModal
      v-if="alertErrorModalOpen"
      :titleText="modalTitleText"
      :errorMessage="errorMessageForModal"
      @close-modal="alertErrorModalOpen = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { User } from "@/store/modules"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import MoveClientListRow from "@/components/superUserAdmin/MoveClientListRow"
import CreateNewMoveClientTaskModal from "@/components/modals/superUserAdmin/CreateNewMoveClientTaskModal"
import FieldsAPI from "@/api/FieldsAPI"
import ConfirmModal from "@/components/modals/ConfirmModal"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import {
  setIntervalAsync,
  clearIntervalAsync,
} from "set-interval-async/dynamic"

export default {
  name: "SuperUserAdminView",
  components: {
    DashboardTitle,
    MoveClientListRow,
    CreateNewMoveClientTaskModal,
    ConfirmModal,
    AlertErrorModal,
  },

  data() {
    return {
      panel: [0],
      showCreateNewMoveClientTaskModal: false,
      fetchIntervalID: null,
      loading: false,
      moveClientJobs: [],
      pendingClientJobs: [],
      targetCorpOptions: [],
      confirmCancelJobModalOpen: false,
      confirmCreateJobModalOpen: false,
      selectedJobId: null,
      createJobPayload: {},
      alertErrorModalOpen: false,
      errorMessageForModal: null,
      modalTitleText: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.User.user,
    }),
  },

  methods: {
    ...mapActions({
      logInToAdmin: User.Actions.logInToAdmin,
    }),

    async clickCreateBtn() {
      this.loading = true
      await FieldsAPI.fetchClientOrgOptions().then(({ data }) => {
        // Filter out the clients that are already in pending state
        this.clientOrgOptions = data.client_org_options.map(org => ({
          ...org,
          clients: org.clients.filter(
            client => !this.pendingClientJobs.includes(client.id)
          ),
        }))
      })

      if (this.targetCorpOptions.length === 0) {
        await this.fetchTargetCorpOptions()
      }

      this.loading = false
      this.showCreateNewMoveClientTaskModal = true
    },

    loginToDjangoAdmin() {
      this.logInToAdmin()
    },

    clickCloseBtnInCreateModal() {
      this.showCreateNewMoveClientTaskModal = false
      this.createJobPayload = {}
    },

    confirmCancelingJob(jobId) {
      this.confirmCancelJobModalOpen = true
      this.selectedJobId = jobId
    },

    confirmCreateNewMoveClientTask(payload) {
      this.confirmCreateJobModalOpen = true
      this.createJobPayload = payload
    },

    async cancelMoveClientJob() {
      this.confirmCancelJobModalOpen = false
      await FieldsAPI.cancelMoveClientJob(this.selectedJobId).then(resp => {
        this.moveClientJobs = resp.data
      })
    },

    async handleCreateNewMoveClientTask() {
      this.confirmCreateJobModalOpen = false
      await FieldsAPI.createMoveClientTask(this.createJobPayload)
        .then(resp => {
          this.moveClientJobs = resp.data
          this.showCreateNewMoveClientTaskModal = false
        })
        .catch(error => {
          this.modalTitleText = "Failed To Create A New Move Client Job"
          this.errorMessageForModal = [error.response.data.message]
          this.alertErrorModalOpen = true
        })
    },

    async fetchMoveClientJobs() {
      await FieldsAPI.getMoveClientTasks().then(resp => {
        this.moveClientJobs = resp.data
        this.moveClientJobs.forEach(job => {
          // If the job is pending, add the client to the pending list
          if (!job.failed_at && !job.completed_at && !job.started_at) {
            if (!this.pendingClientJobs.includes(job.client.id)) {
              this.pendingClientJobs.push(job.client.id)
            }
          }
        })
      })
    },

    async fetchTargetCorpOptions() {
      await FieldsAPI.fetchTargetCorpOptions().then(({ data }) => {
        this.targetCorpOptions = data
      })
    },
  },

  async mounted() {
    await this.fetchMoveClientJobs()
    this.fetchIntervalID = setIntervalAsync(this.fetchMoveClientJobs, 10000)
  },

  destroyed() {
    if (this.fetchIntervalID) clearIntervalAsync(this.fetchIntervalID)
  },
}
</script>

<style scoped></style>
