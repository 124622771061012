import store from "@/store"
import { Uploads } from "@/store/modules"
import API from "./API"

const endpoint = "/uploads/"
const uploadsByFieldEndpoint = "/uploads-by-field/"

const get = payload =>
  API.get(endpoint + "?page=" + payload.currentPage, {
    params: payload,
  })

const getByField = payload =>
  API.post(
    `${uploadsByFieldEndpoint}fetch_uploads/` + "?page=" + payload.currentPage,
    payload
  )

const post = payload => {
  return API.post(`${endpoint}`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress(progressEvent) {
      const percentComplete = parseInt(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      )
      store.commit(Uploads.Mutations.setUploadPercentage, percentComplete)
    },
  })
}

const updateBoundary = payload => {
  return API.post(`${endpoint}update_boundary/`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress(progressEvent) {
      const percentComplete = parseInt(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      )
      store.commit(Uploads.Mutations.setUploadPercentage, percentComplete)
    },
  })
}

const updateRanchBoundary = payload => {
  return API.post(`${endpoint}update_ranching_boundary/`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress(progressEvent) {
      const percentComplete = parseInt(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      )
      store.commit(Uploads.Mutations.setUploadPercentage, percentComplete)
    },
  })
}

const uploadEvidencing = payload => {
  return API.post(`${endpoint}upload_evidencing/`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress(progressEvent) {
      const percentComplete = parseInt(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      )
      store.commit(Uploads.Mutations.setUploadPercentage, percentComplete)
    },
  })
}

const updateRanching = payload => {
  return API.post(`${endpoint}update_ranching/`, payload)
}

const deleteUpload = uploadId => API.delete(`${endpoint}${uploadId}/`)

const downloadDataSet = uploadByFieldId =>
  API.post(`${uploadsByFieldEndpoint}${uploadByFieldId}/get_signed_url/`)

export default {
  get,
  getByField,
  post,
  updateRanching,
  updateBoundary,
  updateRanchBoundary,
  deleteUpload,
  downloadDataSet,
  uploadEvidencing,
}
