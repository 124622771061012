<template>
  <b-tr>
    <!-- date -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(cropProtection.date)"
    >
      <v-menu
        v-model="showDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="date"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="
                  cropProtection.date.source !== 'User Input' &&
                  cropProtection.date.value !== null
                    ? 'mdi-check-circle-outline'
                    : ''
                "
                @click:append="handleClickSubmit('date', date)"
              ></v-text-field>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="date"
          :min="year + '-01-01'"
          :max="year + '-12-31'"
          @input="showDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- method -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(cropProtection.method)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="methodChoices"
        item-text="display_name"
        item-value="value"
        v-model="method"
        :append-icon="
          cropProtection.method.source !== 'User Input' &&
          cropProtection.method.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('method', method)"
      />
    </b-td>
    <!-- type -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(cropProtection.type)"
    >
      <PracticeChoiceDropdown
        clearable
        dense
        hide-details="true"
        :items="typesByMethod(method, cpType)"
        item-text="display_name"
        item-value="value"
        v-model="cpType"
        choiceName="crop_protection_type"
        :append-icon="
          cropProtection.type.source !== 'User Input' &&
          cropProtection.type.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('cpType', cpType)"
      />
    </b-td>
    <!-- product -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(cropProtection.product)"
    >
      <v-combobox
        clearable
        dense
        v-model="product"
        hide-details="true"
        :items="cropProtectionChoices(cropProtection.method.value, cropProtection.type.value)"
        :append-icon="
          cropProtection.product.source !== 'User Input' &&
          cropProtection.product.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('product', product)"
      >
      </v-combobox>
    </b-td>
    <!-- rate -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(cropProtection.rate)"
    >
      <v-text-field
        clearable
        dense
        v-model="rate"
        hide-details="true"
        :append-icon="
          cropProtection.rate.source !== 'User Input' &&
          cropProtection.rate.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('rate', rate)"
      >
      </v-text-field>
    </b-td>
    <!-- unit -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(cropProtection.unit)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="unitsByMethod(cropProtection.method.value)"
        item-text="display_name"
        item-value="value"
        v-model="unit"
        :append-icon="
          cropProtection.unit.source !== 'User Input' &&
          cropProtection.unit.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('unit', unit)"
      />
    </b-td>
    <!-- <b-td
      class="cell-table medium-column"
      :class="getClassForCell(cropProtection.ipm)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="yesNoOptions"
        item-text="display_name"
        item-value="value"
        v-model="ipm"
        :append-icon="
          cropProtection.ipm?.source !== 'User Input' &&
          cropProtection.ipm?.value
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('ipm', ipm)"
      />
    </b-td>
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(cropProtection.iwm)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="yesNoOptions"
        item-text="display_name"
        item-value="value"
        v-model="iwm"
        :append-icon="
          cropProtection.iwm?.source !== 'User Input' &&
          cropProtection.iwm?.value
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('iwm', iwm)"
      />
    </b-td>
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(cropProtection.idm)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="yesNoOptions"
        item-text="display_name"
        item-value="value"
        v-model="idm"
        :append-icon="
          cropProtection.idm?.source !== 'User Input' &&
          cropProtection.idm?.value
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('idm', idm)"
      />
    </b-td> -->
    <b-td class="cell-table white">
      <v-btn icon x-small color="black" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn icon x-small color="black" @click="handleDelete">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>.
    </b-td>
  </b-tr>
</template>
<script>
import { mapMutations, mapGetters } from "vuex"
import { YES_NO_OPTIONS } from "@/constants"
import { Defaults } from "@/store/modules"
import { getClassForCell } from "../utils"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"
import { cropProtectionChoices } from "@/constants/defaults"

export default {
  name: "RanchCropProtectionRow",
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    cropProtection: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
  },
  components: {
    PracticeChoiceDropdown,
  },
  data() {
    return {
      showDate: false,
      getClassForCell,
      yesNoOptions: YES_NO_OPTIONS,
      yesNoMapper: { Yes: true, No: false },
    }
  },
  computed: {
    ...mapGetters({
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    methodChoices() {
      return this.practiceChoices["pasture_crop_protection_method"]
    },
    typeChoices() {
      return this.practiceChoices["pasture_crop_protection_type"]
    },
    unitChoices() {
      return this.practiceChoices["crop_protection_units"]
    },
    date: {
      get() {
        if ("date" in this.cropProtection) {
          const dateReturn = this.cropProtection.date.value
            ? this.cropProtection.date.value
            : `${this.year}-01-01`
          return dateReturn
        } else {
          return ""
        }
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "crop_protection",
          dataSetting: "date",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    method: {
      get() {
        return this.cropProtection.method.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "crop_protection",
          dataSetting: "method",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    product: {
      get() {
        return this.cropProtection.product.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "string")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "crop_protection",
            dataSetting: "product",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    rate: {
      get() {
        return this.cropProtection.rate.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "crop_protection",
            dataSetting: "rate",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    cpType: {
      get() {
        return this.cropProtection.type.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "crop_protection",
          dataSetting: "type",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    unit: {
      get() {
        return this.cropProtection.unit.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "crop_protection",
          dataSetting: "unit",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    ipm: {
      get() {
        if (!this.cropProtection.ipm) {
          return null
        }
        if (typeof this.cropProtection.ipm.value == "string") {
          return this.yesNoMapper[this.cropProtection.ipm.value]
        }

        return this.cropProtection.ipm.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "crop_protection",
          dataSetting: "ipm",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    iwm: {
      get() {
        if (!this.cropProtection.iwm) {
          return null
        }
        if (typeof this.cropProtection.iwm.value == "string") {
          return this.yesNoMapper[this.cropProtection.iwm.value]
        }

        return this.cropProtection.iwm.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "crop_protection",
          dataSetting: "iwm",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    idm: {
      get() {
        if (!this.cropProtection.idm) {
          return null
        }
        if (typeof this.cropProtection.idm.value == "string") {
          return this.yesNoMapper[this.cropProtection.idm.value]
        }

        return this.cropProtection.idm.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "crop_protection",
          dataSetting: "idm",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    typesByMethod(method, existingType) {
      if (["IPM", "IWM", "IDM"].includes(method)) {
        return this.typeChoices.filter(
          c => c.dependent_value === method || c.value === "other"
        )
      }
      return this.typeChoices.filter(
          c => c.dependent_value === method || !c.dependent_value
      )
    },
    unitsByMethod(method) {
      return this.unitChoices.filter(
        c => c.dependent_value === method || !c.dependent_value
      )
    },
    cropProtectionChoices(method, type) {
      const choices = cropProtectionChoices(method, type)
      return choices
    },
    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.cropProtection)
    },
    handleAdd() {
      const payloadObj = {
        crop_id: { source: "User Input", value: this.cropId },
        date: { source: "User Input", value: null },
        method: { source: "User Input", value: null },
        product: { source: "User Input", value: null },
        rate: { source: "User Input", value: null },
        type: { source: "User Input", value: null },
        unit: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "crop_protection",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.cropProtection)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "crop_protection",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleValidateValue(val, type, length = 24) {
      function isNumeric(val) {
        return !isNaN(val) && !isNaN(parseFloat(val));
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      }
    },
    filter(event) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()

      let validVal = this.handleValidateValue(expect, "number", 24)
      if (!validVal) event.preventDefault()
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.cell-table {
  padding: 2px 6px;
}

.pre-gen {
  background-color: #fedb80;
}

.bg-white {
  color: white;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.machine {
  background-color: #c0dce2;
}

.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.v-input {
  font-size: 18px;
}
</style>
