<template>
  <div>
    <EvidencingGroupMetadataEditingSidebar
      :visible="showMetadataEditingSidebar"
      :detailSpec="sidebarMetadataEditingSpecification"
      @closeSidebar="handleMetadataSidebarClose"
    />

    <EvidencingGroupFieldEditingSidebar
      :visible="showFieldEditingSidebar"
      :detailSpec="fieldEditingSidebarSpecification"
      :groupId="groupMetadataSpecification['id']"
      @closeSidebar="handleFieldEditingSidebarClose"
    />

    <div>
      <h1 class="evidencing-title">Edit Evidencing Group</h1>
      <h2 v-if="!loadingGroupFetch" class="evidencing-subtitle">{{ `${groupMetadataSpecification['name']} (Group ID: ${groupMetadataSpecification['id']})` }}</h2>
    </div>

    <v-progress-circular v-if="loadingGroupFetch" :size="48" color="#79c61c" indeterminate />
    <div v-else>  
      <div class="field-configuration-wrapper">
        <div class="fields-display">
          <div class="group-action-btns">
            <v-btn @click="handleMetadataSidebarOpen" outlined>Edit Group Metadata</v-btn>
            <v-btn @click="handleFieldEditingSidebarOpen" outlined>Configure Fields/Crops</v-btn>
            <!-- <v-btn @click="" outlined>Configure Evidencing</v-btn> -->
          </div>

          <div class="group-editor-explainer">
            <p>All fields/crops associated with this group</p>
          </div>

          <div class="table-and-notes">
            <v-simple-table>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>ID #</th>
                  <th>Crops</th>
                  <th>Evidencing Count</th>
                  <th class="dates-cell">Planting Dates</th>
                  <th class="dates-cell">Deadlines</th>
                </tr>
              </thead>
  
              <tbody>
                <tr v-for="fieldSpec, idx in fieldCropSpecification" :key="fieldSpec['name'] + '-' + idx">
                  <td>{{ fieldSpec['name'] }}</td>
                  <td>{{ fieldSpec['id'] }}</td>
                  <td class="crop-cell">{{ getFormattedCrops(fieldSpec['crops']) }}</td>
                  <td>{{ fieldSpec['evidencing'].length }}</td>
                  <td>{{ fieldSpec['plantings'].join('\n') || 'None' }}</td>
                  <td>{{ fieldSpec['deadlines'].join('\n') || 'None' }}</td>
                </tr>
              </tbody>
            </v-simple-table>

            <div class="notes">
              <h4>Notes</h4>

              <p v-if="!groupMetadataSpecification['notes'] || groupMetadataSpecification['notes'].length == 0">No notes.</p>
              <p class="notes-display" v-else>{{ groupMetadataSpecification['notes'] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import EvidencingGroupMetadataEditingSidebar from "@/components/evidencing/EvidencingGroupMetadataEditingSidebar.vue";
import EvidencingGroupFieldEditingSidebar from "@/components/evidencing/EvidencingGroupFieldEditingSidebar.vue";
import { mapState } from "vuex";
import { fetchGroupData, fetchPastureCrops } from "@/api/EvidencingAPI";
import { IS_RANCHFORCE } from "@/constants";

export default {
  name: "EvidencingGroupEditing",
  components: { EvidencingGroupMetadataEditingSidebar, EvidencingGroupFieldEditingSidebar },
  data() {
    return {
      initialRenderError: '',
      fieldCropSpecification: {},
      groupMetadataSpecification: {},
      sidebarMetadataEditingSpecification: {},
      fieldEditingSidebarSpecification: {},
      loadingGroupFetch: false,
      showMetadataEditingSidebar: false,
      showFieldEditingSidebar: false,
      isRanchForce: IS_RANCHFORCE,
    }
  },
  computed: {
    ...mapState({
      user: state => state.User.user,
      year: state => state.Organization.year
    }),
    isSuperUser() {
      if (this.user != null) {
        return this.user['is_superuser'];
      }
      
      return false;
    },
  },
  methods: {
    handleMetadataSidebarOpen() {
      this.sidebarMetadataEditingSpecification = { ...this.groupMetadataSpecification };
      this.showMetadataEditingSidebar = true;
    },
    handleFieldEditingSidebarOpen() {
      const newSidebarSpec = {};

      for (const fieldId in this.fieldCropSpecification) {
        newSidebarSpec[fieldId] = {
          id: fieldId,
          name: this.fieldCropSpecification[fieldId]['name'],
          crops: this.fieldCropSpecification[fieldId]['crops'],
          plantings: this.fieldCropSpecification[fieldId]['plantings'],
          deadlines: this.fieldCropSpecification[fieldId]['deadlines']
        }
      }

      this.fieldEditingSidebarSpecification = newSidebarSpec;
      this.showFieldEditingSidebar = true;
    },
    handleFieldEditingSidebarClose(cancelled) {
      if (!cancelled) {
        this.getGroupData();
      }

      this.fieldEditingSidebarSpecification = {};
      this.showFieldEditingSidebar = false;
    },
    handleMetadataSidebarClose(cancelled, modifiedConfiguration) {
      if (!cancelled) {
        const newGroupSpecification = { ...this.groupMetadataSpecification };

        for (const key in modifiedConfiguration) {
          newGroupSpecification[key] = modifiedConfiguration[key]
        }

        Vue.set(this, 'groupMetadataSpecification', newGroupSpecification);
      }

      this.sidebarMetadataEditingSpecification = {};
      this.showMetadataEditingSidebar = false;
    },
    getFormattedCrops(crops) {
      return crops.map(({ name }) => name).join('\n');
    },
    getGroupData() {
      const groupId = this.$route.params['groupId'];
      this.loadingGroupFetch = true;

      if (groupId == null) {
        this.initialRenderError = "No group id provided!";
        this.loadingGroupFetch = false;
      }
      else {
        // fetch the group data, format it from evidencing list to fieldcrop-based evidencing
        const fetchGroupDataAPI = this.isRanchForce ? fetchPastureCrops : fetchGroupData;
        fetchGroupDataAPI({ groupId, year: this.year })
        .then(response => {
          console.log("dat!", response);
          if (response['data'] != null) {
            const { client, confirmations, id, name, notes } = response['data'];
  
            const newFieldCropSpec = {};
  
            for (const evidencing of confirmations) {
              for (const field of evidencing['fields']) {
                if (!(field['id'] in newFieldCropSpec)) {
                  newFieldCropSpec[field['id']] = {
                    id: field['id'],
                    name: field['name'],
                    evidencing: [evidencing],
                    deadlines: evidencing['deadline'] != null ? [evidencing['deadline']] : [],
                    crops: [],
                    plantings: [],
                  }
                }
                else {
                  newFieldCropSpec[field['id']]['evidencing'].push(evidencing)

                  if (evidencing['deadline'] != null && !newFieldCropSpec[field['id']]['deadlines'].includes(evidencing['deadline'])) {
                    newFieldCropSpec[field['id']]['deadlines'].push(evidencing['deadline'])
                  }
                }
  
                for (const crop of evidencing['crops']) {
                  const foundCrop = newFieldCropSpec[field['id']]['crops'].find(({ id }) => id == crop['id']);
                  if (foundCrop == null) {
                    newFieldCropSpec[field['id']]['crops'].push(crop);
                    newFieldCropSpec[field['id']]['plantings'].push(...crop['plantings']);
                  }
                  else {
                    for (const plantingDate of crop['plantings']) {
                      if (!newFieldCropSpec[field['id']]['plantings'].includes(plantingDate)) {
                        newFieldCropSpec[field['id']]['plantings'].push(plantingDate)
                      }
                    }
                  }
                }
              }
            }
  
            Vue.set(this, 'groupMetadataSpecification', { id, name, client, notes });
            Vue.set(this, 'fieldCropSpecification', newFieldCropSpec);
            console.log('group metadata', this.groupMetadataSpecification);
            console.log('field crop spec', this.fieldCropSpecification);
          }
          else {
            // error state?
          }

          this.loadingGroupFetch = false;
        })
        .catch(err => {
          this.loadingGroupFetch = false;
          // error state
        });
      }
    }
  },
  mounted() {
    this.getGroupData();
  },
  watch: {
    isSuperUser: {
      handler(curr) {
        if (curr != null) {
          if (!curr) {
            // if the user isn't a superuser, reroute them
            location.href = "/evidencing";
          }
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped>
.evidencing-title,
.evidencing-subtitle {
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
  margin: 0 0 8px;
  color: #000000;
}
.evidencing-subtitle {
  font-weight: normal;
}
.field-configuration-wrapper {
  display: flex;
  justify-content: flex-start;
}
.fields-display {
  width: 100%;
}
.crop-cell {
  text-transform: capitalize;
}
.group-action-btns {
  margin: 16px 0 24px;
}
.group-action-btns > button {
  margin-right: 16px;
}
.group-editor-explainer {
  margin: 16px 0 8px;
}
.group-editor-explainer p {
  margin: 0;
  color: #000000;
  font-size: 16px;
  line-height: 1.5;
}
.table-and-notes {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.table-and-notes .v-data-table {
  width: 55%;
}
.table-and-notes .notes {
  width: 35%;
  margin-left: 16px;
  padding: 24px;
  background: #FFFFFF;
  border-radius: 5px;
}
.notes-display {
  color: #000000;
  font-size: 16px;
  line-height: 1.5;
}
</style>