<template>
    <tr>
      <td>
        <input
          @click="checkboxClicked"
          type="checkbox"
          name="selected"
          ref="fieldSelectedCheckbox"
        />
      </td>
      <td>{{ fieldSpec['group']['name'] || '-' }}</td>
      <td>{{ fieldSpec['group']['code'] || '-' }}</td>
      <td>{{ fieldSpec['farm']['name'] || '-' }}</td>
      <td>{{ fieldSpec['field_id'] || '-' }}</td>
      <td>{{ fieldSpec['client'] && fieldSpec['client']['name'] ? fieldSpec['client']['name'] : '-' }}</td>
      <td>{{ fieldSpec['grazings_start_date'] || '-' }}</td>
      <td>{{ fieldSpec['grazings_end_date'] || '-' }}</td>
      <td>{{ fieldSpec['livestock_species'] || '-' }}</td>
      <td>{{ fieldSpec['feed_additive'] || '-' }}</td>
      <td>{{ fieldSpec['due_date'] || '-' }}</td>
    </tr>
  </template>
  
  <script>
  
  export default {
    name: "EvidencingListRow",
  
    props: [
      "fieldSpec",
    ],
  
    data() {
      return {
      }
    },
  
    methods: {
      sendCheckEvents(checkbox) {
        if (checkbox.checked) this.$emit("checked", this.fieldSpec.id)
        else this.$emit("unchecked", this.fieldSpec.id)
      },
  
      checkboxClicked() {
        const checkbox = this.$refs.fieldSelectedCheckbox
        this.sendCheckEvents(checkbox)
      },
  
      setCheckbox(checked) {
        const checkbox = this.$refs.fieldSelectedCheckbox
        checkbox.checked = checked
        this.sendCheckEvents(checkbox)
      },
    },
  }
  </script>
  
  <style scoped>
  .data-type {
    width: 85px;
  }
  
  .defaults-type {
    width: 130px;
  }
  
  .pct {
    font-weight: 500;
  }
  
  .default-name {
    color: #1979f1;
    font-weight: bold;
  }
  
  .default-name:hover {
    color: #333;
    cursor: pointer;
  }
  
  .pre-confidence-cell {
    width: 60px;
  }
  
  .confidence-box {
    display: flex;
    align-items: center;
  }
  
  .check {
    margin-right: 5px;
  }
  
  .confident-check-green {
    color: green;
  }
  
  .confident-check-yellow {
    color: orange;
  }
  
  .confident-check-red {
    color: red;
  }
  
  .data-import {
    font-weight: 500;
    font-size: 12px;
    flex: 1;
    text-align: right;
  }
  </style>
  