<template>
  <b-tr>
    <!-- expected daily dmi -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(feed.expected_daily_dmi)"
    >
      <v-text-field
        clearable
        dense
        v-model="expectedDailyDMI"
        hide-details="true"
        :append-icon="
          feed.expected_daily_dmi.source !== 'User Input' &&
          feed.expected_daily_dmi.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('expectedDailyDMI', expectedDailyDMI)"
      >
      </v-text-field>
    </b-td>
    <!-- dmi unit -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(feed.dmi_unit)"
    >
      <PracticeChoiceDropdown
        clearable
        dense
        hide-details="true"
        :items="dmiUnitsChoices"
        item-text="display_name"
        item-value="value"
        v-model="dmiUnit"
        choiceName="pasture_feed_units"
        :append-icon="
          feed.dmi_unit.source !== 'User Input' &&
          feed.dmi_unit.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('dmiUnit', dmiUnit)"
      />
    </b-td>
    <!-- additive -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(feed.additive)"
    >
      <PracticeChoiceDropdown
        clearable
        dense
        hide-details="true"
        :items="additiveChoices"
        item-text="display_name"
        item-value="value"
        v-model="additive"
        choiceName="pasture_feed_additive"
        :append-icon="
          feed.additive.source !== 'User Input' &&
          feed.additive.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('additive', additive)"
      />
    </b-td>
    <!-- method -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(feed.method)"
    >
      <PracticeChoiceDropdown
        clearable
        dense
        hide-details="true"
        :items="methodChoices"
        item-text="display_name"
        item-value="value"
        v-model="method"
        choiceName="pasture_feed_method"
        :append-icon="
          feed.method.source !== 'User Input' &&
          feed.method.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('method', method)"
      />
    </b-td>
    <!-- amount -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(feed.amount)"
    >
      <v-text-field
        clearable
        dense
        v-model="amount"
        hide-details="true"
        :append-icon="
          feed.amount.source !== 'User Input' &&
          feed.amount.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('amount', amount)"
      >
      </v-text-field>
    </b-td>
    <!-- unit -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(feed.unit)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="unitsChoices"
        item-text="display_name"
        item-value="value"
        v-model="unit"
        :append-icon="
          feed.unit.source !== 'User Input' &&
          feed.unit.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('unit', unit)"
      />
    </b-td>
    <!-- day on additive -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(feed.days_on_additive)"
    >
      <v-text-field
        clearable
        dense
        v-model="days_on_additive"
        hide-details="true"
        :append-icon="
          feed.days_on_additive.source !== 'User Input' &&
          feed.days_on_additive.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('days_on_additive', days_on_additive)"
      >
      </v-text-field>
    </b-td>
    <b-td class="cell-table white">
      <v-btn icon x-small color="black" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn icon x-small color="black" @click="handleDelete">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>.
    </b-td>
  </b-tr>
</template>
<script>
import { mapMutations, mapGetters } from "vuex"
import { Defaults } from "@/store/modules"
import { getClassForCell } from "../utils"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"

export default {
  name: "RanchfeedRow",
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    feed: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
  },
  components: {
    PracticeChoiceDropdown,
  },
  data() {
    return {
      getClassForCell,
    }
  },
  computed: {
    ...mapGetters({
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    dmiUnitsChoices() {
      return this.practiceChoices["pasture_feed_dmi_units"]
    },
    additiveChoices() {
      return this.practiceChoices["pasture_feed_additive"]
    },
    methodChoices() {
      return this.practiceChoices["pasture_feed_method"]
    },
    unitsChoices() {
      return this.practiceChoices["pasture_feed_units"]
    },
    expectedDailyDMI: {
      get() {
        return this.feed.expected_daily_dmi.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "feed",
            dataSetting: "expected_daily_dmi",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    dmiUnit: {
      get() {
        return this.feed.dmi_unit.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "feed",
          dataSetting: "dmi_unit",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    additive: {
      get() {
        return this.feed.additive.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "feed",
          dataSetting: "additive",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    method: {
      get() {
        return this.feed.method.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "feed",
          dataSetting: "method",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    amount: {
      get() {
        return this.feed.amount.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "feed",
            dataSetting: "amount",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    unit: {
      get() {
        return this.feed.unit.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "feed",
          dataSetting: "unit",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    days_on_additive: {
      get() {
        return this.feed.days_on_additive.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "feed",
            dataSetting: "days_on_additive",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.feed)
    },
    handleAdd() {
      const payloadObj = {
        crop_id: { source: "User Input", value: this.cropId },
        expected_daily_dmi: { source: "User Input", value: null },
        dmi_unit: { source: "User Input", value: null },
        additive: { source: "User Input", value: null },
        method: { source: "User Input", value: null },
        amount: { source: "User Input", value: null },
        unit: { source: "User Input", value: null },
        days_on_additive: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "feed",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.feed)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "feed",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleValidateValue(val, type, length = 24) {
      function isNumeric(val) {
        return !isNaN(val) && !isNaN(parseFloat(val));
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      }
    },
    filter(event) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()

      let validVal = this.handleValidateValue(expect, "number", 24)
      if (!validVal) event.preventDefault()
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.cell-table {
  padding: 2px 6px;
}

.pre-gen {
  background-color: #fedb80;
}

.bg-white {
  color: white;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.machine {
  background-color: #c0dce2;
}

.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.v-input {
  font-size: 18px;
}
</style>
