import API from "./API"

// const endpoint = "/users-pasture/"
const endpoint = "/users-pasture/"
const assigned_org_endpoint = "/assigned_org/"
const org_sharing_endpoint = "/org_sharing/"

const fetchUser = () => API.get(endpoint)

// const updateUser = user => API.put(`${endpoint}${user.id}/`, user)

// const updatePassword = (userId, password) =>
//   API.put(`${endpoint}${userId}/update_password/`, { password })

// const displayWelcomeModal = (userId, displayWelcomeFlag) =>
//   API.put(`${endpoint}${userId}/display_welcome_modal/`, { displayWelcomeFlag })

// const batchSuspendUsers = payload =>
//   API.put(`${endpoint}batch_suspend_users/`, payload)

// const getOrgOptions = payload =>
//   API.post(`${endpoint}get_org_options/`, payload)

// const getOrgOptionsByMember = payload =>
//   API.post(`${endpoint}get_org_options_by_member/`, payload)

// const getToSectionOptions = payload =>
//   API.post(`${endpoint}get_to_section_options/`, payload)

// const editUser = payload => API.put(`${endpoint}edit_user/`, payload)

// const addNewUser = payload => API.put(`${endpoint}add_new_user/`, payload)

// const addAssignedOrg = payload => API.post(`${assigned_org_endpoint}`, payload)

// const updateAssignedOrg = payload =>
//   API.put(
//     `${assigned_org_endpoint}${payload.selected_assigned_org_id}/`,
//     payload
//   )

// const removeAssignedOrg = payload =>
//   API.delete(`${assigned_org_endpoint}${payload.assigned_org.id}`, payload)

// const getAssignedOrgByUser = payload =>
//   API.post(`${endpoint}get_assigned_org_by_user/`, payload)

// const getSharedOrgByUser = payload =>
//   API.post(`${endpoint}get_shared_org_by_user/`, payload)

// const switchOrg = payload => API.post(`${endpoint}switch_org/`, payload)

// const createNewDataSharingRequest = payload =>
//   API.put(`${org_sharing_endpoint}create_new_data_sharing_request/`, payload)

// const editSharingTransaction = payload =>
//   API.put(`${org_sharing_endpoint}${payload.id}/`, payload)

// const approveSharingTransaction = payload =>
//   API.put(`${org_sharing_endpoint}approve_sharing_transaction/`, payload)

// const closeSharingTransaction = payload =>
//   API.post(`${org_sharing_endpoint}close_sharing_transaction/`, payload)

// const getDataSharingRequest = () =>
//   API.post(`${org_sharing_endpoint}get_data_sharing_requests/`)

// const getFieldToShare = payload =>
//   API.post(`${org_sharing_endpoint}get_field_to_share/`, payload)

// const updateFieldToShare = payload =>
//   API.put(`${org_sharing_endpoint}update_field_to_share/`, payload)

// const fetchAllUserContacts = user_id => API.post(`${endpoint}get_contactable_users/`, user_id)

export default {
  fetchUser,
  // updateUser,
  // updatePassword,
  // displayWelcomeModal,
  // batchSuspendUsers,
  // getOrgOptions,
  // getOrgOptionsByMember,
  // getToSectionOptions,
  // editUser,
  // addNewUser,
  // getAssignedOrgByUser,
  // addAssignedOrg,
  // updateAssignedOrg,
  // removeAssignedOrg,
  // switchOrg,
  // createNewDataSharingRequest,
  // editSharingTransaction,
  // approveSharingTransaction,
  // closeSharingTransaction,
  // getDataSharingRequest,
  // getFieldToShare,
  // updateFieldToShare,
  // getSharedOrgByUser,
  // fetchAllUserContacts
}
