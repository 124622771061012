<template>
  <li
    :class="{
      'disabled pasture-dropdown': isRanchForce,
      'disabled': !isRanchForce
    }"
    @mouseover="mouseOver = true"
    @mouseout="mouseOver = false"
    @mouseleave="debounceMouseLeave"
  >
    <CountBadge :count="countBadgeVal" />
    <a
      @click="handleHeaderClick"
      :class="navItemStyle"
      href="javascript:void(0)"
    >
      {{ displayName }}
      <i v-if="open" class="fa fa-angle-up ml-1"></i>
      <i v-else class="fa fa-angle-down ml-1"></i>
    </a>
    <div v-if="chunked">
      <div v-if="open">
        <div class="chunked-dropdown-body">
          <div class="box-row">
            <div>
              <NavLink
                :label="'Select All'"
                :color="'#0f9aee'"
                @navlink-signal="handleSelectAll"
              />
              <NavLink
                :label="'Clear All'"
                :color="'#0f9aee'"
                @navlink-signal="handleClearAll"
              />
            </div>
          </div>
          <div class="box-row">
            <SearchBar :searchString="searchString" @searching="handleSearch" />
          </div>
          <div class="box-row">
            <div
              v-for="(chunk, chunkedIndex) in chunkedFilteredItems"
              :key="chunkedIndex"
              class="box-column"
            >
              <DropDownItem
                v-for="item in chunk"
                :key="item.id"
                :item="item"
                :active="item.selected"
                @handle-item-click="handleItemClick"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="open">
        <div class="dropdown-body">
          <div class="mb-3">
            <div>
              <NavLink
              :label="'Select All'"
              :color="'#0f9aee'"
              @navlink-signal="handleSelectAll"
              />
              <NavLink
                :label="'Clear All'"
                :color="'#0f9aee'"
                @navlink-signal="handleClearAll"
              />
            </div>
          </div>
          <div class="mb-2">
            <SearchBar :searchString="searchString" @searching="handleSearch" />
          </div>
          <DropDownItem
            v-for="item in searchFilteredItems"
            :key="item.id"
            :item="item"
            :active="item.selected"
            @handle-item-click="handleItemClick"
          />
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import _ from "lodash"
import { mapMutations } from "vuex"

import CountBadge from "@/components/badges/CountBadge"
import SearchBar from "@/components/misc/SearchBar"
import NavLink from "@/components/layout/NavLink"
import DropDownItem from "./DropDownItem"
import { Filter } from "@/store/modules"
import { DROPDOWN, IS_RANCHFORCE } from "@/constants"

export default {
  name: "FilterDropDown",
  components: {
    CountBadge,
    SearchBar,
    DropDownItem,
    NavLink,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    displayName: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    dropdownType: {
      type: String,
      required: true,
    },
    chunked: {
      type: Boolean,
      default: false,
      required: false,
    },
    countBadgeVal: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      searchString: "",
      mouseOver: false,
      isRanchForce: IS_RANCHFORCE,
    }
  },

  computed: {
    navItemStyle() {
      if (this.dropdownType === DROPDOWN.Crop && this.items.length === 0) {
        return "nav-link dropdown-toggle disabled-color disabled"
      }

      return "nav-link dropdown-toggle"
    },

    searchFilteredItems() {
      if (!this.searchString) return this.items
      const searchString = this.searchString.toLocaleLowerCase()
      return this.items.filter(({ name }) =>
        name.toLowerCase().includes(searchString)
      )
    },

    chunkedFilteredItems() {
      return _.chunk(this.searchFilteredItems, 10)
    },
  },

  watch: {
    visible() {
      this.searchString = ""
    },
  },

  methods: {
    ...mapMutations({
      selectAllOfType: Filter.Mutations.selectAllOfType,
      clearAllOfType: Filter.Mutations.clearAllOfType,
      setClosed: Filter.Mutations.setClosed,
      toggleItem: Filter.Mutations.toggleItem,
      toggleOpen: Filter.Mutations.toggleOpen,
    }),

    debounceMouseLeave: _.debounce(function() {
      if (!this.mouseOver) {
        this.setClosed(this.dropdownType)
        this.searchString = ""
      }
    }, 500),

    handleSelectAll() {
      this.selectAllOfType(this.dropdownType)
      this.searchString = ""
    },

    handleClearAll() {
      this.clearAllOfType(this.dropdownType)
      this.searchString = ""
    },

    handleSearch(searchString) {
      this.searchString = searchString
    },

    handleHeaderClick() {
      this.toggleOpen(this.dropdownType)
    },

    handleItemClick({ id }) {
      this.toggleItem({ id, dropdownType: this.dropdownType })
    },
  },
}
</script>

<style scoped>
.box {
  height: 1em;
  width: 15em;
  margin: 0 0 auto 0;
  padding: 0.15em 0 1.5em 1.5em;
  float: left;
  color: black;
}

.box:hover {
  background: #f6f7fb;
}

.box:active {
  color: white;
  background: #007bff;
}

.box-column {
  height: 21.5em;
  width: 15em;
  margin: auto 0.5em auto 0.5em;
}

.box-row {
  display: flex;
  padding: 1em;
  overflow-x: auto;
}

.chunked-dropdown-body {
  position: absolute;
  top: 65px;
  left: -16px;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
  box-shadow: 0 0.75em 1em rgba(16, 8, 0, 0.5);
  padding: 0;
  padding-right: 20px;
}

.dropdown-body {
  position: absolute;
  background: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
  box-shadow: 0 0.75em 1em rgba(16, 8, 0, 0.5);
  padding: 1em;
  width: 26em;
  max-height: calc(100vh - 65px);
  overflow-y: auto;
}

.form-control + .glyphicon {
  position: absolute;
  right: 0;
  padding: 8px 27px;
}

.disabled-color {
  color: #d3d3d3 !important;
}

.pasture-dropdown {
  margin-right: 20px;
}
</style>
