<template>
  <tr>
    <td>
      <input
        @click="checkboxClicked"
        type="checkbox"
        name="selected"
        ref="fieldSelectedCheckbox"
      />
    </td>
    <td>{{ evdiencing['id'] }}</td>
    <td>{{ evdiencing['year'] }}</td>
    <td>{{ evdiencing['field_name'] }}</td>
    <td>{{ evdiencing['name'] }}</td>
    <td>{{ evdiencing['grazings_start_date'] }}</td>
    <td>{{ evdiencing['grazings_end_date'] }}</td>
    <td>{{ evdiencing['livestock_species'] }}</td>
    <td>{{ evdiencing['count_animals'] }}</td>
    <td>{{ evdiencing['feed_additive'] }}</td>
    <td>{{ evdiencing['due_date'] }}</td>
    <td class="progress-cell">
      <v-chip v-if="evdiencing['status']=='Completed'" color="deep-purple accent-4" outlined>
        {{ evdiencing['status'] }}
      </v-chip>
      <v-chip v-else class = 'status-unsubmitted'>
        {{ evdiencing['status'] }}
      </v-chip>
    </td>
  </tr>
</template>

<script>

export default {
  name: "EvidencingListAllRow",

  props: [
    "evdiencing",
  ],

  data() {
    return {
    }
  },

  methods: {
    sendCheckEvents(checkbox) {
      if (checkbox.checked) this.$emit("checked", this.evdiencing.idx)
      else this.$emit("unchecked", this.evdiencing.idx)
    },

    checkboxClicked() {
      const checkbox = this.$refs.fieldSelectedCheckbox
      this.sendCheckEvents(checkbox)
    },

    setCheckbox(checked) {
      const checkbox = this.$refs.fieldSelectedCheckbox
      checkbox.checked = checked
      this.sendCheckEvents(checkbox)
    },
  },
}
</script>

<style scoped>
.status-unsubmitted {
  background: rgb(255, 228, 204) !important;
  color: rgb(218, 84, 68);
  border: none
}

.data-type {
  width: 85px;
}

.defaults-type {
  width: 130px;
}

.pct {
  font-weight: 500;
}

.default-name {
  color: #1979f1;
  font-weight: bold;
}

.default-name:hover {
  color: #333;
  cursor: pointer;
}

.pre-confidence-cell {
  width: 60px;
}

.confidence-box {
  display: flex;
  align-items: center;
}

.check {
  margin-right: 5px;
}

.confident-check-green {
  color: green;
}

.confident-check-yellow {
  color: orange;
}

.confident-check-red {
  color: red;
}

.data-import {
  font-weight: 500;
  font-size: 12px;
  flex: 1;
  text-align: right;
}
</style>
