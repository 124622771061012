<template>
  <div class="signature-container">
    <canvas class="signature-pad" ref="signaturePad"></canvas>
    <v-icon @click="clearSignature" class="clear-icon">mdi-refresh</v-icon>
  </div>
</template>

<script>
import SignaturePad from "signature_pad"

export default {
  name: "SignatureModal",
  data() {
    return {
      signaturePad: null,
    }
  },
  mounted() {
    this.initializeSignaturePad()
    window.addEventListener("resize", this.resizeCanvas)
    this.$refs.signaturePad.addEventListener(
      "mouseleave",
      this.handlePointerLeave
    )
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeCanvas)
    this.$refs.signaturePad.removeEventListener(
      "mouseleave",
      this.handlePointerLeave
    )
    this.signaturePad.off()
  },
  methods: {
    initializeSignaturePad() {
      const canvas = this.$refs.signaturePad
      this.resizeCanvas()
      this.signaturePad = new SignaturePad(canvas)
      this.backgroundText(canvas)
    },
    resizeCanvas() {
      const canvas = this.$refs.signaturePad
      const ratio = Math.max(window.devicePixelRatio || 1, 1)
      canvas.width = canvas.offsetWidth * ratio
      canvas.height = canvas.offsetHeight * ratio
      canvas.getContext("2d").scale(ratio, ratio)
    },
    backgroundText(canvas) {
      const ctx = canvas.getContext("2d")
      ctx.font = "30px Arial"
      ctx.fillStyle = "rgba(0, 0, 0, 0.2)"
      ctx.textAlign = "center"
      ctx.textBaseline = "middle"
    },
    clearSignature() {
      this.signaturePad.clear()
      this.backgroundText(this.$refs.signaturePad)
      this.saveSignature()
    },
    saveSignature() {
      let signatureData = null
      if (!this.signaturePad.isEmpty()) {
        signatureData = this.signaturePad.toDataURL()
      }
      this.$emit("save-signature", signatureData)
    },
    handlePointerLeave() {
      this.saveSignature()
    },
  },
}
</script>

<style scoped>
.signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signature-pad {
  border: 1px solid #000;
  width: 100%;
  height: 200px;
}

.clear-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  color: red;
  font-size: 24px;
}
</style>
