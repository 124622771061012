<template>
  <span v-show="count > 0" :class="badgeClasses">{{ count }}</span>
</template>

<script>
import { IS_RANCHFORCE } from "@/constants";

export default {
  name: "CountBadge",
  props: {
    count: {
      type: Number,
    },
  },

  data() {
    return {
      isRanchForce: IS_RANCHFORCE,
    };
  },

  computed: {
    badgeClasses() {
      return {
        'badge': !this.isRanchForce,
        'badge-pasture': this.isRanchForce,
        'badge-pill': !this.isRanchForce,
        'badge-success': !this.isRanchForce,
        'badge-primary': !this.isRanchForce,
      };
    },
  },
};
</script>

<style scoped>
.badge {
  position: absolute;
  top: 10px;
}
.badge-pasture {
  background: green;
  color: white;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 5px;
  margin-top: 21px;
  position: absolute;
  margin-left: -17px;
}
</style>
