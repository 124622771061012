import API from "./API"

const resource = "/dataset-types/"
const resourcePasture = "/pasture-dataset-types/"

const get = () => API.get(`${resource}`)
const getPasture = () => API.get(`${resourcePasture}`)

export default {
    get,
    getPasture
}
