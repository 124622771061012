<template>
    <modal
      :name="name"
      :scrollable="true"
      :clickToClose="false"
      height="auto"
      styles="overflow:visible"
      :width='600'
      class="topz"
    >
      <div class="row header-row">
        <v-progress-circular
          class="mt-4 saving-circle"
          :size="100"
          :width="8"
          color="green"
          indeterminate
        >
        </v-progress-circular>
        <div class="saving-entry-text">
          <h3>Saving your entry information</h3>
        </div>
      </div>
      <slot />
    </modal>
  </template>
  
  <script>
  export default {
    name: "ProgressModal",
  
    data() {
      return {
        name: "progressModal"
      }
    },
  
    methods: {
      show() {
        this.$modal.show(this.name)
      },
  
      hide() {
        this.$modal.hide(this.name)
        this.$emit("close-modal")
      },
    },
  
    mounted() {
      this.$modal.show(this.name)
    },
  }
  </script>
  
  <style scoped>
  .close {
    position: absolute;
    top: 15px;
    right: 40px;
    font-size: 32px;
    cursor: pointer;
  }
  
  .header-row {
    position: relative;
    border-bottom: 1px solid #e9ecef;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .title-col {
    padding: 0px 10px;
  }
  
  .modal-title {
    padding: 15px;
    font-size: 19px;
    font-weight: 500;
  }
  
  .wide {
    width: 1000px;
  }
  .topz {
    z-index: 9999999999999999999999999999999;
  }
  
  .saving-entry-text {
    margin-top: 140px;
    margin-left: 60px;
  }
  
  .saving-circle {
    left: 245px;
  }
  </style>
  
  