<template>
  <div
    v-if="!sidePanelOpen"
    class="layer-controller-toggle"
    @mouseover="sidePanelOpen = true"
  >
    <a @click.prevent="sidePanelOpen = true" href="javascript:void(0);">
      <div class="hamburger">
        <div />
        <div />
        <div />
      </div>
    </a>
  </div>
  <div
    v-else
    @mouseleave="debounceMouseLeave"
    @mouseover="mouseOverSidePanel = true"
    @mouseout="mouseOverSidePanel = false"
    class="layer-controller"
  >
    <div class="px-5 py-3">
      <div v-if="!layers">
        <h5>Layers unavailable</h5>
      </div>
      <div v-else>
        <div class="crop-flex-container">
          <div class="full-width d-flex">
            <div class="w-50">
              <span class="layer-name">Include Field Level Info</span>
              <div class="toggle-checkbox toggle-sm toggle-success mr-2 d-flex">
                <input
                  type="checkbox"
                  v-model="includeDefaults"
                  id="includeDefaults"
                  name="includeDefaults"
                  class="checked mx-3"
                />
                <label for="includeDefaults" class="form-check-label" />
              </div>
            </div>
            <div class="w-50">
              <span class="layer-name">Include Animal Counts</span>
              <div class="toggle-checkbox toggle-sm toggle-success mr-2 d-flex">
                <input
                  type="checkbox"
                  v-model="includeAnimalCounts"
                  id="includeAnimalCounts"
                  name="includeAnimalCounts"
                  class="checked mx-3"
                />
                <label for="includeAnimalCounts" class="form-check-label" />
              </div>
            </div>
          </div>

          <div v-if="!isRanchForce" class="full-width">
            <span class="layer-name">Crop Indicators</span>
            <div class="toggle-checkbox toggle-sm toggle-success mr-2 d-flex">
              <input
                type="checkbox"
                v-model="showCropIcons"
                id="showCropIcons"
                name="showCropIcons"
                class="checked mx-3"
              />
              <label for="showCropIcons" class="form-check-label" />
              <v-slider class="slider my-0 py-0" :hide-details="true" v-model="cropIconOpacity" />
            </div>
          </div>
        </div>

        <div class="layer-header-container">
          <span class="layer-header">
            <strong>
              ARVA
              <sup>PY</sup> Layers
            </strong>
          </span>
        </div>

        <div class="layer-wrapper" v-for="layer in arvaLayers" :key="layer.id">
          <template v-if="layerNames[layer.id] !== 'Profit'">
            <span class="layer-name">{{ layerNames[layer.id] }}</span>
            <ActiveLayerToggle :id="layer.id" />
          </template>
        </div>

        <div class="layer-header-container" v-if="arvaLayers.length > 0">
          <span class="layer-header">
            <strong>AGT Layers</strong>
          </span>
        </div>

        <div
          class="d-flex justify-content-between"
          v-if="arvaLayers.length > 0"
        >
          <div v-for="cropId in [27]" :key="cropId">
            <div class="full-width">
              <span class="layer-name">Comprehensive AGT</span>
              <div class="toggle-checkbox toggle-sm toggle-success mr-2 d-flex">
                <input
                  type="checkbox"
                  class="checked mx-3"
                  v-model="agtCrops"
                  :id="`agtCrops${cropId}`"
                  :name="`agtCrops${cropId}`"
                  :value="cropId"
                />
                <label :for="`agtCrops${cropId}`" class="form-check-label" />
              </div>
            </div>
          </div>
        </div>
        <OpacitySlider
          class="agt-slider"
          :id="agtLayerId"
          v-if="arvaLayers.length > 0"
        />

        <div class="layer-header-container">
          <span class="layer-header">
            <strong>Data Layers</strong>
          </span>
        </div>

        <div
          class="layer-wrapper"
          v-for="layer in nonArvaLayers"
          :key="layer.id"
        >
          <span class="layer-name">{{ layerNames[layer.id] }}</span>
          <ActiveLayerToggle :id="layer.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { mapState, mapGetters, mapMutations } from "vuex"

import { MAP_LAYER, MAP_LAYER_NAME, IS_RANCHFORCE } from "@/constants"
import { Map, Filter } from "@/store/modules"

import ActiveLayerToggle from "@/components/misc/ActiveLayerToggle"
import OpacitySlider from "@/components/misc/OpacitySlider"

export default {
  name: "LayerController",
  data() {
    return {
      mouseOverSidePanel: false,
      layerNames: MAP_LAYER_NAME,
      agtLayerId: MAP_LAYER.AGT,
      isRanchForce: IS_RANCHFORCE,
    }
  },
  components: {
    ActiveLayerToggle,
    OpacitySlider,
  },
  methods: {
    ...mapMutations({
      updateAgtCrops: Map.Mutations.updateAgtCrops,
    }),

    debounceMouseLeave: _.debounce(function() {
      this.sidePanelOpen = this.mouseOverSidePanel
    }, 300),
  },

  computed: {
    ...mapState({
      layers: state => state.Map.layers,
      orgAgtCrops: state => state.Map.orgAgtCrops,
      activeLayerIds: state => state.Map.activeLayerIds,
      includeDefaults: state => state.Map.includeDefaults,
      includeAnimalCounts: state => state.Map.includeAnimalCounts,
    }),

    ...mapGetters({
      arvaLayers: Map.Getters.getArvaLayers,
      nonArvaLayers: Map.Getters.getDataLayers,
      crops: Filter.Getters.getCrops,
    }),

    includeDefaults: {
      get() {
        return this.$store.getters[Map.Getters.getIncludeDefaults]
      },
      set(value) {
        this.$store.commit(Map.Mutations.updateIncludeDefaults, value)
      },
    },

    includeAnimalCounts: {
      get() {
        return this.$store.getters[Map.Getters.getIncludeAnimalCounts]
      },
      set(value) {
        this.$store.commit(Map.Mutations.updateIncludeAnimalCounts, value)
      },
    },

    agtCrops: {
      get() {
        return this.$store.getters[Map.Getters.getAgtCrops]
      },
      set(cropIds) {
        this.updateAgtCrops(cropIds)
      },
    },

    showCropIcons: {
      get() {
        return this.$store.getters[Map.Getters.getShowCropIcons]
      },
      set(value) {
        this.$store.commit(Map.Mutations.updateShowCropIcons, value)
      },
    },

    cropIconOpacity: {
      get() {
        return this.$store.getters[Map.Getters.getCropIconOpacity]
      },
      set(value) {
        this.$store.commit(Map.Mutations.updateCropIconOpacity, value)
      },
    },

    sidePanelOpen: {
      get() {
        return this.$store.state.Theme.sidePanelOpen
      },
      set(val) {
        this.$store.commit("Theme/setSidePanelOpen", val)
      },
    },
  },
}
</script>

<style scoped>
.fa-align-right {
  color: #888 !important;
}

.hamburger {
  margin-top: 10px;
}

.hamburger > div {
  margin: 5px 8px;
  width: 34px;
  height: 4px;
  background: #999;
  border-radius: 20px;
}

.layer-controller-toggle {
  top: 0;
  height: 100%;
  width: 50px;
  background: rgba(24, 24, 24, 0.5);
  color: rgb(200, 186, 175);
  position: absolute;
  right: 0px;
}

.layer-controller {
  top: 0;
  position: absolute;
  right: 0;
  background: rgba(24, 24, 24, 0.5);
  color: rgb(200, 186, 175);
  border-radius: 2px;
  width: 24em;
  height: 100%;
  overflow: scroll;
}

.layer-controller-toggle a i {
  color: rgb(200, 186, 175);
}

.layer-header-container {
  margin-top: 25px;
  border-bottom: 1px white solid;
}

.layer-header {
  font-size: 16px;
  color: #ffffff;
}

.layer-name {
  font-size: 12px;
  color: #ffffff;
}

.layer-wrapper {
  margin-top: 7px;
}

.crop-icon {
  height: 30px;
  width: 30px;
}

.crop-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.crop-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 33%;
}

.full-width {
  width: 100%;
}

.slider {
  flex: 1;
  margin-left: 20px;
}

.agt-slider {
  margin-top: 7px;
}
</style>
